import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';

// Get tax table number
export const getTaxTableNumbers = createAsyncThunk(THUNKS.GET_TAX_TABLE_NUMBERS, async (_, thunkApi) => {
  try {
    const data = await api.private.get<{ data: number[] }>(Endpoints.SALARY_TAX_TABLE_NUMBERS).then(res => res.data.data);
    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
