export enum PaymentsTabNamesEnum {
  REIMBURSEMENTS = 'reimbursements',
  ADVANCE = 'advance',
  ADVANCE_MONTHLY = 'advance-monthly',
}

export enum PaymentsSearchParamsEnum {
  TAB = 'tab',
  PAGE = 'page',
  LIMIT = 'limit',
  SELECTED_MONTH = 'selectedMonth',
}
