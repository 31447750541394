export default {
  forgotPassword: {
    title: 'Glömt lösenord',
    subTitle: 'Vi kommer att skicka ett e-postmeddelande för att återställa ditt lösenord',
    email: 'E-post',
    email_placeholder: 'Skriv in e-epostadress',
    sendButton: 'Skicka',
  },
  resetPassword: {
    title: 'Återställ lösenord',
    subTitle: 'Skapa ett nytt lösenord för ditt konto',
    password: 'Lösenord',
    password_placeholder: 'Skriv in lösenord',
    confirmPassword: 'Bekräfta lösenord',
    mismatchError: 'De två lösenorden som du angav matchar inte!',
    sendButton: 'Återställ',
  },
};
