import { Dispatch, FC, SetStateAction } from 'react';

import { Form, UploadFile } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { DraggerProps } from 'antd/lib/upload';

import { InboxOutlined } from '@ant-design/icons';
import { TFunction } from 'i18next';

import { IFiles } from '../types';
import { StyledDragger } from './styled';

type IProps = DraggerProps & {
  files: IFiles;
  name: string;
  setFiles: Dispatch<SetStateAction<IFiles>>;
  t: TFunction;
};

const uploadRules = [
  {
    required: true,
  },
  {
    validator(_: RuleObject, value: UploadFile[]) {
      if (value.length) return Promise.resolve();
      return Promise.reject();
    },
  },
];

const UploadStep: FC<IProps> = ({ files, setFiles, t, name, ...props }) => (
  <Form.Item name={name} rules={uploadRules}>
    <StyledDragger {...props} beforeUpload={() => false}>
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text'>{t('click') as string}</p>
      <p className='ant-upload-hint'>{t('support') as string}</p>
    </StyledDragger>
  </Form.Item>
);

export default UploadStep;
