import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { IAddCarRequest } from 'modules/cars/types';

// Add new car
export const addCar = createAsyncThunk(THUNKS.ADD_CAR, async ({ carData, photos, isAdmin }: IAddCarRequest, thunkApi) => {
  try {
    if (!isAdmin) delete carData.managerId;

    const formData = new FormData();
    formData.append('data', JSON.stringify(carData));
    photos.file1.forEach(file => formData.append('file1', file.originFileObj as Blob));
    photos.file2.forEach(file => formData.append('file2', file.originFileObj as Blob));
    photos.file3.forEach(file => formData.append('file3', file.originFileObj as Blob));

    await api.private.post(isAdmin ? Endpoints.CAR : Endpoints.CAR_REQUEST, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
