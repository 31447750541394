import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import i18n from 'locales';
import { THUNKS } from 'modules/auth/enums';
import { IAuthUser } from 'modules/auth/types';

export const updateLanguage = createAsyncThunk(THUNKS.UPDATE_LANGUAGE, async (lang: string, thunkApi) => {
  try {
    const data = await api.private.post<{ data: IAuthUser }>(`${Endpoints.UPDATE_LANGUAGE}`, { lang }).then(res => res.data.data);
    i18n.changeLanguage(lang);
    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
