import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { approveCarRequestReducer } from './approveCarRequest';
import { approveDriverRequestReducer } from './approveDriverRequest';
import { approveManagerRequestReducer } from './approveManagerRequest';
import { clearSelectedRequestReducer } from './clearSelectedRequest';
import { createAndSendAgreementReducer } from './createAndSendAgreement';
import { declineCarRequestReducer } from './declineCarRequest';
import { declineDriverRequestReducer } from './declineDriverRequest';
import { declineManagerRequestReducer } from './declineManagerRequest';
import { downloadAgreementReducer } from './downloadAgreement';
import { editCarRequestReducer } from './editCarRequest';
import { editDriverRequestReducer } from './editDriverRequest';
import { editManagerRequestReducer } from './editManagerRequest';
import { getCarRequestReducer } from './getCarRequest';
import { getDriverRequestReducer } from './getDriverRequest';
import { getHistoryRequestReducer } from './getHistoryRequest';
import { getManagerRequestReducer } from './getManagerRequest';
import { getRequestByIdReducer } from './getRequestById';
import { setSelectedCarForAgreementRequestReducer } from './setSelectedCarForAgreementRequest';

export const requestsReducers = {
  ...clearSelectedRequestReducer,
};

const extraReducers: ModuleExtraReducer<IRequestsState>[] = [
  approveCarRequestReducer,
  approveDriverRequestReducer,
  approveManagerRequestReducer,
  declineCarRequestReducer,
  declineDriverRequestReducer,
  declineManagerRequestReducer,
  editManagerRequestReducer,
  editDriverRequestReducer,
  editCarRequestReducer,
  getCarRequestReducer,
  getDriverRequestReducer,
  getHistoryRequestReducer,
  getManagerRequestReducer,
  getRequestByIdReducer,
  setSelectedCarForAgreementRequestReducer,
  createAndSendAgreementReducer,
  downloadAgreementReducer,
];

export const buildRequestsReducer: ModuleExtraReducerBuilder<IRequestsState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};
