import { Input } from 'antd';

import { MoreOutlined } from '@ant-design/icons';
import DatePicker from 'modules/common/components/DatePicker';
import styled from 'styled-components';

export const StyledSearch = styled(Input)`
  width: 320px;
  margin-right: 16px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  margin-right: 16px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
`;

export const MoreButton = styled(MoreOutlined)`
  padding: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const PendingName = styled.p`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;
