import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/analytics/enums';
import { IGetReport, IReport } from 'modules/reports/types';
import { convertIntoISODate } from 'utils/index';

// Get all cars
export const getReport = createAsyncThunk(THUNKS.GET_REPORT, async (date: IGetReport, thunkApi) => {
  try {
    const data = await api.private
      .get<{ data: IReport }>(`${Endpoints.REPORT_DASHBOARD}?start=${convertIntoISODate(date.start as Date)}&end=${convertIntoISODate(date.end as Date)}`)
      .then(res => res.data.data);

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
