import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Col, Form, Row } from 'antd';

import { Entities } from 'constants/global';
import Loader from 'modules/common/components/Loader';
import { approveDriverRequest, clearSelectedRequest, downloadAgreement, editDriverRequest, getRequestById } from 'modules/requests';
import { Comment, Content, Details, Footer, Header, Questions, Title } from 'modules/requests/pages/Details/styled';
import { IDriverRequest } from 'modules/requests/types';

import DeclineModal from '../DeclineModal';
import { LinkCarModal } from '../LinkCarModal';
import Edit from './components/Edit';
import Info from './components/Info';

const DriverRequestsDetails: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver' });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [SearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IDriverRequest };
  const id = useMemo(() => SearchParams.get('id'), []);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [driverData, setDriverData] = useState<IDriverRequest>(selectedRequest);
  const [isLinkCarModalOpen, setIsLinkCarModalOpen] = useState<boolean>(false);

  const closeLinkCarModal = () => {
    setIsLinkCarModalOpen(false);
  };

  // Set driverData from selectedRequest
  useEffect(() => {
    if (selectedRequest) setDriverData(selectedRequest);
  }, [selectedRequest]);

  // Get selected request
  useEffect(() => {
    if (id) dispatch(getRequestById({ id, entity: Entities.DRIVER }));

    return () => {
      dispatch(clearSelectedRequest());
    };
  }, []);

  const approveRequestHandler = async () => {
    await dispatch(approveDriverRequest(id));

    // Getting current state from store
    const { error } = store.getState().requests;
    if (!error) navigate('/requests');
  };

  const editInfoHandler = async () => {
    try {
      await form.validateFields();
      await dispatch(editDriverRequest(driverData));
      setIsEdit(false);

      // Todo: validation error crashes app in the runtime without try catch
      // eslint-disable-next-line
    } catch (e) {}
  };

  const renderHandler = () => {
    if (isEdit) return <Edit driverData={driverData} setDriverData={setDriverData} form={form} />;
    return <Info />;
  };

  const downloadAgreementHandler = () => {
    const employeeAgreementId = Number(driverData.agreement?.id);
    dispatch(downloadAgreement({ employeeAgreementId }));
  };

  if (!selectedRequest) return <Loader size='large' />;

  const isApproveDisabled =
    !selectedRequest.agreement?.vilotidbokNumber ||
    !selectedRequest.agreement.acrobatAgreement?.driverSignedAt ||
    !selectedRequest.agreement.acrobatAgreement.roadbirdSignedAt;

  return (
    <>
      <Details>
        <Header>
          <Title>
            {t('driverWord')} “{selectedRequest?.userInfo.firstName} {selectedRequest?.userInfo.lastName}” {t('detailsWord')}
          </Title>
        </Header>

        <Content>{renderHandler()}</Content>

        {!isEdit && selectedRequest.requestComment && (
          <Content>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Title>{t('requestComment')}</Title>
              </Col>
              <Col span={16}>{selectedRequest.requestComment}</Col>
            </Row>
          </Content>
        )}

        {selectedRequest?.comment && (
          <Questions>
            <Title>{t('info')}</Title>
            <Comment>{selectedRequest?.comment}</Comment>
          </Questions>
        )}

        <Footer>
          {isEdit ? (
            <>
              <Button onClick={() => setIsEdit(false)}>{t('cancel')}</Button>
              <Button type='primary' onClick={editInfoHandler}>
                {t('save')}
              </Button>
            </>
          ) : (
            <>
              {!selectedRequest.agreement?.vilotidbokNumber && (
                <Button
                  type='primary'
                  onClick={() => {
                    setIsLinkCarModalOpen(true);
                  }}
                >
                  {t('sendAgreement')}
                </Button>
              )}
              {selectedRequest.agreement?.vilotidbokNumber && <Button onClick={downloadAgreementHandler}>{t('downloadAgreement')}</Button>}

              <Button onClick={() => setIsEdit(true)}>{t('editData')}</Button>
              <Button danger onClick={() => setIsDeclineModalOpen(true)}>
                {t('decline')}
              </Button>
              <Button type='primary' onClick={approveRequestHandler} disabled={isApproveDisabled}>
                {t('approve')}
              </Button>
            </>
          )}
        </Footer>
      </Details>

      <LinkCarModal isModalOpen={isLinkCarModalOpen} closeModal={closeLinkCarModal} requestId={selectedRequest.userInfo.id} />
      <DeclineModal entity={Entities.DRIVER} isModalOpen={isDeclineModalOpen} setIsModalOpen={setIsDeclineModalOpen} />
    </>
  );
};

export default DriverRequestsDetails;
