import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Modal, Radio, Slider, Tooltip } from 'antd';

import { CarConstants } from 'constants/car';
import { calculateDriverEarning } from 'modules/cars';
import { ICreateCarData } from 'modules/cars/types';

import { Content, InfoIcon, Note, OptionsWrapper, Result, ResultRecomend, ResultWrapper } from './styled';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  carData: ICreateCarData;
  setCarData: Dispatch<SetStateAction<ICreateCarData>>;
}

const fieldsHandler = (shift: number | null, driverPercent: number) => [
  {
    name: 'shiftSetting',
    value: shift,
  },
  {
    name: 'driverProvicion',
    value: driverPercent,
  },
];

const EarningCalculator: FC<IProps> = ({ carData, setCarData, isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.calculator' });
  const dispatch = useAppDispatch();
  const { options, isLoading } = useAppSelector(state => state.cars);
  const timerRef = useRef<NodeJS.Timeout>();
  const [driverPercent, setDriverPercent] = useState<number>(carData.driverPercent);
  const [shift, setShift] = useState<number | null>(carData.shiftPlan! + 1);
  const [calculationResult, setCalculationResult] = useState<number>(0);

  // Calculation with debounce
  const calculateHandler = async (percent: number, shiftType?: number) => {
    clearTimeout(timerRef.current);

    if (shiftType) setShift(shiftType);
    else setDriverPercent(percent);

    timerRef.current = setTimeout(async () => {
      await dispatch(calculateDriverEarning({ driverPercent: percent, shiftType: shiftType || carData.shiftPlan! + 1 })).then(data =>
        setCalculationResult(Math.round(data.payload as number))
      );
    }, 300);
  };

  // Initial calculation
  useEffect(() => {
    if (carData.driverPercent) calculateHandler(carData.driverPercent);
  }, []);

  const closeHandler = () => {
    setIsOpen(false);
    setCalculationResult(0);
  };

  const confirmHandler = () => {
    setCarData(prev => ({ ...prev, driverPercent, shiftPlan: shift! - 1, shiftSplitTimes: shift! - 1 }));
    setIsOpen(false);
  };

  const recomendMessageHandler = () => {
    if (driverPercent < 25) {
      return t('lowDriverPercent');
    } else if (driverPercent > 60) {
      return t('highDriverPercent');
    } else {
      return t('recomendShifts');
    }
  };

  return (
    <Modal width={770} title={t('title')} open={isOpen} destroyOnClose onCancel={closeHandler} footer={false}>
      <Note>{t('note')}</Note>
      <Content>
        <OptionsWrapper>
          <Form fields={fieldsHandler(shift, driverPercent)} layout='vertical' size='large'>
            <Form.Item name='shiftSetting' label={t('shiftSetting')}>
              <Radio.Group size='large' onChange={({ target }) => calculateHandler(driverPercent, target.value)}>
                {options.shiftPlans.map(({ id }, index) => (
                  <Radio.Button key={id} value={index + 1}>
                    {`${index + 1} ${t('shift')}`}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item name='driverProvicion' label={t('driverProvicion')}>
              <Slider
                min={CarConstants.MIN_PERCENTAGE}
                max={CarConstants.MAX_PERCENTAGE}
                tipFormatter={val => `${val}%`}
                marks={{
                  45: (
                    <Tooltip placement='bottomLeft' title={t('recomendPercent')}>
                      45% <InfoIcon />
                    </Tooltip>
                  ),
                }}
                onChange={calculateHandler}
              />
            </Form.Item>
          </Form>
          <Button type='primary' size='large' loading={isLoading} onClick={confirmHandler} disabled={driverPercent < 25 || driverPercent > 60}>
            {t('confirm')}
          </Button>
        </OptionsWrapper>
        <ResultWrapper>
          <Result>
            {calculationResult} {t('sek')}
          </Result>
          <ResultRecomend>{recomendMessageHandler()}</ResultRecomend>
        </ResultWrapper>
      </Content>
    </Modal>
  );
};

export default EarningCalculator;
