import { createSlice } from '@reduxjs/toolkit';

import { IRequestsState } from '../types';
import { buildRequestsReducer, requestsReducers } from './reducers';

const initialState: IRequestsState = {
  requests: {
    driverRequests: [],
    managerRequests: [],
    carRequests: [],
    history: [],
  },
  total: {
    drivers: 0,
    managers: 0,
    cars: 0,
    history: 0,
  },
  selectedRequest: null,
  selectedCarForAgreementRequest: null,
  isLoading: false,
  error: null,
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: requestsReducers,
  extraReducers: builder => buildRequestsReducer(builder),
});

export const { clearSelectedRequest } = requestsSlice.actions;

export default requestsSlice.reducer;
