import { config } from 'config';
import { format } from 'date-fns';

import { DateWrapper, Time } from '../../styled';
import { IUberTableItem } from '../../types';
import { IFieldWithCount, ITableColumn, ITableField } from '../types';

export const UberFieldsWithCount: IFieldWithCount[] = [
  {
    key: 'deletedUberRecordsNum',
    titleLocale: 'deletedUberRecords',
  },
  {
    key: 'insertedUberRecordsNum',
    titleLocale: 'insertedUberRecords',
  },
  {
    key: 'deletedUberTripsNum',
    titleLocale: 'deletedUberTrips',
  },
  {
    key: 'insertedUberTripsNum',
    titleLocale: 'insertedUberTrips',
  },
];

const UberTableColumns: ITableColumn[] = [
  {
    name: 'driverFullName',
    titleLocale: 'driverFullName',
    renderFunction: (_, data: IUberTableItem) => data.driverFullName,
  },
  {
    name: 'paymentTime',
    titleLocale: 'paymentTime',
    renderFunction: (_, data: IUberTableItem) => {
      const date = format(new Date(data.paymentTime), config.DATE_FORMAT);
      const time = format(new Date(data.paymentTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'travelPrice',
    titleLocale: 'travelPrice',
    renderFunction: (_, data: IUberTableItem) => data.travelPrice.toString(),
  },
  {
    name: 'paidByUber',
    titleLocale: 'paidByUber',
    renderFunction: (_, data: IUberTableItem) => data.paidByUber.toString(),
  },
  {
    name: 'serviceFee',
    titleLocale: 'serviceFee',
    renderFunction: (_, data: IUberTableItem) => data.serviceFee.toString(),
  },
  {
    name: 'waitingTime',
    titleLocale: 'waitingTime',
    renderFunction: (_, data: IUberTableItem) => data.waitingTime.toString(),
  },
  {
    name: 'nonStopFee',
    titleLocale: 'nonStopFee',
    renderFunction: (_, data: IUberTableItem) => data.nonStopFee.toString(),
  },
  // not need it now
  // {
  //   name: 'cancellationFee',
  //   titleLocale: 'cancellationFee',
  //   renderFunction: (_, data: IUberTableItem) => data.cancellationFee.toString(),
  // },
  // {
  //   name: 'topTraffic',
  //   titleLocale: 'topTraffic',
  //   renderFunction: (_, data: IUberTableItem) => data.topTraffic.toString(),
  // },
  // {
  //   name: 'airportFee',
  //   titleLocale: 'airportFee',
  //   renderFunction: (_, data: IUberTableItem) => data.airportFee.toString(),
  // },
  // {
  //   name: 'petsFee',
  //   titleLocale: 'petsFee',
  //   renderFunction: (_, data: IUberTableItem) => data.petsFee.toString(),
  // },
  // {
  //   name: 'incentive',
  //   titleLocale: 'incentive',
  //   renderFunction: (_, data: IUberTableItem) => data.incentive.toString(),
  // },
  {
    name: 'tips',
    titleLocale: 'tips',
    renderFunction: (_, data: IUberTableItem) => data.tips.toString(),
  },
];

export const UberTables: ITableField[] = [
  {
    key: 'noDriverUberTrips',
    titleLocale: 'noDriverUberTrips',
    columnsPreset: UberTableColumns,
  },
  {
    key: 'moreThanOneDriverUberTrips',
    titleLocale: 'moreThanOneDriverUberTrips',
    columnsPreset: UberTableColumns,
  },
  {
    key: 'noShiftUberTrips',
    titleLocale: 'noShiftUberTrips',
    columnsPreset: UberTableColumns,
  },
];
