import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { changeUserStatus } from '../thunks';

export const changeUserStatusReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Change user status

  builder
    .addCase(changeUserStatus.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(changeUserStatus.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(changeUserStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
