import { FC, useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/index';

import { Table, TableProps } from 'antd';

import { config } from 'config';
import { endOfMonth, startOfMonth } from 'date-fns';
import { getReimbursements, getReimbursementTypes } from 'modules/reimbursements';
import { useManagerReimbursementsTable } from 'modules/reimbursements/hooks';
import { IReimbursement } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSearchParams } from 'utils/searchParams';

// TODO: move to related module

const ManagerReimbursements: FC = () => {
  const [hoveredId, setHoveredId] = useState<number>();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const dispatch = useAppDispatch();
  const { isLoading, reimbursements, total } = useAppSelector(state => state.reimbursements);

  const page = Number(getSearchParam('page')) || 1;
  const pageSize = Number(getSearchParam('pageSize')) || config.DEFAULT_PAGE_SIZE;
  const driverId = getSearchParam('driverId');
  const status = getSearchParam('status');
  const type = getSearchParam('type');
  const selectedDateRange = getSearchParam('dateRange');
  const selectedMonthEndDate = getSearchParam('endDate');

  const dateRange: RangeValue<string> = selectedDateRange && JSON.parse(selectedDateRange);

  const selectedMonth: [Date, Date] = [
    startOfMonth(selectedMonthEndDate ? new Date(selectedMonthEndDate) : new Date()),
    endOfMonth(selectedMonthEndDate ? new Date(selectedMonthEndDate) : new Date()),
  ];

  const driverFilterValue: number[] = driverId ? JSON.parse(driverId) : [];
  const statusFilterValue: string[] = status ? JSON.parse(status) : [];
  const typeFilterValue: string[] = type ? JSON.parse(type) : [];

  const fetchReimbursements = useCallback(() => {
    dispatch(
      getReimbursements({
        start: selectedMonth[0],
        end: selectedMonth[1],
        page: page - 1,
        pageSize,
        periodStart: dateRange?.[0] || undefined,
        periodEnd: dateRange?.[1] || undefined,
        driverId: driverFilterValue,
        status: statusFilterValue,
        reimbType: typeFilterValue,
      })
    );
  }, [selectedDateRange, page, selectedMonthEndDate, pageSize, driverId, status, type]);

  useEffect(() => {
    dispatch(getReimbursementTypes());
  }, []);

  useEffect(() => {
    fetchReimbursements();
  }, [fetchReimbursements]);

  const [tableData, managerColumns] = useManagerReimbursementsTable({
    reimbursements,
    hoveredId,
    dateRangeFilterValue: dateRange,
    driverFilterValue,
    statusFilterValue,
    typeFilterValue,
    fetchReimbursements,
  });

  const onTableChange: TableProps<IReimbursement>['onChange'] = ({ current, pageSize }, filters) => {
    addSearchParams({
      driverId: filters.driver ? JSON.stringify(filters.driver) : '',
      status: filters.status ? JSON.stringify(filters.status) : '',
      payoutStatus: filters.swishStatus ? JSON.stringify(filters.swishStatus) : '',
      type: filters.type ? JSON.stringify(filters.type) : '',
      pageSize: pageSize?.toString() || config.DEFAULT_PAGE_SIZE.toString(),
      page: current?.toString() || '1',
    });
  };

  return (
    <Table
      scroll={{ x: 2000 }}
      dataSource={tableData}
      loading={isLoading}
      pagination={{ showSizeChanger: true, current: page, pageSize, total }}
      columns={managerColumns}
      onChange={onTableChange}
      onRow={record => ({
        onMouseEnter: () => setHoveredId(record.id),
        onMouseLeave: () => setHoveredId(undefined),
      })}
    />
  );
};

export default ManagerReimbursements;
