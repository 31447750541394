import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import { localeByValue } from 'modules/cars/locales';
import { ICarTableData } from 'modules/cars/types';
import { ManagerNameType } from 'modules/manager/types';

import { MoreButton, PendingName } from '../../pages/List/styled';

interface IProps {
  moreDropdown: (record: ICarTableData) => JSX.Element;
  isAdmin: boolean;
  managers: ManagerNameType[];
  selectedManagerId: number[] | undefined;
}

export const generateCarTableColumns = (props: IProps): TableColumnsType<ICarTableData> => {
  const { moreDropdown, isAdmin, managers, selectedManagerId } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  const managerFilterOptions: ColumnFilterItem[] = managers.map(item => ({ key: item[0], text: item[1], value: item[0] }));
  const managerFilterValue = selectedManagerId || [];

  if (isAdmin) {
    return [
      {
        title: t('plate'),
        dataIndex: 'plate',
        key: 'plate',
        render: (value, record) =>
          record.status === Statuses.PENDING ? (
            <PendingName>
              {value} <ClockCircleOutlined />
            </PendingName>
          ) : (
            value
          ),
      },
      {
        title: t('address'),
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: t('manager'),
        dataIndex: 'manager',
        key: 'manager',
        width: 165,
        filteredValue: managerFilterValue,
        filters: managerFilterOptions,
        filterSearch: true,
      },
      {
        title: t('model'),
        dataIndex: 'model',
        key: 'model',
      },
      {
        title: t('year'),
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: t('shiftPlan'),
        dataIndex: 'plan',
        key: 'plan',
        render: (plan: keyof typeof localeByValue) => t(localeByValue[plan]),
      },
      {
        title: t('splitTime'),
        dataIndex: 'splitTime',
        key: 'splitTime',
        render: (splitTime: keyof typeof localeByValue) => t(localeByValue[splitTime]),
      },
      {
        title: t('maxMil'),
        dataIndex: 'maxMil',
        key: 'maxMil',
      },
      {
        title: t('driverPercent'),
        dataIndex: 'persent',
        key: 'persent',
      },
      {
        key: 'actions',
        render: (_, record) => (
          <Dropdown overlay={moreDropdown(record)} trigger={['click']} placement='bottom'>
            <MoreButton />
          </Dropdown>
        ),
      },
    ];
  }

  return [
    {
      title: t('plate'),
      dataIndex: 'plate',
      key: 'plate',
      render: (value, record) =>
        record.status === Statuses.PENDING ? (
          <PendingName>
            {value} <ClockCircleOutlined />
          </PendingName>
        ) : (
          value
        ),
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('insurance'),
      dataIndex: 'insurance',
      key: 'insurance',
    },
    {
      title: t('make'),
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: t('model'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('shiftPlan'),
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: t('splitTime'),
      dataIndex: 'splitTime',
      key: 'splitTime',
    },
    {
      title: t('maxMil'),
      dataIndex: 'maxMil',
      key: 'maxMil',
    },
    {
      title: t('driverPercent'),
      dataIndex: 'persent',
      key: 'persent',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={moreDropdown(record)} trigger={['click']} placement='bottom'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
