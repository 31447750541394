import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const AdditionalInfo = styled.span`
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.gray};
`;

export const ArrowUpIcon = styled(CaretUpOutlined)`
  color: ${({ theme }) => theme.green};
  font-size: 24px;
`;

export const ArrowDownIcon = styled(CaretDownOutlined)`
  color: ${({ theme }) => theme.red};
  font-size: 24px;
`;
