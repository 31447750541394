import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { getSalaryAdjustments } from '../thunks';

export const getSalaryAdjustmentsReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Get salary adjustments

  builder
    .addCase(getSalaryAdjustments.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(getSalaryAdjustments.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getSalaryAdjustments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
