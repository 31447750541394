import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { config } from 'config';
import Endpoints from 'constants/endpoints';
import { RequestStatuses } from 'constants/requests';
import { THUNKS } from 'modules/requests/enums';
import { ICarRequest, IGetRequestsRequest, ITotal } from 'modules/requests/types';

interface IGetCarRequestsResponse {
  data: {
    items: ICarRequest[];
    total: ITotal;
  };
}

// Get car requests
export const getCarRequest = createAsyncThunk(
  THUNKS.GET_CAR_REQUESTS,
  async ({ status = RequestStatuses.NEW, page, searchText }: IGetRequestsRequest, thunkApi) => {
    try {
      return api.private
        .get<IGetCarRequestsResponse>(Endpoints.REQUEST_CAR_NEW, {
          params: { status, page, pageSize: config.DEFAULT_PAGE_SIZE, name: searchText },
        })
        .then(res => res.data.data);
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
