export const Globals = {
  SWEDEN_COUNTRY_CODE: '+46',
  SWEDEN_MAX_NUMBER_LENGTH: 9,
  SWEDEN_MIN_NUMBER_LENGTH: 7,
};

export enum Roles {
  DRIVER = 'driver',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export enum Entities {
  DRIVER = 'driver',
  MANAGER = 'manager',
  CAR = 'car',
  ADMIN = 'admin',
}

export enum Statuses {
  ALL = 'all',
  PENDING = 'pending',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum ChangelogTypes {
  ALL = 'all',
  USER_MANAGEMENT = 'User management',
  PERFOMANCE = 'Performance',
}

export enum Locales {
  EN = 'en',
  SV = 'sv',
}

export enum ReportTypes {
  TAXIMETER = 'taximeter',
  SLING = 'sling',
  UBER = 'uber',
  BOLT = 'bolt',
}

export enum WeeklyReportsStatuses {
  GOOD = 'good',
  BAD = 'bad',
}

export enum ExternalLinks {
  PRIVACY_POLICY = 'https://publicstoragerb.s3.eu-north-1.amazonaws.com/Driver_Privacy_Policy_SWEDEN.txt',
}

export enum SalaryStatus {
  PAID = 'paid',
  NOT_PAID = 'not paid',
  SUBMITTED = 'submitted',
}

export const PasswordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,]).{8,}$/;

export enum SwishStatusEnum {
  PLANNED = 'planned',
  CREATED = 'created',
  INITIATED = 'initiated',
  DEBITED = 'debited',
  ERROR = 'error',
  PAID = 'paid',
}

export enum ReimbursementStatusEnum {
  PENDING = 'not-paid',
  RECEIVED = 'paid',
  DECLINED = 'declined',
}

export enum AdvancedStatusesEnum {
  PENDING = 'pending',
  ERROR = 'error',
  PAID = 'paid',
  DEBITED = 'debited',
}
