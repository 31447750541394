import { TableColumnsType } from 'antd';

import { Roles } from 'constants/global';
import { IDriver, IDriverTableData } from 'modules/driver/types';
import { IManager, IManagerTableData, ManagerNameType } from 'modules/manager/types';
import { IUsers } from 'modules/user/types';

import { generateDriverColumns } from './generateDriverColumns';
import { generateManagerColumns } from './generateManagerColumns';
import { mapDrivers } from './mapDrivers';
import { mapManagers } from './mapManagers';

type ReturnType = [IDriverTableData[] | IManagerTableData[], TableColumnsType<IDriverTableData | IManagerTableData>];

type MoreDropdownType = (user: IDriverTableData | IManagerTableData) => JSX.Element;

interface IProps {
  data: IUsers;
  role: Roles;
  moreDropdown: MoreDropdownType;
  isAdmin: boolean;
  managers: ManagerNameType[];
  selectedManagerId: number | undefined;
}

export const useUsersListTable = (props: IProps): ReturnType => {
  const { data, role, moreDropdown, isAdmin, managers, selectedManagerId } = props;
  const isDriver = role === Roles.DRIVER;
  const mappedData = isDriver ? mapDrivers(data as IDriver[]) : mapManagers(data as IManager[]);
  const tableColumns = isDriver ? generateDriverColumns({ moreDropdown, isAdmin, managers, selectedManagerId }) : generateManagerColumns({ moreDropdown });

  return [mappedData, tableColumns as TableColumnsType<IDriverTableData | IManagerTableData>];
};
