import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NodeIndexOutlined, TeamOutlined } from '@ant-design/icons';
import english from 'assets/english.svg';
import sveden from 'assets/sveden.svg';
import { Locales, Roles } from 'constants/global';
import i18n from 'locales';
import Container from 'modules/common/components/Container';

import { Card, Cards, CardSubTitle, CardTitle, Locale,SubTitle, Title } from './styled';

const RoleSelect: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.roleSelect' });

  return (
    <Container justifyCenter>
      <Title>{t('title')}</Title>
      <SubTitle>{t('subTitle')}</SubTitle>
      <Cards>
        <Card onClick={() => navigate(`/sign-up/${Roles.DRIVER}`)}>
          <NodeIndexOutlined />
          <CardTitle>{t('driver.title')}</CardTitle>
          <CardSubTitle>{t('driver.subTitle')}</CardSubTitle>
        </Card>
        <Card onClick={() => navigate(`/sign-up/${Roles.MANAGER}`)}>
          <TeamOutlined />
          <CardTitle>{t('manager.title')}</CardTitle>
          <CardSubTitle>{t('manager.subTitle')}</CardSubTitle>
        </Card>
      </Cards>
      <div>
        <Locale src={english} alt='en' onClick={() => i18n.changeLanguage(Locales.EN)} />
        <Locale src={sveden} alt='sv' onClick={() => i18n.changeLanguage(Locales.SV)} />
      </div>
    </Container>
  );
};

export default RoleSelect;
