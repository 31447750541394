import { PayoutStatus } from 'modules/salaries/enums';
import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { payWeekly } from '../thunks';

export const payWeeklyReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Pay Weekly salary

  builder
    .addCase(payWeekly.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { errors } = payload;
      const hasErrors = !!errors.length;
      state.payoutModal.payoutResult = payload;
      state.payoutModal.payoutStatus = hasErrors ? PayoutStatus.PayedWithErrors : PayoutStatus.Payed;
    })
    .addCase(payWeekly.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(payWeekly.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as string;
    });
};
