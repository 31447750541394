import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReimbursementsState } from '../types';
import { buildReimbursementsReducer } from './reducers';

const initialState: IReimbursementsState = {
  photos: {},
  reimbursements: [],
  total: 0,
  isLoading: false,
  error: null,
  managers: null,
  drivers: null,
  dateRange: null,
  reimbursementTypes: null,
  payReimbursementResult: null,
  selectedReimbursements: [],
  advancePaymentStatuses: null,
  advanceListData: null,
  advanceMonthlyListData: null,
};

const reimbursementsSlice = createSlice({
  name: 'reimbursements',
  initialState,
  reducers: {
    setSelectedReimbursements: (state, action: PayloadAction<number[]>) => {
      state.selectedReimbursements = action.payload;
    },
  },
  extraReducers: builder => buildReimbursementsReducer(builder),
});

export const { setSelectedReimbursements } = reimbursementsSlice.actions;

export default reimbursementsSlice.reducer;
