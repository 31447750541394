import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Radio } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import LinkCarModal from 'modules/cars/components/CarLinkModal';
import DatePicker from 'modules/common/components/DatePicker';
import Loader from 'modules/common/components/Loader';
import Cars from 'modules/driver/components/Cars';
import Information from 'modules/driver/components/Information';
import Reports from 'modules/driver/components/Reports';
import Shifts from 'modules/driver/components/Shifts';
import { IDriver } from 'modules/driver/types';
import { downloadAgreement } from 'modules/requests/slice/thunks';
import { clearSelectedUser, editUser, getDriverById, getDriverShiftsById } from 'modules/user';
import ActivateUserModal from 'modules/user/components/ActivateUserModal';
import DisableUserModal from 'modules/user/components/DisableUserModal';
import useDebounce from 'utils/debounce';

import { Content, Details, Footer, Header, OptionsWrapper, StyledSearch, Title } from './styled';

// TODO: HIDDEN FOR v1.0
// import CreateNotificationModal from '../../components/CreateNotificationModal';

enum Tabs {
  INFORMATION = 'information',
  CARS = 'cars',
  SHIFTS = 'shifts',
  SALARY = 'salary',
  REPORTS = 'reports',
}

const DriverDetails: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const [SearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isAdmin } = useAppSelector(state => state.auth);
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IDriver };
  const id = useMemo(() => SearchParams.get('id'), []);
  const [tab, setTab] = useState<Tabs>(isAdmin ? Tabs.INFORMATION : Tabs.CARS);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [isLinkCarModalOpen, setIsLinkCarModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [driverData, setDriverData] = useState<IDriver>(selectedUser);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  // TODO: HIDDEN FOR v1.0
  // const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] = useState<boolean>(false);
  // TODO: HIDDEN FOR v1.0
  // const [dateRange, setDateRange] = useState<[Date, Date]>([startOfMonth(new Date()), endOfMonth(new Date())]);

  // Set driverData from selectedUser
  useEffect(() => {
    if (selectedUser) setDriverData(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (id) dispatch(getDriverById({ id })).then(() => dispatch(getDriverShiftsById({ userId: id })));

    return () => {
      dispatch(clearSelectedUser());
    };
  }, []);

  const changeTabHandler = (selected: Tabs) => {
    setTab(selected);
    setIsEdit(false);
  };

  const renderHandler = () => {
    switch (tab) {
      case Tabs.REPORTS:
        return <Reports dateRange={dateRange} />;
      case Tabs.INFORMATION:
        return <Information isEdit={isEdit} driverData={driverData} setDriverData={setDriverData} form={form} />;
      // TODO: HIDDEN FOR v1.0
      // case Tabs.SALARY:
      //   return <Salary dateRange={dateRange} />;
      case Tabs.CARS:
        return <Cars search={debouncedSearch} />;
      case Tabs.SHIFTS:
        return <Shifts />;

      default:
        break;
    }
  };

  const downloadAgreementHandler = () => {
    if (selectedUser.agreement?.id) {
      const employeeAgreementId = Number(selectedUser.agreement?.id);
      dispatch(downloadAgreement({ employeeAgreementId }));
    }
  };

  const editInfoHandler = async () => {
    // TODO: edit driver form validation error crashes the app in a runtime
    try {
      await form.validateFields();
      await dispatch(editUser({ data: driverData, isAdmin }));
      setIsEdit(false);
      // eslint-disable-next-line
    } catch (e) {}
  };

  if (!selectedUser) return <Loader size='large' />;

  return (
    <>
      <Details>
        <Header>
          <Title>
            {selectedUser.user.firstName} {selectedUser.user.lastName}
          </Title>

          <OptionsWrapper>
            <Radio.Group value={tab} size='large' onChange={({ target }) => changeTabHandler(target.value)}>
              {isAdmin && <Radio.Button value={Tabs.REPORTS}>{t('weeklyReports')}</Radio.Button>}
              {isAdmin && <Radio.Button value={Tabs.INFORMATION}>{t('contactInfo')}</Radio.Button>}
              {/* // TODO: HIDDEN FOR v1.0 */}
              {/* {isAdmin && <Radio.Button value={Tabs.SALARY}>{t('salary')}</Radio.Button>} */}
              <Radio.Button value={Tabs.CARS}>
                {t('cars')} ({selectedUser.cars.length})
              </Radio.Button>
              <Radio.Button value={Tabs.SHIFTS}>{t('shifts')}</Radio.Button>
            </Radio.Group>

            {tab === Tabs.CARS && (
              <StyledSearch size='large' prefix={<SearchOutlined />} placeholder={t('searchPlaceholder')} onChange={({ target }) => setSearch(target.value)} />
            )}
            {tab === Tabs.REPORTS && <DatePicker.RangePicker size='large' onChange={date => setDateRange(date as [Date, Date])} value={dateRange} />}
          </OptionsWrapper>
        </Header>

        <Content>{renderHandler()}</Content>

        {tab !== Tabs.SALARY && (
          <Footer>
            {isEdit ? (
              <>
                <Button onClick={() => setIsEdit(false)}>{t('cancel')}</Button>
                <Button type='primary' onClick={editInfoHandler}>
                  {t('save')}
                </Button>
              </>
            ) : selectedUser.status !== Statuses.DISABLED ? (
              <>
                {isAdmin && selectedUser.agreement?.vilotidbokNumber && <Button onClick={downloadAgreementHandler}>{t('downloadAgreement')}</Button>}
                {isAdmin && <Button onClick={() => setIsLinkCarModalOpen(true)}>{t('linkWithCars')}</Button>}
                {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
                {/* // TODO: HIDDEN FOR v1.0 */}
                {/* {isAdmin && <Button onClick={() => setIsCreateNotificationModalOpen(true)}>{t('createNotification')}</Button>} */}
                <Button type='primary' danger onClick={() => setIsDisableModalOpen(true)}>
                  {t('disable')}
                </Button>
              </>
            ) : (
              <>
                {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
                {isAdmin && (
                  <Button type='primary' onClick={() => setIsActivateModalOpen(true)}>
                    {t('activate')}
                  </Button>
                )}
              </>
            )}
          </Footer>
        )}
      </Details>
      <DisableUserModal isModalOpen={isDisableModalOpen} setIsModalOpen={setIsDisableModalOpen} statusFilter={selectedUser.status} />
      <ActivateUserModal statusFilter={selectedUser.status} isModalOpen={isActivateModalOpen} setIsModalOpen={setIsActivateModalOpen} />
      <LinkCarModal isOpen={isLinkCarModalOpen} setIsOpen={setIsLinkCarModalOpen} user={selectedUser} />
      {/* // TODO: HIDDEN FOR v1.0 */}
      {/* <CreateNotificationModal isModalOpen={isCreateNotificationModalOpen} setIsModalOpen={setIsCreateNotificationModalOpen} /> */}
    </>
  );
};

export default DriverDetails;
