import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { ICarWeeklyReportTableData } from 'modules/cars/types';

export const generateCarWeeklyReportsColumns = (): TableColumnsType<ICarWeeklyReportTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('availableShifts'),
      dataIndex: 'availableShifts',
      key: 'availableShifts',
    },
    {
      title: t('bookedShiftsPercent'),
      dataIndex: 'bookedShiftsPercent',
      key: 'bookedShiftsPercent',
    },
    {
      title: t('earnings'),
      dataIndex: 'earnings',
      key: 'earnings',
    },
    {
      title: t('driverKm'),
      dataIndex: 'driverKm',
      key: 'driverKm',
    },
    {
      title: t('provision'),
      dataIndex: 'provision',
      key: 'provision',
    },
  ];
};
