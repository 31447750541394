import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import { CarConstants } from 'constants/car';
import { Roles, Statuses } from 'constants/global';
import { AddCarSteps } from 'modules/cars/enums';
import { IAddCarStepsProps, ICreateCarData } from 'modules/cars/types';
import { getUsers } from 'modules/user';

import { StyledForm, StyledRow } from '../styled';
import { YearPicker } from './styled';

const fieldsHandler = (carData: ICreateCarData) => [
  {
    name: 'chooseFleetManager',
    value: carData.managerId,
  },
  {
    name: 'plate',
    value: carData.registrationPlate || null,
  },
  {
    name: 'make',
    value: carData.make || null,
  },
  {
    name: 'model',
    value: carData.model || null,
  },
  {
    name: 'year',
    value: carData.year || null,
  },
];

const FirstStep: FC<IAddCarStepsProps> = ({ setStep, carData, setCarData }) => {
  const { t } = useTranslation('translation', { keyPrefix: `admin.cars.steps.${AddCarSteps.FIRST}` });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { users: managers } = useAppSelector(state => state.users);
  const { isAdmin } = useAppSelector(state => state.auth);

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  useEffect(() => {
    if (isAdmin) dispatch(getUsers({ role: Roles.MANAGER, status: Statuses.ALL, page: 0, pageSize: 999 }));
  }, []);

  return (
    <StyledForm form={form} fields={fieldsHandler(carData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      {isAdmin && (
        <Form.Item label={t('chooseFleetManager')} name='chooseFleetManager' rules={[{ required: true }]}>
          <Select onChange={val => setCarData(prev => ({ ...prev, managerId: val }))} placeholder={t('chooseFleetManager_placeholder')}>
            {managers.map(manager => (
              <Select.Option key={manager.user.id} value={manager.user.id}>
                {manager.user.firstName} {manager.user.lastName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('plate')} name='plate' rules={[{ required: true, len: 6 }]}>
              <Input onChange={({ target }) => setCarData(prev => ({ ...prev, registrationPlate: target.value }))} placeholder={t('plate_placeholder')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('make')} name='make' rules={[{ required: true }]}>
              <Input onChange={({ target }) => setCarData(prev => ({ ...prev, make: target.value }))} placeholder={t('make_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('model')} name='model' rules={[{ required: true }]}>
              <Input onChange={({ target }) => setCarData(prev => ({ ...prev, model: target.value }))} placeholder={t('model_placeholder')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('year')} name='year' rules={[{ required: true }]}>
              <YearPicker
                min={CarConstants.MIN_YEAR}
                max={new Date().getFullYear()}
                onChange={value => setCarData(prev => ({ ...prev, year: Number(value) }))}
                placeholder={t('year_placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => navigate('/cars')}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default FirstStep;
