import { config } from 'config';
import { format } from 'date-fns';
import { IDriverShift, IDriverShiftTableData } from 'modules/driver/types';

export const mapDriverShifts = (rawDriverShifts: IDriverShift[]): IDriverShiftTableData[] =>
  rawDriverShifts.map(item => ({
    key: item.date + Math.random(),
    car: item.car,
    date: format(new Date(item.date), config.DATE_FORMAT),
    start: item.start,
    end: item.end,
    schedule: item.shiftSchedule,
    shiftType: item.shiftType,
  }));
