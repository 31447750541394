import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/auth/enums';
import { IAuthUser, ISignInRequest } from 'modules/auth/types';
import { setAuthToken } from 'modules/auth/utils';
import { setUser } from 'modules/user';

export const signIn = createAsyncThunk(THUNKS.SIGN_IN, async (data: ISignInRequest, thunkApi) => {
  try {
    const {
      headers: { 'x-auth': authToken },
      data: responseData,
    } = await api.public.post<{ data: IAuthUser }>(Endpoints.SIGN_IN, data);

    setAuthToken(authToken);
    setUser(JSON.stringify(responseData.data));
    return responseData.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
