import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Input, Radio } from 'antd';

import { Globals, Locales, PasswordRegExp } from 'constants/global';
import { changePassword, changeProfile, signOut, updateLanguage } from 'modules/auth';
import { setUser } from 'modules/user';

import { Container, LanguageWrapper, SelectLanguage, StyledForm, Title } from './styled';

const infoFieldsHandler = (email: string, phone: string) => [
  {
    name: 'email',
    value: email || null,
  },
  {
    name: 'phone',
    value: phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
];

const passwordFieldsHandler = (newPassword: string) => [
  {
    name: 'newPassword',
    value: newPassword,
  },
];

const AdminProfile: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.profile' });
  const { t: tG } = useTranslation('translation', { keyPrefix: 'global' });
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const { user, isLoading } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setPhone(user.phone);
      setUser(JSON.stringify(user));
    }
  }, [user]);

  const changeInfoHandler = async () => {
    await form1.validateFields();
    const isLogout = email !== user?.email;
    await dispatch(changeProfile({ email, phone, id: user!.id }));

    const { error } = store.getState().auth;

    if (isLogout && !error) {
      signOut();
    }
  };

  const changePasswordHandler = async () => {
    await form2.validateFields();
    dispatch(changePassword({ email: user!.email, password: newPassword }));
  };

  const updateLanguageHandler = async (lang: string) => {
    await dispatch(updateLanguage(lang));
  };

  return (
    <Container>
      <StyledForm
        form={form1}
        fields={infoFieldsHandler(email, phone)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Title>{t('contactInfo')}</Title>
        <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
          <Input placeholder={t('email_placeholder')} onChange={({ target }) => setEmail(target.value)} disabled />
        </Form.Item>
        <Form.Item label={t('phone')} name='phone' rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}>
          <Input
            type='number'
            placeholder={t('phone_placeholder')}
            prefix={Globals.SWEDEN_COUNTRY_CODE}
            inputMode='numeric'
            onChange={({ target }) => setPhone(`${Globals.SWEDEN_COUNTRY_CODE}${target.value}`)}
          />
        </Form.Item>
        <Button size='large' onClick={changeInfoHandler} loading={isLoading} type='primary'>
          {t('save')}
        </Button>
      </StyledForm>

      <StyledForm
        form={form2}
        fields={passwordFieldsHandler(newPassword)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Title>{t('changePassword')}</Title>
        <Form.Item label={t('newPassword')} name='newPassword' rules={[{ required: true, pattern: PasswordRegExp, message: tG('passwordPattern') }]}>
          <Input.Password placeholder={t('password_placeholder')} onChange={({ target }) => setNewPassword(target.value)} />
        </Form.Item>
        <Button size='large' onClick={changePasswordHandler} loading={isLoading} type='primary'>
          {t('save')}
        </Button>
      </StyledForm>
      <LanguageWrapper>
        <SelectLanguage>{tG('selectLanguage')}</SelectLanguage>
        <Radio.Group value={user?.lang || user?.user?.lang} onChange={({ target }) => updateLanguageHandler(target.value)}>
          <Radio.Button value={Locales.EN}>English</Radio.Button>
          <Radio.Button value={Locales.SV}>Svenska</Radio.Button>
        </Radio.Group>
      </LanguageWrapper>
    </Container>
  );
};

export default AdminProfile;
