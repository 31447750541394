import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { IOption } from 'modules/analytics/types';
import { THUNKS } from 'modules/salaries/enums';
import { IAddAdjustments } from 'modules/salaries/types';

// Add adjustment
export const addAdjustment = createAsyncThunk(THUNKS.ADD_ADJUSTMENT, async (data: IAddAdjustments, thunkApi) => {
  try {
    await api.private.post<{ data: IOption[] }>(`${Endpoints.SALARY_ADJUSTMENTS}`, data).then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.adjAdded'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
