import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IReimbursementType } from 'modules/reimbursements/types';

export const getReimbursementTypes = createAsyncThunk(THUNKS.REIMBURSEMENTS_TYPES, async (_, thunkApi) => {
  try {
    const res = await api.private.get<{ data: IReimbursementType[] }>(`${Endpoints.GET_REIMBURSEMENT_TYPES}`);

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
