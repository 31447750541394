import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { ReportTypes } from 'constants/global';
import { t } from 'i18next';
import { THUNKS } from 'modules/analytics/enums';
import { IReport, IUploadReports } from 'modules/reports/types';

// Upload reports
export const uploadReports = createAsyncThunk(THUNKS.UPLOAD_REPORTS, async ({ files, type }: IUploadReports, thunkApi) => {
  try {
    const formData = new FormData();
    files.map(file => formData.append(type, file.originFileObj as Blob));

    const endpointsByReportTypeMapper = {
      [ReportTypes.TAXIMETER]: Endpoints.REPORT_UPLOAD_TAXIMETER,
      [ReportTypes.SLING]: Endpoints.REPORT_UPLOAD_SLING,
      [ReportTypes.UBER]: Endpoints.REPORT_UPLOAD_UBER,
      [ReportTypes.BOLT]: Endpoints.REPORT_UPLOAD_BOLT,
    };

    const endpointByReportType = endpointsByReportTypeMapper[type] || endpointsByReportTypeMapper[ReportTypes.BOLT];

    const data = await api.private
      .post<{ data: IReport }>(`${endpointByReportType}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
      .then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.reportsUploaded'),
    });

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
