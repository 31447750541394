import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { getCarShiftsById } from '../thunks';

export const getCarShiftsByIdReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Get car shift by ID

  builder
    .addCase(getCarShiftsById.fulfilled, (state, action) => {
      if (state.selectedCar) {
        state.selectedCar = { ...state.selectedCar, shifts: action.payload || []};
      }

      state.isLoading = false;
    })
    .addCase(getCarShiftsById.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCarShiftsById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
