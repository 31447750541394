import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { IDriverRequest, IManagerRequest } from 'modules/requests/types';

// Edit driver request
export const editDriverRequest = createAsyncThunk(THUNKS.EDIT_DRIVER_REQUEST, async (data: IDriverRequest, thunkApi) => {
  try {
    // Todo: check types here
    const res = await api.private
      .put<{ data: IManagerRequest }>(`${Endpoints.DRIVER_REQUEST}?id=${data.userInfo.id}`, data)
      .then(response => response.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.editedDriverRequest'),
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
