import { Form, Row } from 'antd';

import styled from 'styled-components';

export const StyledForm = styled(Form)`
  width: 440px;

  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const StyledRow = styled(Row)`
  padding-top: 16px;
`;
