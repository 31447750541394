import { IReimbursementsState } from 'modules/reimbursements/types';
import { ModuleExtraReducer } from 'types/Module';

import { updateReimbursementStatus } from '../thunks';

export const updateReimbursementStatusReducer: ModuleExtraReducer<IReimbursementsState> = builder => {
  builder
    .addCase(updateReimbursementStatus.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(updateReimbursementStatus.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(updateReimbursementStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
