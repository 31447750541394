import { config } from 'config';
import { format } from 'date-fns';
import { IManagerWeeklyReportTableData } from 'modules/manager/types';
import { IManagerWeeklyReport } from 'modules/reports/types';
import { currencyServices } from 'services/currencyServices';

export const mapManagerWeeklyReports = (rawManagerReports: IManagerWeeklyReport[]): IManagerWeeklyReportTableData[] =>
  rawManagerReports.map(item => ({
    key: Math.random(),
    date: `${format(new Date(item.week.start), config.DATE_FORMAT)} - ${format(new Date(item.week.end), config.DATE_FORMAT)}`,
    earnings: currencyServices.convertToCurrency(item.earning),
    efficiency: item?.efficiency !== undefined ? `${item.efficiency}%` : '-',
    provision: `${item.provision}%`,
    status: item.status,
  }));
