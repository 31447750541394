import styled from 'styled-components';

interface IInfoBlockProps {
  marginTop?: boolean;
}

export const Details = styled.div`
  background-color: #fff;
  padding: 24px 16px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
`;

export const Content = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
`;

export const InfoBlock = styled.div<IInfoBlockProps>`
  ${({ marginTop }) => marginTop && 'margin-top: 32px;'}
`;

export const FieldBlock = styled.div`
  margin-top: 16px;
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #9f9f9f;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  max-width: 60%;
`;

export const Questions = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #ebebeb;
`;

export const Comment = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  margin-top: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 26px 0 10px;

  button:first-child {
    margin-left: auto;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PtotosWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  .ant-image:not(:last-child) {
    margin-right: 8px;
  }
`;
