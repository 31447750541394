import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { RequestStatuses } from 'constants/requests';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { IManagerRequest } from 'modules/requests/types';

// Approve MANAGER status of request
export const approveManagerRequest = createAsyncThunk(THUNKS.APPROVE_MANAGER_REQUEST, async (id: string | null, thunkApi) => {
  try {
    await api.private.put<{ data: IManagerRequest }>(`${Endpoints.MANAGER_REQUEST}?id=${id}`, { status: RequestStatuses.APPROVED });

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.approveManagerRequest'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
