import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/analytics/enums';
import { IChangelog, IGetChangelog } from 'modules/analytics/types';
import { convertIntoISODate } from 'utils/index';

// Get changelog
export const getChangelog = createAsyncThunk(THUNKS.GET_CHANGELOG, async ({ page, pageSize, date, userName }: IGetChangelog, thunkApi) => {
  try {
    const data = await api.private
      .get<{ data: IChangelog }>(
        `${Endpoints.CHANGELOG_EVENTS}?page=${page}&pageSize=${pageSize}${
          date ? `&start=${convertIntoISODate(date[0] as Date)}&end=${convertIntoISODate(date[1] as Date)}` : ''
        }`,
        { params: { userName }}
      )
      .then(res => res.data.data);

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
