import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { store, useAppDispatch } from 'store';

import { Form, Input } from 'antd';

import { PasswordRegExp } from 'constants/global';
import { changePassword } from 'modules/auth';
import ContactMark from 'modules/common/components/ContactMark';
import Container from 'modules/common/components/Container';

import { StyledButton, StyledForm, SubTitle, Title } from './styled';

const fieldsHandler = (email: string | null, password: string) => [
  {
    name: 'email',
    value: email,
  },
  {
    name: 'createPassword',
    value: password,
  },
];

const CreatePassword: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.createPassword' });
  const { t: tG } = useTranslation('translation', { keyPrefix: 'global' });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [SearchParams] = useSearchParams();
  const email = useMemo(() => SearchParams.get('email'), []);
  const uuid = useMemo(() => SearchParams.get('uuid'), []);
  const [password, setPassword] = useState<string>('');

  const submitHandler = async () => {
    await form.validateFields();
    const data = { email, password, uuid, isCreate: true };
    await dispatch(changePassword(data));

    // Getting current state from store
    const { error } = store.getState().auth;
    if (!error) navigate('/sign-in');
  };

  return (
    <Container justifyCenter>
      <Title>{t('title')}</Title>
      <SubTitle>{t('subTitle')}</SubTitle>
      <StyledForm
        form={form}
        fields={fieldsHandler(email, password)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Form.Item label={t('email')} name='email'>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t('createPassword')} name='createPassword' rules={[{ required: true, pattern: PasswordRegExp, message: tG('passwordPattern') }]}>
          <Input.Password placeholder={t('password_placeholder')} onChange={({ target }) => setPassword(target.value)} />
        </Form.Item>
        <Form.Item
          label={t('confirmPassword')}
          name='confirmPassword'
          rules={[
            {
              required: true,
              pattern: PasswordRegExp,
              message: tG('passwordPattern'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('createPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('mismatchError')));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('password_placeholder')} />
        </Form.Item>
        <StyledButton onClick={submitHandler} htmlType='submit' type='primary'>
          {t('loginButton')}
        </StyledButton>
      </StyledForm>
      <ContactMark />
    </Container>
  );
};

export default CreatePassword;
