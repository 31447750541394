import { Input } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Note = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  margin-bottom: 24px;
`;

export const StyledInputNumber = styled(Input)`
  width: 50%;
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.red};
  :hover {
    opacity: 65%;
  }
`;
