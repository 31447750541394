import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Radio } from 'antd';

import { Statuses } from 'constants/global';
import { clearSelectedCar, editCar, getCarById, getCarShiftsById } from 'modules/cars';
import { ActivateCarModal, DisableCarModal, Information, Reports, Shifts } from 'modules/cars/components';
import { ICar } from 'modules/cars/types';
import DatePicker from 'modules/common/components/DatePicker';
import Loader from 'modules/common/components/Loader';

import { Content, Details, Footer, Header, OptionsWrapper, Title } from './styled';

// TODO: HIDDEN FOR v1.0
// import Drivers from './components/Drivers';
// import CreateNotificationModal from '../components/CreateNotificationModal';

enum Tabs {
  REPORTS = 'reports',
  INFORMATION = 'information',
  DRIVERS = 'drivers',
  SHIFTS = 'shifts',
}

const CarDetails: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.details' });
  const [SearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [tab, setTab] = useState<Tabs>(Tabs.INFORMATION);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const { selectedCar, isLoading } = useAppSelector(state => state.cars) as { selectedCar: ICar; isLoading: boolean };
  const { isAdmin, user } = useAppSelector(state => state.auth);
  const id = useMemo(() => SearchParams.get('id'), []);
  const [carData, setCarData] = useState<ICar>(selectedCar);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  // TODO: HIDDEN FOR v1.0
  // const [search, setSearch] = useState<string>('');
  // const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] = useState<boolean>(false);

  // Set carData from selectedUser
  useEffect(() => {
    if (selectedCar) setCarData(selectedCar);
  }, [selectedCar]);

  useEffect(() => {
    if (id) dispatch(getCarById({ id, isAdmin, user: user! })).then(() => dispatch(getCarShiftsById({ carId: id })));

    return () => {
      dispatch(clearSelectedCar());
    };
  }, []);

  useEffect(() => {
    if (dateRange) {
      setDateRange(null);
    }
  }, [tab]);

  const renderHandler = () => {
    switch (tab) {
      case Tabs.REPORTS:
        return <Reports dateRange={dateRange} />;
      case Tabs.INFORMATION:
        return <Information carData={carData} setCarData={setCarData} form={form} isEdit={isEdit} />;
      // TODO: HIDDEN FOR v1.0
      // case Tabs.DRIVERS:
      //   return <Drivers search={search} />;
      case Tabs.SHIFTS:
        return <Shifts />;

      default:
        break;
    }
  };

  const editInfoHandler = async () => {
    await form.validateFields();
    await dispatch(editCar(carData));
    setIsEdit(false);
  };

  if (!selectedCar) return <Loader size='large' />;

  return (
    <>
      <Details>
        <Header>
          <Title>
            {t('car')} {selectedCar.info.registrationPlate}
          </Title>

          <OptionsWrapper>
            <Radio.Group value={tab} size='large' onChange={({ target }) => setTab(target.value)}>
              <Radio.Button value={Tabs.REPORTS}>{t('weeklyReports')}</Radio.Button>
              <Radio.Button value={Tabs.INFORMATION}>{t('contactInfo')}</Radio.Button>
              {/* // TODO: HIDDEN FOR v1.0 */}
              {/* <Radio.Button value={Tabs.DRIVERS}>
                {t('drivers')} ({selectedCar.drivers.length})
              </Radio.Button> */}
              <Radio.Button value={Tabs.SHIFTS}>{t('shifts')}</Radio.Button>
            </Radio.Group>

            {/* // TODO: HIDDEN FOR v1.0 */}
            {/* {tab === Tabs.DRIVERS && (
              <StyledSearch size='large' prefix={<SearchOutlined />} placeholder={t('searchPlaceholder')} onChange={({ target }) => setSearch(target.value)} />
            )} */}
            {tab === Tabs.REPORTS && <DatePicker.RangePicker size='large' value={dateRange} onChange={val => setDateRange(val as [Date, Date])} />}
          </OptionsWrapper>
        </Header>

        <Content>{renderHandler()}</Content>

        <Footer>
          {isEdit ? (
            <>
              <Button onClick={() => setIsEdit(false)}>{t('cancel')}</Button>
              <Button type='primary' onClick={editInfoHandler} loading={isLoading}>
                {t('save')}
              </Button>
            </>
          ) : selectedCar.info.status !== Statuses.DISABLED ? (
            <>
              {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
              {/* // TODO: HIDDEN FOR v1.0 */}
              {/* <Button onClick={() => setIsCreateNotificationModalOpen(true)}>{t('createNotification')}</Button> */}
              <Button type='primary' danger onClick={() => setIsDisableModalOpen(true)}>
                {t('disable')}
              </Button>
            </>
          ) : (
            <>
              {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
              <Button type='primary' onClick={() => setIsActivateModalOpen(true)}>
                {t('activate')}
              </Button>
            </>
          )}
        </Footer>
      </Details>

      <DisableCarModal isModalOpen={isDisableModalOpen} setIsModalOpen={setIsDisableModalOpen} statusFilter={selectedCar.info.status} />
      <ActivateCarModal isModalOpen={isActivateModalOpen} setIsModalOpen={setIsActivateModalOpen} statusFilter={selectedCar.info.status} />
      {/* // TODO: HIDDEN FOR v1.0 */}
      {/* <CreateNotificationModal isModalOpen={isCreateNotificationModalOpen} setIsModalOpen={setIsCreateNotificationModalOpen} /> */}
    </>
  );
};

export default CarDetails;
