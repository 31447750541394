import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { ISearchCarResult } from 'modules/cars/types';
import useDebounce from 'utils/debounce';

import { generateAutocompleteOptions } from './Option';
import { StyledAutocomplete } from './styled';

interface IProps {
  cars: ISearchCarResult[];
  searchCars: (search: string) => void;
  onSelect: (carId: number) => void;
  initialInputValue: string;
  addedCarId: number | undefined;
}

const Autocomplete: FC<IProps> = ({ cars, searchCars, onSelect, initialInputValue, addedCarId }) => {
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [searchValue, setSearchValue] = useState<string>('');
  const [autocompleteOptions, setAutocompleteOptions] = useState<DefaultOptionType[]>([]);
  const debouncedSearch = useDebounce<string>(searchValue, 500);
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver.agreementModal' });

  useEffect(() => {
    setAutocompleteOptions(generateAutocompleteOptions({ cars, addedCarId }));
  }, [cars]);

  useEffect(() => {
    searchCars(debouncedSearch);
  }, [debouncedSearch]);

  const onSearchHandler = (newInputValue: string) => {
    if (newInputValue !== inputValue) {
      setSearchValue(newInputValue);
      setInputValue(newInputValue);
    }
  };

  const handleSelect = (value: string, option: DefaultOptionType) => {
    const { key: carId } = option;

    setInputValue(value);
    setSearchValue('');
    onSelect(carId);
  };

  const onBlur = () => {
    setSearchValue('');
    setInputValue(initialInputValue);
  };

  const filterByLabelText = (inputValue: string, option: DefaultOptionType | undefined) => {
    // TODO: add option.value type check
    const optionLabelText = option?.value as string;
    return optionLabelText.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <StyledAutocomplete
      options={autocompleteOptions}
      onSearch={onSearchHandler}
      value={inputValue}
      onSelect={handleSelect}
      onBlur={onBlur}
      // TODO: add locales
      notFoundContent={!cars?.length ? `${t('noActiveCarsAvailable')}` : null}
      open={searchValue !== ''}
      getPopupContainer={trigger => trigger.parentElement}
      filterOption={filterByLabelText}
    >
      <Input size='large' placeholder={t('carSearch_placeholder')} />
    </StyledAutocomplete>
  );
};

export default Autocomplete;
