export enum THUNKS {
  GET_SALARY = 'salaries/getSalary',
  GET_DRIVER_SALARY = 'salaries/getDriverSalary',
  GET_MANAGER_SALARY = 'salaries/getManagerSalary',
  CHANGE_SALARY_STATUS = 'salaries/changeSalaryStatus',
  CHANGE_FINAL_SALARY_STATUS = 'salaries/changeFinalSalaryStatus',
  GET_SALARY_ADJUSTMENTS = 'salaries/getSalaryAdjustments',
  GET_ADJUSTMENT_TYPES = 'salaries/getAdjustmentTypes',
  ADD_ADJUSTMENT = 'salaries/addAdjustment',
  DELETE_ADJUSTMENT = 'salaries/deleteAdjustment',
  GET_TAX_TABLE_NUMBERS = 'salaries/getTaxTableNumbers',
  SET_TAX_TABLE_NUMBERS = 'salaries/setTaxTableNumbers',
  PAY_WEEKLY = 'salaries/payWeekly',
  PAY_MONTHLY = 'salaries/payMonthly',
  GET_SALARY_STATUSES = 'salaries/salaryStatuses',
  GET_SWISH_STATUSES = 'salaries/swishStatuses',
  GET_MONTHLY_PAYMENTS = 'salaries/getMonthlyPayments',
  GET_SALARY_REPORT = 'salaries/getSalaryReport',
  GET_DRIVER_LAST_SALARY_DATE = 'salaries/getDriverLastSalaryDate',
}
