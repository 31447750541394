import { config } from 'config';
import { Entities } from 'constants/global';
import { format } from 'date-fns';
import { IRequests, IRequestsListTableData } from 'modules/requests/types';

export const mapCarRequests = (carRequests: IRequests): IRequestsListTableData[] =>
  carRequests.carRequests.map(item => ({
    id: item.id,
    key: item.id + item.createdAt,
    date: format(new Date(item.createdAt), config.DATE_FORMAT),
    time: format(new Date(item.createdAt), config.TIME_FORMAT),
    plate: item.registrationPlate,
    address: `${item.carAddress.street}, ${item.carAddress.city}, ${item.carAddress.postalCode}`,
    insurance: item.insuranceAgreement,
    model: item.model,
    year: item.year,
    role: Entities.CAR,
  }));
