import { PaymentTypeEnum } from 'modules/salaries/enums';
import { IMonthlyError, IUpdateSalaryStatusResult, IWeeklyError } from 'modules/salaries/types';

export const generateErrors = (markAsPaidResult: IUpdateSalaryStatusResult | null, paymentType: PaymentTypeEnum) => {
  const isWeeklyPayment = paymentType === PaymentTypeEnum.Weekly;

  return (
    markAsPaidResult?.errors?.map(error => {
      const { salaryId } = error as IWeeklyError;
      const { paymentId } = error as IMonthlyError;
      return { text: error.errors.join('; '), id: isWeeklyPayment ? salaryId : paymentId };
    }) || []
  );
};
