import { TableColumnsType } from 'antd';

import { Roles } from 'constants/global';
import { ISalaryByWeekTableData } from 'modules/salaries/types';
import { IDriverSalary } from 'modules/salaries/types/IDriverSalary';
import { IManagerSalary } from 'modules/salaries/types/IManagerSalary';

import { generateDriverSalariesByWeekColumns } from './generateDriverSalariesByWeekColumns';
import { generateManagerSalariesByWeekColumns } from './generateManagerSalariesByWeekColumns';
import { mapDriverSalariesByWeek } from './mapDriverSalariesByWeek';
import { mapManagerSalariesByWeek } from './mapManagerSalariesByWeek';

interface IProps {
  salaryByWeek: (IDriverSalary & IManagerSalary) | null;
  role: Roles;
  moreDropdown: (record: ISalaryByWeekTableData) => JSX.Element;
  isAdmin: boolean;
}

type ReturnType = [ISalaryByWeekTableData[], TableColumnsType<ISalaryByWeekTableData>];

export const useSalaryByWeekTable = (props: IProps): ReturnType => {
  const { salaryByWeek, isAdmin, role, moreDropdown } = props;
  const isDriver = role === Roles.DRIVER;

  const tableData = isDriver ? mapDriverSalariesByWeek(salaryByWeek) : mapManagerSalariesByWeek(salaryByWeek);

  const tableColumns = isDriver ? generateDriverSalariesByWeekColumns({ moreDropdown }) : generateManagerSalariesByWeekColumns({ moreDropdown, isAdmin });

  return [tableData, tableColumns];
};
