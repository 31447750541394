import { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table } from 'antd';

import { config } from 'config';
import { useDriverShiftsTableData } from 'modules/driver/hooks';
import { IDriver } from 'modules/driver/types';
import { getDriverShiftsById } from 'modules/user';

const Shifts: FC = () => {
  const { selectedUser, isLoading } = useAppSelector(state => state.users) as { selectedUser?: IDriver; isLoading: boolean };
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);

  const driverShifts = selectedUser?.shifts?.items || [];
  const [data, columns] = useDriverShiftsTableData({ driverShifts });

  useEffect(() => {
    if (selectedUser) {
      dispatch(getDriverShiftsById({ userId: selectedUser.user.id.toString(), page: page - 1 }));
    }
  }, [page]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={isLoading}
      pagination={{
        showSizeChanger: false,
        current: page,
        onChange: setPage,
        pageSize: config.DEFAULT_PAGE_SIZE,
        total: selectedUser?.shifts?.total?.total,
      }}
    />
  );
};

export default Shifts;
