import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Input } from 'antd';

import { DriverSteps } from 'modules/auth/enums';
import { IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'clearingNumber',
    value: driverData.bankInfo.clearingNumber || null,
  },
  {
    name: 'bankAccNumber',
    value: driverData.bankInfo.bankAccount || null,
  },
];

const FourthStep: FC<IDriverStepsProps> = ({ setStep, driverData, setDriverData }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.FOURTH}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  return (
    <StyledForm fields={fieldsHandler(driverData)} form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('clearingNumber')} name='clearingNumber' rules={[{ required: true }]}>
        <Input
          type='number'
          placeholder={t('clearingNumber_placeholder')}
          onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, clearingNumber: target.value }}))}
        />
      </Form.Item>
      <Form.Item label={t('bankAccNumber')} name='bankAccNumber' rules={[{ required: true }]}>
        <Input
          type='number'
          placeholder={t('bankAccNumber_placeholder')}
          onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, bankAccount: target.value }}))}
        />
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default FourthStep;
