/* eslint-disable react/jsx-boolean-value */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Radio } from 'antd';

import { DriverSteps } from 'modules/auth/enums';
import { IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'ownCar',
    value: driverData.hasCar,
  },
];

const SixthStep: FC<IDriverStepsProps> = ({ setStep, driverData, setDriverData }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.SIXTH}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('ownCar')} name='ownCar' rules={[{ required: true }]}>
        <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, hasCar: target.value }))}>
          <Radio.Button value={true}>{t('yes')}</Radio.Button>
          <Radio.Button value={false}>{t('no')}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default SixthStep;
