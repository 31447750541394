import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Table } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { ChangelogTypes } from 'constants/global';
import { useChangelogTable } from 'modules/analytics/hooks';
import { getChangelog } from 'modules/analytics/slice';
import TableWrapper from 'modules/common/components/TableWrapper';
import { EventValue } from 'rc-picker/lib/interface';
import useDebounce from 'utils/debounce';

import { StyledRangePicker, StyledSearch } from './styled';

type DateRangeType = [EventValue<Date>, EventValue<Date>] | null;

const Changelog: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.changelog' });
  const { isLoading, changelog } = useAppSelector(state => state.analytics);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const [dateRange, setDateRange] = useState<DateRangeType>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  // TODO: DISABLED BEFORE DRIVER REPORT ADDED
  // const [changelogType, setChangelogType] = useState<ChangelogTypes>(ChangelogTypes.ALL);

  useEffect(() => {
    setPage(1);
  }, [dateRange, search]);

  useEffect(() => {
    dispatch(getChangelog({ page: page - 1, pageSize, date: dateRange, userName: debouncedSearch }));
  }, [dateRange, debouncedSearch, page]);

  const changePageHanler = async (p: number, ps: number) => {
    await dispatch(getChangelog({ page: p - 1, pageSize: ps, date: dateRange }));
    setPage(p);
    setPageSize(ps);
  };

  const [data, columns] = useChangelogTable({ changelog });

  const header = useMemo(
    () => (
      <>
        {/* // TODO: DISABLED BEFORE DRIVER REPORT ADDED */}
        {/* <Radio.Group size='large' value={changelogType} onChange={({ target }) => setChangelogType(target.value)}>
          <Radio.Button value={ChangelogTypes.ALL}>{t('all')}</Radio.Button>
          <Radio.Button value={ChangelogTypes.USER_MANAGEMENT}>{t('userManagement')}</Radio.Button>
          <Radio.Button value={ChangelogTypes.PERFOMANCE}>{t('performance')}</Radio.Button>
        </Radio.Group> */}
        <StyledRangePicker size='large' onChange={setDateRange} />
        <StyledSearch prefix={<SearchOutlined />} placeholder={t('search_placeholder')} size='large' onChange={({ target }) => setSearch(target.value)} />
      </>
    ),
    [ChangelogTypes]
  );

  return (
    <TableWrapper header={header}>
      <Table
        pagination={{
          showSizeChanger: false,
          current: page,
          onChange: (p, ps) => changePageHanler(p, ps),
          total: changelog.total['not.read'] + changelog.total.read,
          pageSize,
        }}
        dataSource={data}
        columns={columns}
        loading={isLoading}
      />
    </TableWrapper>
  );
};

export default Changelog;
