import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IAdvancePaymentStatus } from 'modules/reimbursements/types';

export const getAdvancePaymentStatuses = createAsyncThunk(THUNKS.ADVANCE_PAYMENT_STATUSES, async (_, thunkApi) => {
  try {
    const res = await api.public.get<{ data: IAdvancePaymentStatus[] }>(Endpoints.ADVANCE_PAYMENT_STATUSES);

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
