import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Col, Form, Input, Radio } from 'antd';

import { Globals, Locales, PasswordRegExp } from 'constants/global';
import { changePassword, changeProfile, signOut, updateLanguage } from 'modules/auth';
import { setUser } from 'modules/user';

import {
  BecomeADriverWrapper,
  Container,
  FieldBlock,
  InfoWrapper,
  Label,
  LanguageWrapper,
  SelectLanguage,
  StyledForm,
  StyledRow,
  Title,
  Value,
} from './styled';

const infoFieldsHandler = (email: string, phone: string) => [
  {
    name: 'email',
    value: email || null,
  },
  {
    name: 'phone',
    value: phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
];

const passwordFieldsHandler = (newPassword: string) => [
  {
    name: 'newPassword',
    value: newPassword,
  },
];

const ManagerProfile: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.profile' });
  const { t: infoT } = useTranslation('translation', { keyPrefix: 'admin.users.details.manager' });
  const { t: tG } = useTranslation('translation', { keyPrefix: 'global' });
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const navigate = useNavigate();
  const { user, isLoading } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  useEffect(() => {
    if (user) {
      setEmail(user.user.email);
      setPhone(user.user.phone);
      setUser(JSON.stringify(user));
    }
  }, [user]);

  const changeInfoHandler = async () => {
    await form1.validateFields();
    const isLogout = email !== user?.user?.email;
    await dispatch(changeProfile({ email, phone, id: user!.user.id }));

    if (isLogout) {
      signOut();
    }
  };

  const changePasswordHandler = async () => {
    await form2.validateFields();
    dispatch(changePassword({ email: user!.user.email, password: newPassword }));
  };

  const updateLanguageHandler = async (lang: string) => {
    await dispatch(updateLanguage(lang));
  };

  return (
    <Container>
      <StyledRow justify={{ md: 'space-between' }} wrap gutter={16}>
        <Col md={{ span: 14 }} sm={{ span: 24 }}>
          <StyledForm
            form={form1}
            fields={infoFieldsHandler(email, phone)}
            validateTrigger={['onChange', 'onBlur']}
            layout='vertical'
            requiredMark={false}
            size='large'
          >
            <Title>{t('contactInfo')}</Title>
            <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
              <Input placeholder={t('email_placeholder')} onChange={({ target }) => setEmail(target.value)} disabled />
            </Form.Item>
            <Form.Item
              label={t('phone')}
              name='phone'
              rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
            >
              <Input
                type='number'
                placeholder={t('phone_placeholder')}
                prefix={Globals.SWEDEN_COUNTRY_CODE}
                onChange={({ target }) => setPhone(`${Globals.SWEDEN_COUNTRY_CODE}${target.value}`)}
              />
            </Form.Item>
            <Button onClick={changeInfoHandler} loading={isLoading} type='primary'>
              {t('save')}
            </Button>
          </StyledForm>
          <StyledForm
            form={form2}
            fields={passwordFieldsHandler(newPassword)}
            validateTrigger={['onChange', 'onBlur']}
            layout='vertical'
            requiredMark={false}
            size='large'
          >
            <Title>{t('changePassword')}</Title>
            <Form.Item label={t('newPassword')} name='newPassword' rules={[{ required: true, pattern: PasswordRegExp, message: tG('passwordPattern') }]}>
              <Input.Password placeholder={t('password_placeholder')} onChange={({ target }) => setNewPassword(target.value)} />
            </Form.Item>
            <Button onClick={changePasswordHandler} loading={isLoading} type='primary'>
              {t('save')}
            </Button>
          </StyledForm>
          <LanguageWrapper>
            <SelectLanguage>{tG('selectLanguage')}</SelectLanguage>
            <Radio.Group value={user?.lang || user?.user?.lang} onChange={({ target }) => updateLanguageHandler(target.value)}>
              <Radio.Button value={Locales.EN}>English</Radio.Button>
              <Radio.Button value={Locales.SV}>Svenska</Radio.Button>
            </Radio.Group>
          </LanguageWrapper>
        </Col>

        <Col sm={{ offset: 0, span: 12 }} md={{ offset: 2, span: 8 }}>
          <Title>{t('other')}</Title>
          <InfoWrapper>
            <div>
              <FieldBlock>
                <Label>{infoT('username')}</Label>
                <Value>
                  {user?.user?.firstName} {user?.user?.lastName}
                </Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('email')}</Label>
                <Value>
                  <a href={`mailto:${user?.user?.email}`}>{user?.user?.email}</a>
                </Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('phone')}</Label>
                <Value>{user?.user?.phone}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('postalCode')}</Label>
                <Value>{user?.address?.postalCode}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('city')}</Label>
                <Value>{user?.address?.city}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('street')}</Label>
                <Value>{user?.address?.street}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('carsNumber')}</Label>
                <Value>{user?.cars?.length}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('driversNumber')}</Label>
                <Value>{user?.drivers?.length}</Value>
              </FieldBlock>
            </div>

            <div>
              <FieldBlock>
                <Label>{infoT('socialSecurityNumber')}</Label>
                <Value>{user?.socialSecurityNumber}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('companyRegNumber')}</Label>
                <Value>{user?.companyInfo?.companyRegistrationNumber}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('companyName')}</Label>
                <Value>{user?.companyInfo?.companyName}</Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('companyAddress')}</Label>
                <Value>
                  {user?.companyInfo?.companyAddress &&
                    `${user?.companyInfo.companyAddress.street}, ${user?.companyInfo.companyAddress.city}, ${user?.companyInfo.companyAddress.postalCode}`}
                </Value>
              </FieldBlock>
              <FieldBlock>
                <Label>{infoT('accountingCenter')}</Label>
                <Value>{user?.accountingCenter}</Value>
              </FieldBlock>
            </div>
          </InfoWrapper>

          <BecomeADriverWrapper>
            <Title noMarginBottom>{t('becomeADriver')}</Title>
            {user?.isDriver ? (
              <Label>
                {tG('youAlready')}{' '}
                <Button size='small' type='link' onClick={() => navigate('/sign-in')}>
                  {tG('signIn')}
                </Button>{' '}
                {tG('signIn')}
              </Label>
            ) : (
              <Label>
                {t('ifYouWant')}
                <Button size='small' type='link' onClick={() => navigate('/sign-up/driver')}>
                  {t('theLink')}
                </Button>
              </Label>
            )}
          </BecomeADriverWrapper>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default ManagerProfile;
