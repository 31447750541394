import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IDriver } from 'modules/driver/types';
import { IManager } from 'modules/manager/types';
import { THUNKS } from 'modules/user/enums';
import { IGetUsersRequest } from 'modules/user/types';
import { convertIntoISODate } from 'utils';

interface IGetUsersResponse {
  data: {
    total: { active: number; disabled: number; pending: number };
    items: IDriver[] | IManager[];
  };
}

// Get all users by ADMIN
export const getUsers = createAsyncThunk(
  THUNKS.GET_USERS,
  async ({ role, status, page, pageSize = 10, managerId, userName, start, end }: IGetUsersRequest, thunkApi) => {
    try {
      const data = await api.private
        .get<IGetUsersResponse>(`${Endpoints.ADMIN_USER}?end=${end ? convertIntoISODate(end) : ''}&start=${start ? convertIntoISODate(start) : ''}`, {
          params: {
            role,
            status,
            page,
            pageSize,
            managerId,
            userName,
          },
        })
        .then(res => res.data.data);

      const total = { ...data.total, all: data.total.active + data.total.disabled + data.total.pending };

      return { users: data.items, total };
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
