import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Row } from 'antd';

import { IPayoutResult } from 'modules/salaries/types';

import { ErrorBlockContainer, ErrorIcon, ErrorsBlock, StyledColumn, TextContainer, WarningIcon } from './styled';

type PropsType = {
  closePayoutModal: () => void;
  payoutResult: IPayoutResult | null;
};

const FailedPayout: FC<PropsType> = ({ closePayoutModal, payoutResult }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });
  const paymentErrors = payoutResult?.errors;
  const successfulPayments = payoutResult?.payouts;

  return (
    <Row gutter={[0, 12]} justify='center'>
      <StyledColumn span={24}>{successfulPayments?.length ? <WarningIcon /> : <ErrorIcon />}</StyledColumn>
      <StyledColumn span={24}>
        <TextContainer>{t('errorText')}</TextContainer>
      </StyledColumn>
      {paymentErrors?.length && (
        <StyledColumn>
          <ErrorBlockContainer>
            <ErrorsBlock>
              {paymentErrors.map(error => (
                <Col span={24} key={error.paymentId}>
                  {error.errors.join('; ')}
                  <Divider />
                </Col>
              ))}
            </ErrorsBlock>
          </ErrorBlockContainer>
        </StyledColumn>
      )}
      <StyledColumn span={24}>
        <Button onClick={closePayoutModal} type='primary'>
          {t('okay')}
        </Button>
      </StyledColumn>
    </Row>
  );
};

export default FailedPayout;
