import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import english from 'assets/english.svg';
import sveden from 'assets/sveden.svg';
import { Locales } from 'constants/global';
import i18n from 'locales';
import ContactMark from 'modules/common/components/ContactMark';
import Container from 'modules/common/components/Container';

import { ButtonsWrapper, LanguageContainer, Locale, StyledCircleIcon, SubTitle, Title } from './styled';

const FinalScreen: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.finalScreen' });
  const navigate = useNavigate();
  const state = useLocation().state as { fromCar?: boolean };

  return (
    <>
      <LanguageContainer>
        <Locale src={english} alt='en' onClick={() => i18n.changeLanguage(Locales.EN)} />
        <Locale src={sveden} alt='sv' onClick={() => i18n.changeLanguage(Locales.SV)} />
      </LanguageContainer>
      <Container justifyCenter>
        <StyledCircleIcon />
        <Title>{t('title')}</Title>
        <SubTitle>{t('subTitle')}</SubTitle>
        <ButtonsWrapper>
          {state?.fromCar ? (
            <>
              <Button onClick={() => navigate('/cars')}>{t('back')}</Button>
              <Button type='primary' onClick={() => navigate('/add-car')}>
                {t('addAnotherCar')}
              </Button>
            </>
          ) : (
            <Button type='primary' onClick={() => navigate('/')}>
              {t('backToSite')}
            </Button>
          )}
        </ButtonsWrapper>
        <ContactMark />
      </Container>
    </>
  );
};

export default FinalScreen;
