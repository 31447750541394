import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { Button, Col, Divider, Row } from 'antd';

import { ErrorBlockContainer, ErrorIcon, ErrorsBlock, StyledColumn, TextContainer, WarningIcon } from './styled';

interface IProps {
  closeModal: () => void;
}

const FailedPayout: FC<IProps> = ({ closeModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });
  const { payoutResult } = useAppSelector(state => state.salaries.payoutModal);
  const paymentErrors = payoutResult?.errors;
  const successfulPayouts = payoutResult?.payouts;

  const buttonHandler = () => {
    closeModal();
  };

  return (
    <Row gutter={[0, 12]} justify='center'>
      <StyledColumn span={24}>{successfulPayouts?.length ? <WarningIcon /> : <ErrorIcon />}</StyledColumn>
      <StyledColumn span={24}>
        <TextContainer>{t('errorText')}</TextContainer>
      </StyledColumn>
      {paymentErrors?.length && (
        <StyledColumn>
          <ErrorBlockContainer>
            <ErrorsBlock>
              {paymentErrors.map(error => (
                <Col span={24} key={error.paymentId}>
                  {error.errors.join('; ')}
                  <Divider />
                </Col>
              ))}
            </ErrorsBlock>
          </ErrorBlockContainer>
        </StyledColumn>
      )}
      <StyledColumn span={24}>
        <Button onClick={buttonHandler} type='primary'>
          {t('okay')}
        </Button>
      </StyledColumn>
    </Row>
  );
};

export default FailedPayout;
