import { TableColumnsType } from 'antd';

import { IReimbursement } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';

import { IReimbursementTableData } from '../../types/IReimbursementTableData';
import { generateReimbursementsColumns } from './generateReimbursementsColumns';

interface IProps {
  reimbursements: IReimbursement[];
  hoveredId: number | undefined;
  dateRangeFilterValue: RangeValue<string>;
  managerFilterValue: number[];
  driverFilterValue: number[];
  statusFilterValue: string[];
  swishStatusFilterValue: string[];
  typeFilterValue: string[];
  fetchReimbursements: () => void;
}

type ReturnType = [IReimbursementTableData[], TableColumnsType<IReimbursementTableData>];

export const useReimbursementsTable = (props: IProps): ReturnType => {
  const { reimbursements, ...generateColumnsProps } = props;
  const tableData = reimbursements.map(reimbursement => ({ key: reimbursement.id, ...reimbursement }));
  const tableColumns = generateReimbursementsColumns(generateColumnsProps);

  return [tableData, tableColumns];
};
