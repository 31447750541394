import { ILinkCarTableData } from 'modules/cars/types';
import { IDriver } from 'modules/driver/types';

import { IMappedCar } from './types/IMappedCar';

interface IProps {
  cars: IMappedCar[];
  selectedCar: ILinkCarTableData | null;
  highlightedCars: number[];
  user: IDriver;
}

export const addColorsToRows = (props: IProps): ILinkCarTableData[] => {
  const { cars, selectedCar, highlightedCars, user } = props;

  const rowColorHandler = (car: IMappedCar) => {
    if (user.cars.some(item => item.id === car.id)) return 'green-row';
    if (selectedCar?.id === car.id) return 'disabled-row';
    if (highlightedCars.includes(car.id)) return 'purple-row';
    return '';
  };

  return cars.map(car => ({
    ...car,
    rowColor: rowColorHandler(car),
    linked: user.cars.some(item => item.id === car.id),
    selected: selectedCar?.id === car.id,
  }));
};
