import { Row } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  width: auto;
  display: grid;
`;

export const StyledCircleIcon = styled(CheckCircleOutlined)`
  svg {
    height: 17.5px;
    width: 17.5px;
    fill: ${({ theme }) => theme.green};
  }
`;

export const SuccessTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.green};
`;

export const SuccessMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const StyledTableHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
