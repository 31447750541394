import { config } from 'config';
import { Entities } from 'constants/global';
import { format } from 'date-fns';
import { IRequests, IRequestsListTableData } from 'modules/requests/types';

export const mapDriverRequests = (driverRequests: IRequests): IRequestsListTableData[] =>
  driverRequests.driverRequests.map(item => ({
    id: item.userInfo.id,
    key: item.userInfo.id,
    date: item.userInfo.createdAt ? format(new Date(item.userInfo.createdAt), config.DATE_FORMAT) : '-',
    time: item.userInfo.createdAt ? format(new Date(item.userInfo.createdAt), config.TIME_FORMAT) : '-',
    name: `${item.userInfo.firstName} ${item.userInfo.lastName}`,
    role: Entities.DRIVER,
    email: item.userInfo.email,
    phone: item.userInfo.phone,
    address: `${item.addressInfo.street}, ${item.addressInfo.city}, ${item.addressInfo.postalCode}`,
  }));
