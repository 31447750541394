import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import { ISearchCarResult } from 'modules/cars/types';

interface IOptionProps {
  car: ISearchCarResult;
  isAdded: boolean;
}

interface IProps {
  cars: ISearchCarResult[];
  addedCarId?: number;
}

const AutocompleteItem: FC<IOptionProps> = ({ car, isAdded }) => {
  const { registrationPlate, make, model } = car;
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver.agreementModal' });

  return (
    <div>
      <Typography.Text>
        {registrationPlate}, {make}, {model}
      </Typography.Text>
      {isAdded && <div>{t('currentlySelectedCar')}</div>}
    </div>
  );
};

export const generateAutocompleteOptions = ({ cars, addedCarId }: IProps) =>
  cars.map(car => {
    const { id: key, registrationPlate, make, model } = car;
    const isAdded = Number(key) === addedCarId;

    return {
      key,
      value: `${registrationPlate}, ${make}, ${model}`,
      label: <AutocompleteItem car={car} isAdded={isAdded} />,
      disabled: isAdded,
    };
  });

export default AutocompleteItem;
