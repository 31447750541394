import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IManager } from 'modules/manager/types';
import { THUNKS } from 'modules/user/enums';
import { IGetUserById } from 'modules/user/types';

// Get Managerr by Id
export const getManagerById = createAsyncThunk(THUNKS.GET_MANAGER_BY_ID, async ({ id }: IGetUserById, thunkApi) => {
  try {
    const res = await api.private.get<{ data: IManager }>(`${Endpoints.ADMIN_MANAGER}/${id}`);
    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
