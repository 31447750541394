export default {
  title: 'Logga in',
  signUp: 'Bli medlem',
  subTitle: 'Logga in och börja hantera ditt taxiåkeri!',
  email: 'E-post',
  email_placeholder: 'Skriv in e-post',
  password: 'Lösenord',
  password_placeholder: 'Skriv in lösenord',
  loginButton: 'Logga in',
  remember: 'Kom ihåg mig',
  forgotPass: 'Glömt lösenord',
};
