import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType, Tag } from 'antd';

import { config } from 'config';
import { SalaryStatus } from 'constants/global';
import { MoreButton } from 'modules/salaries/components/Details/styled';
import { localeByStatus } from 'modules/salaries/locales/localeByStatus';
import { IFinalPayment } from 'modules/salaries/types';
import { currencyServices } from 'services/currencyServices';
import { renderDateRange } from 'utils/date';

interface IProps {
  moreDropdown: (record: IFinalPayment) => JSX.Element;
}

export const generateFinalSalaryColumns = (props: IProps): TableColumnsType<IFinalPayment> => {
  const { moreDropdown } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  return [
    {
      title: t('period'),
      dataIndex: 'period',
      key: 'period',
      render: (_, tableData) => renderDateRange({ timeRange: { range: tableData.period }, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (value: keyof typeof localeByStatus) => (
        <Tag color={value.toLowerCase() === SalaryStatus.PAID ? 'green' : 'yellow'}>{t(localeByStatus[value])}</Tag>
      ),
    },
    {
      title: t('tips'),
      dataIndex: 'tips',
      key: 'tips',
      render: (_, tableData) => currencyServices.convertToCurrency(tableData.tips),
    },
    {
      title: t('brutto'),
      dataIndex: 'brutto',
      key: 'brutto',
      render: (_, tableData) => currencyServices.convertToCurrency(tableData.brutto),
    },
    {
      title: t('taxTable'),
      dataIndex: 'taxTable',
      key: 'taxTable',
      render: (_, tableData) => (tableData.taxTable ? `${tableData.taxTable} (${tableData.taxYear})` : '–'),
    },
    {
      title: t('taxes'),
      dataIndex: 'taxes',
      key: 'taxes',
      render: (_, tableData) => (tableData.taxes ? currencyServices.convertToCurrency(tableData.taxes) : '–'),
    },
    {
      title: t('netSalary'),
      dataIndex: 'netSalary',
      key: 'netSalary',
      render: (_, tableData) => (tableData.netSalary ? currencyServices.convertToCurrency(tableData.netSalary) : '–'),
    },
    {
      title: t('payouts'),
      dataIndex: 'payouts',
      key: 'payouts',
      render: (_, tableData) => currencyServices.convertToCurrency(tableData.payouts),
    },
    {
      title: t('adjustments'),
      dataIndex: 'adjustments',
      key: 'adjustments',
      render: (_, tableData) => currencyServices.convertToCurrency(tableData.finalAdjustment),
    },

    {
      title: t('previousMonthDebt'),
      dataIndex: 'previousMonthDebt',
      key: 'previousMonthDebt',
      render: (_, tableData) => (tableData.advancePayments ? currencyServices.convertToCurrency(tableData.previousMonthDebt) : '–'),
    },

    {
      title: t('advanceUsed'),
      dataIndex: 'advanceUsed',
      key: 'advanceUsed',
      render: (_, tableData) => (tableData.advancePayments ? currencyServices.convertToCurrency(tableData.advancePayments) : '–'),
    },

    {
      title: t('debited'),
      dataIndex: 'debited',
      key: 'debited',
      render: (_, tableData) => (tableData.debited ? currencyServices.convertToCurrency(tableData.debited) : '–'),
    },

    {
      title: t('debtLeftover'),
      dataIndex: 'advanceLeftover',
      key: 'advanceLeftover',
      render: (_, tableData) => (tableData.advanceLeftover ? currencyServices.convertToCurrency(tableData.advanceLeftover) : '–'),
    },

    {
      title: t('finalPayment'),
      dataIndex: 'finalPayment',
      key: 'finalPayment',
      render: (_, tableData) => (tableData.finalPayout ? currencyServices.convertToCurrency(tableData.finalPayout) : '–'),
    },
    {
      width: 50,
      key: 'options',
      render: (_, tableData) => (
        <Dropdown trigger={['click']} overlay={moreDropdown(tableData)} placement='topRight'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
