import { Key } from 'react';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IPayoutResult } from 'modules/salaries/types';

// Pay monthly salary with Swish
export const payMonthly = createAsyncThunk(THUNKS.PAY_MONTHLY, async (paymentIds: Key[], thunkApi) => {
  try {
    const response = await api.private.put<{ data: IPayoutResult }>(`${Endpoints.PAY_MONTHLY}`, { paymentIds }).then(res => res.data.data);

    return response;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
