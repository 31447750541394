import styled from "styled-components";

export const DetailsWrapper = styled.div`
display: flex;
gap: 5rem;
`;

export const ColumnWrapper = styled.div`
display: flex;
flex-direction:column;
`;
