import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import { Area } from '@ant-design/plots';
import { config } from 'config';
import { Locales } from 'constants/global';
import { format } from 'date-fns';
import { IReportPaidData } from 'modules/analytics/types';
import { dateLocaleByUserLanguage, renderDateRange } from 'utils/date';

import Change from '../Change';
import ChartCard from '../ChartCard';
import { AdditionalInfo, ArrowDownIcon, ArrowUpIcon } from './styled';

interface IProps {
  title: string;
  initialChartData: IReportPaidData;
  payment: string;
}

const AreaPlotChart: FC<IProps> = ({ title, initialChartData, payment }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'admin.dashboard' });

  const [chartData, setChartData] = useState<{ value: number; date: string }[]>();

  useEffect(() => {
    if (initialChartData.paidByTimeRange.length === 1) {
      const { value } = initialChartData.paidByTimeRange[0];
      const startDate = initialChartData.paidByTimeRange[0].timeRangeLabel.range?.start;
      const endDate = initialChartData.paidByTimeRange[0].timeRangeLabel.range?.end;

      const data: { value: number; date: string }[] = [
        {
          value,
          date: format(new Date(startDate || 0), config.DATE_FORMAT_WITH_WEEKDAY_NAME, {
            locale: dateLocaleByUserLanguage[i18n.language as Locales],
          }),
        },
        {
          value,
          date: format(new Date(endDate || 0), config.DATE_FORMAT_WITH_WEEKDAY_NAME, {
            locale: dateLocaleByUserLanguage[i18n.language as Locales],
          }),
        },
      ];

      setChartData(data);
    } else {
      const data = initialChartData.paidByTimeRange.map(timeRangeData => ({
        value: timeRangeData.value,
        date: renderDateRange({ timeRange: timeRangeData.timeRangeLabel, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
      }));

      setChartData(data);
    }
  }, []);

  return (
    <ChartCard title={t(title)}>
      <>
        <Change
          value={(
            <Row gutter={[16, 0]}>
              <Col span={24}>{payment}</Col>
              <Col span={24}>
                <Row gutter={[11, 0]}>
                  <Col>
                    <AdditionalInfo>{initialChartData.prevPeriodPercent}%</AdditionalInfo>
                  </Col>
                  <Col>{initialChartData.prevPeriodPercent > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}</Col>
                </Row>
              </Col>
            </Row>
          )}
          marginBottom
        />
        <Area
          meta={{
            value: {
              alias: t('value'),
            },
          }}
          data={chartData || []}
          xField='date'
          yField='value'
          color='#7B2CBF'
          yAxis={false}
          xAxis={{ range: [0, 1], label: null }}
          height={160}
        />
      </>
    </ChartCard>
  );
};

export default AreaPlotChart;
