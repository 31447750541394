import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { getSalaryReport } from '../thunks';

export const getSalaryReportReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Get salary report

  builder
    .addCase(getSalaryReport.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(getSalaryReport.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getSalaryReport.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
