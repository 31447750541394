import { Image as AntImage } from 'antd';

import styled from 'styled-components';

export const Image = styled(AntImage)`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  padding: 3px;
`;
