import { Spin } from 'antd';

import styled from 'styled-components';

export const Loader = styled(Spin)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
`;
