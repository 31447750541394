import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { editCar } from '../thunks';

export const editCarReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Edit car data

  builder
    .addCase(editCar.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCar = action.payload;
    })
    .addCase(editCar.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(editCar.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
