import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Menu, Radio, Table, TableProps } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Roles, Statuses } from 'constants/global';
import { getCarManagerNames } from 'modules/cars';
import LinkCarModal from 'modules/cars/components/CarLinkModal';
import TableWrapper from 'modules/common/components/TableWrapper';
import { IDriver, IDriverTableData } from 'modules/driver/types';
import { IManagerTableData } from 'modules/manager/types';
import ActivateUserModal from 'modules/user/components/ActivateUserModal';
import DisableUserModal from 'modules/user/components/DisableUserModal';
import { useUsersListTable } from 'modules/user/hooks';
import { getDriversByManager, getUsers } from 'modules/user/slice/thunks';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import useDebounce from 'utils/debounce';

import { OptionsWrapper, StyledRangePicker, StyledSearch } from './styled';

// TODO: HIDDEN FOR v1.0
// import CreateNotificationModal from '../components/CreateNotificationModal;

type DateRangeType = [EventValue<Date>, EventValue<Date>] | null;

const UsersList: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.list' });
  const { isLoading, users, total } = useAppSelector(state => state.users);
  const { isAdmin } = useAppSelector(state => state.auth);
  const { managerNames, isLoading: isCarManagersLoading } = useAppSelector(state => state.cars);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState<Statuses>(Statuses.ALL);
  const { pathname } = useLocation();
  const roleFilter = useMemo(() => pathname.replace('/', '').slice(0, -1), [pathname]) as Roles;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const [dateRange, setDateRange] = useState<DateRangeType>(null);
  const [isLinkCarModalOpen, setIsLinkCarModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IDriver | null>(null);
  const [selectedManagerId, setSelectedManagerId] = useState<number>();
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IDriverTableData | IManagerTableData | null>(null);
  // TODO: HIDDEN FOR v1.0
  // const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] = useState<boolean>(false);

  // Total counter for table pagination
  const paginationTotal = useMemo(() => {
    switch (statusFilter) {
      case Statuses.ALL:
        return total.all;
      case Statuses.ACTIVE:
        return total.active;
      case Statuses.PENDING:
        return total.pending;
      default: // DISABLED
        return total.disabled;
    }
  }, [statusFilter, total]);

  const openDetailsHandler = (record: IDriverTableData | IManagerTableData) =>
    navigate(`/${record.role}s/${record.name!.replace(' ', '-')}?id=${record.id}`, {
      state: { role: record.role },
    });

  const openModalHandler = (record: IDriverTableData | IManagerTableData, isActivate?: boolean) => {
    setSelected(record);

    if (isActivate) {
      setIsActivateModalOpen(true);
    } else {
      setIsDisableModalOpen(true);
    }
  };

  // TODO: HIDDEN FOR v1.0
  // const openCreateNotificationModalHandler = (record: IDriverTableData | IManagerTableData) => {
  //   setSelected(record);
  //   setIsCreateNotificationModalOpen(true);
  // };

  useEffect(() => {
    if (isAdmin) dispatch(getCarManagerNames());
  }, []);

  // Get Users
  useEffect(() => {
    if (isAdmin) {
      dispatch(
        getUsers({
          role: roleFilter,
          status: statusFilter,
          page: page - 1,
          managerId: selectedManagerId,
          userName: search,
          start: dateRange?.[0] || undefined,
          end: dateRange?.[1] || undefined,
        })
      );
    } else {
      dispatch(
        getDriversByManager({
          userName: debouncedSearch,
          status: Statuses.ACTIVE,
          page: page - 1,
          start: dateRange?.[0] || undefined,
          end: dateRange?.[1] || undefined,
        })
      );
    }
  }, [roleFilter, page, statusFilter, selectedManagerId, debouncedSearch, dateRange]);

  const moreDropdown = useCallback(
    (record: IDriverTableData | IManagerTableData) =>
      record.status === Statuses.DISABLED ? (
        <Menu
          items={[
            {
              key: 0,
              label: t('getDetails'),
              onClick: () => openDetailsHandler(record),
            },
            {
              key: 2,
              danger: true,
              label: t('activate'),
              onClick: () => openModalHandler(record, true),
            },
          ]}
        />
      ) : (
        <Menu
          items={[
            {
              key: 0,
              label: t('getDetails'),
              onClick: () => openDetailsHandler(record),
            },
            // TODO: HIDDEN FOR v1.0
            // {
            //   key: 1,
            //   label: t('createNotification'),
            //   onClick: () => openCreateNotificationModalHandler(record),
            // },
            {
              key: 2,
              label: t('linkWithACar'),
              onClick: () => {
                setIsLinkCarModalOpen(true);
                setSelectedUser((record as IDriverTableData).data);
              },
            },
            {
              key: 3,
              danger: true,
              label: t('disable'),
              onClick: () => openModalHandler(record),
            },
          ]
            .filter(item => (record.role === Roles.MANAGER ? item.key !== 2 : item))
            .filter(item => (isAdmin ? item : item.key !== 1 && item.key !== 2))}
        />
      ),
    []
  );

  const [data, columns] = useUsersListTable({ data: users, role: roleFilter, moreDropdown, isAdmin, managers: managerNames, selectedManagerId });

  const onTableChange: TableProps<IDriverTableData | IManagerTableData>['onChange'] = (_, filters) => {
    if (filters.manager?.length && filters.manager[0]) setSelectedManagerId(Number(filters.manager[0]));
    else setSelectedManagerId(undefined);
  };

  const searchHandler = (searchText: string) => {
    setPage(1);
    setSearch(searchText);
  };

  const onRangePickerChange = (range: RangeValue<Date>) => {
    setPage(1);
    setDateRange(range);
  };

  const header = useMemo(
    () => (
      <>
        {isAdmin && (
          <Radio.Group
            size='large'
            value={statusFilter}
            onChange={({ target }) => {
              setStatusFilter(target.value);
              setPage(1);
            }}
          >
            <Radio.Button value='all'>
              {t('all')} ({total.all})
            </Radio.Button>
            <Radio.Button value='pending'>
              {t('pending')} ({total.pending})
            </Radio.Button>
            <Radio.Button value='active'>
              {t('active')} ({total.active})
            </Radio.Button>
            <Radio.Button value='disabled'>
              {t('disabled')} ({total.disabled})
            </Radio.Button>
          </Radio.Group>
        )}
        <OptionsWrapper>
          <StyledRangePicker size='large' onChange={onRangePickerChange} />
          <StyledSearch prefix={<SearchOutlined />} placeholder={t('search_placeholder')} size='large' onChange={({ target }) => searchHandler(target.value)} />
        </OptionsWrapper>
      </>
    ),
    [statusFilter, setStatusFilter, users]
  );

  return (
    <>
      <TableWrapper header={header}>
        <Table
          dataSource={data}
          rowClassName={record => (record.status === Statuses.DISABLED ? 'opacity-row' : '')}
          loading={isLoading || isCarManagersLoading}
          pagination={{ showSizeChanger: false, current: page, onChange: p => setPage(p), total: paginationTotal }}
          columns={columns}
          onChange={onTableChange}
          onRow={record => ({
            onClick: e => {
              if (
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-title-content') ||
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-item') ||
                (e.target as HTMLElement).classList.contains('anticon') ||
                (e.target as HTMLElement).tagName === 'svg'
              )
                return;
              openDetailsHandler(record);
            },
          })}
        />
      </TableWrapper>

      {selectedUser && <LinkCarModal isOpen={isLinkCarModalOpen} setIsOpen={setIsLinkCarModalOpen} user={selectedUser} setUser={setSelectedUser} />}
      {selected && (
        <>
          <DisableUserModal
            page={page}
            statusFilter={statusFilter}
            user={selected}
            setUser={setSelected}
            isModalOpen={isDisableModalOpen}
            setIsModalOpen={setIsDisableModalOpen}
          />
          <ActivateUserModal
            page={page}
            statusFilter={statusFilter}
            user={selected}
            setUser={setSelected}
            isModalOpen={isActivateModalOpen}
            setIsModalOpen={setIsActivateModalOpen}
          />
        </>
      )}
      {/* // TODO: HIDDEN FOR v1.0 */}
      {/* {selected && (
        <CreateNotificationModal
          user={selected}
          setUser={setSelected}
          isModalOpen={isCreateNotificationModalOpen}
          setIsModalOpen={setIsCreateNotificationModalOpen}
        />
      )} */}
    </>
  );
};

export default UsersList;
