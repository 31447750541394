import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-table-wrapper {
    flex: 1;
  }
`;
