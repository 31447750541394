import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { Entities } from 'constants/global';
import { RoleTag } from 'modules/common/components';
import { DateWrapper, Time } from 'modules/requests/pages/List/styled';
import { IRequestsListTableData } from 'modules/requests/types';

interface IProps {
  roleFilterValue?: Entities[];
}

export const generateHistoryRequestsColumns = (props: IProps): TableColumnsType<IRequestsListTableData> => {
  const { roleFilterValue } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  const roleFilterParams: ColumnFilterItem[] = [
    {
      value: Entities.MANAGER,
      text: t('manager'),
    },
    {
      value: Entities.DRIVER,
      text: t('driver'),
    },
    {
      value: Entities.CAR,
      text: t('car'),
    },
  ];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: value => <RoleTag role={value} />,
      filteredValue: roleFilterValue,
      filters: roleFilterParams,
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: reason => (reason ? t(reason) : '-'),
    },
  ];
};
