import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { getCarById } from '../thunks';

export const getCarByIdReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Get car by ID

  builder
    .addCase(getCarById.fulfilled, (state, action) => {
      state.selectedCar = action.payload;
      state.isLoading = false;
    })
    .addCase(getCarById.pending, state => {
      state.selectedCar = null;
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCarById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
