import { useId } from 'react';

import { TableColumnsType } from 'antd';

import { IEarningPerCar, IEarningPerCarCartTableData } from 'modules/analytics/types';

import { generateEarningPerCarChartColumns } from './generateEarningPerCarChartColumns';

interface IProps {
  earningsPerCar: IEarningPerCar[];
}

type ReturnType = [IEarningPerCarCartTableData[], TableColumnsType<IEarningPerCarCartTableData>];

export const useEarningPerCarChartTable = (props: IProps): ReturnType => {
  const { earningsPerCar } = props;
  const id = useId();
  const tableData = earningsPerCar.map((earningPerCar, index) => ({ ...earningPerCar, key: `${id}${index}` }));
  const tableColumns = generateEarningPerCarChartColumns();

  return [tableData, tableColumns];
};
