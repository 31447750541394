import { Dispatch, SetStateAction } from 'react';

import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';
import { IDriver } from 'modules/driver/types';

export enum CarsCounter {
  LINKED = 'linked',
  UNLINKED = 'unlinked',
}

export interface IProps {
  cars: ILinkCarTableData[];
  user: IDriver;
  selectedCar: ILinkCarTableData | null;
  setSelectedCar: Dispatch<SetStateAction<ILinkCarTableData | null>>;
  highlightedCars: number[];
  setHighlightedCars: Dispatch<SetStateAction<number[]>>;
}

export interface ICarsGroup {
  linked: number;
  unlinked: number;
  latitude: number;
  longitude: number;
  carIDs: number[];
}

export interface IGroupedCars {
  [key: string]: ICarsGroup;
}
