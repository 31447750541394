import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { IDriver } from 'modules/driver/types';
import { THUNKS } from 'modules/user/enums';
import { IUnlinkCarFromDriver } from 'modules/user/types';

// Unlink car from driver
export const unlinkCarFromDriver = createAsyncThunk(THUNKS.UNLINK_CAR_FROM_DRIVER, async ({ driver, car }: IUnlinkCarFromDriver, thunkApi) => {
  try {
    const data = await api.private.delete<{ data: IDriver }>(`${Endpoints.LINK}?driverId=${driver.user.id}&carId=${car.id}`).then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${car.registrationPlate} ${t<string>('global.notifications.success.unlinkCarFromDriver')} ${driver.user.firstName} ${driver.user.lastName}`,
    });

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
