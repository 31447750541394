import { IAnalyticsState } from 'modules/analytics/types';
import { ModuleExtraReducer } from 'types/Module';

import { getChangelog } from '../thunks';

export const getChangelogReducer: ModuleExtraReducer<IAnalyticsState> = builder => {
  // Get changelog

  builder
    .addCase(getChangelog.fulfilled, (state, action) => {
      state.changelog = action.payload;
      state.isLoading = false;
    })
    .addCase(getChangelog.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getChangelog.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
