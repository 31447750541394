import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { Roles } from 'constants/global';
import { t } from 'i18next';
import { IDriver } from 'modules/driver/types';
import { IManager } from 'modules/manager/types';
import { THUNKS } from 'modules/user/enums';
import { IChangeUserData } from 'modules/user/types';

// Edit user
export const editUser = createAsyncThunk(THUNKS.EDIT_USER, async ({ data, isAdmin }: IChangeUserData, thunkApi) => {
  try {
    // TODO: check if 'isAdmin' needed here
    const res = isAdmin
      ? await api.private.put<{ data: IManager | IDriver }>(`${Endpoints.ADMIN_USER}?id=${data.user.id}`, data).then(response => response.data.data)
      : await api.private.put<{ data: IManager | IDriver }>(`${Endpoints.MANAGER_DRIVER}?id=${data.user.id}`, data).then(response => response.data.data);

    // Todo: move notifications to related layers
    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description:
        data.user.role === Roles.MANAGER ? t<string>('global.notifications.success.editManager') : t<string>('global.notifications.success.editDriver'),
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
