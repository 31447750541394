import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IOption } from 'modules/analytics/types';
import { THUNKS } from 'modules/cars/enums';

// Get options
export const getOptions = createAsyncThunk(THUNKS.GET_OPTIONS, async (_, thunkApi) => {
  try {
    const [shifts, shiftPlans] = await Promise.all([
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_SHIFTS),
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_SHIFT_PLANS),
    ]).then(res => res.map(item => item.data.data));

    return {
      shifts,
      shiftPlans,
    };
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
