import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ICar, IGetCarById } from 'modules/cars/types';

// Get Car by Id
export const getCarById = createAsyncThunk(THUNKS.GET_CAR_BY_ID, async ({ id }: IGetCarById, thunkApi) => {
  try {
    return api.private.get<{ data: ICar }>(`${Endpoints.CAR}/${id}`).then(response => response.data.data);
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
