import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { AdjustmentType, THUNKS } from 'modules/salaries/enums';
import { IGetSalaryAdjustments, ISalaryAdjustment } from 'modules/salaries/types';

const generateAdjustmentTypeQuery = (adjustmentTypes: AdjustmentType[] | undefined): string => {
  if (!adjustmentTypes) {
    return '';
  }

  return adjustmentTypes.reduce((query: string, currentType: AdjustmentType) => {
    const updatedQuery = `${query}&adjustmentType=${currentType}`;
    return updatedQuery;
  }, '');
};

// Get salary adjustments
export const getSalaryAdjustments = createAsyncThunk(
  THUNKS.GET_SALARY_ADJUSTMENTS,
  async ({ driverId, date, adjustmentType }: IGetSalaryAdjustments, thunkApi) => {
    try {
      const baseQuery = `${Endpoints.SALARY_ADJUSTMENTS}?driverId=${driverId}&start=${date.start}&end=${date.end}`;
      const adjustmentTypesQuery = generateAdjustmentTypeQuery(adjustmentType);

      const data = await api.private.get<{ data: ISalaryAdjustment[] }>(`${baseQuery}${adjustmentTypesQuery}`).then(res => res.data.data);

      return data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
