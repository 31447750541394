export enum THUNKS {
  GET_OPTIONS = 'signUp/getOptions',
  CREATE_DRIVER_REQUEST = 'signUp/createDriverRequest',
  CREATE_MANAGER_REQUEST = 'signUp/createManagerRequest',
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  FORGOT_PASSWORD = 'forgotPassword',
  CHANGE_PASSWORD = 'changePassword',
  CHANGE_PROFILE = 'changeProfile',
  UPDATE_LANGUAGE = 'updateLanguage',
}
