import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer } from 'types/Module';

import { createDriverRequest } from '../thunks';

export const createDriverRequestReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Create Driver Request

  builder
    .addCase(createDriverRequest.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(createDriverRequest.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(createDriverRequest.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
