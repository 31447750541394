import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 24px 16px;
  padding-bottom: 0;
  width: 100%;

  .ant-table-wrapper {
    width: 100%;
  }

  .ant-table-row {
    cursor: pointer;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
`;
