import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IDriver } from 'modules/driver/types';
import { THUNKS } from 'modules/user/enums';
import { IGetDriversByManager } from 'modules/user/types';
import { convertIntoISODate } from 'utils';

interface IGetDriversByManagerResponse {
  data: {
    total: { active: number; disabled: number; pending: number };
    items: IDriver[];
  };
}

// Get drivers by MANAGER
export const getDriversByManager = createAsyncThunk(
  THUNKS.GET_DRIVERS_BY_MANAGER,
  async ({ status, page, pageSize = 10, userName, start, end }: IGetDriversByManager, thunkApi) => {
    try {
      const data = await api.private
        .get<IGetDriversByManagerResponse>(`${Endpoints.DRIVER}?start=${start && convertIntoISODate(start)}&end=${end && convertIntoISODate(end)}`, {
          params: { status, page, pageSize, userName },
        })
        .then(res => res.data.data);

      const total = { ...data.total, all: data.total.active + data.total.disabled + data.total.pending };

      return { drivers: data.items, total };
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
