// TODO refactor with backend
export const localeByPlan = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};

export // TODO should be refactored with backend
const localeByValue = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};
