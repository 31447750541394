import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { createAndSendAgreement } from '../thunks';

export const createAndSendAgreementReducer: ModuleExtraReducer<IRequestsState> = builder => {
  builder
    .addCase(createAndSendAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCarForAgreementRequest = action.payload;
    })
    .addCase(createAndSendAgreement.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(createAndSendAgreement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
