import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { setFinalTaxTableNumber } from '../thunks';

export const setFinalTaxTableNumberReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Set final tax table number

  builder
    .addCase(setFinalTaxTableNumber.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(setFinalTaxTableNumber.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(setFinalTaxTableNumber.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
