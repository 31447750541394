import { Key } from 'react';

import { IFinalPaymentsResult } from 'modules/salaries/types';

interface IProps {
  monthlyPayments: IFinalPaymentsResult | null;
  selectedPayoutsIds: Key[];
}

export const calculateMonthly = (props: IProps): { total: number; count: number } => {
  const { monthlyPayments, selectedPayoutsIds } = props;
  const selectedSalaries = monthlyPayments?.items?.filter(payment => selectedPayoutsIds.some(selectedId => selectedId === payment.id));
  const total = selectedSalaries?.reduce((sum, payment) => sum + payment.finalPayout, 0) || 0;
  const count = selectedSalaries?.length || 0;

  return { total, count };
};
