import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/index';

import { Button, CheckboxOptionType, Col, Radio, RadioChangeEvent, Row } from 'antd';

import { config } from 'config';
import { ReimbursementStatusEnum } from 'constants/global';
import { endOfMonth, startOfMonth } from 'date-fns';
import DatePicker from 'modules/common/components/DatePicker';
import { getReimbursements, setSelectedReimbursements, updateReimbursementStatus } from 'modules/reimbursements';
import { PaymentsSearchParamsEnum, PaymentsTabNamesEnum } from 'modules/reimbursements/enums/Payments';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSearchParams } from 'utils/searchParams';

import PayoutModal from '../PayoutModal';
import { StyledRow } from './styled';

const PaymentsHeader: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.tableHeader' });
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const { setSearchParams, getSearchParam } = useSearchParams();
  const { isLoading } = useAppSelector(store => store.reimbursements);
  const dispatch = useAppDispatch();

  const selectedDateRange = getSearchParam('dateRange');
  const dateRange: RangeValue<string> = selectedDateRange && JSON.parse(selectedDateRange);
  const selectedMonthSearchParam = getSearchParam(PaymentsSearchParamsEnum.SELECTED_MONTH);
  const selectedMonth = new Date(selectedMonthSearchParam || new Date());
  const page = Number(getSearchParam('page')) || 1;
  const pageSize = Number(getSearchParam('pageSize')) || config.DEFAULT_PAGE_SIZE;
  const managerId = getSearchParam('managerId');
  const driverId = getSearchParam('driverId');
  const status = getSearchParam('status');
  const payoutStatus = getSearchParam('payoutStatus');
  const type = getSearchParam('type');
  const tab = getSearchParam(PaymentsSearchParamsEnum.TAB) || PaymentsTabNamesEnum.REIMBURSEMENTS;

  const managerFilterValue: number[] = managerId ? JSON.parse(managerId) : [];
  const driverFilterValue: number[] = driverId ? JSON.parse(driverId) : [];
  const statusFilterValue: string[] = status ? JSON.parse(status) : [];
  const payoutStatusFilterValue: string[] = payoutStatus ? JSON.parse(payoutStatus) : [];
  const typeFilterValue: string[] = type ? JSON.parse(type) : [];

  const selectedIds = useAppSelector(store => store.reimbursements.selectedReimbursements);

  const currentTab = (getSearchParam(PaymentsSearchParamsEnum.TAB) as PaymentsTabNamesEnum) || PaymentsTabNamesEnum.REIMBURSEMENTS;

  const handleMonthChange = (value: Date) => {
    setSearchParams({ [PaymentsSearchParamsEnum.TAB]: tab, [PaymentsSearchParamsEnum.SELECTED_MONTH]: startOfMonth(value).toDateString() });
  };

  const fetchReimbursements = useCallback(() => {
    dispatch(
      getReimbursements({
        start: startOfMonth(selectedMonth),
        end: endOfMonth(selectedMonth),
        page: page - 1,
        pageSize,
        periodStart: dateRange?.[0] || undefined,
        periodEnd: dateRange?.[1] || undefined,
        managerId: managerFilterValue,
        driverId: driverFilterValue,
        status: statusFilterValue,
        payoutStatus: payoutStatusFilterValue,
        reimbType: typeFilterValue,
      })
    );
  }, [selectedDateRange, selectedMonthSearchParam, page, pageSize, managerId, driverId, status, payoutStatus, type]);

  const markAsPaid = async () => {
    await dispatch(
      updateReimbursementStatus({
        reimbursementIds: selectedIds,
        status: ReimbursementStatusEnum.RECEIVED,
      })
    );

    dispatch(setSelectedReimbursements([]));
    fetchReimbursements();
  };

  const closePayoutModal = () => {
    setIsPaymentModalVisible(false);
    dispatch(setSelectedReimbursements([]));
  };

  const onTabChange = (e: RadioChangeEvent) => {
    setSearchParams({ [PaymentsSearchParamsEnum.SELECTED_MONTH]: selectedMonth.toISOString(), [PaymentsSearchParamsEnum.TAB]: e.target.value });
  };

  const radioOptions: CheckboxOptionType[] = [
    { value: PaymentsTabNamesEnum.REIMBURSEMENTS, label: t('reimbursements') },
    { value: PaymentsTabNamesEnum.ADVANCE, label: t('advance') },
    { value: PaymentsTabNamesEnum.ADVANCE_MONTHLY, label: t('advanceMonthly') },
  ];

  return (
    <>
      <StyledRow justify='space-between'>
        <Col>
          <Row gutter={[40, 0]}>
            <Col>
              <Radio.Group value={currentTab} size='large' optionType='button' onChange={onTabChange} options={radioOptions} />
            </Col>
            <Col>
              <DatePicker.MonthPicker size='large' allowClear={false} value={selectedMonth} onChange={value => handleMonthChange(value || new Date())} />
            </Col>
          </Row>
        </Col>
        {tab === PaymentsTabNamesEnum.REIMBURSEMENTS && (
          <Col>
            <Row gutter={[12, 0]} align='middle'>
              {selectedIds.length > 0 && <Col>{t('selectedItems', { count: selectedIds.length })}</Col>}
              <Col>
                <Button loading={isLoading} onClick={() => setIsPaymentModalVisible(true)} disabled={selectedIds.length === 0} type='primary' size='large'>
                  {t('pay')}
                </Button>
              </Col>
              <Col>
                <Button onClick={markAsPaid} loading={isLoading} disabled={selectedIds.length === 0} size='large'>
                  {t('markAsPaid')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </StyledRow>
      <PayoutModal
        fetchReimbursements={fetchReimbursements}
        key={isPaymentModalVisible.toString()}
        isOpen={isPaymentModalVisible}
        selectedIds={selectedIds}
        closePayoutModal={closePayoutModal}
      />
    </>
  );
};

export default PaymentsHeader;
