import { TableColumnsType } from 'antd';

import { ICar, ICarTableData } from 'modules/cars/types';
import { ManagerNameType } from 'modules/manager/types';

import { generateCarTableColumns } from './generateCarTableColumns';
import { mapCars } from './mapCars';

interface IProps {
  cars: ICar[];
  moreDropdown: (record: ICarTableData) => JSX.Element;
  isAdmin: boolean;
  managers: ManagerNameType[];
  selectedManagerId: number[] | undefined;
}

type ReturnType = [ICarTableData[], TableColumnsType<ICarTableData>];

export const useCarsTable = (props: IProps): ReturnType => {
  const { cars, ...restProps } = props;
  const mappedCars = mapCars(cars);
  const tableColumns = generateCarTableColumns(restProps);

  return [mappedCars, tableColumns];
};
