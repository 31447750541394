import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/auth/enums';
import { ICreateManagerData } from 'modules/manager/types';

export const createManagerRequest = createAsyncThunk(THUNKS.CREATE_MANAGER_REQUEST, async (data: ICreateManagerData, thunkApi) => {
  try {
    await api.public.post(Endpoints.MANAGER_REQUEST, data);
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
