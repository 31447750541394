import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Form, Input, Modal, Select } from 'antd';

import { Entities } from 'constants/global';
import {
  declineCarRequest,
  declineDriverRequest,
  declineManagerRequest,
  getCarRequest,
  getDriverRequest,
  getHistoryRequest,
  getManagerRequest,
} from 'modules/requests';
import { IDriverRequest, IManagerRequest } from 'modules/requests/types';

import { IRequestsListTableData } from '../../types/IRequestsListTableData';
import { Note } from './styled';

interface IProps {
  entity?: Entities;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  request?: IRequestsListTableData | null;
  setRequest?: Dispatch<SetStateAction<IRequestsListTableData | null>>;
  page?: number;
}

const driverRequestDeclineReasons = ['driverRequestDeclineReasons_1', 'driverRequestDeclineReasons_2', 'driverRequestDeclineReasons_3'];
const fleetManagerRequestDeclineReasons = ['fleetManagerRequestDeclineReasons_1', 'fleetManagerRequestDeclineReasons_2'];
const carRequestDeclineReasons = ['carRequestDeclineReasons_1', 'carRequestDeclineReasons_2'];

const fieldsHandler = (reason: string, comment: string) => [
  {
    name: 'choseReason',
    value: reason || null,
  },
  {
    name: 'comment',
    value: comment,
  },
];

const DeclineModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, entity, request, setRequest, page = 1 }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.declineModal' });
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedRequest } = useAppSelector(state => state.requests);
  const [reason, setReason] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const resetHandler = () => {
    setIsModalOpen(false);
    setReason('');
    setComment('');
    if (request) setRequest!(null);
  };

  const declineRequestHandler = async () => {
    await form.validateFields();

    // Todo: refactor?

    if (entity === Entities.MANAGER) {
      await dispatch(declineManagerRequest({ id: (selectedRequest as IManagerRequest).userInfo.id || request!.id, reason, comment }));
    } else if (entity === Entities.DRIVER) {
      await dispatch(declineDriverRequest({ id: (selectedRequest as IDriverRequest)?.userInfo.id || request!.id, reason, comment }));
    } else {
      await dispatch(declineCarRequest({ id: selectedRequest?.id || request!.id, reason, comment }));
    }

    resetHandler();

    // Getting current state from store
    const { error } = store.getState().auth;
    if (error) return;

    if (request) {
      dispatch(getCarRequest({ page: page - 1 }));
      dispatch(getDriverRequest({ page: page - 1 }));
      dispatch(getHistoryRequest({ page: page - 1 }));
      dispatch(getManagerRequest({ page: page - 1 }));
    } else navigate('/requests');
  };

  const reasonOptionsHandler = () => {
    switch (entity) {
      case Entities.DRIVER:
        return driverRequestDeclineReasons.map(item => (
          <Select.Option value={item} key={item}>
            {t(item)}
          </Select.Option>
        ));
      case Entities.MANAGER:
        return fleetManagerRequestDeclineReasons.map(item => (
          <Select.Option value={item} key={item}>
            {t(item)}
          </Select.Option>
        ));
      case Entities.CAR:
        return carRequestDeclineReasons.map(item => (
          <Select.Option value={item} key={item}>
            {t(item)}
          </Select.Option>
        ));

      default:
        break;
    }
  };

  return (
    <Modal
      width={448}
      title={`${t('decline')} ${entity?.toLowerCase()}`}
      open={isModalOpen}
      destroyOnClose
      okText={t('decline')}
      onOk={declineRequestHandler}
      okButtonProps={{ danger: true }}
      onCancel={resetHandler}
    >
      <Note>
        {t('note_part1')}
        <span> {entity === Entities.CAR ? 'manager' : entity} </span>
        {t('note_part2')}
      </Note>
      <Form fields={fieldsHandler(reason, comment)} form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
        <Form.Item label={t('choseReason')} name='choseReason' rules={[{ required: true }]}>
          <Select onChange={val => setReason(val)} placeholder={t('choseReason_placeholder')}>
            {reasonOptionsHandler()}
          </Select>
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <Input.TextArea rows={1} onChange={({ target }) => setComment(target.value)} placeholder={t('comment_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeclineModal;
