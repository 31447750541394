import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { searchActiveCars } from '../thunks';

export const searchActiveCarsReducer: ModuleExtraReducer<ICarsState> = builder => {
  builder
    .addCase(searchActiveCars.fulfilled, (state, action) => {
      state.isLoading = false;
      state.activeCars = action.payload;
    })
    .addCase(searchActiveCars.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(searchActiveCars.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.activeCars = [];
    });
};
