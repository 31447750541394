import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IDriver } from 'modules/driver/types';
import { THUNKS } from 'modules/user/enums';
import { IGetUserById } from 'modules/user/types';

// Get Driver by Id
export const getDriverById = createAsyncThunk(THUNKS.GET_DRIVER_BY_ID, async ({ id }: IGetUserById, thunkApi) => {
  try {
    const res = await api.private.get<{ data: IDriver }>(`${Endpoints.DRIVER}/${id}`);
    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
