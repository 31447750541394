import { PayloadAction } from '@reduxjs/toolkit';
import { IDriver } from 'modules/driver/types';
import { IManager } from 'modules/manager/types';
import { IUsersState } from 'modules/user/types';

export const setSelectedUserReducer = {
  setSelectedUser: (state: IUsersState, action: PayloadAction<(IDriver | IManager) | null>) => {
    state.selectedUser = action.payload;
  },
};
