import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/auth/enums';
import { IChangePasswordRequest } from 'modules/auth/types';

export const changePassword = createAsyncThunk(THUNKS.CHANGE_PASSWORD, async ({ uuid, isCreate, ...data }: IChangePasswordRequest, thunkApi) => {
  try {
    if (uuid) {
      if (isCreate) {
        await api.public.post(`${Endpoints.PASSWORD}?uuid=${uuid}`, data);

        notification.success({
          placement: 'topRight',
          message: t<string>('global.notifications.success.successWord'),
          description: t<string>('global.notifications.success.nowYouCanLogIn'),
        });
      } else {
        await api.public.post(`${Endpoints.PASSWORD}?uuid=${uuid}`, data);

        notification.success({
          placement: 'topRight',
          message: t<string>('global.notifications.success.successWord'),
          description: t<string>('global.notifications.success.changePassword'),
        });
      }
    } else {
      await api.private.put(`${Endpoints.PASSWORD}`, data);

      notification.success({
        placement: 'topRight',
        message: t<string>('global.notifications.success.successWord'),
        description: t<string>('global.notifications.success.changePassword'),
      });
    }
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
