import { config } from 'config';
import { format } from 'date-fns';
import { IChangelog, IChangelogTableData } from 'modules/analytics/types';

export const mapChangelog = (changelog: IChangelog): IChangelogTableData[] =>
  changelog.items?.map(item => ({
    date: format(new Date(item.eventTime), config.DATE_FORMAT),
    time: format(new Date(item.eventTime), config.TIME_FORMAT),
    rawDate: item.eventTime,
    performer: `${item.performer.fistName} ${item.performer.lastName}`,
    type: item.objectType,
    action: item.eventType,
    object: item.objectInfo,
    reason: item.reason,
    key: item.id,
  }));
