import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ISearchCarResult } from 'modules/cars/types';

interface ISearchCarRequestParams {
  search: string;
}

export const searchActiveCars = createAsyncThunk(THUNKS.SEARCH_ACTIVE_CARS, async (params: ISearchCarRequestParams, thunkApi) => {
  try {
    return await api.private.get<{ data: ISearchCarResult[] }>(`${Endpoints.CAR_SEARCH}`, { params }).then(res => res.data.data);
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
