import { Spin } from "antd";

import styled from "styled-components";

export const UserName = styled.p`
  font-weight: 600;
  font-size: 20px;
`;

export const StyleSpin = styled(Spin)`
  align-self: center;
`;
