import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType, Tooltip } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import { IDriverTableData } from 'modules/driver/types';
import { ManagerNameType } from 'modules/manager/types';
import { DateWrapper, MoreButton, PendingName, Time } from 'modules/user/pages/UsersList/styled';

interface IProps {
  isAdmin: boolean;
  managers: ManagerNameType[];
  selectedManagerId: number | undefined;
  moreDropdown: (user: IDriverTableData) => JSX.Element;
}

export const generateDriverColumns = (props: IProps): TableColumnsType<IDriverTableData> => {
  const { isAdmin, managers, selectedManagerId, moreDropdown } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const managerFilterOptions: ColumnFilterItem[] = managers.map(item => ({ key: item[0], text: item[1], value: item[0] }));
  const managerFilterValue = selectedManagerId ? [selectedManagerId] : [];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) =>
        record.status === Statuses.PENDING ? (
          <PendingName>
            {value} <ClockCircleOutlined />
          </PendingName>
        ) : (
          value
        ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
      className: !isAdmin ? 'hide' : '',
    },
    {
      title: t('manager'),
      dataIndex: 'manager',
      key: 'manager',
      className: !isAdmin ? 'hide' : '',
      width: 165,
      render: val => (val.length ? val.join(', ') : '-'),
      filteredValue: managerFilterValue,
      filters: managerFilterOptions,
      filterMultiple: false,
      filterSearch: true,
    },
    {
      title: t('carAccess'),
      dataIndex: 'carAccess',
      key: 'carAccess',
      width: 152,
      render: val => <Tooltip title={val}>{val.length > 14 ? `${val.slice(0, 15)}...` : val}</Tooltip>,
    },
    {
      title: t('earnings'),
      className: !isAdmin ? 'hide' : '',
      dataIndex: 'earnings',
      key: 'earnings',
    },
    {
      title: t('employment'),
      dataIndex: 'employment',
      key: 'employment',
    },
    {
      title: t('efficiency'),
      dataIndex: 'efficiency',
      key: 'efficiency',
    },
    {
      title: t('fulfillmentRate'),
      dataIndex: 'fulfillmentRate',
      key: 'fulfillmentRate',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={moreDropdown(record)} trigger={['click']} placement='bottom'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
