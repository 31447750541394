export default {
  plate: 'Plate',
  make: 'Make',
  address: 'Address',
  insurance: 'Insurance',
  model: 'Model',
  year: 'Year',
  shiftPlan: 'Shift plan',
  splitTime: 'Split time',
  maxMil: 'Max mil.',
  driverPercent: 'Driver %',
  date: 'Date',
  name: 'Name',
  phone: 'Phone',
  earnings: 'Earnings',
  employment: 'Employment',
  efficiency: 'Efficiency',
  fulfillment: 'Fulfillment',
  status: 'Status',
  provision: 'Manager provision',
  provision_word: 'Provision',
  start: 'Start',
  end: 'End',
  schedule: 'Shift schedule',
  shiftType: 'Shift type',
  type: 'Type',
  role: 'Role',
  email: 'Email',
  reason: 'Reason',
  performer: 'Performer',
  manager: 'Fleet manager',
  carAccess: 'Car access',
  fulfillmentRate: 'Fulfillment rate',
  drivers: 'Drivers',
  driver: 'Driver',
  cars: 'Cars',
  car: 'Car',
  shiftsAvailable: 'Shift available',
  drivenProvision: 'Driver provision',
  action: 'Action',
  object: 'Object',
  period: 'Period',
  roadbirdFee: 'RoadBird fee',
  netto: 'Netto',
  efficiencyAdj: 'Efficiency Adj.',
  payout: 'Payout',
  taxTable: 'Tax table',
  brutto: 'Brutto',
  payouts: 'Payouts',
  netSalary: 'Net salary',
  finalPayment: 'Final payout',
  employerTax: 'Employer tax',
  adjustments: 'Adjustments',
  driverProvision: 'Driver provision',
  tax: 'Tax',
  comment: 'Comment',
  availableShifts: 'Shifts available',
  bookedShiftsPercent: 'Shifts booked',
  driverKm: 'Driven km',
  grade: 'Employment gr.',
  netEarning: 'Net earnings',
  tips: 'Tips',
  incentives: 'Incentives',
  taxes: 'Taxes',
  reimbursements: 'Reimbursements',
  fleetManager: 'Fleet manager',
  good: 'Good job',
  bad: 'Bad variables',
  shiftsPlan: 'Shifts plan',
  shiftSplitTimes: 'Shift split times',
  goToLink: 'Go to link',
  firstPlan: '1 shift per day',
  secondPlan: '2 shifts per day',
  thirdPlan: '3 shifts per day',
  paid: 'Paid',
  notPaid: 'Not paid',
  fullDay: '24 hours',
  halfDay: '12 hours',
  thirdDay: '8 hours',
  driverDisableByAdminReasons_1: 'More than 10 shifts missed',
  driverDisableByAdminReasons_2: 'Breach of agreement',
  driverDisableByAdminReasons_3: 'No activity for longer than 45 days',
  driverDisableByAdminReasons_4: 'Driver initiated the end of the agreement',
  driverDisableByManagerReasons_1: 'Efficiency less than 70%',
  driverDisableByManagerReasons_2: 'More than 10 shifts missed',
  driverDisableByManagerReasons_3: 'No activity for longer than 45 days',
  fleetManagerDisableReasons_1: 'Breach of agreement',
  fleetManagerDisableReasons_2: 'No cars added for longer than 45 days',
  fleetManagerDisableReasons_3: 'Fleet manager initiated the end of the agreement',
  driverRequestDeclineReasons_1: 'Absence of Taxi license',
  driverRequestDeclineReasons_2: 'Bad criminal record or repeated misconduct',
  driverRequestDeclineReasons_3: 'Bad credit history',
  fleetManagerRequestDeclineReasons_1: 'Absence of Taxi license',
  fleetManagerRequestDeclineReasons_2: 'Company data is invalid',
  carRequestDeclineReasons_1: 'Absence of some documents',
  carRequestDeclineReasons_2: 'Necessity of repair',
  fleetManagerActivateReasons_1: 'Contract renewal',
  fleetManagerActivateReasons_2: 'Pending review',
  driverActivateReasons_1: 'Contract renewal',
  driverActivateReasons_2: 'Pending review',
  carDisableReasons_1: 'Car accident',
  carDisableReasons_2: 'Necessity of repair',
  carActivateReasons_1: 'Damages fixed',
  carActivateReasons_2: 'Pending review',
  swishStatus: 'Swish status',
  created: 'Created',
  debited: 'Debited',
  error: 'Error',
  initiated: 'Initiated',
  planned: 'Planned',
  submitted: 'Submitted',
  reimbursement_short: 'Reimbs.',
  final: 'Final',
  declined: 'Declined',
  amount: 'Amount',
  photo: 'Photo',
  vat: 'VAT',
  processedDate: 'Processed at',
  dispute: 'Dispute',
  reimbursementsVat: 'Reimbursements VAT',
  hours: 'hours',
  shiftsPerDay: 'shifts per day',
  shiftPerDay: 'shift per day',
  limit: 'Limit',
  advancedAmount: 'Advanced amount',
  availableBalance: 'Available balance',
  advanceUsed: 'Advance used',
  requested: 'Requested',
  debtLeftover: 'Debt leftover',
  previousMonthDebt: 'Previous month debt',
  advanceLeftover: 'Advance leftover',

  swishStatuses: {
    created: 'Created',
    debited: 'Debited',
    error: 'Error',
    initiated: 'Initiated',
    planned: 'Planned',
    submitted: 'Submitted',
    paid: 'Paid',
  },
  reimbursementTypes: {
    'car-washing': 'Car washing',
    refueling: 'Refueling',
    transportation: 'Transportation',
    fines: 'Fines',
    other: 'Others',
    expenses: 'Expenses',
  },
  managerStatuses: {
    notPaid: 'Pending',
    paid: 'Accepted',
    declined: 'Declined',
  },
  advancePaymentStatuses: {
    pending: 'Pending',
    error: 'Error',
    paid: 'Paid',
    debited: 'Debited',
  },
};
