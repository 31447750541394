import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/auth/enums';
import { ICreateDriverRequest } from 'modules/auth/types';

export const createDriverRequest = createAsyncThunk(THUNKS.CREATE_DRIVER_REQUEST, async ({ driverData, documents }: ICreateDriverRequest, thunkApi) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(driverData));
    documents.forEach(file => formData.append('files', file.originFileObj as Blob));

    if (!driverData.comment) delete driverData.comment;

    await api.public.post(Endpoints.DRIVER_REQUEST, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
