import { config } from 'config';
import { format } from 'date-fns';

import { DateWrapper, Time } from '../../styled';
import { ITaximeterTableItem } from '../../types';
import { IFieldWithCount, ITableColumn, ITableField } from '../types';

export const TaximeterFieldsWithCount: IFieldWithCount[] = [
  {
    key: 'deletedTaximeterRecordsNum',
    titleLocale: 'deletedTaximeterRecords',
  },
  {
    key: 'insertedTaximeterRecordsNum',
    titleLocale: 'insertedTaximeterRecords',
  },
  {
    key: 'deletedTaximeterShiftsNum',
    titleLocale: 'deletedTaximeterShifts',
  },
  {
    key: 'insertedTaximeterShiftsNum',
    titleLocale: 'insertedTaximeterShifts',
  },
  {
    key: 'deletedShiftReportRecordsNum',
    titleLocale: 'deletedShiftReportRecords',
  },
  {
    key: 'insertedShiftReportRecordsNum',
    titleLocale: 'insertedShiftReportRecords',
  },
];

const TaximeterTableColumns: ITableColumn[] = [
  {
    name: 'carRegistrationPlate',
    titleLocale: 'carRegistrationPlate',
    renderFunction: (_, data: ITaximeterTableItem) => data.carRegistrationPlate,
  },
  {
    name: 'taximeterStartTime',
    titleLocale: 'taximeterStartTime',
    renderFunction: (_, data: ITaximeterTableItem) => {
      const date = format(new Date(data.taximeterStartTime), config.DATE_FORMAT);
      const time = format(new Date(data.taximeterStartTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'taximeterEndTime',
    titleLocale: 'taximeterEndTime',
    renderFunction: (_, data: ITaximeterTableItem) => {
      const date = format(new Date(data.taximeterEndTime), config.DATE_FORMAT);
      const time = format(new Date(data.taximeterEndTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'drivenKm',
    titleLocale: 'drivenKm',
    renderFunction: (_, data: ITaximeterTableItem) => data.drivenKm.toString(),
  },
  {
    name: 'driverTaxiIdNumber',
    titleLocale: 'driverTaxiIdNumber',
    renderFunction: (_, data: ITaximeterTableItem) => data.driverTaxiIdNumber,
  },
];

export const TaximeterTables: ITableField[] = [
  { key: 'noCarTaximeterShifts', titleLocale: 'noCarTaximeterShifts', columnsPreset: TaximeterTableColumns },
  { key: 'noDriverTaximeterShifts', titleLocale: 'noDriverTaximeterShifts', columnsPreset: TaximeterTableColumns },
  { key: 'noSlingShiftTaximeterShifts', titleLocale: 'noSlingShiftTaximeterShifts', columnsPreset: TaximeterTableColumns },
];
