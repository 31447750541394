import styled from 'styled-components';

interface IProps {
  alignStart?: boolean;
  justifyCenter?: boolean;
  smallPadding?: boolean;
}

export const Container = styled.div<IProps>`
  min-height: 100%;
  max-width: 1920px;
  padding: 64px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 980px) {
    padding: 24px;
  }

  ${({ alignStart }) => alignStart && 'align-items: flex-start;'};
  ${({ justifyCenter }) => justifyCenter && 'justify-content: center;'};
  ${({ smallPadding }) => smallPadding && 'padding: 16px 24px 24px;'}
`;
