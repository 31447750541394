import { FC } from 'react';

import { TableHeader, Wrapper } from './styled';

interface IProps {
  header: JSX.Element;
  children: JSX.Element;
}

const TableWrapper: FC<IProps> = ({ header, children }) => (
  <Wrapper>
    <TableHeader>{header}</TableHeader>
    {children}
  </Wrapper>
);

export default TableWrapper;
