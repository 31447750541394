import { TableColumnsType } from 'antd';

import { IEarningPerCarCartTableData } from 'modules/analytics/types';
import { currencyServices } from 'services/currencyServices';

export const generateEarningPerCarChartColumns = (): TableColumnsType<IEarningPerCarCartTableData> => [
  {
    key: 'carRegistrationPlate',
    dataIndex: 'carRegistrationPlate',
  },
  {
    key: 'earning',
    dataIndex: 'earning',
    align: 'right',
    render: (_, tableData) => currencyServices.convertToCurrency(tableData.earning),
  },
];
