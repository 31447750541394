import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { IUserCarTableData } from 'modules/user/types';

export const generateUserCarColumns = (): TableColumnsType<IUserCarTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('plate'),
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('insurance'),
      dataIndex: 'insurance',
      key: 'insurance',
    },
    {
      title: t('model'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('shiftPlan'),
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: t('splitTime'),
      dataIndex: 'splitTime',
      key: 'splitTime',
    },
    {
      title: t('maxMil'),
      dataIndex: 'maxMil',
      key: 'maxMil',
    },
    {
      title: t('driverPercent'),
      dataIndex: 'persent',
      key: 'persent',
    },
  ];
};
