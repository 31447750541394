import { Button, Form } from 'antd';

import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  align-items: center;
  color: #070707;
  margin-bottom: 8px;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #393939;
  margin-bottom: 40px;
`;

export const StyledForm = styled(Form)`
  width: 440px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const SelectLanguage = styled.div`
  margin-top: 12px;
`;
