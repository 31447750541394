import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch } from 'store';

import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';

import { createManagerRequest } from 'modules/auth';
import { ManagerSteps } from 'modules/auth/enums';
import { IManagerStepsProps } from 'modules/auth/types';
import { ICreateManagerData } from 'modules/manager/types';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (managerData: ICreateManagerData) => [
  {
    name: 'companyNumber',
    value: managerData.companyInfo.companyRegistrationNumber || null,
  },
  {
    name: 'companyName',
    value: managerData.companyInfo.companyName || null,
  },
  {
    name: 'postalCode',
    value: managerData.companyInfo.companyAddress.postalCode || null,
  },
  {
    name: 'city',
    value: managerData.companyInfo.companyAddress.city || null,
  },
  {
    name: 'street',
    value: managerData.companyInfo.companyAddress.street || null,
  },
  {
    name: 'redovisnigscentral',
    value: managerData.accountingCenter || null,
  },
  {
    name: 'agreement',
    value: managerData.accountingAcceptance,
  },
  {
    name: 'securityNumber',
    value: managerData.socialSecurityNumber || null,
  },
  {
    name: 'swishNumber',
    value: managerData.swishNumber || null,
  },
];

const ThirdStep: FC<IManagerStepsProps> = ({ setStep, managerData, setManagerData }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.managerRole.steps.${ManagerSteps.SECOND}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    await dispatch(createManagerRequest(managerData));

    // Getting current state from store
    const { error } = store.getState().auth;
    if (!error) navigate('/final');
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(managerData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('companyNumber')} name='companyNumber' rules={[{ required: true, len: 10 }]}>
        <Input
          type='number'
          placeholder={t('companyNumber_placeholder')}
          onChange={({ target }) => setManagerData(prev => ({ ...prev, companyInfo: { ...prev.companyInfo, companyRegistrationNumber: target.value }}))}
        />
      </Form.Item>
      <Form.Item label={t('companyName')} name='companyName' rules={[{ required: true }]}>
        <Input
          placeholder={t('companyName_placeholder')}
          onChange={({ target }) => setManagerData(prev => ({ ...prev, companyInfo: { ...prev.companyInfo, companyName: target.value }}))}
        />
      </Form.Item>
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('postalCode')} name='postalCode' rules={[{ required: true, len: 5, message: t('postalCodeLengthMessage') }]}>
              <Input
                type='number'
                onChange={({ target }) =>
                  setManagerData(prev => ({
                    ...prev,
                    companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, postalCode: target.value }},
                  }))
                }
                placeholder={t('postalCode_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('city')} name='city' rules={[{ required: true }]}>
              <Input
                onChange={({ target }) =>
                  setManagerData(prev => ({
                    ...prev,
                    companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, city: target.value }},
                  }))
                }
                placeholder={t('city_placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item label={t('street')} name='street' rules={[{ required: true }]}>
        <Input
          onChange={({ target }) =>
            setManagerData(prev => ({
              ...prev,
              companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, street: target.value }},
            }))
          }
          placeholder={t('street_placeholder')}
        />
      </Form.Item>
      <Form.Item label={t('redovisnigscentral')} name='redovisnigscentral' rules={[{ required: true }]}>
        <Select placeholder={t('redovisnigscentral_placeholder')} onChange={val => setManagerData(prev => ({ ...prev, accountingCenter: val }))}>
          <Select.Option value='halda'>Halda</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t('securityNumber')}
        name='securityNumber'
        rules={[
          {
            required: true,
            validator: (_, value: string) =>
              new Promise((resolve, reject) => {
                const textWithoutDashes = value.replace(/-/g, '');
                const { length } = textWithoutDashes;

                if (length >= 10 && length <= 12) resolve('');
                else reject(new Error());
              }),
            message: t('lengthValidationMessage10_12'),
          },
          {
            validator: (_, value: string) =>
              new Promise((resolve, reject) => {
                const textWithoutDashes = value.replace(/-|[0-9]/g, '');
                const { length } = textWithoutDashes;

                if (length === 0) resolve('');
                else reject(new Error());
              }),
            message: t('wrongDigestError'),
          },
        ]}
      >
        <Input
          placeholder={t('securityNumber_placeholder')}
          onChange={({ target }) => setManagerData(prev => ({ ...prev, socialSecurityNumber: target.value }))}
        />
      </Form.Item>
      <Form.Item label={t('swishNumber')} name='swishNumber' rules={[{ max: 15, min: 8 }]}>
        <Input
          type='number'
          placeholder={t('swishNumberPlaceholder')}
          onChange={({ target }) => setManagerData(prev => ({ ...prev, swishNumber: target.value }))}
        />
      </Form.Item>
      <Form.Item
        name='agreement'
        valuePropName='checked'
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t('agreement_message')))),
          },
        ]}
      >
        <Checkbox checked onChange={({ target }) => setManagerData(prev => ({ ...prev, accountingAcceptance: target.checked }))}>
          {t('agreement')}
        </Checkbox>
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default ThirdStep;
