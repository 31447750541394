import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { changePasswordReducer } from './changePassword';
import { changeProfileReducer } from './changeProfile';
import { createDriverRequestReducer } from './createDriverRequest';
import { createManagerRequestReducer } from './createManagerRequest';
import { forgotPasswordReducer } from './forgotPassword';
import { getOptionsReducer } from './getOptions';
import { signInReducer } from './signIn';
import { updateLanguageReducer } from './updateLanguage';

const extraReducers: ModuleExtraReducer<IAuthState>[] = [
  changePasswordReducer,
  changeProfileReducer,
  createDriverRequestReducer,
  createManagerRequestReducer,
  forgotPasswordReducer,
  getOptionsReducer,
  signInReducer,
  updateLanguageReducer,
];

export const buildAuthReducers: ModuleExtraReducerBuilder<IAuthState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};
