import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { ISwishStatus } from 'modules/salaries/types';

// Get swish statuses
export const getSwishStatuses = createAsyncThunk(THUNKS.GET_SWISH_STATUSES, async (_, thunkApi) => {
  try {
    const res = await api.private.get<{ data: ISwishStatus[] }>(Endpoints.GET_SWISH_STATUSES);

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
