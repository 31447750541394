import { Breadcrumb, Button, Divider, Layout, Menu, Space } from 'antd';

import styled from 'styled-components';

const { Header, Content } = Layout;

export const StyledHeader = styled(Header)`
  padding: 16px 24px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 40px;
`;

export const StyledMenu = styled(Menu)`
  min-width: 1000px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > span svg {
    width: 20px;
    height: 20px;
    margin-right: 44px;
  }
`;

export const Username = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  cursor: pointer;
  display: flex;
  align-items: center;

  > span svg {
    height: 12px;
    width: 12px;
    margin-left: 6px;
    fill: #070707;
  }
`;

export const StyledContent = styled(Content)`
  height: calc(100vh - 64px);
  background-color: #f5f5f5;
  overflow: auto;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  text-transform: capitalize;
  margin-bottom: 16px;
`;

export const UploadReportsButton = styled(Button)`
  margin-right: 40px;
`;

export const StyledDropdown = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  .ant-dropdown-menu {
    box-shadow: none;
  }
`;

export const SupportText = styled.span`
  color: ${({ theme }) => theme.gray};
`;

export const SupportLink = styled.a`
  color: ${({ theme }) => theme.primary};
`;

export const StyledSpace = styled.a`
  padding: 16;
`;

export const DividerWithoutMargin = styled(Divider)`
  margin: 0px;
`;

export const SpaceWithPadding = styled(Space)`
  padding: 16px;
`;
