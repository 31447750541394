import { Select } from 'antd';

import styled from 'styled-components';

export const Note = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  margin-bottom: 24px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;
