import { IAuthState, IAuthUser } from 'modules/auth/types';
import { setUser } from 'modules/user';
import { ModuleExtraReducer } from 'types/Module';

import { changeProfile } from '../thunks';

export const changeProfileReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Change admin contact info

  builder
    .addCase(changeProfile.fulfilled, (state, action) => {
      const updatedProfile = state.isAdmin ? action.payload : { ...state.user, user: action.payload };
      state.user = updatedProfile as IAuthUser;
      setUser(JSON.stringify(updatedProfile));
      state.isLoading = false;
    })
    .addCase(changeProfile.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(changeProfile.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
