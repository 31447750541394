import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IGetReimbursementsRequest, IGetReimbursementsResponse } from 'modules/reimbursements/types';

export const getReimbursements = createAsyncThunk(THUNKS.GET_REIMBURSEMENTS, async ({ pageSize = 10, ...restProps }: IGetReimbursementsRequest, thunkApi) => {
  try {
    const res = await api.private.get<{ data: IGetReimbursementsResponse }>(`${Endpoints.GET_REIMBURSEMENTS}`, {
      params: {
        pageSize,
        ...restProps,
      },
    });

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
