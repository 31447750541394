import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { carInfoLocalesByValue } from 'modules/requests/locales';
import { FieldBlock, InfoBlock, Label, Title, Value } from 'modules/requests/pages/Details/styled';
import { ICarRequest } from 'modules/requests/types';

import { ColumnWrapper, DetailsWrapper } from './styled';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.car' });
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: ICarRequest };

  return (
    <InfoBlock>
      <Title>
        {t('carWord')} {selectedRequest?.registrationPlate} {t('detailsWord')}
      </Title>
      <DetailsWrapper>
        <ColumnWrapper>
          <FieldBlock>
            <Label>{t('plate')}</Label>
            <Value>{selectedRequest?.registrationPlate}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('make')}</Label>
            <Value>{selectedRequest?.make}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('model')}</Label>
            <Value>{selectedRequest?.model}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('year')}</Label>
            <Value>{selectedRequest?.year}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('insurance')}</Label>
            <Value>{selectedRequest?.insuranceAgreement}</Value>
          </FieldBlock>
        </ColumnWrapper>
        <ColumnWrapper>
          {selectedRequest?.shiftPlan && (
            <FieldBlock>
              <Label>{t('shiftPlan')}</Label>
              <Value>{t(carInfoLocalesByValue[selectedRequest?.shiftPlan.value])}</Value>
            </FieldBlock>
          )}
          {selectedRequest?.shiftSplitTimes && (
            <FieldBlock>
              <Label>{t('splitTimes')}</Label>
              <Value>{t(carInfoLocalesByValue[selectedRequest?.shiftSplitTimes.value])}</Value>
            </FieldBlock>
          )}
          <FieldBlock>
            <Label>{t('percentage')}</Label>
            <Value>{selectedRequest?.driverPercent}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('maxMill')}</Label>
            <Value>{selectedRequest?.mileageMax}</Value>
          </FieldBlock>
          <FieldBlock>
            <Label>{t('address')}</Label>
            <Value>
              {selectedRequest?.carAddress.street}, {selectedRequest?.carAddress.city}, {selectedRequest?.carAddress.postalCode}
            </Value>
          </FieldBlock>
        </ColumnWrapper>
      </DetailsWrapper>
    </InfoBlock>
  );
};

export default Info;
