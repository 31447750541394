import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Col, Menu, notification, Row, Spin, Table, Tooltip } from 'antd';

import { Roles, SalaryStatus } from 'constants/global';
import { endOfMonth, startOfMonth } from 'date-fns';
import { changeFinalSalaryStatus, changeSalaryStatus } from 'modules/salaries';
import { MoreDropDown } from 'modules/salaries/enums';
import { useFinalSalaryTable, useSalaryByWeekTable } from 'modules/salaries/hooks';
import { IFinalPayment, ISalaryByWeekTableData } from 'modules/salaries/types';
import { currencyServices } from 'services/currencyServices';
import { useSearchParams } from 'utils/searchParams';

import AddAdjustmentsModal from '../AddAdjustmentsModal';
import { AddFinalAdjustmentModal } from '../AddFinalAdjustmentModal';
import { TableLabel } from '../Details/styled';
import DetailsModal from '../GetAdjustmentsModal';
import { GetFinalAdjustmentDetailsModal } from '../GetFinalAdjustmentDetails';
import TaxTableModal from '../TaxTableModal';
import { BoldColText, HostColText, MoneyBoldColText, MoneyHostColText, RowVatContainer, StyledInfoIcon, VatContainer } from './styled';

type PropsType = {
  getSalaryByRole: (dateRange: [Date, Date]) => void;
};

const SalaryInfo: FC<PropsType> = ({ getSalaryByRole }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const { selectedUserSalary, isLoading } = useAppSelector(state => state.salaries);
  const [isAddAdjustmentsModalOpen, setIsAddAdjustmentsModalOpen] = useState<boolean>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isAddFinalAdjustmentModalOpen, setIsAddFinalAdjustmentModalOpen] = useState<boolean>(false);
  const [isGetFinalAdjustmentModalOpen, setIsGetFinalAdjustmentModalOpen] = useState<boolean>(false);
  const [isTaxTableOpen, setIsTaxTableOpen] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState<ISalaryByWeekTableData | null>(null);
  const dispatch = useAppDispatch();
  const { isAdmin } = useAppSelector(state => state.auth);
  const { getSearchParam } = useSearchParams();

  const userId = useMemo(() => getSearchParam('id')!, []);
  const userRole = useMemo(() => getSearchParam('role') || Roles.MANAGER, [isAdmin]);
  const endDate = getSearchParam('endDate');

  const dateRange = useMemo<[Date, Date]>(
    () => [startOfMonth(endDate ? new Date(endDate) : new Date()), endOfMonth(isAdmin && endDate ? new Date(endDate) : new Date())],
    [endDate]
  );

  const selectedUserRole = selectedUserSalary?.manager ? Roles.MANAGER : Roles.DRIVER;

  useEffect(() => {
    getSalaryByRole(dateRange);
  }, []);

  const markAsPaidHandler = async (salaryId: number) => {
    await dispatch(changeSalaryStatus({ salaryIds: [salaryId], status: SalaryStatus.PAID }));

    const { error } = store.getState().salaries;

    if (error) {
      return notification.error({
        placement: 'topRight',
        message: error,
      });
    }

    getSalaryByRole(dateRange);
  };

  const markAsPaidMonthHandler = async (paymentId: number) => {
    await dispatch(changeFinalSalaryStatus({ finalPaymentIds: [paymentId], status: SalaryStatus.PAID }));

    const { error } = store.getState().salaries;

    if (error) {
      return notification.error({
        placement: 'topRight',
        message: error,
      });
    }

    getSalaryByRole(dateRange);
  };

  const getDetailsHandler = (record: ISalaryByWeekTableData) => {
    setSelectedSalary(record);

    setIsDetailsModalOpen(true);
  };

  const addAdjustmentsHandler = (record: ISalaryByWeekTableData) => {
    setSelectedSalary(record);
    setIsAddAdjustmentsModalOpen(true);
  };

  const moreDropdownMonthPayment = useCallback(
    (record: IFinalPayment) => {
      const menuItems = [
        {
          key: MoreDropDown.MARK_AS_PAID,
          label: t(MoreDropDown.MARK_AS_PAID),
          onClick: () => markAsPaidMonthHandler(record.id),
        },
        {
          key: MoreDropDown.CHOOSE_TAX_TABLE,
          label: t(MoreDropDown.CHOOSE_TAX_TABLE),
          onClick: () => setIsTaxTableOpen(true),
        },

        { key: MoreDropDown.ADD_ADJUSTMENTS, label: t(MoreDropDown.ADD_ADJUSTMENTS), onClick: () => setIsAddFinalAdjustmentModalOpen(true) },

        { key: MoreDropDown.GET_DETAILS, label: t(MoreDropDown.GET_DETAILS), onClick: () => setIsGetFinalAdjustmentModalOpen(true) },
      ];

      const isPaidSalary = record.status === SalaryStatus.PAID;

      const displayedTabs = isPaidSalary
        ? [MoreDropDown.GET_DETAILS]
        : [MoreDropDown.ADD_ADJUSTMENTS, MoreDropDown.GET_DETAILS, MoreDropDown.CHOOSE_TAX_TABLE, MoreDropDown.MARK_AS_PAID];

      const filteredMenuItems = menuItems.filter(menuItem => displayedTabs.includes(menuItem.key));
      return !!filteredMenuItems.length ? <Menu items={filteredMenuItems} /> : <Menu items={[{ key: '0', label: t('noActions'), disabled: true }]} />;
    },
    [dateRange]
  );

  const moreDropdownWeekPayment = useCallback(
    (record: ISalaryByWeekTableData) => {
      const menuItems = [
        {
          key: MoreDropDown.MARK_AS_PAID,
          label: t(MoreDropDown.MARK_AS_PAID),
          onClick: () => markAsPaidHandler(record.salaryId!),
        },
        {
          key: MoreDropDown.GET_DETAILS,
          label: t(MoreDropDown.GET_DETAILS),
          onClick: () => getDetailsHandler(record),
        },
        {
          key: MoreDropDown.ADD_ADJUSTMENTS,
          label: t(MoreDropDown.ADD_ADJUSTMENTS),
          onClick: () => addAdjustmentsHandler(record),
        },
      ];

      const isPaidSalary = record.status === SalaryStatus.PAID;
      const isDriverSelected = userRole === Roles.DRIVER;

      const driverSalaryInfoTabs = isPaidSalary
        ? [MoreDropDown.GET_DETAILS]
        : [MoreDropDown.ADD_ADJUSTMENTS, MoreDropDown.GET_DETAILS, MoreDropDown.MARK_AS_PAID];

      const managerSalaryTabs = isPaidSalary ? [] : [MoreDropDown.MARK_AS_PAID];
      const displayedTabs = isDriverSelected ? driverSalaryInfoTabs : managerSalaryTabs;
      const filteredMenuItems = menuItems.filter(menuItem => displayedTabs.includes(menuItem.key));

      return !!filteredMenuItems.length ? <Menu items={filteredMenuItems} /> : <Menu items={[{ key: '0', label: t('noActions'), disabled: true }]} />;
    },
    [dateRange, isAdmin, userRole]
  );

  const [data, columns] = useSalaryByWeekTable({ isAdmin, salaryByWeek: selectedUserSalary, role: userRole as Roles, moreDropdown: moreDropdownWeekPayment });

  const [finalData, finalColumns] = useFinalSalaryTable({ selectedUserSalary, moreDropdown: moreDropdownMonthPayment });

  return (
    <>
      <Table dataSource={data} rowClassName={record => (record.isSummary ? 'bold' : '')} columns={columns} pagination={false} loading={isLoading} />
      {finalData.length >= 1 && (
        <>
          <TableLabel>{t('finalPayment')}</TableLabel>
          <Table dataSource={finalData} columns={finalColumns} pagination={false} loading={isLoading} />
        </>
      )}
      {selectedUserSalary?.finalPayment?.id && (
        <>
          <TaxTableModal
            isTaxTableOpen={isTaxTableOpen}
            refetchGetSalaryByRole={getSalaryByRole}
            finalPaymentId={selectedUserSalary.finalPayment.id}
            setIsTaxTableOpen={setIsTaxTableOpen}
          />
          <AddFinalAdjustmentModal
            getSalaryByRole={getSalaryByRole}
            driverId={userId}
            finalPayment={selectedUserSalary.finalPayment}
            isModalOpen={isAddFinalAdjustmentModalOpen}
            setIsModalOpen={setIsAddFinalAdjustmentModalOpen}
          />

          <GetFinalAdjustmentDetailsModal
            refreshSalaryByRole={getSalaryByRole}
            dateRange={selectedUserSalary.finalPayment.period}
            driverId={userId}
            isModalOpen={isGetFinalAdjustmentModalOpen}
            setIsModalOpen={setIsGetFinalAdjustmentModalOpen}
          />
        </>
      )}
      {selectedUserRole === Roles.MANAGER && (
        <Spin spinning={isLoading}>
          <RowVatContainer justify='end'>
            <VatContainer>
              <Row wrap={false}>
                <Col flex='auto'>
                  <Row gutter={[0, 16]}>
                    <BoldColText span={24}>{t('vat')}</BoldColText>
                    <BoldColText span={24}>{t('deductableVat')}</BoldColText>
                    <HostColText span={24}>
                      {t('estimatedVat')}
                      <Tooltip title={t('vatTooltipText')}>
                        <StyledInfoIcon />
                      </Tooltip>
                    </HostColText>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={[0, 16]}>
                    <MoneyBoldColText span={24}>
                      {selectedUserSalary?.amount?.vat ? currencyServices.convertToCurrency(selectedUserSalary.amount.vat) : '-'}
                    </MoneyBoldColText>
                    <MoneyBoldColText span={24}>
                      {selectedUserSalary?.amount?.vat ? currencyServices.convertToCurrency(selectedUserSalary.amount.reimbursementsVat) : '-'}
                    </MoneyBoldColText>
                    <MoneyHostColText span={24}>
                      {selectedUserSalary?.amount?.vat ? currencyServices.convertToCurrency(selectedUserSalary.amount.estimatedVat) : '-'}
                    </MoneyHostColText>
                  </Row>
                </Col>
              </Row>
            </VatContainer>
          </RowVatContainer>
        </Spin>
      )}
      {selectedSalary && (
        <>
          <AddAdjustmentsModal
            getSalaryByRole={getSalaryByRole}
            driverId={userId}
            cars={selectedSalary.cars!}
            dateRange={selectedSalary.dateRange!}
            salaryPeriod={selectedSalary.period!}
            isModalOpen={isAddAdjustmentsModalOpen}
            setIsModalOpen={setIsAddAdjustmentsModalOpen}
          />
          <DetailsModal
            refreshSalaryByRole={getSalaryByRole}
            dateRange={selectedSalary.dateRange!}
            driverId={userId}
            isModalOpen={isDetailsModalOpen}
            setIsModalOpen={setIsDetailsModalOpen}
          />
        </>
      )}
    </>
  );
};

export default SalaryInfo;
