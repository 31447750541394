import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Modal } from 'antd';

import { disputeReimbursements } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';

import Comment from './components/Comment';
import Success from './components/Success';

type PropsType = {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const DisputeModal: FC<PropsType> = ({ reimbursement, fetchReimbursements, setIsOpen, isOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.disputeModal' });
  const [text, setText] = useState('');
  const [isDeclined, setIsDeclined] = useState(false);
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.reimbursements);

  const decline = async () => {
    await dispatch(
      disputeReimbursements({
        reimbursementId: reimbursement.id,
        comment: text,
      })
    );

    fetchReimbursements();

    const { error } = store.getState().reimbursements;

    if (!error) {
      setIsDeclined(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const content = isDeclined ? <Success closeModal={closeModal} /> : <Comment text={text} setText={setText} />;

  return (
    <Modal
      closable={false}
      onCancel={closeModal}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading, danger: true }}
      open={isOpen}
      title={isDeclined ? false : t('title')}
      okText={t('dispute')}
      onOk={decline}
      footer={isDeclined ? false : undefined}
    >
      {content}
    </Modal>
  );
};

export default DisputeModal;
