import { Button,Input, Modal } from 'antd';

import styled from 'styled-components';

interface IInfoBlockProps {
  marginTop?: boolean;
}

interface ITagProps {
  green?: boolean;
  gray?: boolean;
  popover?: boolean;
}

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const ContentWrapper = styled.div`
  padding: 16px 24px 28px;
  width: 740px;

  .ant-table-wrapper {
    min-height: 415px;
    margin-bottom: 16px;
  }
`;

export const MapWrapper = styled.div`
  flex: 1;
`;

export const StyledSearch = styled(Input)`
  margin-bottom: 16px;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const InfoBlock = styled.div`
  display: flex;
  margin-bottom: 16px;
  min-width: 260px;

  > div:first-child {
    margin-right: 40px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #070707;
`;

export const FieldBlock = styled.div<IInfoBlockProps>`
  margin-top: 8px;
  ${({ marginTop }) => marginTop && 'margin-top: 32px;'}
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #9f9f9f;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
  max-width: 165px;
`;

export const SummaryWrapper = styled.div`
  width: 100%;
  height: 102px;
  background: #f5f5f5;
  padding: 16px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const Tag = styled.div<ITagProps>`
  padding: 8px;
  height: 38px;
  background: #ffffff;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-left: 9px;
    fill: #db504a;
  }

  :not(:last-child) {
    margin-right: 16px;
  }

  ${({ green }) => green && 'color: #0DAB6C'}
  ${({ gray }) => gray && 'color: #888888'}
  ${({ popover }) => popover && 'margin-right: 0 !important;'}
`;

export const MoreButton = styled(Button)`
  height: 38px;
  width: 38px;
  padding: 0;
  border: 0;
`;

export const LinkButton = styled(Button)`
  height: 80px;
  width: 80px;
  align-self: center;

  svg {
    height: 36px;
    width: 36px;
  }
`;

export const InfoText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #888888;
  align-self: center;
  width: 260px;
`;

export const Check = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #0dab6c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 4px;

  svg {
    fill: #fff;
    height: 10px;
    width: 10px;
  }
`;

export const CarLinkModalLinkButton = styled(Button)`
  margin-right: -15px;
`;
