import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "SF Pro" !important;
  }

  div#root {
    height: 100vh;
  }

  p {
    margin: 0;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  .bold {
    font-weight: 600;
  }

  .hide {
    display: none;
  }

  // AntDesign: Form.Item label height
  .ant-form-large .ant-form-item-label > label {
    height: 22px;
  }

  // AntDesign: Validation color change
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #E63946 !important;
  }
  .ant-form-item-explain-error {
    color: #E63946 !important;
  }

  // AntDesign: DRIVER Tag - geekblue colors change
  .ant-tag-geekblue {
    color: #129CCE !important;
    background: #ECF7FB !important;
    border: 1px solid #95D3E9 !important;
  }
  // AntDesign: MANAGER Tag - volcano colors change
  .ant-tag-volcano {
    color: #9F5658 !important;
    background: #F7F1F2 !important;
    border: 1px solid #D4B3B4 !important;
  }
  // AntDesign: CAR Tag - default colors change
  .ant-tag-default {
    color: #657B75 !important;
  }
  // AntDesign: Tag - green colors change
  .ant-tag-green {
    color: #5DC79D !important;
    background: #ECF8F3 !important;
    border: 1px solid #5DC79D !important;
  }
  // AntDesign: Tag - red colors change
  .ant-tag-red {
    color: #E78A86 !important;
    background: #FCF1F1 !important;
    border: 1px solid #E78A86 !important;
  }
  
  // AntDesign: Image preview centered
  .ant-image-preview-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-image-preview-img {
      max-width: 70vw;
    }
  }

  // AntDesign: Notification message color
  .ant-notification-notice-success  {
    .ant-notification-notice-message {
      color: #0DAB6C;
    }
    
    .anticon-check-circle > svg {
      fill: #0DAB6C;
    }
  }
  .ant-notification-notice-error  {
    .ant-notification-notice-message {
      color: #FF5964;
    }
    
    .anticon-check-circle > svg {
      fill: #FF5964;
    }
  }

  // Table row coloring
  .green-row {
    background-color: #ECF8F3;
  }
  .purple-row {
    background-color: #ad72df33;
  }
  .disabled-row {
  background-color: #FAFAFA;
  pointer-events: none;
  }
  .opacity-row {
    color: #888888;
    
    svg {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  // Map container
  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  // Leaflet badge hide
  .leaflet-bottom {
    display: none;
  }
  .leaflet-div-icon {
    background: none;
    border: none;
  }


  // Hide input(number) arrows
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const theme = {
  primary: '#7b2cbf',
  green: '#0DAB6C',
  gray: '#888888',
  red: '#FF5964',
  white: '#fff',
  warning: '#ff9966',
  lightGray: '#F5F5F5',
};
