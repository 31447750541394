export enum THUNKS {
  GET_CARS = 'cars/getCars',
  GET_CARS_BY_RADIUS = 'cars/getCarsByRadius',
  GET_OPTIONS = 'cars/getOptions',
  ADD_CAR = 'cars/addCar',
  CHANGE_CAR_STATUS = 'cars/changeCarStatus',
  CALCULATE_DRIVER_EARNING = 'cars/calculateDriverEarning',
  GET_CAR_BY_ID = 'cars/getCarById',
  EDIT_CAR = 'cars/editCar',
  EDIT_CAR_SHIFTS_BY_ID = 'cars/getCarShiftsById',
  GET_CAR_WEEKLY_REPORTS = 'cars/getCarWeeklyReports',
  GET_MANAGERS = 'cars/getManagers',
  SEARCH_ACTIVE_CARS = 'cars/searchActiveCars',
}
