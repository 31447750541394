import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const MobileRedirect: FC = () => {
  const [SearchParams] = useSearchParams();
  const id = useMemo(() => SearchParams.get('uuid'), [SearchParams]);
  const email = useMemo(() => SearchParams.get('email'), [SearchParams]);
  const screen = useMemo(() => SearchParams.get('screen'), [SearchParams]);
  useEffect(() => window.location.replace(screen ? `exp://192.168.1.6:19000/--/${screen}/${id}/${email}` : 'exp://192.168.1.6:19000/--/signIn/'), []);

  return <> </>;
};

export default MobileRedirect;
