import { Col, Row } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const BoldColText = styled(Col)`
  font-weight: 600;
  font-size: 14px;
`;

export const HostColText = styled(Col)`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.gray};
`;

export const MoneyBoldColText = styled(Col)`
  font-weight: 600;
  font-size: 14px;
  text-align: right;
`;

export const MoneyHostColText = styled(Col)`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.gray};
  text-align: right;
`;

export const VatContainer = styled(Col)`
  width: 300px;
`;

export const StyledInfoIcon = styled(ExclamationCircleOutlined)`
  padding-left: 5px;
`;

export const RowVatContainer = styled(Row)`
  margin-right: 80px;
`;
