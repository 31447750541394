import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ICar, IGetCarsByRadiusRequest } from 'modules/cars/types';

interface IGetCarsByRadiusResponse {
  data: {
    total: { active: number; disabled: number; pending: number };
    items: ICar[];
  };
}

// Get Cars by radius
export const getCarsByRadius = createAsyncThunk(THUNKS.GET_CARS_BY_RADIUS, async ({ latitude, longitude }: IGetCarsByRadiusRequest, thunkApi) => {
  try {
    const data = await api.private
      .get<IGetCarsByRadiusResponse>(`${Endpoints.CAR_BOXED}?latitude=${latitude}&longitude=${longitude}&radius=99999999&page=0&pageSize=9999`)
      .then(res => res.data.data);

    return data.items;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
