import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Checkbox, Col, Form, Input } from 'antd';

import { ExternalLinks } from 'constants/global';
import { createDriverRequest } from 'modules/auth';
import { DriverSteps } from 'modules/auth/enums';
import { IDocuments, IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'info',
    value: driverData.comment,
  },
];

const SeventhStep: FC<IDriverStepsProps & IDocuments> = ({ setStep, driverData, setDriverData, documents }) => {
  const [form] = Form.useForm();
  const { isLoading } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.SEVENTH}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    await dispatch(createDriverRequest({ driverData, documents }));

    // Getting current state from store
    const { error } = store.getState().auth;
    if (!error) navigate('/final');
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('info')} name='info'>
        <Input.TextArea showCount maxLength={1024} onChange={({ target }) => setDriverData(prev => ({ ...prev, comment: target.value }))} />
      </Form.Item>
      <Form.Item
        name='Privacy policy'
        valuePropName='checked'
        rules={[
          {
            validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t('privacyPolicy_message')))),
          },
        ]}
      >
        <Checkbox checked>
          {t('iAcceptThe')}{' '}
          <a target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()} href={ExternalLinks.PRIVACY_POLICY}>
            {t('privacyPolicy')}
          </a>
        </Checkbox>
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button loading={isLoading} type='primary' onClick={nextStepHandler}>
            {t('submitButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default SeventhStep;
