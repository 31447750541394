import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { addAdjustmentReducer } from './addAdjustment';
import { changeFinalSalaryStatusReducer } from './changeFinalSalaryStatus';
import { changeSalaryStatusReducer } from './changeSalaryStatus';
import { closePayoutModalReducer } from './closePayoutModal';
import { deleteAdjustmentReducer } from './deleteAdjustment';
import { getAdjustmentTypesReducer } from './getAdjustmentTypes';
import { getDriverLastSalaryDateReducer } from './getDriverLastSalaryDate';
import { getDriverSalaryReducer } from './getDriverSalary';
import { getManagerSalaryReducer } from './getManagerSalary';
import { getMonthlyPaymentsReducer } from './getMonthlyPayments';
import { getSalaryReducer } from './getSalary';
import { getSalaryAdjustmentsReducer } from './getSalaryAdjustments';
import { getSalaryReportReducer } from './getSalaryReport';
import { getSalaryStatusesReducer } from './getSalaryStatuses';
import { getSwishStatusesReducer } from './getSwishStatuses';
import { getTaxTableNumbersReducer } from './getTaxTableNumbers';
import { openPayoutModalReducer } from './openPayoutModal';
import { payMonthlyReducer } from './payMonthly';
import { payWeeklyReducer } from './payWeekly';
import { setFinalTaxTableNumberReducer } from './setFinalTaxTableNumber';
import { setSelectedPayoutsIdsIdsReducer } from './setSelectedPayoutsIds';

const extraReducers: ModuleExtraReducer<ISalaryState>[] = [
  addAdjustmentReducer,
  changeFinalSalaryStatusReducer,
  changeSalaryStatusReducer,
  deleteAdjustmentReducer,
  getAdjustmentTypesReducer,
  getDriverLastSalaryDateReducer,
  getDriverSalaryReducer,
  getManagerSalaryReducer,
  getMonthlyPaymentsReducer,
  getSalaryAdjustmentsReducer,
  getSalaryReducer,
  getSalaryReportReducer,
  getSalaryStatusesReducer,
  getSwishStatusesReducer,
  getTaxTableNumbersReducer,
  payMonthlyReducer,
  payWeeklyReducer,
  setFinalTaxTableNumberReducer,
];

export const buildSalariesReducer: ModuleExtraReducerBuilder<ISalaryState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};

export const salariesReducers = {
  ...openPayoutModalReducer,
  ...closePayoutModalReducer,
  ...setSelectedPayoutsIdsIdsReducer,
};
