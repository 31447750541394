import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Input, Menu, Radio, Table, TableProps } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Entities } from 'constants/global';
import { RequestTypes } from 'constants/requests';
import TableWrapper from 'modules/common/components/TableWrapper';
import {
  approveCarRequest,
  approveDriverRequest,
  approveManagerRequest,
  getCarRequest,
  getDriverRequest,
  getHistoryRequest,
  getManagerRequest,
} from 'modules/requests';
import DeclineModal from 'modules/requests/components/DeclineModal';
import { useRequestsListTable } from 'modules/requests/hooks';
import { IRequestsListTableData } from 'modules/requests/types';
import styled from 'styled-components';
import useDebounce from 'utils/debounce';

export const StyledSearch = styled(Input)`
  width: 320px;
`;

// TODO: move to requests.

const RequestsList: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.list' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, requests, total } = useAppSelector(state => state.requests);
  const [requestType, setRequestType] = useState<RequestTypes>(RequestTypes.DRIVER);
  const [selected, setSelected] = useState<IRequestsListTableData | null>(null);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const [selectedEntities, setSelectedEntities] = useState<Entities[]>();
  const [page, setPage] = useState<number>(1);

  // Total counter for table pagination
  const paginationTotal = useMemo(() => {
    switch (requestType) {
      case RequestTypes.DRIVER:
        return total.drivers;
      case RequestTypes.MANAGER:
        return total.managers;
      case RequestTypes.CAR:
        return total.cars;
      default: // HISTORY
        return total.history;
    }
  }, [requestType, total]);

  const dataRequestByRequestType = {
    [RequestTypes.CAR]: () => dispatch(getCarRequest({ page: page - 1, searchText: debouncedSearch })),
    [RequestTypes.DRIVER]: () => dispatch(getDriverRequest({ page: page - 1, searchText: debouncedSearch })),
    [RequestTypes.HISTORY]: () => dispatch(getHistoryRequest({ page: page - 1, searchText: debouncedSearch, entities: selectedEntities })),
    [RequestTypes.MANAGER]: () => dispatch(getManagerRequest({ page: page - 1, searchText: debouncedSearch })),
  };

  // Get Requests data
  useEffect(() => {
    dataRequestByRequestType[requestType]();
  }, [page, requestType, debouncedSearch, selectedEntities]);

  const openDetailsHandler = (record: IRequestsListTableData) =>
    navigate(`/requests/${[requestType !== RequestTypes.CAR ? record.name!.replace(' ', '-') : record.plate!]}?id=${record.id}&role=${record.role}`);

  const openDeclineModalHandler = (record: IRequestsListTableData) => {
    setSelected(record);
    setIsDeclineModalOpen(true);
  };

  const approveRequestHandler = async (record: IRequestsListTableData) => {
    if (requestType === RequestTypes.DRIVER) await dispatch(approveDriverRequest(String(record.id)));
    if (requestType === RequestTypes.MANAGER) await dispatch(approveManagerRequest(String(record.id)));
    if (requestType === RequestTypes.CAR) await dispatch(approveCarRequest(String(record.id)));

    // Getting current state from store
    const { error } = store.getState().requests;

    if (!error) {
      dataRequestByRequestType[requestType]();
    }
  };

  const moreDropdown = useCallback(
    (record: IRequestsListTableData) => (
      <Menu
        items={[
          {
            key: '1',
            label: t('getDetails'),
            onClick: () => openDetailsHandler(record),
          },
          {
            key: '2',
            label: t('approve'),
            onClick: () => approveRequestHandler(record),
          },
          {
            key: '3',
            label: t('decline'),
            onClick: () => openDeclineModalHandler(record),
            danger: true,
          },
        ]}
      />
    ),
    [approveRequestHandler, openDetailsHandler, openDeclineModalHandler]
  );

  const [data, columns] = useRequestsListTable({ requests, requestType, moreDropdown, roleFilterValue: selectedEntities || []});

  const onSearchChange = (searchText: string) => {
    setSearch(searchText);
    setPage(1);
  };

  const header = useMemo(
    () => (
      <>
        <Radio.Group
          size='large'
          value={requestType}
          onChange={({ target }) => {
            setRequestType(target.value);
            setPage(1);
          }}
        >
          <Radio.Button value={RequestTypes.DRIVER}>
            {t('drivers')} ({total.drivers})
          </Radio.Button>
          <Radio.Button value={RequestTypes.MANAGER}>
            {t('managers')} ({total.managers})
          </Radio.Button>
          <Radio.Button value={RequestTypes.CAR}>
            {t('cars')} ({total.cars})
          </Radio.Button>
          <Radio.Button value={RequestTypes.HISTORY}>
            {t('history')} ({total.history})
          </Radio.Button>
        </Radio.Group>
        <StyledSearch
          prefix={<SearchOutlined />}
          placeholder={t('search_placeholder')}
          size='large'
          onChange={({ target }) => {
            onSearchChange(target.value);
          }}
        />
      </>
    ),
    [requestType, setRequestType, total]
  );

  const onTableChange: TableProps<IRequestsListTableData>['onChange'] = (_, filters) => {
    if (filters.role?.length) {
      setSelectedEntities(filters.role as Entities[]);
      setPage(1);
    } else setSelectedEntities(undefined);
  };

  return (
    <>
      <TableWrapper header={header}>
        <Table
          loading={isLoading}
          dataSource={data}
          pagination={{
            current: page,
            showSizeChanger: false,
            onChange: setPage,
            total: paginationTotal,
          }}
          columns={columns}
          onChange={onTableChange}
          onRow={record => ({
            onClick: e => {
              if (requestType === RequestTypes.HISTORY) return;
              if (
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-title-content') ||
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-item') ||
                (e.target as HTMLElement).classList.contains('anticon') ||
                (e.target as HTMLElement).tagName === 'svg'
              )
                return;

              openDetailsHandler(record);
            },
          })}
        />
      </TableWrapper>

      <DeclineModal
        page={page}
        entity={selected?.role}
        request={selected}
        setRequest={setSelected}
        isModalOpen={isDeclineModalOpen}
        setIsModalOpen={setIsDeclineModalOpen}
      />
    </>
  );
};

export default RequestsList;
