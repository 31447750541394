enum Endpoints {
  SIGN_IN = 'signIn',
  PROFILE = 'profile',
  PASSWORD = 'password',
  DRIVER_REQUEST = 'driver/request',
  DRIVER = 'driver',
  MANAGER_REQUEST = 'manager/request',
  DATA_PLATFORMS = 'data/platforms',
  DATA_WEEKDAYS = 'data/weekdays',
  DATA_EMPLOYMENT_TYPES = 'data/employmentTypes',
  DATA_ADJUSTMENT_TYPES = 'data/adjustmentTypes',
  DATA_SCHEDULES = 'data/schedules',
  DATA_SHIFTS = 'data/shifts',
  DATA_SHIFT_PLANS = 'data/shiftPlans',
  ADMIN_USER = 'admin/user',
  ADMIN_DRIVER = 'admin/driver',
  ADMIN_MANAGER = 'admin/manager',
  CAR = 'car',
  CAR_REQUEST = 'car/request',
  CAR_BOXED = 'car/boxed',
  CAR_MANAGERS = 'car/managers',
  CAR_SEARCH = 'car/search',
  LINK = 'link',
  CALC = 'calc',
  REPORT = 'report',
  REPORT_DASHBOARD = 'report/dashboard',
  CHANGELOG_EVENTS = 'changelog/events',
  REPORT_UPLOAD = 'report/upload',
  REPORT_UPLOAD_TAXIMETER = 'report/uploadTaximeter',
  REPORT_UPLOAD_SLING = 'report/uploadSling',
  REPORT_UPLOAD_UBER = 'report/uploadUber',
  REPORT_UPLOAD_BOLT = 'report/uploadBolt',
  ADMIN = 'admin',
  MANAGER_DRIVER = 'manager/driver',
  UPDATE_LANGUAGE = 'update/language',
  REPORT_SHIFTS_FOR_DRIVER = 'report/shiftsForDriver',
  REPORT_SHIFTS_FOR_CAR = 'report/shiftsForCar',
  REPORT_WEEKLY_CAR = 'report/weekly/car',
  REPORT_WEEKLY_DRIVER = 'report/weekly/driver',
  REPORT_WEEKLY_MANAGER = 'report/weekly/manager',
  SALARY_WEEKLY = 'salary/weekly',
  SALARY_FINAL_PAYMENTS = 'salary/finalPayments',
  SALARY_DRIVER = 'salary/driver',
  SALARY_MANAGER = 'salary/manager',
  SALARY_UPDATE_STATUS = 'salary/updateStatus',
  SALARY_FINAL_UPDATE_STATUS = 'salary/final/updateStatus',
  SALARY_FINAL_TAX_TABLE_NUMBER = 'salary/final/taxTableNumber',
  SALARY_TAX_TABLE_NUMBERS = 'salary/taxTableNumbers',
  SALARY_ADJUSTMENTS = 'salary/adjustment',
  SALARY_DRIVER_REPORT = 'salary/driver/download',
  SALARY_MANAGER_REPORT = 'salary/manager/download',
  GET_DRIVER_LAST_SALARY_DATE = '/salary/driver/lastDate',
  REQUEST_CAR_NEW = 'request/car/new',
  REQUEST_MANAGER_NEW = 'request/manager/new',
  REQUEST_DRIVER_NEW = 'request/driver/new',
  REQUEST_HISTORY = 'request/history',
  PAY_WEEKLY = 'payout/create/weeklySalary',
  PAY_MONTHLY = 'payout/create/finalPayment',
  GET_SALARY_STATUSES = 'data/salaryStatuses',
  GET_SWISH_STATUSES = 'data/payoutStatuses',
  GET_REIMBURSEMENTS = 'reimbursement/list',
  GET_REIMBURSEMENT_PHOTO = 'reimbursement/photo',
  GET_REIMBURSEMENT_TYPES = 'data/reimbursementTypes',
  UPDATE_REIMBURSEMENT_STATUS = 'reimbursement/process',
  UPDATE_REIMBURSEMENT = 'reimbursement/update',
  PAY_REIMBURSEMENT = 'payout/create/reimbursement',
  DISPUTE_REIMBURSEMENT = 'reimbursement/dispute',
  ADVANCE_PAYMENT_STATUSES = 'data/advancePaymentStatuses',
  ADVANCE_LIST = 'advances/list',
  ADVANCE_MONTHLY_LIST = 'advances/monthly/list',
  ADVANCE_CREATE = 'advances/create',
  AGREEMENT_SET_SELECTED_CAR = 'agreement/driver/car',
  AGREEMENT_CREATE_AND_SEND = 'agreement/driver',
  AGREEMENT_DOWNLOAD = 'agreement/driver/document',
}

export default Endpoints;
