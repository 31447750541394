import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Form, Input, Modal, Select } from 'antd';

import { Roles, Statuses } from 'constants/global';
import { IDriverTableData } from 'modules/driver/types';
import { IManagerTableData } from 'modules/manager/types';
import { changeUserStatus, getDriversByManager, getUsers } from 'modules/user/slice/thunks';

import { Note } from './styled';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  user?: IDriverTableData | IManagerTableData;
  setUser?: Dispatch<SetStateAction<IDriverTableData | IManagerTableData | null>>;
  page?: number;
  statusFilter: Statuses;
}

const fleetManagerDisableReasons = ['fleetManagerDisableReasons_1', 'fleetManagerDisableReasons_2', 'fleetManagerDisableReasons_3'];
const driverDisableByManagerReasons = ['driverDisableByManagerReasons_1', 'driverDisableByManagerReasons_2', 'driverDisableByManagerReasons_3'];
export const driverDisableByAdminReasons = [
  'driverDisableByAdminReasons_1',
  'driverDisableByAdminReasons_2',
  'driverDisableByAdminReasons_3',
  'driverDisableByAdminReasons_4',
];

const fieldsHandler = (reason: string, comment: string) => [
  {
    name: 'chooseReason',
    value: reason || null,
  },
  {
    name: 'comment',
    value: comment,
  },
];

const DisableUserModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, user, setUser, page = 1, statusFilter }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.disableUserModal' });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin } = useAppSelector(state => state.auth);
  const { selectedUser, isLoading } = useAppSelector(state => state.users);
  const [reason, setReason] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const resetHandler = () => {
    setIsModalOpen(false);
    setReason('');
    setComment('');
    if (user) setUser!(null);
  };

  const disableUserHandler = async () => {
    await form.validateFields();

    const userData = {
      name: selectedUser ? `${selectedUser?.user.firstName} ${selectedUser?.user.lastName}` : user!.name,
      id: selectedUser?.user?.id || user!.id,
      role: selectedUser?.user?.role || user!.role,
    };

    await dispatch(changeUserStatus({ user: userData, data: { status: Statuses.DISABLED, reason, comment }, isAdmin }));
    resetHandler();

    // Getting current state from store
    const { error } = store.getState().users;
    if (error) return;
    if (user) {
      if (isAdmin) {
        dispatch(getUsers({ page: page - 1, role: userData.role, status: statusFilter }));
      } else {
        dispatch(getDriversByManager({ page: page - 1, status: statusFilter }));
      }
    } else navigate(`/${userData.role}s`);
  };

  const reasonOptionsHandler = () => {
    const JSXHandler = (reasons: string[]) =>
      reasons.map(item => (
        <Select.Option value={item} key={item}>
          {t(item)}
        </Select.Option>
      ));

    if (isAdmin) {
      if (user?.role === Roles.DRIVER || selectedUser?.user?.role === Roles.DRIVER) return JSXHandler(driverDisableByAdminReasons);
      if (user?.role === Roles.MANAGER || selectedUser?.user?.role === Roles.MANAGER) return JSXHandler(fleetManagerDisableReasons);
    } else {
      return JSXHandler(driverDisableByManagerReasons);
    }
  };

  return (
    <Modal
      width={448}
      title={`${t('disable')} ${selectedUser ? `${selectedUser?.user.firstName} ${selectedUser?.user.lastName}` : user?.name}`}
      open={isModalOpen}
      destroyOnClose
      okText={t('disable')}
      onOk={disableUserHandler}
      okButtonProps={{ danger: true, loading: isLoading }}
      onCancel={resetHandler}
    >
      <Note>{t('note')}</Note>
      <Form fields={fieldsHandler(reason, comment)} form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
        <Form.Item label={t('chooseReason')} name='chooseReason' rules={[{ required: true }]}>
          <Select onChange={val => setReason(val)} placeholder={t('chooseReason_placeholder')}>
            {reasonOptionsHandler()}
          </Select>
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <Input.TextArea rows={1} onChange={({ target }) => setComment(target.value)} placeholder={t('comment_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DisableUserModal;
