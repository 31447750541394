import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IChangeSalaryStatus, IUpdateSalaryStatusResult } from 'modules/salaries/types';

// Change weekly salary status
export const changeSalaryStatus = createAsyncThunk(THUNKS.CHANGE_SALARY_STATUS, async ({ salaryIds, status }: IChangeSalaryStatus, thunkApi) => {
  try {
    const res = await api.private.put<{ data: IUpdateSalaryStatusResult }>(`${Endpoints.SALARY_UPDATE_STATUS}`, { salaryIds, status });

    if (res.data.data.errors.length) {
      return thunkApi.rejectWithValue(res.data.data.errors[0].errors[0]);
    }

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
