import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { IAdvanceMonthly } from 'modules/reimbursements/types/IAdvanceMonthly';
import { currencyServices } from 'services/currencyServices';

export const useColumns = (): ColumnsType<IAdvanceMonthly> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const drivers = useAppSelector(state => state.reimbursements.advanceMonthlyListData?.filters?.drivers);

  const driverOptions: ColumnFilterItem[] = drivers?.map(driver => ({ text: `${driver.firstName} ${driver.lastName}`, value: driver.id })) || [];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, rowData) => rowData.period,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      filters: driverOptions,
      render: (_, rowData) => rowData.driverName,
    },
    {
      title: t('advanceUsed'),
      dataIndex: 'advanceUsed',
      key: 'advanceUsed',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.advanceUsed),
    },
    {
      title: t('limit'),
      dataIndex: 'limit',
      key: 'limit',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.advanceLimit),
    },
    {
      title: t('debited'),
      dataIndex: 'debited',
      key: 'debited',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.debited),
    },
    {
      title: t('advanceLeftover'),
      dataIndex: 'advanceLeftover',
      key: 'advanceLeftover',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.advanceLeftover),
    },
  ];
};
