import { useTranslation } from 'react-i18next';

import { ICarInfo } from 'modules/cars/types';
import { IUserCarTableData } from 'modules/user/types';

export const mapUserCars = (rawCarData: ICarInfo[]): IUserCarTableData[] => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  const localizeSplitTime = (rawValue: string): string => {
    const [value] = rawValue.split(' ');
    return `${value} ${t('hours')}`;
  };

  const localizeShiftPlan = (rawValue: string): string => {
    const [value] = rawValue.split(' ');
    const isPluralDayValue = value !== '1';
    const shiftPlanText = isPluralDayValue ? t('shiftsPerDay') : t('shiftPerDay');

    return `${value} ${shiftPlanText}`;
  };

  return rawCarData.map(car => ({
    key: car.id,
    id: car.id,
    plate: car.registrationPlate,
    status: car.status,
    address: `${car.address.street}, ${car.address.city}, ${car.address.postalCode}`,
    insurance: car.insuranceAgreement,
    model: car.model,
    year: car.year,
    plan: localizeShiftPlan(car.shiftPlan.value),
    splitTime: localizeSplitTime(car.shiftSplitTimes.value),
    maxMil: car.mileageMax,
    persent: car.driverPercent,
  }));
};
