import { Row } from 'antd';

import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin-bottom: 16px;
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;
