import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/auth/enums';
import { IAuthUser, IChangeAdmin } from 'modules/auth/types';

export const changeProfile = createAsyncThunk(THUNKS.CHANGE_PROFILE, async (data: IChangeAdmin, thunkApi) => {
  try {
    const res = await api.private.post<{ data: IAuthUser }>(`${Endpoints.PROFILE}`, data).then(result => result.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.contactInfo'),
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
