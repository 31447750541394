import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Form, Input, Modal, Select } from 'antd';

import { Statuses } from 'constants/global';
import { changeCarStatus, getCars } from 'modules/cars/slice/thunks';
import { ICarTableData } from 'modules/cars/types';

import { Note } from './styled';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  car?: ICarTableData;
  setCar?: Dispatch<SetStateAction<ICarTableData | null>>;
  page?: number;
  statusFilter: Statuses;
}

const fieldsHandler = (reason: string, comment: string) => [
  {
    name: 'chooseReason',
    value: reason || null,
  },
  {
    name: 'comment',
    value: comment,
  },
];

const carActivateReasons = ['carActivateReasons_1', 'carActivateReasons_2'];

const ActivateCarModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, car, setCar, page = 1, statusFilter }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.activateCarModal' });
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, selectedCar } = useAppSelector(state => state.cars);
  const [reason, setReason] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const resetHandler = () => {
    setIsModalOpen(false);
    setReason('');
    setComment('');
    if (car) setCar!(null);
  };

  const activateCarHandler = async () => {
    await form.validateFields();

    const carData = {
      id: selectedCar?.info?.id || car!.id,
      plate: selectedCar?.info?.registrationPlate || car!.plate,
    };

    await dispatch(changeCarStatus({ car: carData, data: { status: Statuses.ACTIVE, reason, comment }}));
    resetHandler();

    // Getting current state from store
    const { error } = store.getState().cars;
    if (error) return;
    if (car) {
      dispatch(getCars({ page: page - 1, status: statusFilter }));
    } else navigate('/cars');
  };

  return (
    <Modal
      width={448}
      title={`${t('activateCar')} ${selectedCar?.info?.registrationPlate || car!.plate}`}
      open={isModalOpen}
      destroyOnClose
      okText={t('activate')}
      onOk={activateCarHandler}
      okButtonProps={{ type: 'primary', loading: isLoading }}
      onCancel={resetHandler}
    >
      <Note>{t('note')}</Note>
      <Form fields={fieldsHandler(reason, comment)} form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
        <Form.Item label={t('chooseReason')} name='chooseReason' rules={[{ required: true }]}>
          <Select onChange={val => setReason(val)} placeholder={t('chooseReason_placeholder')}>
            {carActivateReasons.map(item => (
              <Select.Option value={item} key={item}>
                {t(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <Input.TextArea rows={1} onChange={({ target }) => setComment(target.value)} placeholder={t('comment_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActivateCarModal;
