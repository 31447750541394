import { config } from 'config';
import { format } from 'date-fns';
import { IDriverWeeklyReportTableData } from 'modules/driver/types';
import { IDriverWeeklyReport } from 'modules/reports/types';
import { currencyServices } from 'services/currencyServices';

export const mapDriverWeeklyReports = (rawDriverReports: IDriverWeeklyReport[]): IDriverWeeklyReportTableData[] =>
  rawDriverReports.map(item => ({
    key: Math.random(),
    date: `${format(new Date(item.week.start), config.DATE_FORMAT)} - ${format(new Date(item.week.end), config.DATE_FORMAT)}`,
    earnings: currencyServices.convertToCurrency(item.earning),
    grade: `${item.grade}%`,
    efficiency: item?.efficiency !== undefined ? `${item.efficiency}%` : '-',
    fulfillment: item?.fulfillment !== undefined ? `${item.fulfillment}%` : '-',
    status: item.status,
  }));
