import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, notification, Row } from 'antd';

import { StyledColumn, SuccessIcon, TextContainer } from './styled';

interface IProps {
  closeModal: () => void;
}

const SuccessfulPayout: FC<IProps> = ({ closeModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });

  useEffect(() => {
    closeModal();

    notification.success({
      placement: 'topRight',
      message: t('success'),
      description: t('successText'),
    });
  }, []);

  return (
    <Row gutter={[0, 12]} justify='center'>
      <StyledColumn span={24}>
        <SuccessIcon />
      </StyledColumn>
      <StyledColumn span={24}>
        <TextContainer>{t('successText')}</TextContainer>
      </StyledColumn>
      <StyledColumn span={24}>
        <Button onClick={closeModal} type='primary'>
          {t('okay')}
        </Button>
      </StyledColumn>
    </Row>
  );
};

export default SuccessfulPayout;
