import { IAnalyticsState } from 'modules/analytics/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { cleanUploadRequestReportReducer } from './cleanUploadRequestReport';
import { getChangelogReducer } from './getChangelog';
import { getReportReducer } from './getReport';
import { uploadReportsReducer } from './uploadReports';

const extraReducers: ModuleExtraReducer<IAnalyticsState>[] = [getChangelogReducer, uploadReportsReducer, getReportReducer];

export const globalReducers = {
  ...cleanUploadRequestReportReducer,
};

export const buildAnalyticsReducers: ModuleExtraReducerBuilder<IAnalyticsState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};
