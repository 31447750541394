import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer } from 'types/Module';

import { createManagerRequest } from '../thunks';

export const createManagerRequestReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Create Manager Request

  builder
    .addCase(createManagerRequest.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(createManagerRequest.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(createManagerRequest.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
