import { FC, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table } from 'antd';

import { getCarWeeklyReports } from 'modules/cars';
import { useCarWeeklyReportsTable } from 'modules/cars/hooks';
import { convertIntoISODate } from 'utils';

import { ContentWrapper } from './styled';

interface IProps {
  dateRange: [Date, Date] | null;
}

// TODO: Table refactor

const Reports: FC<IProps> = ({ dateRange }) => {
  const dispatch = useAppDispatch();
  const { selectedCar, isLoading } = useAppSelector(state => state.cars);
  const [page, setPage] = useState<number>(1);
  const dateFilter = useMemo(() => (dateRange ? { start: convertIntoISODate(dateRange[0]), end: convertIntoISODate(dateRange[1]) } : {}), [dateRange]);

  const weeklyReports = selectedCar?.weeklyReports?.items || [];
  const [data, columns] = useCarWeeklyReportsTable({ weeklyReports });

  useEffect(() => {
    dispatch(getCarWeeklyReports({ carId: selectedCar!.info.id, ...dateFilter }));
  }, [dateFilter]);

  const changePageHandler = async (p: number) => {
    await dispatch(getCarWeeklyReports({ carId: selectedCar!.info.id, page: p - 1, ...dateFilter }));
    setPage(p);
  };

  return (
    <ContentWrapper>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={{ showSizeChanger: false, current: page, onChange: p => changePageHandler(p), total: selectedCar?.weeklyReports?.total?.total }}
      />
    </ContentWrapper>
  );
};

export default Reports;
