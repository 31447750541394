import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Contacts } from './styled';

const ContactMark: FC = () => {
  const { t } = useTranslation();

  return (
    <Contacts>
      {t('global.contacts')} <span>08-505 424 27</span>
    </Contacts>
  );
};

export default ContactMark;
