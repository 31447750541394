import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Entities } from 'constants/global';
import CarDetails from 'modules/requests/components/Car';
import DriverRequestsDetails from 'modules/requests/components/Driver';
import ManagerDetails from 'modules/requests/components/Manager';

const RequestsDetails: FC = () => {
  const [SearchParams] = useSearchParams();
  const role = useMemo(() => SearchParams.get('role'), []);

  switch (role) {
    case Entities.DRIVER:
      return <DriverRequestsDetails />;
    case Entities.MANAGER:
      return <ManagerDetails />;
    default:
      return <CarDetails />;
  }
};

export default RequestsDetails;
