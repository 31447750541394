import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ICarShifts, IGetCarShiftsById } from 'modules/cars/types';

// Get Car shifts by Id
export const getCarShiftsById = createAsyncThunk(THUNKS.EDIT_CAR_SHIFTS_BY_ID, async ({ carId, page = 0, pageSize = 7 }: IGetCarShiftsById, thunkApi) => {
  try {
    const res = await api.private.get<{ data: ICarShifts }>(`${Endpoints.REPORT_SHIFTS_FOR_CAR}?carId=${carId}&page=${page}&pageSize=${pageSize}`);
    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
