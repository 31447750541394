import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IOption } from 'modules/analytics/types';
import { THUNKS } from 'modules/auth/enums';

export const getOptions = createAsyncThunk(THUNKS.GET_OPTIONS, async (_, thunkApi) => {
  try {
    const [platforms, weekdays, employmentTypes, schedules] = await Promise.all([
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_PLATFORMS),
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_WEEKDAYS),
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_EMPLOYMENT_TYPES),
      api.public.get<{ data: IOption[] }>(Endpoints.DATA_SCHEDULES),
    ]).then(res => res.map(item => item.data.data));

    return {
      platforms,
      weekdays,
      employmentTypes,
      schedules,
    };
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
