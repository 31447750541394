import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Popover, Table } from 'antd';

import { CloseCircleOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { config } from 'config';
import { format } from 'date-fns';
import { useCarLinkTable } from 'modules/cars/hooks';
import { getCarsByRadius } from 'modules/cars/slice/thunks';
import { ICarInfo } from 'modules/cars/types';
import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';
import { IDriver } from 'modules/driver/types';
import { linkDriverWithACar, setSelectedUser, unlinkCarFromDriver } from 'modules/user';

import Map from '../Map';
import {
  Container,
  ContentWrapper,
  FieldBlock,
  InfoBlock,
  InfoText,
  InfoWrapper,
  Label,
  LinkButton,
  MapWrapper,
  MoreButton,
  StyledModal,
  StyledSearch,
  SummaryWrapper,
  Tag,
  TagsWrapper,
  Title,
  Value,
} from './styled';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  user: IDriver;
  setUser?: Dispatch<SetStateAction<IDriver | null>>;
}

const localesByValue: { [key: string]: string } = {
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
};

const LinkCarModal: FC<IProps> = ({ isOpen, setIsOpen, user, setUser }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.linkCarModal' });
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const { carsInRadius, isLoading } = useAppSelector(state => state.cars);
  const [selectedCar, setSelectedCar] = useState<ILinkCarTableData | null>(null);
  const [highlightedCars, setHighlightedCars] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getCarsByRadius({ latitude: user.address.location.latitude, longitude: user.address.location.longitude }));
  }, [user]);

  const [data, columns] = useCarLinkTable({ search, carsInRadius, user, selectedCar, highlightedCars, setSelectedCar });

  const localizeSplitTimes = (splitTimes: string) => {
    const [splitTimesValue] = splitTimes.split(' ');
    return `${splitTimesValue} ${t('hours')}`;
  };

  const linkCarHanlder = useCallback(
    async (car: ILinkCarTableData) => {
      await dispatch(linkDriverWithACar({ driver: user, car })).then(({ payload }) =>
        setUser ? setUser(payload as IDriver) : dispatch(setSelectedUser(payload as IDriver))
      );

      setSelectedCar(null);
      setHighlightedCars([]);
    },
    [user]
  );

  const unlinkCarHandler = useCallback(
    async (car: ICarInfo) =>
      await dispatch(unlinkCarFromDriver({ driver: user, car })).then(({ payload }) =>
        setUser ? setUser(payload as IDriver) : dispatch(setSelectedUser(payload as IDriver))
      ),
    [user]
  );

  const closeHandler = () => {
    setIsOpen(false);
    if (setUser) setUser(null);
  };

  const popoverContent = useMemo(
    () =>
      user.cars.slice(2).map(car => (
        <Tag popover key={car.id}>
          {t('car')} {car.registrationPlate} <CloseCircleOutlined onClick={() => unlinkCarHandler(car)} />
        </Tag>
      )),
    [user]
  );

  return (
    <StyledModal centered width={1392} title={`${t('linkWord')} ${t('withACar')}`} onCancel={closeHandler} open={isOpen} footer={null}>
      <Container>
        <ContentWrapper>
          <StyledSearch prefix={<SearchOutlined />} placeholder={t('searchPlaceholder')} onChange={({ target }) => setSearch(target.value)} />
          <Table loading={isLoading} rowClassName={record => record.rowColor} dataSource={data} columns={columns} scroll={{ y: 360 }} pagination={false} />

          <InfoWrapper>
            <InfoBlock>
              <div>
                <Title>{t('driver')}</Title>
                <FieldBlock>
                  <Label>{t('startWorking')}</Label>
                  <Value>{user && format(new Date(user.workSchedule.startingDate), config.DATE_FORMAT)}</Value>
                </FieldBlock>
                <FieldBlock>
                  <Label>{t('workingTime')}</Label>
                  <Value>{t(user.workSchedule.workSchedule)}</Value>
                </FieldBlock>

                <FieldBlock>
                  <Label>{t('days')}</Label>
                  <Value>{user.workSchedule.workWeekdays.map(day => t(localesByValue[day.value])).join(', ')}</Value>
                </FieldBlock>
              </div>
              <div>
                <FieldBlock marginTop>
                  <Label>{t('workingDays')}</Label>
                  <Value>{user.workSchedule.daysPerWeek}</Value>
                </FieldBlock>
                <FieldBlock>
                  <Label>{t('preference')}</Label>
                  <Value>{t(user.workSchedule.workTime)}</Value>
                </FieldBlock>
              </div>
            </InfoBlock>

            <LinkButton loading={isLoading} shape='circle' icon={<LinkOutlined />} disabled={!selectedCar} onClick={() => linkCarHanlder(selectedCar!)} />

            <InfoBlock>
              {selectedCar ? (
                <>
                  <div>
                    <Title>
                      {t('car')} {selectedCar?.plate}
                    </Title>
                    <FieldBlock>
                      <Label>{t('manufacturer')}</Label>
                      <Value>{selectedCar?.make}</Value>
                    </FieldBlock>
                    <FieldBlock>
                      <Label>{t('model')}</Label>
                      <Value>{selectedCar?.model}</Value>
                    </FieldBlock>
                    <FieldBlock>
                      <Label>{t('year')}</Label>
                      <Value>{selectedCar?.year}</Value>
                    </FieldBlock>
                  </div>
                  <div>
                    <FieldBlock marginTop>
                      <Label>{t('splitTimes')}</Label>
                      <Value>{localizeSplitTimes(selectedCar?.splitTimes)}</Value>
                    </FieldBlock>
                    <FieldBlock>
                      <Label>{t('startTime')}</Label>
                      <Value>{selectedCar?.startTime}</Value>
                    </FieldBlock>
                    <FieldBlock>
                      <Label>{t('maxMill')}</Label>
                      <Value>{selectedCar?.maxMill}</Value>
                    </FieldBlock>
                  </div>
                </>
              ) : (
                <InfoText>{t('chooseTheCar')}</InfoText>
              )}
            </InfoBlock>
          </InfoWrapper>

          <SummaryWrapper>
            <Title>{t('summary')}</Title>
            <TagsWrapper>
              <Tag>
                {user.user.firstName} {user.user.lastName}
              </Tag>
              {user.cars.length > 0 ? <Tag green>{t('linkedWith')}</Tag> : <Tag gray>{t('notLinkedYet')}</Tag>}
              {user.cars.slice(0, 2).map(car => (
                <Tag key={car.id}>
                  {t('car')} {car.registrationPlate} <CloseCircleOutlined onClick={() => unlinkCarHandler(car)} />
                </Tag>
              ))}

              {user.cars.length > 2 && (
                <Popover content={popoverContent} trigger={['click']}>
                  <MoreButton>+{user.cars.slice(2).length}</MoreButton>
                </Popover>
              )}
            </TagsWrapper>
          </SummaryWrapper>
        </ContentWrapper>

        <MapWrapper>
          <Map
            cars={data}
            user={user}
            selectedCar={selectedCar}
            setSelectedCar={setSelectedCar}
            highlightedCars={highlightedCars}
            setHighlightedCars={setHighlightedCars}
          />
        </MapWrapper>
      </Container>
    </StyledModal>
  );
};

export default LinkCarModal;
