import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getManagerById } from '../thunks';

export const getManagerByIdReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get manager by ID

  builder
    .addCase(getManagerById.fulfilled, (state, action) => {
      state.selectedUser = action.payload;
      state.isLoading = false;
    })
    .addCase(getManagerById.pending, state => {
      state.selectedUser = null;
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getManagerById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
