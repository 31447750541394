import { createSlice } from '@reduxjs/toolkit';

import { PayoutStatus } from '../enums';
import { ISalaryState } from '../types';
import { buildSalariesReducer, salariesReducers } from './reducers';

const initialState: ISalaryState = {
  salary: null,
  selectedUserSalary: null,
  taxTableNumbers: null,
  isLoading: false,
  error: null,
  salaryStatuses: null,
  swishStatuses: null,
  monthlyPayments: null,
  monthlyTotal: null,
  weeklyTotal: null,
  markAsPaidResult: null,
  driverLastSalaryDate: null,

  payoutModal: {
    isOpen: false,
    payoutStatus: PayoutStatus.NotPayed,
    payoutResult: null,
    selectedPayoutsIds: [],
  },
};

const salariesSlice = createSlice({
  name: 'salaries',
  initialState,
  reducers: salariesReducers,
  extraReducers: builder => buildSalariesReducer(builder),
});

export default salariesSlice.reducer;

export const { openPayoutModal, closePayoutModal, setSelectedPayoutsIds } = salariesSlice.actions;
