import {
  CreatePassword,
  DriverRole,
  FinalScreen,
  ForgotPassword,
  ManagerRole,
  MobileRedirect,
  ResetPassword,
  RoleSelect,
  SignIn as SuperAdmin,
  Welcome,
} from 'modules/auth';

export default [
  {
    element: <SuperAdmin />,
    path: '/sign-in',
  },
  {
    element: <Welcome />,
    path: '/sign-up',
  },
  {
    element: <RoleSelect />,
    path: '/sign-up/role',
  },
  {
    element: <DriverRole />,
    path: '/sign-up/driver',
  },
  {
    element: <ManagerRole />,
    path: '/sign-up/manager',
  },
  {
    element: <FinalScreen />,
    path: '/final',
  },
  {
    element: <CreatePassword />,
    path: '/sign-up/create-password',
  },

  {
    element: <ForgotPassword />,
    path: '/forgot-password',
  },
  {
    element: <ResetPassword />,
    path: '/reset-password',
  },
  {
    element: <MobileRedirect />,
    path: '/redirect',
  },
];
