import { config } from 'config';
import { format } from 'date-fns';

import { DateWrapper, Time } from '../../styled';
import { ISlingTableItem } from '../../types';
import { IFieldWithCount, ITableColumn, ITableField } from '../types';

export const SlingFieldsWihCount: IFieldWithCount[] = [
  {
    key: 'deletedSlingRecordsNum',
    titleLocale: 'deletedSlingRecords',
  },
  {
    key: 'insertedSlingRecordsNum',
    titleLocale: 'insertedSlingRecords',
  },
  {
    key: 'deletedSlingShiftsNum',
    titleLocale: 'deletedSlingShifts',
  },
  {
    key: 'insertedSlingShiftsNum',
    titleLocale: 'insertedSlingShifts',
  },
];

const SlingTableColumns: ITableColumn[] = [
  {
    name: 'carRegistrationPlate',
    titleLocale: 'carRegistrationPlate',
    renderFunction: (_, data: ISlingTableItem) => data.carRegistrationPlate,
  },
  {
    name: 'shiftStartTime',
    titleLocale: 'shiftStartTime',
    renderFunction: (_, data: ISlingTableItem) => {
      const date = format(new Date(data.shiftStartTime), config.DATE_FORMAT);
      const time = format(new Date(data.shiftStartTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'shiftEndTime',
    titleLocale: 'shiftEndTime',
    renderFunction: (_, data: ISlingTableItem) => {
      const date = format(new Date(data.shiftEndTime), config.DATE_FORMAT);
      const time = format(new Date(data.shiftEndTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'driverFullName',
    titleLocale: 'driverFullName',
    renderFunction: (_, data: ISlingTableItem) => data.driverFullName,
  },
];

export const SlingTables: ITableField[] = [
  {
    key: 'noCarSlingShifts',
    titleLocale: 'noCarSlingShifts',
    columnsPreset: SlingTableColumns,
  },
  {
    key: 'noDriverSlingShifts',
    titleLocale: 'noDriverSlingShifts',
    columnsPreset: SlingTableColumns,
  },
  {
    key: 'moreThanOneDriverSlingShifts',
    titleLocale: 'moreThanOneDriverSlingShifts',
    columnsPreset: SlingTableColumns,
  },
];
