import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/salaries/enums';

// Delete adjustments
export const deleteAdjustment = createAsyncThunk(THUNKS.DELETE_ADJUSTMENT, async (id: number, thunkApi) => {
  try {
    // prettier-ignore
    await api.private.delete(`${Endpoints.SALARY_ADJUSTMENTS}`, { params: { id }}).then(res => res.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.adjDeleted'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
