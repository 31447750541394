import { PayoutStatus } from 'modules/salaries/enums';
import { ISalaryState } from 'modules/salaries/types';

export const closePayoutModalReducer = {
  closePayoutModal: (state: ISalaryState) => {
    state.payoutModal.isOpen = false;
    state.payoutModal.payoutResult = null;
    state.payoutModal.payoutStatus = PayoutStatus.NotPayed;
    state.payoutModal.selectedPayoutsIds = [];
  },
};
