import { ChangeEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';

import TextArea from 'antd/lib/input/TextArea';

type PropsType = {
  text: string;
  setText: (text: string) => void;
};

const Comment: FC<PropsType> = ({ text, setText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.disputeModal' });

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setText(event.target.value);
  };

  return (
    <>
      <p>{t('comment')}</p>
      <TextArea value={text} onChange={onTextAreaChange} rows={4} />
    </>
  );
};

export default Comment;
