import { IReimbursementsState } from 'modules/reimbursements/types';
import { ModuleExtraReducer } from 'types/Module';

import { getReimbursements } from '../thunks';

export const getReimbursementsReducer: ModuleExtraReducer<IReimbursementsState> = builder => {
  builder
    .addCase(getReimbursements.fulfilled, (state, action) => {
      state.reimbursements = action.payload.items;
      state.total = action.payload.total.total;
      state.drivers = action.payload.filters.drivers;
      state.managers = action.payload.filters.managers;
      state.dateRange = [action.payload.dateRange.start, action.payload.dateRange.end];
      state.isLoading = false;
    })
    .addCase(getReimbursements.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getReimbursements.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
