import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { Statuses } from 'constants/global';
import { t } from 'i18next';
import { THUNKS } from 'modules/cars/enums';
import { ICar, IChangeCarStatus } from 'modules/cars/types';

// Change car status
export const changeCarStatus = createAsyncThunk(THUNKS.CHANGE_CAR_STATUS, async ({ data, car }: IChangeCarStatus, thunkApi) => {
  try {
    await api.private.put<{ data: ICar }>(`${Endpoints.CAR}/${car.id}`, data).then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${t<string>('global.notifications.success.carWord')} ${car?.plate} ${
        data.status === Statuses.DISABLED ? t<string>('global.notifications.success.disabled') : t<string>('global.notifications.success.activated')
      }`,
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
