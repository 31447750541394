import { ColumnsType } from 'antd/lib/table';

import { t } from 'i18next';

import { IReportTableItem } from '../types';
import { ITableColumn } from './types';

export const getColumns = (columnsPreset: ITableColumn[]): ColumnsType<IReportTableItem> =>
  columnsPreset.map(columnPreset => ({
    key: columnPreset.name,
    render: columnPreset.renderFunction,
    title: t(`global.layout.reportStep.${columnPreset.titleLocale}`).toString(),
  }));
