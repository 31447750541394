import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { FieldBlock, InfoBlock, Label, PtotosWrapper, Value } from 'modules/cars/pages/Details/styled';
import Photo from 'modules/common/components/Photo';

// TODO should be refactored with backend
const localeByValue = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.details' });
  const { selectedCar } = useAppSelector(state => state.cars);

  return (
    <>
      <InfoBlock>
        <FieldBlock>
          <Label>{t('plate')}</Label>
          <Value>{selectedCar?.info?.registrationPlate}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('make')}</Label>
          <Value>{selectedCar?.info?.make}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('model')}</Label>
          <Value>{selectedCar?.info?.model}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('year')}</Label>
          <Value>{selectedCar?.info?.year}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('insuranceAggrement')}</Label>
          <Value>{selectedCar?.info?.insuranceAgreement}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('shiftPlan')}</Label>
          <Value>{t(localeByValue[selectedCar?.info?.shiftPlan?.value as keyof typeof localeByValue])}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('shiftSplitTimes')}</Label>
          <Value>{t(localeByValue[selectedCar?.info?.shiftSplitTimes?.value as keyof typeof localeByValue])}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('driverPercent')}</Label>
          <Value>{selectedCar?.info?.driverPercent}%</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('maxMill')}</Label>
          <Value>{selectedCar?.info?.mileageMax}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('location')}</Label>
          <Value>
            {selectedCar?.info?.address?.street}, {selectedCar?.info?.address?.city}, {selectedCar?.info?.address?.postalCode}
          </Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('regCertificate')}</Label>
          <PtotosWrapper>
            <Photo src={`data:image/png;base64,${selectedCar?.certificatePhoto}`} />
          </PtotosWrapper>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('carInsurance')}</Label>
          <PtotosWrapper>
            <Photo src={`data:image/png;base64,${selectedCar?.insurancePhoto}`} />
          </PtotosWrapper>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('car')}</Label>
          <PtotosWrapper>
            {selectedCar?.photos?.map((photo: string) => (
              <Photo key={photo.slice(0, 10)} src={`data:image/png;base64,${photo}`} />
            ))}
          </PtotosWrapper>
        </FieldBlock>
      </InfoBlock>
    </>
  );
};

export default Info;
