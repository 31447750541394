import { Locales } from 'constants/global';

const searchParamsLanguage = new URLSearchParams(window.location.search).get('language');

export const config = {
  BASE_URL: process.env.REACT_APP_BASE_API,
  DATE_FORMAT: 'dd.MM.yy',
  TIME_FORMAT: 'HH:mm:ss',
  TIME_FORMAT_HOUR_MINUTE_12H: 'hh:mm aaaa',
  DATE_FORMAT_DAY_MONTH: 'dd.MM',
  DATE_FORMAT_MONTH: 'MMM',
  DATE_FORMAT_MONOLITH_YEAR_MONTH_DAY: 'yyyyMMdd',
  DATE_FORMAT_WITH_WEEKDAY_NAME: 'dd.MM EE',
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_LANGUAGE: searchParamsLanguage || Locales.SV,
};
