import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { changeUserStatusReducer } from './changeUserStatus';
import { clearSelectedUserReducer } from './clearSelectedUser';
import { editUserReducer } from './editUser';
import { getDriverByIdReducer } from './getDriverById';
import { getDriversByManagerReducer } from './getDriversByManager';
import { getDriverShiftsByIdReducer } from './getDriverShiftsById';
import { getDriverWeeklyReportsReducer } from './getDriverWeeklyReports';
import { getManagerByIdReducer } from './getManagerById';
import { getManagerWeeklyReportsReducer } from './getManagerWeeklyReports';
import { getUsersReducer } from './getUsers';
import { linkDriverWithACarReducer } from './linkDriverWithACar';
import { setSelectedUserReducer } from './setSelectedUser';
import { unlinkCarFromDriverReducer } from './unlinkCarFromDriver';

export const usersReducers = {
  ...setSelectedUserReducer,
  ...clearSelectedUserReducer,
};

const extraReducers: ModuleExtraReducer<IUsersState>[] = [
  changeUserStatusReducer,
  editUserReducer,
  getDriverByIdReducer,
  getDriversByManagerReducer,
  getDriverShiftsByIdReducer,
  getDriverWeeklyReportsReducer,
  getManagerByIdReducer,
  getManagerWeeklyReportsReducer,
  getUsersReducer,
  linkDriverWithACarReducer,
  unlinkCarFromDriverReducer,
];

export const buildUsersReducer: ModuleExtraReducerBuilder<IUsersState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};
