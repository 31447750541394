import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { ISelectedCarForAgreement } from 'modules/requests/types';

export const createAndSendAgreement = createAsyncThunk(THUNKS.CREATE_AND_SEND_AGREEMENT, async (params: { requestId: number }, thunkApi) => {
  try {
    const { requestId } = params;

    const res = await api.private
      .put<{ data: ISelectedCarForAgreement }>(`${Endpoints.AGREEMENT_CREATE_AND_SEND}?requestId=${requestId}`)
      .then(response => response.data.data);

    // TODO: add wordings

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${t<string>('global.notifications.success.carWord')} ${'test'} ${t<string>('global.notifications.success.carEdit')}`,
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
