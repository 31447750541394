import { Key } from 'react';

import { PayloadAction } from '@reduxjs/toolkit';
import { ISalaryState } from 'modules/salaries/types';

export const setSelectedPayoutsIdsIdsReducer = {
  setSelectedPayoutsIds: (state: ISalaryState, action: PayloadAction<Key[]>) => {
    state.payoutModal.selectedPayoutsIds = action.payload;
  },
};
