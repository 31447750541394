import { IDriver } from 'modules/driver/types';
import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getDriverShiftsById } from '../thunks';

export const getDriverShiftsByIdReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get driver shifts by ID

  builder
    .addCase(getDriverShiftsById.fulfilled, (state, action) => {
      // Todo: rework
      state.selectedUser = { ...(state.selectedUser as IDriver), shifts: action.payload };
      state.isLoading = false;
    })
    .addCase(getDriverShiftsById.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getDriverShiftsById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
