import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { changeCarStatus } from '../thunks';

export const changeCarStatusReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Change car status

  builder
    .addCase(changeCarStatus.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(changeCarStatus.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(changeCarStatus.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
