import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { IDriver } from 'modules/driver/types';
import { THUNKS } from 'modules/user/enums';
import { ILinkDriverWithACarRequest } from 'modules/user/types';

// Link car with driver
export const linkDriverWithACar = createAsyncThunk(THUNKS.LINK_DRIVER_WITH_A_CAR, async ({ driver, car }: ILinkDriverWithACarRequest, thunkApi) => {
  try {
    const data = await api.private.post<{ data: IDriver }>(`${Endpoints.LINK}?driverId=${driver.user.id}&carId=${car.id}`).then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${driver.user.firstName} ${driver.user.lastName} ${t<string>('global.notifications.success.linkDriverWithACar')} ${car.plate}`,
    });

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
