import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { deleteAdjustment } from '../thunks';

export const deleteAdjustmentReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Delete adjustment

  builder
    .addCase(deleteAdjustment.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(deleteAdjustment.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(deleteAdjustment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
