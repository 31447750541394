import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { Button, Col, Divider, Row } from 'antd';

import { PaymentTypeEnum } from 'modules/salaries/enums';

import { generateErrors } from './generateErrors';
import { ErrorBlockContainer, ErrorsBlock, StyledColumn, WarningIcon } from './styled';

interface IProps {
  closeModal: () => void;
  paymentType: PaymentTypeEnum;
}

export const MarkAsPaidError: FC<IProps> = ({ closeModal, paymentType }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.errorsModal' });
  const { markAsPaidResult } = useAppSelector(state => state.salaries);

  const errors = generateErrors(markAsPaidResult, paymentType);

  return (
    <Row gutter={[0, 12]} justify='center'>
      <StyledColumn span={24}>
        <WarningIcon />
      </StyledColumn>
      <StyledColumn>
        <ErrorBlockContainer>
          <ErrorsBlock>
            {errors?.map(error => (
              <Col span={24} key={error.id}>
                {error.text}
                <Divider />
              </Col>
            ))}
          </ErrorsBlock>
        </ErrorBlockContainer>
      </StyledColumn>
      <StyledColumn span={24}>
        <Button onClick={closeModal} type='primary'>
          {t('okay')}
        </Button>
      </StyledColumn>
    </Row>
  );
};
