import { Button, Steps } from 'antd';

import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #070707;
  margin-bottom: 25px;
`;

export const StyledSteps = styled(Steps)`
  margin-bottom: 60px;
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 40px;
  right: 40px;
`;
