export default {
  declineModal: {
    title: 'Neka förfrågan',
    decline: 'Neka',
    comment: 'Kommentar',
  },
  disputeModal: {
    title: 'Bestrid förfrågan',
    decline: 'Bestrid',
    comment: 'Kommentar',
    okay: 'Okej',
    successText: 'Din förfrågan om att bestrida har skickats till vårt supportteam. Vi kommer att titta på det och kontakta dig med svar via e-post så snart som möjligt'
  },
  table: {},
  tableHeader: {
    selectedItems: 'Valda {{count}} objekt',
    pay: 'Betala med Swish',
    markAsPaid: 'Markera som betald',
    reimbursements: 'Ersättningar',
    advance: 'Förskott',
    advanceMonthly: 'Förskott månadsvis',
  },
  payModal: {
    submit: 'Skicka',
    payment: 'Betalning',
    items: 'Objekt',
  },
  notifications: {
    success: 'Klart',
    carUpdate: 'Bilen uppdaterades',
    amountUpdate: 'Beloppet uppdaterades',
    vatUpdate: 'Momsen uppdaterades',
  },
};
