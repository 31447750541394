import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table, TableProps } from 'antd';

import { config } from 'config';
import { endOfMonth, startOfMonth } from 'date-fns';
import { PaymentsSearchParamsEnum } from 'modules/reimbursements/enums';
import { getAdvanceMonthlyList } from 'modules/reimbursements/slice/thunks';
import { IAdvanceMonthly } from 'modules/reimbursements/types/IAdvanceMonthly';
import { useSearchParams } from 'utils/searchParams';

import { useColumns } from './columns';

const AdvancedMonthly: FC = () => {
  const columns = useColumns();
  const dispatch = useAppDispatch();
  const { getSearchParam, addSearchParams } = useSearchParams();
  const { advanceMonthlyListData, isLoading } = useAppSelector(state => state.reimbursements);

  const selectedMonthSearchParam = getSearchParam(PaymentsSearchParamsEnum.SELECTED_MONTH);
  const selectedMonth = new Date(selectedMonthSearchParam || new Date());
  const page = getSearchParam('page') || '1';
  const pageSize = getSearchParam('pageSize') || config.DEFAULT_PAGE_SIZE.toString();
  const driverIdsSearchParam = getSearchParam('driverNames') || '';

  const driverIds = driverIdsSearchParam ? JSON.parse(driverIdsSearchParam) : [];

  useEffect(() => {
    dispatch(
      getAdvanceMonthlyList({
        start: startOfMonth(selectedMonth),
        end: endOfMonth(selectedMonth),
        driverId: driverIds,
        page: Number(page) - 1,
        pageSize: Number(pageSize),
      })
    );
  }, [selectedMonthSearchParam, page, pageSize, driverIdsSearchParam]);

  const onTableChange: TableProps<IAdvanceMonthly>['onChange'] = ({ current, pageSize }, filters) => {
    addSearchParams({
      driverNames: filters.name ? JSON.stringify(filters.name) : '',
      pageSize: pageSize?.toString() || config.DEFAULT_PAGE_SIZE.toString(),
      page: current?.toString() || '1',
      date: filters.date ? JSON.stringify(filters.date) : '',
    });
  };

  const data = advanceMonthlyListData?.items?.map(dataItem => ({ ...dataItem, key: Math.random() }));

  return (
    <Table
      pagination={{
        current: Number(page),
        total: advanceMonthlyListData?.total?.total,
      }}
      onChange={onTableChange}
      loading={isLoading}
      dataSource={data}
      columns={columns}
    />
  );
};

export default AdvancedMonthly;
