import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType, Tag } from 'antd';

import { SalaryStatus } from 'constants/global';
import { MoreButton } from 'modules/salaries/components/Details/styled';
import { localeByStatus } from 'modules/salaries/locales/localeByStatus';
import { ISalaryByWeekTableData } from 'modules/salaries/types';

interface IProps {
  moreDropdown: (record: ISalaryByWeekTableData) => JSX.Element;
  isAdmin: boolean;
}

export const generateManagerSalariesByWeekColumns = (props: IProps): TableColumnsType<ISalaryByWeekTableData> => {
  const { moreDropdown, isAdmin } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('period'),
      dataIndex: 'period',
      key: 'period',
      align: 'right',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (value: keyof typeof localeByStatus, record) =>
        !record.isSummary ? <Tag color={value.toLowerCase() === SalaryStatus.PAID ? 'green' : 'yellow'}>{t(localeByStatus[value])}</Tag> : value,
      align: 'right',
    },
    {
      title: t('netEarning'),
      dataIndex: 'netEarning',
      key: 'netEarning',
      align: 'right',
    },
    {
      title: t('provision'),
      dataIndex: 'provision',
      key: 'provision',
      align: 'right',
    },
    {
      title: t('efficiencyAdj'),
      dataIndex: 'efficiencyAdj',
      key: 'efficiencyAdj',
      align: 'right',
    },
    {
      title: t('employerTax'),
      dataIndex: 'employerTax',
      key: 'employerTax',
      align: 'right',
    },
    {
      align: 'right',
      title: t('reimbursements'),
      dataIndex: 'reimbursements',
      key: 'reimbursements',
    },
    {
      align: 'right',
      title: t('reimbursementsVat'),
      dataIndex: 'reimbursementsVat',
      key: 'reimbursementsVat',
    },
    {
      title: t('payout'),
      dataIndex: 'payout',
      key: 'payout',
      align: 'right',
    },
    {
      align: 'right',
      key: 'actions',
      width: 60,
      render: (_, record) =>
        !record.isSummary &&
        isAdmin && (
          <Dropdown overlay={moreDropdown(record)} trigger={['click']} placement='bottomRight'>
            <MoreButton />
          </Dropdown>
        ),
    },
  ];
};
