import { config } from 'config';
import { format } from 'date-fns';
import { IGetSalaryAdjustmentsTableData, ISalaryAdjustment } from 'modules/salaries/types';
import { currencyServices } from 'services/currencyServices';

export const mapSalaryAdjustments = (adjustments: ISalaryAdjustment[], isFinal: boolean): IGetSalaryAdjustmentsTableData[] =>
  adjustments.map(item => {
    const adjustmentPeriod = isFinal ? item.month : item.week;

    return {
      key: item.id,
      car: item.carRegistrationPlate,
      period: `${format(new Date(adjustmentPeriod.start), config.DATE_FORMAT_DAY_MONTH)} - ${format(
        new Date(adjustmentPeriod.end),
        config.DATE_FORMAT_DAY_MONTH
      )}`,
      adjustments: currencyServices.convertToCurrency(item.amount),
      comment: item.comment,
    };
  });
