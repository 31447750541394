import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, CheckboxOptionType, Col, Radio, RadioChangeEvent, Row } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';
import { Roles } from 'constants/global';
import { endOfMonth, startOfMonth } from 'date-fns';
import DatePicker from 'modules/common/components/DatePicker';
import { getSalaryReport } from 'modules/salaries';
import { RadioButton } from 'modules/salaries/enums/RadioButton';
import { useSearchParams } from 'utils/searchParams';

import { StyleSpin, UserName } from './styled';

type PropsType = {
  getSalaryByRole: (dateRange: [Date, Date]) => void;
  selectedTab: RadioButton;
};

const DetailsHeader: FC<PropsType> = ({ selectedTab, getSalaryByRole }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.header' });
  const { selectedUserSalary, isLoading } = useAppSelector(state => state.salaries);
  const { setSearchParams, addSearchParams, getSearchParam } = useSearchParams();
  const { isAdmin } = useAppSelector(state => state.auth);
  const selectedUserId = getSearchParam('id');

  const viewedUserRole = selectedUserSalary?.driver ? Roles.DRIVER : Roles.MANAGER;
  const selectedUserData = selectedUserSalary?.driver || selectedUserSalary?.manager;

  const endDate = getSearchParam('endDate');

  const dateRange = useMemo<[Date, Date]>(
    () => [startOfMonth(endDate ? new Date(endDate) : new Date()), endOfMonth(endDate ? new Date(endDate) : new Date())],
    [endDate]
  );

  const filterByDateRangeHandler = (val: Date) => {
    addSearchParams({ endDate: val.toString() });

    const dateRange: [Date, Date] = [startOfMonth(val ? new Date(val) : new Date()), endOfMonth(val ? new Date(val) : new Date())];

    getSalaryByRole(dateRange);
  };

  const radioOptions: CheckboxOptionType[] = [
    { label: t('revenue'), value: RadioButton.SALARY },
    { label: t('reimbursements'), value: RadioButton.REIMBURSEMENTS },
  ];

  const onRadioChange = (e: RadioChangeEvent) => {
    setSearchParams({ id: selectedUserId || '', selectedTab: e.target.value });
  };

  const downloadSalaryReport = () => {
    const [start, end] = dateRange;
    dispatch(getSalaryReport({ role: viewedUserRole, selectedUserData, start, end }));
  };

  return (
    <Row justify='space-between' gutter={[24, 0]} align='bottom'>
      {isAdmin ? (
        <Col>{isLoading ? <StyleSpin /> : <UserName>{`${selectedUserData?.firstName} ${selectedUserData?.lastName}`}</UserName>}</Col>
      ) : (
        <Col>
          <Radio.Group onChange={onRadioChange} optionType='button' size='large' value={selectedTab} options={radioOptions} />
        </Col>
      )}
      <Col flex='auto'>
        <DatePicker.MonthPicker
          size='large'
          allowClear={false}
          onChange={date => {
            filterByDateRangeHandler(date || dateRange[0]);
          }}
          value={dateRange[0]}
        />
      </Col>

      <Col>
        <Button onClick={downloadSalaryReport} icon={<DownloadOutlined />}>
          {t('report')}
        </Button>
      </Col>
    </Row>
  );
};

export default DetailsHeader;
