import { TableColumnsType } from 'antd';

import { Entities } from 'constants/global';
import { RequestTypes } from 'constants/requests';
import { IRequests, IRequestsListTableData } from 'modules/requests/types';

import { generateCarRequestsColumns } from './generateCarRequestsColumns';
import { generateHistoryRequestsColumns } from './generateHistoryRequestsColumns';
import { generateUsersRequestsColumns } from './generateUserRequestsColumns';
import { mapCarRequests } from './mapCarRequests';
import { mapDriverRequests } from './mapDriverRequests';
import { mapHistoryRequests } from './mapHistoryRequests';
import { mapManagerRequests } from './mapManagerRequests';

interface IProps {
  requests: IRequests;
  requestType: RequestTypes;
  roleFilterValue: Entities[];
  moreDropdown: (record: IRequestsListTableData) => JSX.Element;
}

type ReturnType = [IRequestsListTableData[], TableColumnsType<IRequestsListTableData>];

type MappperType = {
  [key: string]: [(requests: IRequests) => IRequestsListTableData[], (props: Partial<IProps>) => TableColumnsType<IRequestsListTableData>];
};

const requestsByTypeMapper: MappperType = {
  [RequestTypes.DRIVER]: [mapDriverRequests, generateUsersRequestsColumns],
  [RequestTypes.MANAGER]: [mapManagerRequests, generateUsersRequestsColumns],
  [RequestTypes.CAR]: [mapCarRequests, generateCarRequestsColumns],
  [RequestTypes.HISTORY]: [mapHistoryRequests, generateHistoryRequestsColumns],
};

export const useRequestsListTable = (props: IProps): ReturnType => {
  const { requests, requestType, ...columnGeneratorProps } = props;
  const [dataMapper, columnGenerator] = requestsByTypeMapper[requestType] || requestsByTypeMapper[RequestTypes.HISTORY];
  const tableData = dataMapper(requests);
  const tableColumns = columnGenerator(columnGeneratorProps);

  return [tableData, tableColumns];
};
