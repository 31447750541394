import { FC } from 'react';

import { Card, ChartHeader, ChartTitle } from './styled';

interface IProps {
  title?: string | JSX.Element;
  children: JSX.Element;
}

const CharCard: FC<IProps> = ({ title, children }) => (
  <Card>
    {title && (
      <ChartHeader>
        <ChartTitle>{title}</ChartTitle>
      </ChartHeader>
    )}
    {children}
  </Card>
);

export default CharCard;
