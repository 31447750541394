import { createSlice } from '@reduxjs/toolkit';

import { IUsersState } from '../types';
import { buildUsersReducer, usersReducers } from './reducers';

const initialState: IUsersState = {
  users: [],
  selectedUser: null,
  total: {
    all: 0,
    active: 0,
    pending: 0,
    disabled: 0,
  },
  isLoading: false,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: usersReducers,
  extraReducers: builder => buildUsersReducer(builder),
});

export const { clearSelectedUser, setSelectedUser } = usersSlice.actions;

export default usersSlice.reducer;
