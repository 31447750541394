import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Modal } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { endOfMonth, startOfMonth } from 'date-fns';
import { getTaxTableNumbers, setFinalTaxTableNumber } from 'modules/salaries';
import { useSearchParams } from 'utils/searchParams';

import { Note, StyledSelect } from './styled';

interface IProps {
  isTaxTableOpen: boolean;
  setIsTaxTableOpen: Dispatch<SetStateAction<boolean>>;
  refetchGetSalaryByRole: (dateRange: [Date, Date]) => void;
  finalPaymentId: number;
}

const TaxTableModal: FC<IProps> = ({ isTaxTableOpen, finalPaymentId, setIsTaxTableOpen, refetchGetSalaryByRole }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.taxTableModal' });
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [taxTableNumber, setTaxTableNumber] = useState<number | undefined>();
  const taxNumbers = useAppSelector(store => store.salaries.taxTableNumbers);
  const { getSearchParam } = useSearchParams();

  const endDate = getSearchParam('endDate');

  const dateRange = useMemo<[Date, Date]>(
    () => [startOfMonth(endDate ? new Date(endDate) : new Date()), endOfMonth(endDate ? new Date(endDate) : new Date())],
    [endDate]
  );

  const selectOptions: DefaultOptionType[] = useMemo(() => taxNumbers?.map(number => ({ value: number, label: number })), [taxNumbers]) || [];

  useEffect(() => {
    dispatch(getTaxTableNumbers());
  }, []);

  const resetHandler = () => {
    setIsTaxTableOpen(false);
    setTaxTableNumber(undefined);
    form.resetFields();
  };

  const submitHandler = async () => {
    await form.validateFields();

    if (taxTableNumber && finalPaymentId) {
      await dispatch(
        setFinalTaxTableNumber({
          finalPaymentId,
          taxTableNumber,
        })
      );
    }

    refetchGetSalaryByRole(dateRange);
    resetHandler();
  };

  const onClearSelect = () => {
    setTaxTableNumber(undefined);
  };

  const onSelectChange = (value: number) => {
    setTaxTableNumber(value);
  };

  return (
    <Modal
      closable
      width={448}
      title={t('title')}
      onCancel={resetHandler}
      open={isTaxTableOpen}
      footer={[
        <Button key='submit' onClick={submitHandler}>
          {t('submit')}
        </Button>,
      ]}
    >
      <Note>{t('note')}</Note>
      <Form form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false}>
        <Form.Item label={t('type')} name='taxTableNumber' rules={[{ required: true }]}>
          <StyledSelect onChange={value => onSelectChange(Number(value))} value={taxTableNumber} onClear={onClearSelect} allowClear options={selectOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TaxTableModal;
