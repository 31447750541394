import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Form, Input, Modal, Select } from 'antd';

import { IOption } from 'modules/analytics/types';
import { addAdjustment, getAdjustmentTypes } from 'modules/salaries';
import { AdjustmentType } from 'modules/salaries/enums';

import { Note, StyledInputNumber } from './styled';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  dateRange: { start: string; end: string };
  salaryPeriod: string;
  driverId: string;
  cars: { id: number; plate: string }[];
  getSalaryByRole: (dateRange: [Date, Date]) => void;
}

const fieldsHandler = (place: AdjustmentType, platform: string, car: string, amount: string, comment: string) => [
  {
    name: 'place',
    value: place || null,
  },
  {
    name: 'platform',
    value: platform || null,
  },
  {
    name: 'car',
    value: car || null,
  },
  {
    name: 'amount',
    value: amount,
  },
  {
    name: 'comment',
    value: comment || null,
  },
];

const localeByPlace = { earning: 'earningAdjustment', provision: 'provisionAdjustment', final: 'finalAdjustment' };

const AddAdjustmentsModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, dateRange, salaryPeriod, driverId, cars, getSalaryByRole }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.addAdjustmentsModal' });
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.salaries);
  const [form] = Form.useForm();
  const [adjustmentTypes, setAdjustmentTypes] = useState<IOption[]>([]);
  const [place, setPlace] = useState<AdjustmentType>(AdjustmentType.EARNING);
  const [car, setCar] = useState<string>('');
  const [platform, setPlatform] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    dispatch(getAdjustmentTypes()).then(res => {
      const allTypes = res.payload as IOption[];
      const allowedTypes = allTypes.filter(type => type.value !== AdjustmentType.FINAL);
      setAdjustmentTypes(allowedTypes);
    });
  }, []);

  const resetHandler = () => {
    setIsModalOpen(false);
    setPlace(AdjustmentType.EARNING);
    setCar('');
    setPlatform('');
    setAmount('');
    setComment('');
  };

  const submitHandler = async () => {
    await form.validateFields();

    await dispatch(
      addAdjustment({
        adjustmentDate: new Date(dateRange.start).toISOString(),
        adjustmentType: place,
        amount: Number(amount),
        comment,
        carId: Number(car),
        driverId: Number(driverId),
        platform,
      })
    );

    getSalaryByRole([new Date(dateRange.start), new Date(dateRange.end)]);
    resetHandler();
  };

  return (
    <Modal
      title={t('addAdjustments')}
      open={isModalOpen}
      destroyOnClose
      okText={t('submit')}
      onOk={submitHandler}
      onCancel={resetHandler}
      okButtonProps={{ loading: isLoading }}
    >
      <Note>{t('note')}</Note>
      <Form
        fields={fieldsHandler(place, platform, car, amount, comment)}
        form={form}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Form.Item label={t('place')} name='place' rules={[{ required: true }]}>
          <Select onChange={val => setPlace(val)} placeholder={t('place_placeholder')}>
            {adjustmentTypes.map(item => (
              <Select.Option key={item.id} value={item.value}>
                {t(localeByPlace[item.value as keyof typeof localeByPlace])}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('platform')} name='platform' rules={[{ required: true }]}>
          <Select onChange={val => setPlatform(val)} placeholder={t('platform_placeholder')}>
            <Select.Option value='uber'>Uber</Select.Option>
            <Select.Option value='bolt'>Bolt</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('car')} name='car' rules={[{ required: true }]}>
          <Select onChange={val => setCar(val)} placeholder={t('car_placeholder')}>
            {cars.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.plate}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('period')}>
          <Input disabled value={salaryPeriod} />
        </Form.Item>
        <Form.Item
          label={t('amount')}
          name='amount'
          rules={[
            { required: true },
            {
              validator: (_, value: string) =>
                new Promise((resolve, reject) => {
                  if (Number(value) === 0) {
                    reject(new Error());
                  }

                  resolve('');
                }),
              message: t('isZeroAmountValidationMessage'),
            },
          ]}
        >
          <StyledInputNumber suffix={t('sek')} type='number' onChange={({ target }) => setAmount(target.value)} placeholder={t('amount_placeholder')} />
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <Input.TextArea rows={1} onChange={({ target }) => setComment(target.value)} placeholder={t('comment_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAdjustmentsModal;
