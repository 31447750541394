import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Col, Form, Input, InputNumber } from 'antd';

import { CalculatorOutlined } from '@ant-design/icons';
import { CarConstants } from 'constants/car';
import { AddCarSteps } from 'modules/cars/enums';
import { addCar } from 'modules/cars/slice/thunks';
import { IAddCarStepsProps, ICreateCarData, IWithFiles } from 'modules/cars/types';
import { IManager } from 'modules/manager/types/IManager';

import { StyledForm, StyledNumberInput, StyledRow } from '../styled';
import EarningCalculator from './components/EarningCalculator';

const fieldsHandler = (carData: ICreateCarData, address: string) => [
  {
    name: 'driverPercentage',
    value: carData.driverPercent || null,
  },
  {
    name: 'maximumMileage',
    value: carData.mileageMax,
  },
  {
    name: 'pickUpLocation',
    value: address,
  },
];

const FourthStep: FC<IAddCarStepsProps & IWithFiles> = ({ setStep, carData, setCarData, files }) => {
  const { t } = useTranslation('translation', { keyPrefix: `admin.cars.steps.${AddCarSteps.FOURTH}` });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin } = useAppSelector(state => state.auth);
  const { users: managers } = useAppSelector(state => state.users);
  const selectedManager = useMemo(() => (managers as IManager[]).find(manager => manager.user.id === carData.managerId), [managers]);

  const address = useMemo(
    () =>
      `${selectedManager?.companyInfo?.companyAddress?.street}, ${selectedManager?.companyInfo?.companyAddress?.city}, ${selectedManager?.companyInfo?.companyAddress?.postalCode}`,
    [
      selectedManager?.companyInfo?.companyAddress?.street,
      selectedManager?.companyInfo?.companyAddress?.city,
      selectedManager?.companyInfo?.companyAddress?.postalCode,
    ]
  );

  const [isCalcOpen, setIsCalcOpen] = useState<boolean>(true);

  const nextStepHandler = async () => {
    await form.validateFields();
    await dispatch(addCar({ carData, photos: files, isAdmin }));

    // Getting current state from store
    const { error } = store.getState().cars;
    if (!error) navigate('/final', { state: { fromCar: true }});
  };

  return (
    <>
      <StyledForm
        form={form}
        fields={fieldsHandler(carData, address)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        {isAdmin && (
          <Form.Item label={t('pickUpLocation')} name='pickUpLocation'>
            <Input readOnly />
          </Form.Item>
        )}
        <Form.Item label={t('maximumMileage')} name='maximumMileage' rules={[{ required: true }, { min: 0, max: CarConstants.MAX_MILAGE, type: 'number' }]}>
          <StyledNumberInput
            min={0}
            max={CarConstants.MAX_MILAGE}
            type='number'
            placeholder={t('maximumMileage_placeholder')}
            onChange={val => setCarData(prev => ({ ...prev, mileageMax: val as number }))}
          />
        </Form.Item>
        <Form.Item
          label={t('driverPercentage')}
          name='driverPercentage'
          rules={[{ required: true, min: CarConstants.MIN_PERCENTAGE, max: CarConstants.MAX_PERCENTAGE, type: 'number' }]}
        >
          <InputNumber
            min={CarConstants.MIN_PERCENTAGE}
            max={CarConstants.MAX_PERCENTAGE}
            type='number'
            addonAfter={<CalculatorOutlined onClick={() => setIsCalcOpen(true)} />}
            placeholder={t('driverPercentage_placeholder')}
            onChange={val => setCarData(prev => ({ ...prev, driverPercent: val as number }))}
          />
        </Form.Item>

        <StyledRow justify='space-between'>
          <Col>
            <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
          </Col>
          <Col>
            <Button type='primary' onClick={nextStepHandler}>
              {t('nextButton')}
            </Button>
          </Col>
        </StyledRow>
      </StyledForm>

      <EarningCalculator isOpen={isCalcOpen} setIsOpen={setIsCalcOpen} carData={carData} setCarData={setCarData} />
    </>
  );
};

export default FourthStep;
