import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { RequestStatuses } from 'constants/requests';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { IDeclineRequestRequest, IManagerRequest } from 'modules/requests/types';

// Decline DRIVER status of request
export const declineDriverRequest = createAsyncThunk(THUNKS.DECLINE_DRIVER_REQUEST, async ({ id, reason, comment }: IDeclineRequestRequest, thunkApi) => {
  try {
    await api.private.put<{ data: IManagerRequest }>(`${Endpoints.DRIVER_REQUEST}?id=${id}`, { status: RequestStatuses.DECLINED, reason, comment });

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.declineDriverRequest'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
