import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Bar } from '@ant-design/plots';
import { config } from 'config';
import { renderDateRange } from 'utils/date';

import ChartCard from '../ChartCard';

const TotalDrivenChart: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.dashboard' });
  const { report } = useAppSelector(state => state.analytics);

  const date = useMemo(
    () =>
      report?.drivenKm
        ?.map(item => ({ Date: renderDateRange({ timeRange: item.timeRangeLabel, formatOfDate: config.DATE_FORMAT_DAY_MONTH }), Km: item.value }))
        ?.reverse() || [],
    [report]
  );

  return (
    <ChartCard title={t('totalDriven')}>
      <Bar data={date} xField='Km' yField='Date' legend={false} color='rgba(25, 123, 189, 0.85)' />
    </ChartCard>
  );
};

export default TotalDrivenChart;
