import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { IGetWeeklyReports } from 'modules/cars/types';
import { ICarWeeklyReport } from 'modules/reports/types';

interface IGetCarWeeklyReportsResponse {
  data: {
    total: { total: number };
    items: ICarWeeklyReport[];
  };
}

// Get Car weekly reports
export const getCarWeeklyReports = createAsyncThunk(
  THUNKS.GET_CAR_WEEKLY_REPORTS,
  async ({ carId, page = 0, pageSize = 7, start, end }: IGetWeeklyReports, thunkApi) => {
    try {
      const res = await api.private.get<IGetCarWeeklyReportsResponse>(
        `${Endpoints.REPORT_WEEKLY_CAR}?id=${carId}&page=${page}&pageSize=${pageSize}${start ? `&start=${start}&end=${end}` : ''}`
      );

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
