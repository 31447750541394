import { config } from 'config';
import { Roles } from 'constants/global';
import { format } from 'date-fns';
import { IManager, IManagerTableData } from 'modules/manager/types';
import { currencyServices } from 'services/currencyServices';

export const mapManagers = (rawManagers: IManager[]): IManagerTableData[] =>
  rawManagers.map(managerData => ({
    id: managerData.user.id,
    key: managerData.user.id,
    status: managerData.status,
    role: Roles.MANAGER,
    date: format(new Date(managerData.user.createdAt), config.DATE_FORMAT),
    time: format(new Date(managerData.user.createdAt), config.TIME_FORMAT),
    name: `${managerData.user.firstName} ${managerData.user.lastName}`,
    phone: managerData.user.phone,
    drivers: managerData.drivers?.length,
    cars: managerData.cars?.length,
    efficiency: managerData.efficiency !== undefined ? `${managerData.efficiency}%` : '-',
    shiftsAvailable: managerData.shiftsAvailable,
    earnings: managerData.earning !== undefined ? currencyServices.convertToCurrency(managerData.earning) : '-',
    driverProvision: managerData.driverProvision !== undefined ? `${managerData.driverProvision}%` : '-',
  }));
