import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Image, Input, Modal } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { UploadFile } from 'antd/lib/upload/interface';

import { UploadOutlined } from '@ant-design/icons';
import { startOfDay } from 'date-fns';
import { AddCarSteps } from 'modules/cars/enums';
import { IAddCarStepsProps, ICreateCarData, IFiles, IFilesUseState } from 'modules/cars/types';
import DatePicker from 'modules/common/components/DatePicker';

import { Label, LabelWrapper, StyledForm, StyledRow, StyledUpload } from '../styled';
import backseat from './assets/backseat.jpeg';
import front from './assets/front.jpeg';
import side from './assets/side.jpeg';
import { ImageLabel, ImagesContainer, OpenTemplatesButton } from './styled';

const fieldsHandler = (carData: ICreateCarData, files: IFiles) => [
  {
    name: 'registrationCertificate',
    value: files.file1,
  },
  {
    name: 'insuranceNumber',
    value: carData.insuranceAgreement || null,
  },
  {
    name: 'insuranceExpire',
    value: carData.insuranceExpireDate,
  },
  {
    name: 'insuranceAgreement',
    value: files.file2,
  },
  {
    name: 'carPictures',
    value: files.file3,
  },
];

const SecondStep: FC<IAddCarStepsProps & IFilesUseState> = ({ setStep, carData, setCarData, files, setFiles }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', { keyPrefix: `admin.cars.steps.${AddCarSteps.SECOND}` });
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  const uploadRules = [
    {
      required: true,
    },
    {
      validator(_: RuleObject, value: UploadFile[]) {
        if (value.length) return Promise.resolve();
        return Promise.reject();
      },
    },
  ];

  return (
    <>
      <StyledForm
        form={form}
        fields={fieldsHandler(carData, files)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Form.Item
          label={(
            <LabelWrapper>
              {t('registrationCertificate')}
              <Label>{t('registrationCertificate_label')}</Label>
            </LabelWrapper>
          )}
          name='registrationCertificate'
          rules={uploadRules}
        >
          <StyledUpload
            name='file1'
            beforeUpload={() => false}
            onChange={val => setFiles(prev => ({ ...prev, file1: val.fileList }))}
            fileList={files.file1}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>{t('upload')}</Button>
          </StyledUpload>
        </Form.Item>
        <Form.Item label={t('insuranceNumber')} name='insuranceNumber' rules={[{ required: true }]}>
          <Input
            type='number'
            placeholder={t('insuranceNumber_placeholder')}
            onChange={({ target }) => setCarData(prev => ({ ...prev, insuranceAgreement: target.value }))}
          />
        </Form.Item>
        <Form.Item label={t('insuranceExpire')} name='insuranceExpire' rules={[{ required: true }]}>
          <DatePicker
            onChange={val => setCarData(prev => ({ ...prev, insuranceExpireDate: val }))}
            disabledDate={current => current && current < startOfDay(new Date())}
            format='DD MMM, YYYY'
            style={{ width: 160 }}
          />
        </Form.Item>
        <Form.Item
          label={(
            <LabelWrapper>
              {t('insuranceAgreement')}
              <Label>{t('insuranceAgreement_label')}</Label>
            </LabelWrapper>
          )}
          name='insuranceAgreement'
          rules={uploadRules}
        >
          <StyledUpload
            name='file2'
            beforeUpload={() => false}
            onChange={val => setFiles(prev => ({ ...prev, file2: val.fileList }))}
            fileList={files.file2}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>{t('upload')}</Button>
          </StyledUpload>
        </Form.Item>
        <Form.Item
          label={(
            <LabelWrapper>
              {t('carPictures')}
              <Label>{t('carPictures_label')}</Label>
              <OpenTemplatesButton type='link' size='small' onClick={() => setIsTemplatesOpen(true)}>
                {t('seeTemplates')}
              </OpenTemplatesButton>
            </LabelWrapper>
          )}
          name='carPictures'
          rules={uploadRules}
        >
          <StyledUpload name='file3' beforeUpload={() => false} onChange={val => setFiles(prev => ({ ...prev, file3: val.fileList }))} fileList={files.file3}>
            <Button icon={<UploadOutlined />}>{t('upload')}</Button>
          </StyledUpload>
        </Form.Item>

        <StyledRow justify='space-between'>
          <Col>
            <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
          </Col>
          <Col>
            <Button type='primary' onClick={nextStepHandler}>
              {t('nextButton')}
            </Button>
          </Col>
        </StyledRow>
      </StyledForm>

      <Modal width={800} centered title={t('modalTitle')} footer={false} open={isTemplatesOpen} onCancel={() => setIsTemplatesOpen(false)}>
        <Label noMargin>{t('modalSubtitle')}</Label>
        <ImagesContainer>
          <div>
            <ImageLabel>{t('frontSide')}</ImageLabel>
            <Image width={240} src={front} alt={t('frontSide')} />
          </div>
          <div>
            <ImageLabel>{t('backSide')}</ImageLabel>
            <Image width={240} src={side} alt={t('backSide')} />
          </div>
          <div>
            <ImageLabel>{t('backseat')}</ImageLabel>
            <Image width={240} src={backseat} alt={t('backseat')} />
          </div>
        </ImagesContainer>
      </Modal>
    </>
  );
};

export default SecondStep;
