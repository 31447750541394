import { initReactI18next } from 'react-i18next';

import { config } from 'config';
import { Locales } from 'constants/global';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { IAuthUser } from 'modules/auth/types';
import { getUser } from 'modules/user/utils';

import en from './en';
import sv from './sv';

// Todo: check this logic
const userStringified = getUser();

const storedUser: IAuthUser = userStringified && JSON.parse(userStringified);
const storedLang = storedUser?.lang || storedUser?.user.lang;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: storedLang || config.DEFAULT_LANGUAGE,
    debug: true,
    resources: {
      [Locales.EN]: {
        translation: en,
      },
      [Locales.SV]: {
        translation: sv,
      },
    },
  });

export default i18n;
