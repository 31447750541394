import { createSlice } from '@reduxjs/toolkit';

import { ICarsState } from '../types';
import { buildCarsReducer, carsReducers } from './reducers';

const initialState: ICarsState = {
  managers: [],
  cars: [],
  selectedCar: null,
  carsInRadius: [],
  total: {
    all: 0,
    active: 0,
    pending: 0,
    disabled: 0,
  },
  options: {
    shifts: [],
    shiftPlans: [],
  },
  managerNames: [],
  isLoading: false,
  error: null,
  activeCars: [],
};

const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: carsReducers,
  extraReducers: builder => buildCarsReducer(builder),
});

export const { clearSelectedCar } = carsSlice.actions;

export default carsSlice.reducer;
