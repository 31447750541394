import { IReimbursementsState } from 'modules/reimbursements/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { createDriverAdvanceReducer } from './createDriverAdvance';
import { disputeReimbursementsReducer } from './disputeReimbursements';
import { getAdvanceListReducer } from './getAdvanceList';
import { getAdvanceMonthlyListReducer } from './getAdvanceMonthlyList';
import { getAdvancePaymentStatusesReducer } from './getAdvancePaymentStatuses';
import { getReimbursementPhotoReducer } from './getReimbursementPhoto';
import { getReimbursementsReducer } from './getReimbursements';
import { getReimbursementTypesReducer } from './getReimbursementTypes';
import { payReimbursementReducer } from './payReimbursement';
import { updateReimbursementReducer } from './updateReimbursement';
import { updateReimbursementStatusReducer } from './updateReimbursementStatus';

const extraReducers: ModuleExtraReducer<IReimbursementsState>[] = [
  disputeReimbursementsReducer,
  getReimbursementPhotoReducer,
  getReimbursementTypesReducer,
  getReimbursementsReducer,
  payReimbursementReducer,
  updateReimbursementReducer,
  updateReimbursementStatusReducer,
  getAdvancePaymentStatusesReducer,
  getAdvanceListReducer,
  getAdvanceMonthlyListReducer,
  createDriverAdvanceReducer,
];

export const buildReimbursementsReducer: ModuleExtraReducerBuilder<IReimbursementsState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};

export const reducers = {};
