import { config } from 'config';
import { format } from 'date-fns';
import { IRequests, IRequestsListTableData } from 'modules/requests/types';

export const mapHistoryRequests = (historyRequests: IRequests): IRequestsListTableData[] =>
  historyRequests.history.map(item => ({
    id: item.id,
    key: item.id,
    createdAt: item.createdAt,
    date: format(new Date(item.createdAt), config.DATE_FORMAT),
    time: format(new Date(item.createdAt), config.TIME_FORMAT),
    name: item.name,
    role: item.role,
    address: `${item.address.street}, ${item.address.city}, ${item.address.postalCode}`,
    reason: item.reason,
  }));
