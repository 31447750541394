import { Upload } from 'antd';

import styled from 'styled-components';

export const StyledDragger = styled(Upload.Dragger)`
  height: 168px;
  padding: 16px;
  border: none;
  width: 560px !important;
`;
