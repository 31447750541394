import { config } from 'config';
import { format } from 'date-fns';

import { DateWrapper, Time } from '../../styled';
import { IBoltTableItem } from '../../types';
import { IFieldWithCount, ITableColumn, ITableField } from '../types';

export const BoltFieldsWithCount: IFieldWithCount[] = [
  {
    key: 'deletedBoltRecordsNum',
    titleLocale: 'deletedBoltRecords',
  },
  {
    key: 'insertedBoltRecordsNum',
    titleLocale: 'insertedBoltRecords',
  },
  {
    key: 'deletedBoltTripsNum',
    titleLocale: 'deletedBoltTrips',
  },
  {
    key: 'insertedBoltTripsNum',
    titleLocale: 'insertedBoltTrips',
  },
];

const BoltTableColumns: ITableColumn[] = [
  {
    name: 'driverFullName',
    titleLocale: 'driverFullName',
    renderFunction: (_, data: IBoltTableItem) => data.driverFullName,
  },
  {
    name: 'driverPhone',
    titleLocale: 'driverPhone',
    renderFunction: (_, data: IBoltTableItem) => data.driverPhone,
  },
  {
    name: 'orderTime',
    titleLocale: 'orderTime',
    renderFunction: (_, data: IBoltTableItem) => {
      const date = format(new Date(data.orderTime), config.DATE_FORMAT);
      const time = format(new Date(data.orderTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'paymentTime',
    titleLocale: 'paymentTime',
    renderFunction: (_, data: IBoltTableItem) => {
      const date = format(new Date(data.paymentTime), config.DATE_FORMAT);
      const time = format(new Date(data.paymentTime), config.TIME_FORMAT);
      return (
        <DateWrapper>
          <p>{date}</p>
          <Time>{time}</Time>
        </DateWrapper>
      );
    },
  },
  {
    name: 'travelPrice',
    titleLocale: 'travelPrice',
    renderFunction: (_, data: IBoltTableItem) => data.travelPrice.toString(),
  },
  // not need it now
  // {
  //   name: 'bookingFee',
  //   titleLocale: 'bookingFee',
  //   renderFunction: (_, data: IBoltTableItem) => data.bookingFee.toString(),
  // },
  // {
  //   name: 'customsDuties',
  //   titleLocale: 'customsDuties',
  //   renderFunction: (_, data: IBoltTableItem) => data.customsDuties.toString(),
  // },
  // {
  //   name: 'cancellationFee',
  //   titleLocale: 'cancellationFee',
  //   renderFunction: (_, data: IBoltTableItem) => data.cancellationFee.toString(),
  // },
  {
    name: 'tips',
    titleLocale: 'tips',
    renderFunction: (_, data: IBoltTableItem) => data.tips.toString(),
  },
];

export const BoltTables: ITableField[] = [
  {
    key: 'moreThanOneDriverBoltTrips',
    titleLocale: 'moreThanOneDriverBoltTrips',
    columnsPreset: BoltTableColumns,
  },
  {
    key: 'noShiftBoltTrips',
    titleLocale: 'noShiftBoltTrips',
    columnsPreset: BoltTableColumns,
  },
  {
    key: 'noCarBoltTrips',
    titleLocale: 'noCarBoltTrips',
    columnsPreset: BoltTableColumns,
  },
];
