import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Pie } from '@ant-design/plots';
import { currencyServices } from 'services/currencyServices';

import ChartCard from '../ChartCard';

const RevenueChart: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.dashboard' });
  const { report } = useAppSelector(state => state.analytics);

  const data = useMemo(
    () => [
      { type: 'Bolt', value: report?.earning?.bolt && Math.round(report.earning.bolt) },
      { type: 'Uber', value: report?.earning?.uber && Math.round(report.earning.uber) },
    ],
    [report]
  );

  return (
    <ChartCard title={t('revenue')}>
      <Pie
        data={data}
        legend={false}
        angleField='value'
        colorField='type'
        radius={1}
        innerRadius={0.64}
        label={{
          type: 'inner',
          offset: '-50%',
          style: {
            textAlign: 'center',
          },
          autoRotate: false,
          content: '{value}',
        }}
        statistic={{
          title: false,
          content: {
            offsetY: 4,
            style: {
              fontSize: '26px',
            },
            content: report?.earning?.total ? currencyServices.convertToSwedishCurrencyNoLabel(report?.earning.total).toString() : '',
          },
        }}
      />
    </ChartCard>
  );
};

export default RevenueChart;
