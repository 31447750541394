import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { config } from 'config';
import { format } from 'date-fns';
import Photo from 'modules/common/components/Photo';
import { localesByValue } from 'modules/requests/locales';
import { FieldBlock, InfoBlock, Label, PtotosWrapper, Title, Value } from 'modules/requests/pages/Details/styled';
import { IDriverRequest } from 'modules/requests/types';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver' });
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IDriverRequest };

  return (
    <>
      <InfoBlock>
        <Title>{t('contactInfo')}</Title>
        <FieldBlock>
          <Label>{t('username')}</Label>
          <Value>
            {selectedRequest?.userInfo.firstName} {selectedRequest?.userInfo.lastName}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('role')}</Label>
          <Value>{t('driverWord')}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('email')}</Label>
          <Value>
            <a href={`mailto:${selectedRequest?.userInfo.email}`}>{selectedRequest?.userInfo.email}</a>
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('phone')}</Label>
          <Value>{selectedRequest?.userInfo.phone}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('postalCode')}</Label>
          <Value>{selectedRequest?.addressInfo.postalCode}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('city')}</Label>
          <Value>{selectedRequest?.addressInfo.city}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('street')}</Label>
          <Value>{selectedRequest?.addressInfo.street}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <Title>{t('documents')}</Title>
        <FieldBlock>
          <Label>{t('socialSecurityNumber')}</Label>
          <Value>{selectedRequest?.socialSecurityNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('taxiIdNumber')}</Label>
          <Value>{selectedRequest?.taxiIdNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('taxiDriverLicense')}</Label>
          <PtotosWrapper>
            {selectedRequest?.documents.map(document => (
              <Photo key={document} src={`data:image/png;base64,${document}`} />
            ))}
          </PtotosWrapper>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <Title>{t('driverAccount')}</Title>
        <FieldBlock>
          <Label>{t('drivingExperience')}</Label>
          <Value>{selectedRequest?.driverInfo.driverExperience}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('drivenFor')}</Label>
          <Value>{selectedRequest?.driverInfo.driverPlatform.join(', ')}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('interestedIn')}</Label>
          <Value>{t(selectedRequest?.driverInfo.interestedPlatform)}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('existingDriverAccount')}</Label>
          <Value>{t(selectedRequest?.havingAccount || '')}</Value>
        </FieldBlock>
        <FieldBlock>
          {selectedRequest?.uberAccountInfo && (
            <>
              <Label>{t('personalDataUber')}</Label>
              <Value>
                <a href={`mailto:${selectedRequest.uberAccountInfo.email}`}>{selectedRequest.uberAccountInfo.email}</a> /{' '}
                {selectedRequest.uberAccountInfo.phone}
              </Value>
            </>
          )}
          {selectedRequest?.boltAccountInfo && (
            <>
              <Label>{t('personalDataBolt')}</Label>
              <Value>
                <a href={`mailto:${selectedRequest.boltAccountInfo.email}`}>{selectedRequest.boltAccountInfo.email}</a> /{' '}
                {selectedRequest.boltAccountInfo.phone}
              </Value>
            </>
          )}
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <Title>{t('bank')}</Title>
        <FieldBlock>
          <Label>{t('clearingNumber')}</Label>
          <Value>{selectedRequest?.bankInfo.clearingNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('bankAccountNumber')}</Label>
          <Value>{selectedRequest?.bankInfo.bankAccount}</Value>
        </FieldBlock>

        <InfoBlock marginTop>
          <Title>{t('car')}</Title>
          <FieldBlock>
            <Label>{t('availability')}</Label>
            <Value>{selectedRequest?.hasCar ? t('yes') : t('no')}</Value>
          </FieldBlock>
        </InfoBlock>
      </InfoBlock>

      <InfoBlock>
        <Title>{t('workSchedule')}</Title>
        <FieldBlock>
          <Label>{t('startWorking')}</Label>
          <Value>{selectedRequest?.workSchedule?.startingDate && format(new Date(selectedRequest?.workSchedule.startingDate), config.DATE_FORMAT)}</Value>
        </FieldBlock>
        {selectedRequest?.workSchedule && (
          <>
            <FieldBlock>
              <Label>{t('workingDays')}</Label>
              <Value>{selectedRequest.workSchedule.daysPerWeek}</Value>
            </FieldBlock>
            <FieldBlock>
              <Label>{t('workingTime')}</Label>
              <Value>{t(localesByValue[selectedRequest.workSchedule.workTime])}</Value>
            </FieldBlock>
          </>
        )}
        {selectedRequest?.workSchedule && (
          <FieldBlock>
            <Label>{t('preference')}</Label>
            <Value>{t(selectedRequest.workSchedule.workSchedule)}</Value>
          </FieldBlock>
        )}
        <FieldBlock>
          <Label>{t('days')}</Label>
          <Value>{selectedRequest?.workSchedule.workWeekdays.map(day => t(localesByValue[day.value])).join(', ')}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('advanceLimit')}</Label>
          <Value>{selectedRequest.advanceLimit ? `${selectedRequest.advanceLimit} ${t('edit.sek')}` : t('edit.notAllowed')}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <Title>{t('agreement')}</Title>

        <FieldBlock>
          <Label>{t('registrationPlate')}</Label>
          <Value>{selectedRequest.agreement?.carRegistrationPlate || '-'}</Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('carMake')}</Label>
          <Value>{selectedRequest.agreement?.carMake || '-'}</Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('carModel')}</Label>
          <Value>{selectedRequest.agreement?.carModel || '-'}</Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('createdAt')}</Label>
          <Value>
            {(selectedRequest.agreement?.acrobatAgreement?.createdAt &&
              format(new Date(selectedRequest.agreement?.acrobatAgreement?.createdAt), config.DATE_FORMAT)) ||
              '-'}
          </Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('vilotidbokNumber')}</Label>
          <Value>{selectedRequest.agreement?.vilotidbokNumber || '-'}</Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('roadbirdSignedAt')}</Label>
          <Value>
            {(selectedRequest.agreement?.acrobatAgreement?.roadbirdSignedAt &&
              format(new Date(selectedRequest.agreement?.acrobatAgreement?.roadbirdSignedAt), config.DATE_FORMAT)) ||
              '-'}
          </Value>
        </FieldBlock>

        <FieldBlock>
          <Label>{t('driverSignedAt')}</Label>
          <Value>
            {(selectedRequest.agreement?.acrobatAgreement?.driverSignedAt &&
              format(new Date(selectedRequest.agreement?.acrobatAgreement?.driverSignedAt), config.DATE_FORMAT)) ||
              '-'}
          </Value>
        </FieldBlock>
      </InfoBlock>
    </>
  );
};

export default Info;
