import { Modal, Steps, Upload } from 'antd';

import styled from 'styled-components';

export const StyledDragger = styled(Upload.Dragger)`
  height: 168px;
  padding: 16px;
  border: none;
  width: 560px !important;
`;

export const StyledSteps = styled(Steps)`
  margin-bottom: 24px;
  width: 100%;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .ant-btn-primary {
    margin-left: auto !important;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
`;
