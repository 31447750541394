import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/reimbursements/enums';
import { IAdvance } from 'modules/reimbursements/types';

interface IRequestParams {
  finalPaymentId: number;
  amount: number;
}

export const createDriverAdvance = createAsyncThunk(THUNKS.CREATE_DRIVER_ADVANCE, async (requestParams: IRequestParams, thunkApi) => {
  try {
    const res = await api.private.post<{ data: IAdvance }>(`${Endpoints.ADVANCE_CREATE}`, requestParams);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.advanceAdded'),
    });

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
