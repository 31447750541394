export enum THUNKS {
  GET_REQUESTS = 'requests/getRequests',
  GET_DRIVER_REQUESTS = 'requests/getDriverRequests',
  GET_MANAGER_REQUESTS = 'requests/getManagerRequests',
  GET_CAR_REQUESTS = 'requests/getCarRequests',
  GET_HISTORY_REQUESTS = 'requests/getHistoryRequest',
  GET_REQUEST_BY_ID = 'requests/getRequestById',
  APPROVE_MANAGER_REQUEST = 'requests/approveManagerRequest',
  DECLINE_MANAGER_REQUEST = 'requests/declineManagerRequest',
  APPROVE_DRIVER_REQUEST = 'requests/approveDriverRequest',
  DECLINE_DRIVER_REQUEST = 'requests/declineDriverRequest',
  EDIT_DRIVER_REQUEST = 'requests/editDriverRequest',
  EDIT_MANAGER_REQUEST = 'requests/editManagerRequest',
  EDIT_CAR_REQUEST = 'requests/editCarRequest',
  APPROVE_CAR_REQUEST = 'requests/approveCarRequest',
  DECLINE_CAR_REQUEST = 'requests/declineCarRequest',
  SET_SELECTED_CAR_FOR_AGREEMENT = 'agreement/setSelectedCarForAgreement',
  CREATE_AND_SEND_AGREEMENT = 'agreement/createAndSendAgreement',
  DOWNLOAD_AGREEMENT = 'agreement/downloadAgreement',
}
