import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Menu, Radio, Table, TableProps } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import { getCarManagerNames, getCars } from 'modules/cars';
import { ActivateCarModal, DisableCarModal } from 'modules/cars/components';
import { useCarsTable } from 'modules/cars/hooks';
import { ICarTableData } from 'modules/cars/types';
import TableWrapper from 'modules/common/components/TableWrapper';
import useDebounce from 'utils/debounce';

import { OptionsWrapper, StyledSearch } from './styled';

// TODO: HIDDEN FOR v1.0
// import CreateNotificationModal from '../components/CreateNotificationModal';

// TODO: Table refactor

const CarsList: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.list' });
  const dispatch = useAppDispatch();
  const { isLoading, cars, total, managerNames: managers } = useAppSelector(state => state.cars);
  const { isAdmin } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState<Statuses>(Statuses.ALL);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);

  const [page, setPage] = useState<number>(1);
  const [selectedManagerId, setSelectedManagerId] = useState<number[]>();
  const [selected, setSelected] = useState<ICarTableData | null>(null);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
  // TODO: HIDDEN FOR v1.0
  // const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] = useState<boolean>(false);

  // Total counter for table pagination
  const paginationTotal = useMemo(() => {
    switch (statusFilter) {
      case Statuses.ALL:
        return total.all;
      case Statuses.ACTIVE:
        return total.active;
      case Statuses.PENDING:
        return total.pending;
      default: // DISABLED
        return total.disabled;
    }
  }, [statusFilter, total]);

  useEffect(() => {
    if (isAdmin) dispatch(getCarManagerNames());
  }, []);

  useEffect(() => {
    dispatch(getCars({ page: page - 1, status: statusFilter, managerId: selectedManagerId, registrationPlate: debouncedSearch }));
  }, [page, statusFilter, selectedManagerId, debouncedSearch]);

  const openModalHandler = (record: ICarTableData, isActivate?: boolean) => {
    setSelected(record);

    if (isActivate) {
      setIsActivateModalOpen(true);
    } else {
      setIsDisableModalOpen(true);
    }
  };

  // TODO: HIDDEN FOR v1.0
  // const openCreateNotificationModalHandler = (record: ITableData) => {
  //   setSelected(record);
  //   setIsCreateNotificationModalOpen(true);
  // };

  const moreDropdown = useCallback(
    (record: ICarTableData) => (
      <Menu
        items={
          record.status !== Statuses.DISABLED
            ? [
                // TODO: HIDDEN FOR v1.0
                // {
                //   key: '0',
                //   label: 'Create notification',
                //   onClick: () => openCreateNotificationModalHandler(record),
                // },
                {
                  key: '1',
                  label: t('disable'),
                  danger: true,
                  onClick: () => openModalHandler(record),
                },
              ].filter(item => (isAdmin ? item : item.key !== '0'))
            : [
                {
                  key: '1',
                  label: t('activate'),
                  onClick: () => openModalHandler(record, true),
                },
              ]
        }
      />
    ),
    [openModalHandler]
  );

  const [data, columns] = useCarsTable({ cars, moreDropdown, isAdmin, selectedManagerId, managers });

  const onTableChange: TableProps<ICarTableData>['onChange'] = (_, filters) => {
    if (filters.manager?.length && filters.manager[0]) setSelectedManagerId(filters.manager as number[]);
    else setSelectedManagerId(undefined);
  };

  const searchHandler = (searchText: string) => {
    setPage(1);
    setSearch(searchText);
  };

  const header = useMemo(
    () => (
      <>
        <Radio.Group
          size='large'
          value={statusFilter}
          onChange={({ target }) => {
            setStatusFilter(target.value);
            setPage(1);
          }}
        >
          <Radio.Button value='all'>
            {t('all')} ({total.all})
          </Radio.Button>
          <Radio.Button value='pending'>
            {t('pending')} ({total.pending})
          </Radio.Button>
          <Radio.Button value='active'>
            {t('active')} ({total.active})
          </Radio.Button>
          <Radio.Button value='disabled'>
            {t('disabled')} ({total.disabled})
          </Radio.Button>
        </Radio.Group>
        <OptionsWrapper>
          <StyledSearch prefix={<SearchOutlined />} placeholder={t('search_placeholder')} size='large' onChange={({ target }) => searchHandler(target.value)} />
          <Button size='large' type='primary' onClick={() => navigate('/add-car')}>
            {t('addCar')}
          </Button>
        </OptionsWrapper>
      </>
    ),
    [statusFilter, setStatusFilter, cars]
  );

  return (
    <>
      <TableWrapper header={header}>
        <Table
          rowClassName={record => (record.status === Statuses.DISABLED ? 'opacity-row' : '')}
          dataSource={data}
          // TODO: check if it loads properly
          loading={isLoading}
          pagination={{ showSizeChanger: false, current: page, onChange: p => setPage(p), total: paginationTotal }}
          columns={columns}
          onChange={onTableChange}
          onRow={record => ({
            onClick: e => {
              if (
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-title-content') ||
                (e.target as HTMLElement).classList.contains('ant-dropdown-menu-item') ||
                (e.target as HTMLElement).classList.contains('anticon') ||
                (e.target as HTMLElement).tagName === 'svg'
              )
                return;

              navigate(`/cars/${record.plate}?id=${record.id}`);
            },
          })}
        />
      </TableWrapper>

      {selected && (
        <>
          <DisableCarModal
            page={page}
            statusFilter={statusFilter}
            car={selected}
            setCar={setSelected}
            isModalOpen={isDisableModalOpen}
            setIsModalOpen={setIsDisableModalOpen}
          />
          <ActivateCarModal
            page={page}
            statusFilter={statusFilter}
            car={selected}
            setCar={setSelected}
            isModalOpen={isActivateModalOpen}
            setIsModalOpen={setIsActivateModalOpen}
          />
        </>
      )}
      {/* // TODO: HIDDEN FOR v1.0 */}
      {/* {selected && (
        <CreateNotificationModal
          car={selected}
          setCar={setSelected}
          isModalOpen={isCreateNotificationModalOpen}
          setIsModalOpen={setIsCreateNotificationModalOpen}
        />
      )} */}
    </>
  );
};

export default CarsList;
