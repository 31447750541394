import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Col, Form, Input, Row } from 'antd';

import { Globals } from 'constants/global';
import { DriverSteps } from 'modules/auth/enums';
import { IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'firstName',
    value: driverData.userInfo.firstName || null,
  },
  {
    name: 'lastName',
    value: driverData.userInfo.lastName || null,
  },
  {
    name: 'email',
    value: driverData.userInfo.email || null,
  },
  {
    name: 'phone',
    value: driverData.userInfo.phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'postalCode',
    value: driverData.addressInfo.postalCode || null,
  },
  {
    name: 'city',
    value: driverData.addressInfo.city || null,
  },
  {
    name: 'street',
    value: driverData.addressInfo.street || null,
  },
];

const FirstStep: FC<IDriverStepsProps> = ({ setStep, driverData, setDriverData }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.FIRST}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  //* could be used for validation. but it doesn't work properly
  // const firstName = useRegExp(t, firstNameRegExp);
  // const city = useRegExp(t, cityRegExp);
  // const email = useRegExp(t, emailRegExp);

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('firstName')} name='firstName' rules={[{ required: true, type: 'string' }]}>
              <Input
                placeholder={t('firstName_placeholder')}
                onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, firstName: target.value }}))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('lastName')} name='lastName' rules={[{ required: true, type: 'string' }]}>
              <Input
                placeholder={t('lastName_placeholder')}
                onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, lastName: target.value }}))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
        <Input
          placeholder={t('email_placeholder')}
          onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, email: target.value }}))}
        />
      </Form.Item>
      <Form.Item label={t('phone')} name='phone' rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}>
        <Input
          type='number'
          placeholder={t('phone_placeholder')}
          prefix={Globals.SWEDEN_COUNTRY_CODE}
          value={342}
          onChange={({ target }) =>
            setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, phone: `${Globals.SWEDEN_COUNTRY_CODE}${target.value}` }}))
          }
        />
      </Form.Item>
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('postalCode')} name='postalCode' rules={[{ required: true, len: 5, message: t('postalCodeLengthMessage') }]}>
              <Input
                type='number'
                placeholder={t('postalCode_placeholder')}
                onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, postalCode: target.value }}))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('city')} name='city' rules={[{ required: true, type: 'string' }]}>
              <Input
                placeholder={t('city_placeholder')}
                onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, city: target.value }}))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <Form.Item label={t('street')} name='street' rules={[{ required: true }]}>
        <Input
          placeholder={t('street_placeholder')}
          onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, street: target.value }}))}
        />
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => navigate('/sign-up/role')}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default FirstStep;
