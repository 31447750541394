import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { Entities, Roles } from 'constants/global';

interface IProps {
  role: Entities;
}

const RoleTag: FC<IProps> = ({ role }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  switch (role.toLowerCase()) {
    case Roles.DRIVER:
      return <Tag color='geekblue'>{t('driver')}</Tag>;
    case Roles.MANAGER:
      return <Tag color='volcano'>{t('fleetManager')}</Tag>;

    default: // CAR
      return <Tag color='default'>{t('car')}</Tag>;
  }
};

export default RoleTag;
