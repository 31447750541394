import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType } from 'antd';

import { DateWrapper, MoreButton, Time } from 'modules/requests/pages/List/styled';
import { IRequestsListTableData } from 'modules/requests/types';

interface IProps {
  moreDropdown?: (record: IRequestsListTableData) => JSX.Element;
}

export const generateCarRequestsColumns = (props: IProps): TableColumnsType<IRequestsListTableData> => {
  const { moreDropdown } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('plate'),
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('insurance'),
      dataIndex: 'insurance',
      key: 'insurance',
    },
    {
      title: t('model'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={moreDropdown!(record)} trigger={['click']} placement='bottom'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
