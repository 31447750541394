import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { IManager } from 'modules/manager/types';

import { FieldBlock, InfoBlock, Label, Value } from '../../../../pages/Details/styled';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.manager' });
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IManager };

  return (
    <>
      <InfoBlock>
        <FieldBlock>
          <Label>{t('username')}</Label>
          <Value>
            {selectedUser?.user.firstName} {selectedUser?.user.lastName}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('role')}</Label>
          <Value>{t(`roles.${selectedUser?.user.role}`)}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('email')}</Label>
          <Value>
            <a href={`mailto:${selectedUser?.user.email}`}>{selectedUser?.user.email}</a>
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('phone')}</Label>
          <Value>{selectedUser?.user.phone}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('postalCode')}</Label>
          <Value>{selectedUser?.address.postalCode}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('city')}</Label>
          <Value>{selectedUser?.address.city}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('street')}</Label>
          <Value>{selectedUser?.address.street}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('socialSecurityNumber')}</Label>
          <Value>{selectedUser?.socialSecurityNumber}</Value>
        </FieldBlock>
        {selectedUser?.swishNumber && (
          <FieldBlock>
            <Label>{t('swishNumber')}</Label>
            <Value>{selectedUser?.swishNumber}</Value>
          </FieldBlock>
        )}
        <FieldBlock>
          <Label>{t('companyRegNumber')}</Label>
          <Value>{selectedUser?.companyInfo.companyRegistrationNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('companyName')}</Label>
          <Value>{selectedUser?.companyInfo.companyName}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('accountingCenter')}</Label>
          <Value>{selectedUser?.accountingCenter}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('companyAddress')}</Label>
          <Value>
            {selectedUser?.companyInfo.companyAddress.street}, {selectedUser?.companyInfo.companyAddress.city},{' '}
            {selectedUser?.companyInfo.companyAddress.postalCode}
          </Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('accountingCenter')}</Label>
          <Value>{selectedUser?.accountingCenter}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('carsNumber')}</Label>
          <Value>{selectedUser?.cars.length}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('driversNumber')}</Label>
          <Value>{selectedUser?.drivers.length}</Value>
        </FieldBlock>
      </InfoBlock>
    </>
  );
};

export default Info;
