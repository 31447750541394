import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import Container from 'modules/common/components/Container';

import { SubTitle,Title } from './styled';

const Welcome: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.welcome' });

  return (
    <Container>
      <Title>{t('title')}</Title>
      <SubTitle>{t('subTitle')}</SubTitle>
      <Button type='primary' onClick={() => navigate('/sign-up/role')}>
        {t('button')}
      </Button>
    </Container>
  );
};

export default Welcome;
