import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer } from 'types/Module';

import { forgotPassword } from '../thunks';

export const forgotPasswordReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Forgot password

  builder
    .addCase(forgotPassword.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(forgotPassword.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(forgotPassword.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
