// TODO: rework

import { DriverDetails } from 'modules/driver';
import { ManagerDetails } from 'modules/manager';
import { UsersList } from 'modules/user/pages';

export const usersAdminRoutes = [
  {
    element: <UsersList />,
    path: '/managers',
  },

  {
    element: <ManagerDetails />,
    path: '/managers/:id',
  },
];

export const usersRoutes = [
  {
    element: <UsersList />,
    path: '/drivers',
  },

  {
    element: <DriverDetails />,
    path: '/drivers/:id',
  },
];
