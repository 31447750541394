import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ICar, IGetCarsRequest } from 'modules/cars/types';

interface IGetCarsResponse {
  data: {
    total: { active: number; disabled: number; pending: number };
    items: ICar[];
  };
}

// Get all cars
export const getCars = createAsyncThunk(THUNKS.GET_CARS, async ({ page, status, pageSize = 10, managerId, registrationPlate }: IGetCarsRequest, thunkApi) => {
  try {
    const data = await api.private
      .get<IGetCarsResponse>(`${Endpoints.CAR}`, {
        params: { managerId, page, status, pageSize, registrationPlate },
      })
      .then(res => res.data.data);

    const totalSum = Object.values(data.total).reduce((sum, currentSum) => sum + currentSum, 0);

    return { cars: data.items, total: { ...data.total, all: totalSum }};
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
