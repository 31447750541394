import { FC } from 'react';

import { useAppDispatch, useAppSelector } from 'store/index';

import { ImageProps } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';
import Loader from 'modules/common/components/Loader';
import Photo from 'modules/common/components/Photo';
import { getReimbursementPhoto } from 'modules/reimbursements';

import { ImgButton, ImgDiv } from './styled';

interface IPhotoProps extends ImageProps {
  reimbursementId: number;
  photoName: string;
}

const PhotoWithSpin: FC<IPhotoProps> = ({ reimbursementId, photoName }) => {
  const dispatch = useAppDispatch();
  const { photos } = useAppSelector(state => state.reimbursements);
  const photoData = photos[reimbursementId];

  const onClick = () => {
    dispatch(getReimbursementPhoto({ photoName, reimbursementId }));
  };

  return !photoData ? (
    <ImgDiv>
      <ImgButton onClick={onClick}>
        <CloudDownloadOutlined size={25} />
      </ImgButton>
    </ImgDiv>
  ) : (
    <ImgDiv>
      <Loader spinning={photoData?.isLoading || false}>{photoData?.photo && <Photo src={photoData.photo} />}</Loader>
    </ImgDiv>
  );
};

export default PhotoWithSpin;
