import { TableColumnsType } from 'antd';

import { IChangelog, IChangelogTableData } from 'modules/analytics/types';

import { generateChangelogColumns } from './generateChangelogColumns';
import { mapChangelog } from './mapChangelog';

interface IProps {
  changelog: IChangelog;
}

type ReturnType = [IChangelogTableData[], TableColumnsType<IChangelogTableData>];

export const useChangelogTable = (props: IProps): ReturnType => {
  const { changelog } = props;
  const tableData = mapChangelog(changelog);
  const tableColumns = generateChangelogColumns();

  return [tableData, tableColumns];
};
