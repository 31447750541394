export enum THUNKS {
  REIMBURSEMENTS_TYPES = 'reimbursements/types',
  GET_REIMBURSEMENTS = 'reimbursement/list',
  GET_REIMBURSEMENT_PHOTO = 'reimbursement/photo',
  UPDATE_REIMBURSEMENT_STATUS = 'reimbursement/updateStatus',
  PAY_REIMBURSEMENT = 'reimbursement/pay',
  UPDATE_REIMBURSEMENT = 'reimbursement/update',
  DISPUTE_REIMBURSEMENT = 'reimbursement/dispute',
  ADVANCE_PAYMENT_STATUSES = 'advancePaymentStatuses',
  ADVANCE_LIST = 'advances/list',
  ADVANCE_MONTHLY_LIST = 'advances/monthlyList',
  CREATE_DRIVER_ADVANCE = 'advance/createDriverAdvance',
}
