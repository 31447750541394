import { Button } from 'antd';

import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border-radius: 50%;

  :hover {
    border-radius: 50%;
  }
`;
