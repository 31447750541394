import { config } from 'config';
import { format } from 'date-fns';
import { IDriverSalary } from 'modules/salaries/types/IDriverSalary';
import { currencyServices } from 'services/currencyServices';

import { ISalaryByWeekTableData } from '../../types/ISalaryByWeekTableData';

export const mapDriverSalariesByWeek = (driverSalary: IDriverSalary | null): ISalaryByWeekTableData[] => {
  if (!driverSalary?.salariesByWeek?.length) {
    return [];
  }

  return [
    ...driverSalary.salariesByWeek.map(item => ({
      key: item.id,
      cars: item.salariesByCar.map(i => ({ id: i.carId, plate: i.carRegistrationPlate })),
      salaryId: item.id,
      dateRange: item.week,
      period: `${format(new Date(item.week.start), config.DATE_FORMAT_DAY_MONTH)} - ${format(new Date(item.week.end), config.DATE_FORMAT_DAY_MONTH)}`,
      status: item.status,
      earnings: currencyServices.convertToCurrency(item.amount.earning),
      roadbirdFee: currencyServices.convertToCurrency(item.amount.roadBirdFee),
      netto: currencyServices.convertToCurrency(item.amount.netto),
      brutto: currencyServices.convertToCurrency(item.amount.brutto),
      provision: currencyServices.convertToCurrency(item.amount.provision),
      payout: currencyServices.convertToCurrency(item.amount.payout),
    })),
    {
      key: Math.random(),
      earnings: currencyServices.convertToCurrency(driverSalary.amount.earning),
      roadbirdFee: currencyServices.convertToCurrency(driverSalary.amount.roadBirdFee),
      netto: currencyServices.convertToCurrency(driverSalary.amount.netto),
      brutto: currencyServices.convertToCurrency(driverSalary.amount.brutto),
      provision: currencyServices.convertToCurrency(driverSalary.amount.provision),
      payout: currencyServices.convertToCurrency(driverSalary.amount.payout),
      isSummary: true,
    },
  ];
};
