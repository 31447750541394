// Todo: remove eslint ignore
/* eslint-disable react/jsx-boolean-value */
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Col, Form, FormInstance, Input, Row, Select } from 'antd';

import { Globals } from 'constants/global';
import { getOptions } from 'modules/auth';
import { IManagerRequest } from 'modules/requests/types';

import { StyledForm } from './styled';

interface IProps {
  managerData: IManagerRequest;
  setManagerData: Dispatch<SetStateAction<IManagerRequest>>;
  form: FormInstance;
}

const fieldsHandler = (managerData: IManagerRequest) => [
  {
    name: 'firstName',
    value: managerData.userInfo.firstName || null,
  },
  {
    name: 'lastName',
    value: managerData.userInfo.lastName || null,
  },
  {
    name: 'email',
    value: managerData.userInfo.email || null,
  },
  {
    name: 'phone',
    value: managerData.userInfo.phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'postalCode',
    value: managerData.addressInfo.postalCode || null,
  },
  {
    name: 'city',
    value: managerData.addressInfo.city || null,
  },
  {
    name: 'street',
    value: managerData.addressInfo.street || null,
  },
  {
    name: 'companyNumber',
    value: managerData.companyInfo.companyRegistrationNumber || null,
  },
  {
    name: 'companyName',
    value: managerData.companyInfo.companyName || null,
  },
  {
    name: 'companyPostalCode',
    value: managerData.companyInfo.companyAddress.postalCode || null,
  },
  {
    name: 'companyCity',
    value: managerData.companyInfo.companyAddress.city || null,
  },
  {
    name: 'companyStreet',
    value: managerData.companyInfo.companyAddress.street || null,
  },
  {
    name: 'redovisnigscentral',
    value: managerData.accountingCenter || null,
  },
  {
    name: 'securityNumber',
    value: managerData.socialSecurityNumber || null,
  },
  {
    name: 'swishNumber',
    value: managerData.swishNumber || null,
  },
];

const Edit: FC<IProps> = ({ managerData, setManagerData, form }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.manager.edit' });
  const dispatch = useAppDispatch();
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IManagerRequest };

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(
    () => () => {
      setManagerData(selectedRequest);
    },
    [selectedRequest]
  );

  return (
    <StyledForm form={form} fields={fieldsHandler(managerData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Row justify='space-between' gutter={40}>
        <Col span={8}>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('firstName')} name='firstName' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) => setManagerData(prev => ({ ...prev, userInfo: { ...prev.userInfo, firstName: target.value }}))}
                    placeholder={t('firstName_placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('lastName')} name='lastName' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) => setManagerData(prev => ({ ...prev, userInfo: { ...prev.userInfo, lastName: target.value }}))}
                    placeholder={t('lastName_placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
            <Input
              onChange={({ target }) => setManagerData(prev => ({ ...prev, userInfo: { ...prev.userInfo, email: target.value }}))}
              placeholder={t('email_placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('phone')} name='phone' rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}>
            <Input
              type='number'
              prefix={Globals.SWEDEN_COUNTRY_CODE}
              onChange={({ target }) =>
                setManagerData(prev => ({ ...prev, userInfo: { ...prev.userInfo, phone: `${Globals.SWEDEN_COUNTRY_CODE}${target.value}` }}))
              }
              placeholder={t('phone_placeholder')}
            />
          </Form.Item>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('postalCode')} name='postalCode' rules={[{ required: true, len: 5, message: t('postalCodeLengthMessage') }]}>
                  <Input
                    type='number'
                    onChange={({ target }) => setManagerData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, postalCode: target.value }}))}
                    placeholder={t('postalCode_placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('city')} name='city' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) => setManagerData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, city: target.value }}))}
                    placeholder={t('city_placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('street')} name='street' rules={[{ required: true }]}>
            <Input
              onChange={({ target }) => setManagerData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, street: target.value }}))}
              placeholder={t('street_placeholder')}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t('companyNumber')} name='companyNumber' rules={[{ required: true, len: 10 }]}>
            <Input
              type='number'
              placeholder={t('companyNumber_placeholder')}
              onChange={({ target }) => setManagerData(prev => ({ ...prev, companyInfo: { ...prev.companyInfo, companyRegistrationNumber: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('companyName')} name='companyName' rules={[{ required: true }]}>
            <Input
              placeholder={t('companyName_placeholder')}
              onChange={({ target }) => setManagerData(prev => ({ ...prev, companyInfo: { ...prev.companyInfo, companyName: target.value }}))}
            />
          </Form.Item>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t('companyPostalCode')}
                  name='companyPostalCode'
                  rules={[{ required: true }, { required: true, len: 5, message: t('postalCodeLengthMessage') }]}
                >
                  <Input
                    type='number'
                    onChange={({ target }) =>
                      setManagerData(prev => ({
                        ...prev,
                        companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, postalCode: target.value }},
                      }))
                    }
                    placeholder={t('postalCode_placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('companyCity')} name='companyCity' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) =>
                      setManagerData(prev => ({
                        ...prev,
                        companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, city: target.value }},
                      }))
                    }
                    placeholder={t('city_placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('companyStreet')} name='companyStreet' rules={[{ required: true }]}>
            <Input
              onChange={({ target }) =>
                setManagerData(prev => ({
                  ...prev,
                  companyInfo: { ...prev.companyInfo, companyAddress: { ...prev.companyInfo.companyAddress, street: target.value }},
                }))
              }
              placeholder={t('street_placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('redovisnigscentral')} name='redovisnigscentral' rules={[{ required: true }]}>
            <Select placeholder={t('redovisnigscentral_placeholder')} onChange={val => setManagerData(prev => ({ ...prev, accountingCenter: val }))}>
              <Select.Option value='halda'>Halda</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('securityNumber')}
            name='securityNumber'
            rules={[
              {
                required: true,
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-/g, '');
                    const { length } = textWithoutDashes;

                    if (length >= 10 && length <= 12) resolve('');
                    else reject(new Error());
                  }),
                message: t('lengthValidationMessage10_12'),
              },
              {
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-|[0-9]/g, '');
                    const { length } = textWithoutDashes;

                    if (length === 0) resolve('');
                    else reject(new Error());
                  }),
                message: t('wrongDigestError'),
              },
            ]}
          >
            <Input
              placeholder={t('securityNumber_placeholder')}
              onChange={({ target }) => setManagerData(prev => ({ ...prev, socialSecurityNumber: target.value }))}
            />
          </Form.Item>
          <Form.Item label={t('swishNumber')} name='swishNumber' rules={[{ max: 15, min: 8 }]}>
            <Input
              type='number'
              placeholder={t('swishNumberPlaceholder')}
              onChange={({ target }) => setManagerData(prev => ({ ...prev, swishNumber: target.value }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default Edit;
