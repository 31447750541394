import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Button, Col, Form, Radio, Select } from 'antd';

import { startOfDay } from 'date-fns';
import { DriverSteps } from 'modules/auth/enums';
import { IDriverStepsProps } from 'modules/auth/types';
import DatePicker from 'modules/common/components/DatePicker';
import { ICreateDriverData } from 'modules/driver/types';

import { LabelWrapper, SelectAllDaysButton, StyledForm, StyledRow } from '../styled';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'startTime',
    value: driverData.workScheduleInfo.startingDate,
  },
  {
    name: 'daysAWeek',
    value: driverData.workScheduleInfo.daysPerWeek || null,
  },
  {
    name: 'workTime',
    value: driverData.workScheduleInfo.workTime || null,
  },
  {
    name: 'workSchedule',
    value: driverData.workScheduleInfo.workSchedule || null,
  },
  {
    name: 'workDays',
    value: driverData.workScheduleInfo.workWeekdays,
  },
];

// TODO should be refactored with backend
const localeByValue = {
  'full-time': 'fullTime',
  'part-time': 'partTime',
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
};

const FifthStep: FC<IDriverStepsProps> = ({ setStep, driverData, setDriverData }) => {
  const [form] = Form.useForm();
  const { weekdays, employmentTypes, schedules } = useAppSelector(state => state.auth.options);
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.FIFTH}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('startTime')} name='startTime' rules={[{ required: true }]}>
        <DatePicker
          onChange={val => setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, startingDate: val }}))}
          disabledDate={current => current && current < startOfDay(new Date())}
          format='DD MMM, YYYY'
          style={{ width: 160 }}
        />
      </Form.Item>
      <Form.Item label={t('daysAWeek')} name='daysAWeek' rules={[{ required: true }]}>
        <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, daysPerWeek: target.value }}))}>
          <Radio.Button value='1-3'>1-3</Radio.Button>
          <Radio.Button value='3-5'>3-5</Radio.Button>
          <Radio.Button value='5-7'>5-7</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('workTime')} name='workTime' rules={[{ required: true }]}>
        <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, workTime: target.value }}))}>
          {employmentTypes.map(item => (
            <Radio.Button value={item.value} key={item.id}>
              {t(localeByValue[item.value as keyof typeof localeByValue])}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('workSchedule')} name='workSchedule' rules={[{ required: true }]}>
        <Radio.Group
          onChange={({ target }) => {
            setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, workSchedule: target.value }}));
          }}
        >
          {schedules.map(item => (
            <Radio.Button value={item.value} key={item.value}>
              {t(item.value)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={(
          <LabelWrapper>
            {t('workDays')}
            <SelectAllDaysButton
              onClick={() => setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, workWeekdays: weekdays.map(day => day.id) }}))}
              size='small'
              type='link'
            >
              {t('allDays')}
            </SelectAllDaysButton>
          </LabelWrapper>
        )}
        name='workDays'
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          mode='multiple'
          placeholder={t('workDays_placeholder')}
          onChange={val => setDriverData(prev => ({ ...prev, workScheduleInfo: { ...prev.workScheduleInfo, workWeekdays: val }}))}
        >
          {weekdays.map(item => (
            <Select.Option value={item.id} key={item.id}>
              {t(localeByValue[item.value as keyof typeof localeByValue])}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default FifthStep;
