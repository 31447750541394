import { Upload } from 'antd';

import DatePicker from 'modules/common/components/DatePicker';
import styled from 'styled-components';

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
  height: 168px;
  padding: 16px;
  border: none;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
