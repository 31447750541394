import { TableColumnsType } from 'antd';

import { ICarInfo } from 'modules/cars/types';
import { IUserCarTableData } from 'modules/user/types';

import { generateUserCarColumns } from './generateUserCarColumns';
import { mapUserCars } from './mapUserCars';

interface IProps {
  cars: ICarInfo[];
  search: string;
}

type ReturnType = [IUserCarTableData[], TableColumnsType<IUserCarTableData>];

export const useUserCarTable = (props: IProps): ReturnType => {
  const { cars, search } = props;
  const tableColumns = generateUserCarColumns();
  const mappedCars = mapUserCars(cars);
  const mappedAndFilteredCars = mappedCars.filter(({ plate }) => plate.toLowerCase().includes(search.toLowerCase()));

  return [mappedAndFilteredCars, tableColumns];
};
