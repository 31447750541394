import { TableColumnsType } from 'antd';

import { ICarWeeklyReport } from 'modules/reports/types';

import { ICarWeeklyReportTableData } from '../../types/ICarWeeklyReportsTableData';
import { generateCarWeeklyReportsColumns } from './generateCarWeeklyReportsColumns';
import { mapCarWeeklyReports } from './mapCarWeeklyReports';

interface IProps {
  weeklyReports: ICarWeeklyReport[];
}

type ReturnType = [ICarWeeklyReportTableData[], TableColumnsType<ICarWeeklyReportTableData>];

export const useCarWeeklyReportsTable = (props: IProps): ReturnType => {
  const { weeklyReports } = props;
  const tableData = mapCarWeeklyReports(weeklyReports);
  const tableColumns = generateCarWeeklyReportsColumns();

  return [tableData, tableColumns];
};
