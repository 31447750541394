import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Col, Select, Space } from 'antd';

import { CarOutlined, NodeIndexOutlined, TeamOutlined } from '@ant-design/icons';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { getReport } from 'modules/analytics';
import DatePicker from 'modules/common/components/DatePicker';
import { currencyServices } from 'services/currencyServices';
import { getEndOfWeek, getStartOfWeek } from 'utils/date';

import AreaPlotChart from '../../components/AreaPlotChart';
import AverageChart from '../../components/AverageChart';
import Change from '../../components/Change';
import EarningPerCarChart from '../../components/EarningPerCarChart';
import RevenueChart from '../../components/RevenueChart';
import TotalDrivenChart from '../../components/TotalDrivenChart';
import { Card, Header, IconWrapper, StyledEmpty, StyledLoader,StyledRow, Title } from './styled';

enum DatePickerTypes {
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

const previousWeek = new Date();
previousWeek.setDate(new Date().getDate() - 7);

const getDateRangeByType: { [key in DatePickerTypes]: (date: Date) => { start: string; end: string } } = {
  [DatePickerTypes.WEEK]: date => ({ start: getStartOfWeek(new Date(date)).toISOString(), end: getEndOfWeek(new Date(date)).toISOString() }),
  [DatePickerTypes.MONTH]: date => ({ start: startOfMonth(new Date(date)).toISOString(), end: endOfMonth(new Date(date)).toISOString() }),
  [DatePickerTypes.QUARTER]: date => ({ start: startOfQuarter(new Date(date)).toISOString(), end: endOfQuarter(new Date(date)).toISOString() }),
  [DatePickerTypes.YEAR]: date => ({ start: startOfYear(new Date(date)).toISOString(), end: endOfYear(new Date(date)).toISOString() }),
};

const Dashboard: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.dashboard' });
  const { user, isAdmin } = useAppSelector(state => state.auth);
  const { report, isLoading } = useAppSelector(state => state.analytics);
  const dispatch = useAppDispatch();
  const [type, setType] = useState<DatePickerTypes>(DatePickerTypes.WEEK);
  const [selectedDate, setSelectedDate] = useState<Date>();

  useEffect(() => {
    setSelectedDate(previousWeek);
  }, []);

  useEffect(() => {
    if (selectedDate) dispatch(getReport(getDateRangeByType[type](selectedDate)));
  }, [type, selectedDate]);

  const contentRenderer = useCallback(() => {
    if ((!report && !isLoading) || (report && !report.earning.total)) {
      return <StyledEmpty description={t(report && !report.earning.total ? 'noData' : 'selectDate') as string} />;
    } else if (report && !isLoading) {
      return (
        <>
          <Col span={isAdmin ? 8 : 12}>
            <Card>
              <IconWrapper background='#F3F4F4' iconColor='#657B75'>
                <CarOutlined />
              </IconWrapper>
              <Change label={t('activeCars')} value={report?.activeCars} />
            </Card>
          </Col>
          <Col span={isAdmin ? 8 : 12}>
            <Card>
              <IconWrapper background='#ECF7FB' iconColor='#129CCE'>
                <NodeIndexOutlined />
              </IconWrapper>
              <Change label={t('activeDrivers')} value={report?.activeDrivers} />
            </Card>
          </Col>
          {isAdmin && (
            <Col span={8}>
              <Card>
                <IconWrapper background='#F7F1F2' iconColor='#9F5658'>
                  <TeamOutlined />
                </IconWrapper>
                <Change label={t('activeManagers')} value={report?.activeManagers} />
              </Card>
            </Col>
          )}
          <Col span={12}>
            <RevenueChart />
          </Col>
          <Col span={12}>
            <TotalDrivenChart />
          </Col>

          <Col span={24}>
            <AverageChart />
          </Col>

          {isAdmin ? (
            <>
              <Col span={8}>
                <AreaPlotChart
                  payment={currencyServices.convertToCurrency(report.driversPaid.total)}
                  initialChartData={report.driversPaid}
                  title='driversPaid'
                />
              </Col>
              <Col span={8}>
                <AreaPlotChart
                  payment={currencyServices.convertToCurrency(report.roadBirdRevenue.total)}
                  initialChartData={report.roadBirdRevenue}
                  title='roadBirdRevenue'
                />
              </Col>
              <Col span={8}>
                <AreaPlotChart
                  payment={currencyServices.convertToCurrency(report.managersPaid.total)}
                  initialChartData={report.managersPaid}
                  title='managerPaid'
                />
              </Col>
            </>
          ) : (
            <Col span={24}>
              <EarningPerCarChart carsEarning={report.carsEarning} earningsPerCar={report.earningsPerCar} />
            </Col>
          )}
        </>
      );
    } else return <StyledLoader size='large' />;
  }, [report, isLoading]);

  return (
    <StyledRow justify='space-between' gutter={[16, 16]}>
      <Col span={24}>
        <Header>
          <Title>
            {t('hi') as string}, {isAdmin ? `${user?.firstName} ${user?.lastName}` : `${user?.user?.firstName} ${user?.user?.lastName}`}
          </Title>
          <Space>
            <Select value={type} onChange={setType}>
              <Select.Option value={DatePickerTypes.WEEK}>{t('week').toString()}</Select.Option>
              <Select.Option value={DatePickerTypes.MONTH}>{t('month').toString()}</Select.Option>
              <Select.Option value={DatePickerTypes.QUARTER}>{t('quarter').toString()}</Select.Option>
              <Select.Option value={DatePickerTypes.YEAR}>{t('year').toString()}</Select.Option>
            </Select>
            <DatePicker
              picker={type}
              value={selectedDate}
              onChange={date => setSelectedDate(date || selectedDate)}
              {...(type === DatePickerTypes.QUARTER ? { format: t('format') } : {})}
            />
          </Space>
        </Header>
      </Col>
      {contentRenderer()}
    </StyledRow>
  );
};

export default Dashboard;
