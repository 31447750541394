import { Table } from 'antd';

import styled from 'styled-components';

export const TableTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #070707;
`;

export const StyledTable = styled(Table)`
  // Highligh 1st row by default
  .ant-table-tbody > .ant-table-row:nth-child(2) {
    background: #f5f5f5;
  }
`;
