import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { config } from 'config';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IGetAdvanceListRequest, IGetAdvanceListResponse } from 'modules/reimbursements/types';

export const getAdvanceList = createAsyncThunk(
  THUNKS.ADVANCE_LIST,
  async ({ pageSize = config.DEFAULT_PAGE_SIZE, ...restParams }: IGetAdvanceListRequest, thunkApi) => {
    try {
      const res = await api.private.get<{ data: IGetAdvanceListResponse }>(Endpoints.ADVANCE_LIST, {
        params: {
          pageSize,
          ...restParams,
        },
      });

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
