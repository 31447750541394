import { Col } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SuccessIcon = styled(CheckCircleOutlined)`
  font-size: 100px;
  color: ${({ theme }) => theme.green};
`;

export const StyledColumn = styled(Col)`
  text-align: center;
`;

export const TextContainer = styled.span`
  font-weight: 400;
  font-size: 14px;
`;
