import { FC, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Roles } from 'constants/global';
import ManagerReimbursements from 'modules/reimbursements/components/ManagerReimbursements';
import { getDriverSalary, getManagerSalary } from 'modules/salaries';
import { RadioButton } from 'modules/salaries/enums/RadioButton';
import { useSearchParams } from 'utils/searchParams';

import DetailsHeader from '../DetailsHeader';
import SalaryInfo from '../SalaryInfo';
import { Details, SalaryWrapper } from './styled';

const SalaryDetails: FC = () => {
  const { getSearchParam } = useSearchParams();
  const dispatch = useAppDispatch();
  const selectedTabSearchParam = getSearchParam('selectedTab') as RadioButton | undefined;
  const selectedTab: RadioButton = selectedTabSearchParam ?? RadioButton.SALARY;
  const { user } = useAppSelector(state => state.auth);
  const isAdmin = useAppSelector(state => state.auth.isAdmin);

  const userId = useMemo(() => getSearchParam('id')!, []);
  const userRole = useMemo(() => getSearchParam('role') || Roles.MANAGER, []);

  // TODO would be better to move it to useEffect
  const getSalaryByRole = (dateRange: [Date, Date]) => {
    // If manager panel - return his salary
    if (!isAdmin) {
      dispatch(getManagerSalary({ date: dateRange, id: user!.user.id.toString() }));
      return;
    }

    if (userRole === Roles.DRIVER) {
      dispatch(getDriverSalary({ date: dateRange, id: userId }));
    } else {
      dispatch(getManagerSalary({ date: dateRange, id: userId }));
    }
  };

  const contentByTabName = {
    [RadioButton.SALARY]: <SalaryInfo getSalaryByRole={getSalaryByRole} />,
    [RadioButton.REIMBURSEMENTS]: <ManagerReimbursements />,
  };

  return (
    <Details>
      <DetailsHeader selectedTab={selectedTab} getSalaryByRole={getSalaryByRole} />
      <SalaryWrapper>{contentByTabName[selectedTab]}</SalaryWrapper>
    </Details>
  );
};

export default SalaryDetails;
