import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { Roles, Statuses } from 'constants/global';
import { t } from 'i18next';
import { IDriver } from 'modules/driver/types';
import { IManager } from 'modules/manager/types';
import { THUNKS } from 'modules/user/enums';
import { IChangeUserStatus } from 'modules/user/types';

// Change user status
export const changeUserStatus = createAsyncThunk(THUNKS.CHANGE_USER_STATUS, async ({ data, user, isAdmin }: IChangeUserStatus, thunkApi) => {
  try {
    // Todo: check if 'isAdmin' needed here
    if (isAdmin) {
      await api.private.put<{ data: IManager | IDriver }>(`${Endpoints.ADMIN_USER}?id=${user.id}`, data).then(res => res.data.data);
    } else {
      await api.private.put<{ data: IDriver }>(`${Endpoints.DRIVER}?id=${user.id}`, data).then(res => res.data.data);
    }

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${user.role === Roles.MANAGER ? t<string>('global.notifications.success.manager') : t<string>('global.notifications.success.driver')} ${
        user.name
      } ${data.status === Statuses.DISABLED ? t<string>('global.notifications.success.disabled') : t<string>('global.notifications.success.activated')}`,
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
