import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Form } from 'antd';

import { ReportTypes } from 'constants/global';
import { cleanUploadRequestReport, uploadReports } from 'modules/analytics/slice';

import ReportStep from './ReportStep';
import { BoltFieldsWithCount, BoltTables } from './ReportStep/reportPresets/bolt';
import { SlingFieldsWihCount, SlingTables } from './ReportStep/reportPresets/sling';
import { TaximeterFieldsWithCount, TaximeterTables } from './ReportStep/reportPresets/taximeter';
import { UberFieldsWithCount, UberTables } from './ReportStep/reportPresets/uber';
import { Footer, StyledModal, StyledSteps } from './styled';
import { IFiles } from './types';
import UploadStep from './UploadStep';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const fieldsWithCont = [SlingFieldsWihCount, TaximeterFieldsWithCount, UberFieldsWithCount, BoltFieldsWithCount];
const fieldsWithTables = [SlingTables, TaximeterTables, UberTables, BoltTables];

const fieldsHandler = (files: IFiles) => [
  {
    name: 'sling',
    value: files.sling,
  },
  {
    name: 'taximeter',
    value: files.taximeter,
  },
  {
    name: 'bolt',
    value: files.bolt,
  },
  {
    name: 'uber',
    value: files.uber,
  },
];

const filesDefaultState = { [ReportTypes.SLING]: [], [ReportTypes.TAXIMETER]: [], [ReportTypes.UBER]: [], [ReportTypes.BOLT]: []};

const UploadReportsModal: FC<IProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'global.layout.uploadReportsModal' });
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.analytics);
  const report = useAppSelector(state => state.analytics.uploadRequestReport);
  const [files, setFiles] = useState<IFiles>(filesDefaultState);
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    dispatch(cleanUploadRequestReport());
  }, []);

  const resetHandler = () => {
    setIsOpen(false);
    setStep(0);
    setFiles(filesDefaultState);
    dispatch(cleanUploadRequestReport());
  };

  const submitHandler = async () => {
    await form.validateFields();

    const reportDataOptions = {
      0: { files: files.sling, type: ReportTypes.SLING },
      1: { files: files.taximeter, type: ReportTypes.TAXIMETER },
      2: { files: files.uber, type: ReportTypes.UBER },
      3: { files: files.bolt, type: ReportTypes.BOLT },
    };

    // TODO: remove 'as'
    const reportData = reportDataOptions[step as keyof typeof reportDataOptions] || reportDataOptions[3];
    await dispatch(uploadReports(reportData));
    const { error } = store.getState().cars;

    if (!error && step > 3) {
      resetHandler();
    }
  };

  const onNextButtonClick = () => {
    dispatch(cleanUploadRequestReport());
    setStep(prev => prev + 1);
  };

  const onBackClick = () => {
    dispatch(cleanUploadRequestReport());
    setStep(prev => prev - 1);
  };

  const footer = useMemo(() => {
    const currentStepFilesCount = Object.values(files)[step].length;

    return (
      <Footer>
        {step > 0 && (
          <Button loading={isLoading} onClick={onBackClick}>
            {t('back')}
          </Button>
        )}
        {report ? (
          <Button type='primary' loading={isLoading} onClick={step === 3 ? resetHandler : onNextButtonClick}>
            {step === 3 ? t('ok') : t('next')}
          </Button>
        ) : (
          <Button
            type='primary'
            loading={isLoading}
            onClick={() => (currentStepFilesCount ? submitHandler() : step === 3 ? resetHandler() : setStep(prev => prev + 1))}
          >
            {currentStepFilesCount ? t('submit') : t('skip')}
          </Button>
        )}
      </Footer>
    );
  }, [step, files.bolt, files.sling, files.taximeter, files.uber, isLoading]);

  return (
    <StyledModal width='fit-content' title={t('title')} open={isOpen} destroyOnClose footer={footer} onCancel={resetHandler}>
      <StyledSteps progressDot current={step}>
        <StyledSteps.Step title={t('sling')} />
        <StyledSteps.Step title={t('taximeter')} />
        <StyledSteps.Step title={t('uber')} />
        <StyledSteps.Step title={t('bolt')} />
      </StyledSteps>

      {report ? (
        <ReportStep fieldsWithTables={fieldsWithTables[step]} report={report} fieldsWithCount={fieldsWithCont[step]} />
      ) : (
        <Form form={form} fields={fieldsHandler(files)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
          {step === 0 && (
            <UploadStep
              name='sling'
              accept='.csv'
              files={files}
              setFiles={setFiles}
              t={t}
              fileList={files.sling}
              onChange={val => setFiles(prev => ({ ...prev, sling: val.fileList }))}
              maxCount={1}
            />
          )}
          {step === 1 && (
            <UploadStep
              name='taximeter'
              accept='.xls'
              files={files}
              setFiles={setFiles}
              t={t}
              fileList={files.taximeter}
              onChange={val => setFiles(prev => ({ ...prev, taximeter: val.fileList }))}
            />
          )}
          {step === 2 && (
            <UploadStep
              name='uber'
              accept='.csv'
              files={files}
              setFiles={setFiles}
              t={t}
              fileList={files.uber}
              onChange={val => setFiles(prev => ({ ...prev, uber: val.fileList }))}
              maxCount={1}
            />
          )}
          {step === 3 && (
            <UploadStep
              name='bolt'
              accept='.csv'
              files={files}
              setFiles={setFiles}
              t={t}
              fileList={files.bolt}
              onChange={val => setFiles(prev => ({ ...prev, bolt: val.fileList }))}
              maxCount={1}
            />
          )}
        </Form>
      )}
    </StyledModal>
  );
};

export default UploadReportsModal;
