import { FC, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table } from 'antd';

import { useManagerWeeklyReportsTable } from 'modules/manager/hooks';
import { IManager } from 'modules/manager/types';
import { getManagerWeeklyReports } from 'modules/user';
import { convertIntoISODate } from 'utils';

import { ContentWrapper } from './styled';

interface IProps {
  dateRange: [Date, Date] | null;
}

const Reports: FC<IProps> = ({ dateRange }) => {
  // TODO: Implement in future
  // const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const dispatch = useAppDispatch();
  const { selectedUser, isLoading } = useAppSelector(state => state.users) as { selectedUser: IManager; isLoading: boolean };
  const [page, setPage] = useState<number>(1);
  const dateFilter = useMemo(() => (dateRange ? { start: convertIntoISODate(dateRange[0]), end: convertIntoISODate(dateRange[1]) } : {}), [dateRange]);

  const weeklyReports = selectedUser?.weeklyReports?.items || [];
  const [data, columns] = useManagerWeeklyReportsTable({ weeklyReports });

  useEffect(() => {
    dispatch(getManagerWeeklyReports({ id: selectedUser!.user.id, ...dateFilter }));
  }, [dateFilter]);

  const changePageHandler = async (p: number) => {
    await dispatch(getManagerWeeklyReports({ id: selectedUser!.user.id, page: p - 1, ...dateFilter }));
    setPage(p);
  };

  return (
    <ContentWrapper>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={{ showSizeChanger: false, current: page, onChange: p => changePageHandler(p), total: selectedUser?.weeklyReports?.total.total }}
      />

      {/* // TODO: Implement in future */}
      {/* <Changelog>
        <Title>{t('changelog')}</Title>
        <Label>09.05.22 - 13.05.22</Label>
        <Alert message='Low efficiency' description='Your EFF is Less than 70%.' type='warning' showIcon />
        <Label>18.04.22 - 24.04.22</Label>
        <Alert message='Missed earnings' description='Driver have missed weekly earnings goal by more than 50%.' type='error' showIcon />
        <Label>18.04.22 - 24.04.22</Label>
        <Alert message='Missed earnings' description='Driver have missed weekly earnings goal by more than 50%.' type='error' showIcon />
        <Label>18.04.22 - 24.04.22</Label>
        <Alert message='Missed earnings' description='Driver have missed weekly earnings goal by more than 50%.' type='error' showIcon />
        <Label>18.04.22 - 24.04.22</Label>
        <Alert message='Missed earnings' description='Driver have missed weekly earnings goal by more than 50%.' type='error' showIcon />
      </Changelog> */}
    </ContentWrapper>
  );
};

export default Reports;
