export default {
  plate: 'Registreringsnummer',
  make: 'Bilmärke',
  address: 'Adress',
  insurance: 'Försäkring',
  model: 'Modell',
  year: 'År',
  shiftPlan: 'Skiftschema',
  splitTime: 'Deltid',
  maxMil: 'Max mil',
  driverPercent: 'Chaufförens %',
  date: 'Datum',
  name: 'Namn',
  phone: 'Telefon',
  earnings: 'Inkomster',
  employment: 'Anställning',
  efficiency: 'Effektivitet',
  fulfillment: 'Genomförande',
  car: 'Bil',
  status: 'Status',
  provision: 'Provision chef',
  start: 'Start',
  end: 'Slut',
  schedule: 'Skiftschema',
  shiftType: 'Skifttyp',
  type: 'Typ',
  role: 'Roll',
  email: 'E-post',
  reason: 'Anledning',
  performer: 'Utförare',
  manager: 'Åkerichef',
  carAccess: 'Biltillgång',
  fulfillmentRate: 'Genomförandegrad',
  drivers: 'Chaufförer',
  driver: 'Chaufför',
  cars: 'Bilar',
  shiftsAvailable: 'Skift tillgängligt',
  drivenProvision: 'Provision chaufför',
  action: 'Åtgärd',
  object: 'Objekt',
  period: 'Period',
  roadbirdFee: 'RoadBirds avgift',
  netto: 'Netto',
  efficiencyAdj: 'Effektivitetsjustering',
  payout: 'Utbetalning',
  taxTable: 'Skattetabell',
  provision_word: 'Provision',
  brutto: 'Brutto',
  payouts: 'Utbetalningar',
  netSalary: 'Nettolön',
  finalPayment: 'Slutlig utbetalning',
  employerTax: 'Arbetsgivaravgift',
  adjustments: 'Justeringar',
  driverProvision: 'Chaufförens provision',
  tax: 'Skatt',
  comment: 'Kommentar',
  availableShifts: 'Tillgängliga skift',
  bookedShiftsPercent: 'Bokade skift',
  driverKm: 'Körda km',
  grade: 'Anställningsgrad',
  netEarning: 'Nettointäkt',
  reimbursements: 'Ersättningar',
  fleetManager: 'Åkerichef',
  tips: 'Dricks',
  taxes: 'Skatter',
  good: 'Bra jobbat',
  bad: 'Dåliga resultat',
  shiftsPlan: 'Skiftschema',
  shiftSplitTimes: 'Delat skift',
  goToLink: 'Gå till länk',
  firstPlan: '1 skift per dag',
  secondPlan: '2 skift per dag',
  thirdPlan: '3 skift per dag',
  paid: 'Betald',
  notPaid: 'Inte betald',
  fullDay: '24 timmar',
  halfDay: '12 timmar',
  thirdDay: '8 timmar',
  driverDisableByAdminReasons_1: 'Mer än 10 skift missade',
  driverDisableByAdminReasons_2: 'Avtalsbrott',
  driverDisableByAdminReasons_3: 'Ingen aktivitet under mer än 45 dagar',
  driverDisableByAdminReasons_4: 'Chauffören initierade uppsägning av avtal',
  driverDisableByManagerReasons_1: 'Effektivitet lägre än 70%',
  driverDisableByManagerReasons_2: 'Mer än 10 skift missade',
  driverDisableByManagerReasons_3: 'Ingen aktivitet under mer än 45 dagar',
  fleetManagerDisableReasons_1: 'Avtalsbrott',
  fleetManagerDisableReasons_2: 'Inga bilar har lagts till under mer än 45 dagar',
  fleetManagerDisableReasons_3: 'Åkerichef initierade uppsägning av avtal',
  driverRequestDeclineReasons_1: 'Avsaknad av taxilicens',
  driverRequestDeclineReasons_2: 'Anmärkning i belastningsregister eller upprepade tjänstefel',
  driverRequestDeclineReasons_3: 'Låg kreditvärdighet',
  fleetManagerRequestDeclineReasons_1: 'Avsaknad av taxilicens',
  fleetManagerRequestDeclineReasons_2: 'Företagsinformationen är ogiltig',
  carRequestDeclineReasons_1: 'Avsaknad av vissa dokument',
  carRequestDeclineReasons_2: 'Reparationsbehov',
  fleetManagerActivateReasons_1: 'Kontraktsförnyelse',
  fleetManagerActivateReasons_2: 'Väntar på granskning',
  driverActivateReasons_1: 'Kontraktsförnyelse',
  driverActivateReasons_2: 'Väntar på granskning',
  carDisableReasons_1: 'Bilolycka',
  carDisableReasons_2: 'Reparationsbehov',
  carActivateReasons_1: 'Skador åtgärdade',
  carActivateReasons_2: 'Väntar på granskning',
  swishStatus: 'Swish-status',
  created: 'Skapad',
  debited: 'Debiterad',
  error: 'Fel',
  initiated: 'Påbörjad',
  planned: 'Planerad',
  submitted: 'Inlämnad',
  reimbursement_short: 'Ersättn.',
  final: 'Slutgiltig',
  declined: 'Nekad',
  amount: 'Belopp',
  photo: 'Foto',
  vat: 'Moms',
  processedDate: 'Bearbetningsdatum',
  dispute: 'Tvist',
  reimbursementsVat: 'Moms för ersättningar',
  hours: 'timmar',
  shiftsPerDay: 'skift per dag',
  shiftPerDay: 'skift per dag',
  limit: 'Maxgräns',
  advancedAmount: 'Förskottsbetalning',
  availableBalance: 'Tillgängligt saldo',
  advanceUsed: 'Använt förskott',
  debtLeftover: 'Kvarvarande skuld',
  previousMonthDebt: 'Föregående månads skuld',
  advanceLeftover: 'Kvarvarande förskott',

  requested: 'Förfrågan',
  swishStatuses: {
    created: 'Skapad',
    debited: 'Debiterad',
    error: 'Fel',
    initiated: 'Påbörjad',
    planned: 'Planerad',
    submitted: 'Inlämnad',
    paid: 'Betald',
  },
  reimbursementTypes: {
    'car-washing': 'Biltvätt',
    refueling: 'Drivmedel',
    transportation: 'Transport',
    fines: 'Böter',
    other: 'Övrigt',
    expenses: 'Omkostnader',
  },
  managerStatuses: {
    notPaid: 'Väntande',
    paid: 'Accepterad',
    declined: 'Nekad',
  },
  advancePaymentStatuses: {
    pending: 'Behandlas',
    error: 'Fel',
    paid: 'Betald',
    debited: 'Debiterad',
  },
};
