import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IGetReimbursementPhotoRequest } from 'modules/reimbursements/types';

export const getReimbursementPhoto = createAsyncThunk(
  THUNKS.GET_REIMBURSEMENT_PHOTO,
  async ({ reimbursementId, photoName }: IGetReimbursementPhotoRequest, thunkApi) => {
    try {
      const data = await api.private
        .get<Blob>(`${Endpoints.GET_REIMBURSEMENT_PHOTO}`, {
          params: { reimbursementId },
          responseType: 'blob',
          headers: {
            'Content-Disposition': `attachment; filename="${photoName}"`,
            'Content-Type': 'image/jpg',
          },
        })
        .then(res => URL.createObjectURL(res.data));

      return {
        id: reimbursementId,
        photo: data,
      };
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
