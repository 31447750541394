import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Row, Space, Table } from 'antd';

import { IUploadRequestReport } from 'modules/reports/types';

import { getColumns } from './columns';
import { StyledCircleIcon, StyledRow, StyledTableHeader, SuccessMessage, SuccessTitle } from './styled';
import { IFieldWithCount, ITableField } from './types';

// TODO: rework this?

type IProps = {
  report: IUploadRequestReport;
  fieldsWithCount: IFieldWithCount[];
  fieldsWithTables: ITableField[];
};

const ReportStep: FC<IProps> = ({ fieldsWithTables, report, fieldsWithCount }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'global.layout.reportStep' });

  return (
    <StyledRow gutter={[0, 8]}>
      <Col span={24}>
        <Space size={10}>
          <StyledCircleIcon />
          <SuccessTitle>{t('success')}</SuccessTitle>
        </Space>
      </Col>
      <Col span={24}>
        <SuccessMessage>{t('successMessage')}</SuccessMessage>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      {fieldsWithCount.map(field => (
        <Col key={field.key} span={24}>
          <Row>
            <Col span={8}>{t(field.titleLocale)}</Col>
            <Col>{report[field.key as keyof IUploadRequestReport]?.toString()}</Col>
          </Row>
        </Col>
      ))}
      {fieldsWithTables.map(field => {
        const columns = getColumns(field.columnsPreset);
        const tableData = report[field.key as keyof IUploadRequestReport];

        if (typeof tableData === 'number' || !tableData?.items?.length) return undefined;
        // TODO here is no id field to use it as key
        const dataSource = tableData.items.map((item, index) => ({ ...item, key: index }));

        return (
          <Col key={field.key} span={24}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <StyledTableHeader>{t(field.titleLocale)}</StyledTableHeader>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    pageSize: 10,
                    total: dataSource.length,
                    showSizeChanger: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
        );
      })}
    </StyledRow>
  );
};

export default ReportStep;
