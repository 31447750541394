import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { editCarRequest } from '../thunks';

export const editCarRequestReducer: ModuleExtraReducer<IRequestsState> = builder => {
  // Edit car request data

  builder
    .addCase(editCarRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedRequest = action.payload;
    })
    .addCase(editCarRequest.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(editCarRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
