import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/requests/enums';

export const downloadAgreement = createAsyncThunk(THUNKS.DOWNLOAD_AGREEMENT, async ({ employeeAgreementId }: { employeeAgreementId: number }, thunkApi) => {
  try {
    await api.private.get<Blob>(`${Endpoints.AGREEMENT_DOWNLOAD}?employeeAgreementId=${employeeAgreementId}`, { responseType: 'blob' }).then(res => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `agreement.${employeeAgreementId}.pdf`);
      link.click();
      link.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    });

    return;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
