import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer, ModuleExtraReducerBuilder } from 'types/Module';

import { addCarReducer } from './addCar';
import { calculateDriverEarningReducer } from './calculateDriverEarning';
import { changeCarStatusReducer } from './changeCarStatus';
import { clearSelectedCarReducer } from './clearSelectedCar';
import { editCarReducer } from './editCar';
import { getCarByIdReducer } from './getCarById';
import { getCarManagerNamesReducer } from './getCarManagerNames';
import { getCarsReducer } from './getCars';
import { getCarsByRadiusReducer } from './getCarsByRadius';
import { getCarShiftsByIdReducer } from './getCarShiftsById';
import { getCarWeeklyReportsReducer } from './getCarWeeklyReports';
import { getOptionsReducer } from './getOptions';
import { searchActiveCarsReducer } from './searchActiveCars';

export const carsReducers = { ...clearSelectedCarReducer };

const extraReducers: ModuleExtraReducer<ICarsState>[] = [
  addCarReducer,
  calculateDriverEarningReducer,
  changeCarStatusReducer,
  editCarReducer,
  getCarByIdReducer,
  getCarShiftsByIdReducer,
  getCarWeeklyReportsReducer,
  getCarsReducer,
  getCarsByRadiusReducer,
  getOptionsReducer,
  getCarManagerNamesReducer,
  searchActiveCarsReducer,
];

export const buildCarsReducer: ModuleExtraReducerBuilder<ICarsState> = builder => {
  extraReducers.forEach(reducer => reducer(builder));
};
