import { FC, Key, useEffect, useMemo } from 'react';

import { store, useAppDispatch, useAppSelector } from 'store';

import { notification } from 'antd';

import { config } from 'config';
import { SalaryStatus } from 'constants/global';
import { endOfMonth, startOfMonth } from 'date-fns';
import {
  changeFinalSalaryStatus,
  changeSalaryStatus,
  getDriverLastSalaryDate,
  getMonthlyPayments,
  getSalary,
  getSalaryStatuses,
  getSwishStatuses,
} from 'modules/salaries';
import { PaymentTypeEnum } from 'modules/salaries/enums';
import { closePayoutModal, setSelectedPayoutsIds } from 'modules/salaries/slice';
import { getMonthRangeByDate } from 'utils/date';
import { useSearchParams } from 'utils/searchParams';

import Header from '../../components/Header';
import MonthlyTable from '../../components/MonthlyTable';
import PayoutModal from '../../components/PayoutModal';
import WeeklyTable from '../../components/WeeklyTable';
import { Details, SalaryWrapper } from './styled';

const Salary: FC = () => {
  const dispatch = useAppDispatch();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const selectedMonthQueryParam = getSearchParam('selectedMonth');

  const { driverLastSalaryDate } = useAppSelector(state => state.salaries);

  const relevantPaymentDate = driverLastSalaryDate ? new Date(driverLastSalaryDate) : new Date();
  const selectedMonth = selectedMonthQueryParam ? new Date(selectedMonthQueryParam) : relevantPaymentDate;
  const page = Number(getSearchParam('page')) || 1;
  const pageSize = Number(getSearchParam('pageSize')) || config.DEFAULT_PAGE_SIZE;
  const stringifiedRoleFilter = getSearchParam('roleFilter');
  const stringifiedStatusFilter = getSearchParam('statusFilter');
  const stringifiedSwishStatusFilter = getSearchParam('swishStatusFilter');
  const stringifiedPeriodFilter = getSearchParam('periodFilter');
  const stringifiedNameFilter = getSearchParam('nameFilter');
  const roleFilter: string[] = stringifiedRoleFilter?.length ? JSON.parse(stringifiedRoleFilter) : [];
  const statusFilter: string[] = stringifiedStatusFilter?.length ? JSON.parse(stringifiedStatusFilter) : [];
  const swishStatusFilter: string[] = stringifiedSwishStatusFilter?.length ? JSON.parse(stringifiedSwishStatusFilter) : [];
  const periodFilter: string[] = stringifiedPeriodFilter?.length ? JSON.parse(stringifiedPeriodFilter) : [];
  const nameFilter: string[] = stringifiedNameFilter?.length ? JSON.parse(stringifiedNameFilter) : [];
  const paymentType: PaymentTypeEnum = (getSearchParam('paymentRangeType') || PaymentTypeEnum.Weekly) as PaymentTypeEnum;

  const changeableParams = [
    pageSize,
    page,
    selectedMonthQueryParam,
    stringifiedStatusFilter,
    stringifiedRoleFilter,
    paymentType,
    stringifiedSwishStatusFilter,
    stringifiedPeriodFilter,
    stringifiedNameFilter,
    driverLastSalaryDate,
  ];

  useEffect(() => {
    dispatch(getDriverLastSalaryDate());
    dispatch(getSalaryStatuses());
    dispatch(getSwishStatuses());
  }, []);

  const changePageHandler = (currentPage: number, pageSize: number) => {
    addSearchParams({ page: currentPage.toString(), pageSize: pageSize.toString() });
  };

  const refetchSalary = useMemo(
    () => () =>
      paymentType === PaymentTypeEnum.Weekly
        ? dispatch(
            getSalary({
              page: page - 1,
              pageSize,
              date: getMonthRangeByDate(selectedMonth),
              status: statusFilter,
              payoutStatus: swishStatusFilter,
              role: roleFilter,
              userId: nameFilter,
              period: periodFilter,
            })
          )
        : dispatch(
            getMonthlyPayments({
              page: page - 1,
              pageSize,
              status: statusFilter,
              payoutStatus: swishStatusFilter,
              start: startOfMonth(selectedMonth),
              end: endOfMonth(selectedMonth),
              userId: nameFilter,
            })
          ),
    [changeableParams]
  );

  const closeModal = () => {
    dispatch(closePayoutModal());
    refetchSalary();
  };

  const markAsPaid = async (paymentsIds: Key[]) => {
    const isWeeklyPayment = paymentType === PaymentTypeEnum.Weekly;

    if (isWeeklyPayment) {
      await dispatch(changeSalaryStatus({ salaryIds: paymentsIds, status: SalaryStatus.PAID }));
    } else {
      await dispatch(changeFinalSalaryStatus({ finalPaymentIds: paymentsIds, status: SalaryStatus.PAID }));
    }

    const { error } = store.getState().salaries;

    if (error) {
      notification.error({
        placement: 'topRight',
        message: error,
      });
    }

    refetchSalary();
    dispatch(setSelectedPayoutsIds([]));
  };

  const weeklyTableParams = {
    pageSize,
    page,
    statusFilter,
    roleFilter,
    swishStatusFilter,
    nameFilter,
    periodFilter,
    changePageHandler,
    changeableParams,
    selectedMonth,
    refetchSalary,
    markAsPaid,
  };

  const monthlyTableParams = {
    pageSize,
    page,
    statusFilter,
    swishStatusFilter,
    nameFilter,
    periodFilter,
    changePageHandler,
    changeableParams,
    selectedMonth,
    refetchSalary,
    markAsPaid,
  };

  const tableBySalaryType = {
    [PaymentTypeEnum.Weekly]: <WeeklyTable {...weeklyTableParams} />,
    [PaymentTypeEnum.Monthly]: <MonthlyTable {...monthlyTableParams} />,
  };

  return (
    <>
      <Details>
        <Header selectedMonth={selectedMonth} paymentType={paymentType} markAsPaid={markAsPaid} />
        <SalaryWrapper>{tableBySalaryType[paymentType]}</SalaryWrapper>
      </Details>
      <PayoutModal key={Math.random()} paymentType={paymentType} closeModal={closeModal} />
    </>
  );
};

export default Salary;
