import { config } from 'config';
import { format } from 'date-fns';
import { ISalaryItem } from 'modules/salaries/types';
import { currencyServices } from 'services/currencyServices';

import { IWeeklySalaryTableData } from '../../types/IWeeklySalaryTableData';

export const mapWeeklySalary = (salary: ISalaryItem[]): IWeeklySalaryTableData[] =>
  salary.map(item => ({
    key: item.salaryId,
    endDate: item.week.end,
    dateRange: item.week,
    salaryId: item.salaryId,
    userId: item.userId,
    period: `${format(new Date(item.week.start), config.DATE_FORMAT_DAY_MONTH)} - ${format(new Date(item.week.end), config.DATE_FORMAT_DAY_MONTH)}`,
    name: item.userName,
    role: item.role,
    status: item.status,
    brutto: currencyServices.convertToCurrency(item.brutto),
    provision: currencyServices.convertToCurrency(item.provision),
    payout: currencyServices.convertToCurrency(item.payout),
    errorMessage: item.payoutInfo?.errorMessage,
    swishStatus: item.payoutInfo?.status,
  }));
