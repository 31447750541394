import { FC, Key, useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table, TableProps } from 'antd';

import { config } from 'config';
import { ReimbursementStatusEnum } from 'constants/global';
import { endOfMonth, startOfMonth } from 'date-fns';
import { getReimbursements, getReimbursementTypes, setSelectedReimbursements } from 'modules/reimbursements';
import { PaymentsSearchParamsEnum } from 'modules/reimbursements/enums/Payments';
import { useReimbursementsTable } from 'modules/reimbursements/hooks';
import { IReimbursement, IReimbursementTableData } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSearchParams } from 'utils/searchParams';

const Reimbursements: FC = () => {
  const dispatch = useAppDispatch();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const { isLoading, reimbursements, total } = useAppSelector(state => state.reimbursements);
  const [hoveredId, setHoveredId] = useState<number>();
  const selectedIds = useAppSelector(state => state.reimbursements.selectedReimbursements);

  const selectedDateRange = getSearchParam('dateRange');
  const dateRange: RangeValue<string> = selectedDateRange && JSON.parse(selectedDateRange);
  const selectedMonthSearchParam = getSearchParam(PaymentsSearchParamsEnum.SELECTED_MONTH);
  const selectedMonth = new Date(selectedMonthSearchParam || new Date());
  const page = Number(getSearchParam('page')) || 1;
  const pageSize = Number(getSearchParam('pageSize')) || config.DEFAULT_PAGE_SIZE;
  const managerId = getSearchParam('managerId');
  const driverId = getSearchParam('driverId');
  const status = getSearchParam('status');
  const payoutStatus = getSearchParam('payoutStatus');
  const type = getSearchParam('type');

  const managerFilterValue: number[] = managerId ? JSON.parse(managerId) : [];
  const driverFilterValue: number[] = driverId ? JSON.parse(driverId) : [];
  const statusFilterValue: string[] = status ? JSON.parse(status) : [];
  const payoutStatusFilterValue: string[] = payoutStatus ? JSON.parse(payoutStatus) : [];
  const typeFilterValue: string[] = type ? JSON.parse(type) : [];

  const fetchReimbursements = useCallback(() => {
    dispatch(
      getReimbursements({
        start: startOfMonth(selectedMonth),
        end: endOfMonth(selectedMonth),
        page: page - 1,
        pageSize,
        periodStart: dateRange?.[0] || undefined,
        periodEnd: dateRange?.[1] || undefined,
        managerId: managerFilterValue,
        driverId: driverFilterValue,
        status: statusFilterValue,
        payoutStatus: payoutStatusFilterValue,
        reimbType: typeFilterValue,
      })
    );
  }, [selectedDateRange, selectedMonthSearchParam, page, pageSize, managerId, driverId, status, payoutStatus, type]);

  useEffect(() => {
    dispatch(getReimbursementTypes());
  }, []);

  useEffect(() => {
    fetchReimbursements();
  }, [fetchReimbursements]);

  const [tableData, adminColumns] = useReimbursementsTable({
    reimbursements,
    hoveredId,
    dateRangeFilterValue: dateRange,
    managerFilterValue,
    driverFilterValue,
    statusFilterValue,
    swishStatusFilterValue: payoutStatusFilterValue,
    typeFilterValue,
    fetchReimbursements,
  });

  const onTableChange: TableProps<IReimbursementTableData>['onChange'] = ({ current, pageSize }, filters) => {
    addSearchParams({
      managerId: filters.fleetManager ? JSON.stringify(filters.fleetManager) : '',
      driverId: filters.driver ? JSON.stringify(filters.driver) : '',
      status: filters.status ? JSON.stringify(filters.status) : '',
      payoutStatus: filters.swishStatus ? JSON.stringify(filters.swishStatus) : '',
      type: filters.type ? JSON.stringify(filters.type) : '',
      pageSize: pageSize?.toString() || config.DEFAULT_PAGE_SIZE.toString(),
      page: current?.toString() || '1',
    });
  };

  const onSelect = (_Ids: Key[], selectedRows: IReimbursement[]) => {
    const filteredRows = selectedRows.filter(row => row.status === ReimbursementStatusEnum.PENDING);

    const ids = filteredRows.map(row => row.id);

    if (JSON.stringify(selectedIds) === JSON.stringify(ids)) return dispatch(setSelectedReimbursements([]));

    dispatch(setSelectedReimbursements(ids));
  };

  return (
    <Table
      scroll={{ x: 2000 }}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelect,
        renderCell: (_checked, record, _index, ReactNode) => (record.status === ReimbursementStatusEnum.PENDING ? ReactNode : null),
      }}
      dataSource={tableData}
      loading={isLoading}
      pagination={{ showSizeChanger: true, current: page, pageSize, total }}
      columns={adminColumns}
      onChange={onTableChange}
      onRow={record => ({
        onMouseEnter: () => setHoveredId(record.id),
        onMouseLeave: () => setHoveredId(undefined),
      })}
    />
  );
};

export default Reimbursements;
