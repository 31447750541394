import { config } from 'config';
import { format } from 'date-fns';
import { ICarWeeklyReportTableData } from 'modules/cars/types';
import { ICarWeeklyReport } from 'modules/reports/types';
import { currencyServices } from 'services/currencyServices';

export const mapCarWeeklyReports = (weeklyReports: ICarWeeklyReport[]): ICarWeeklyReportTableData[] =>
  weeklyReports.map(item => ({
    key: Math.random(),
    date: `${format(new Date(item.week.start), config.DATE_FORMAT)} - ${format(new Date(item.week.end), config.DATE_FORMAT)}`,
    earnings: currencyServices.convertToCurrency(item.earning),
    availableShifts: item.availableShifts,
    bookedShiftsPercent: item.bookedShiftsPercent !== undefined ? `${item.bookedShiftsPercent}%` : '-',
    driverKm: item.drivenKm,
    provision: `${item.provision}%`,
  }));
