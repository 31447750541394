import { AddCarSteps } from 'modules/cars/enums';

export default {
  salary: {
    searchPlaceholder: 'Sök namn',
    downloadReport: 'Ladda ner rapport',
    submitSalary: 'Lämna in lön',
    addAdjustments: 'Lägg till justeringar',
  },
  requests: {
    list: {
      drivers: 'Chaufförer',
      managers: 'Chefer',
      cars: 'Bilar',
      search_placeholder: 'Sök',
      history: 'Historik',
      getDetails: 'Få detaljer',
      approve: 'Godkänn',
      decline: 'Neka',
    },
    details: {
      driver: {
        edit: {
          firstName: 'Förnamn',
          firstName_placeholder: 'Ange förnamn',
          lastName: 'Efternamn',
          lastName_placeholder: 'Ange efternamn',
          email: 'E-post',
          email_placeholder: 'Skriv in e-postadress',
          phone: 'Telefon',
          boltAccountEmail: 'Bolt-konto e-post',
          boltAccountPhone: 'Bolt-konto telefonnummer',
          uberAccountEmail: 'Uber-konto e-post',
          uberAccountPhone: 'Uber-konto telefonnummer',
          phone_placeholder: 'Skriv in telefonnummer',
          postalCode: 'Postnummer',
          postalCode_placeholder: 'Ange postnummer',
          postalCodeLengthMessage: 'Krävs 5 tecken',
          city: 'Stad',
          city_placeholder: 'Ange stad',
          street: 'Gata',
          street_placeholder: 'Ange gata',
          securityNumber: 'Personnummer',
          securityNumber_placeholder: 'Ange personnummer',
          taxiIDNumber: 'Nummer för taxiförarlegitimation',
          taxiIDNumber_placeholder: 'Ange nummer för taxiförarlegitimation',
          drivingTerm: 'Hur länge har du kört taxi?',
          drivingTerm_placeholder: '0-1',
          drivingPlatformExp: 'Vilken plattform har du kört för?',
          drivingPlatformExp_placeholder: 'Uber',
          drivingPlatformInterest: 'Vilka plattformar är du intresserad av att köra för?',
          existingDrivingAcc: 'Har du ett befintligt förarkonto?',
          existingDrivingAcc_message: "'Har du ett befintligt förarkonto...' är obligatoriskt",
          samePersonalData: 'Använder du samma personuppgifter för det?',
          clearingNumber: 'Clearingnummer',
          clearingNumber_placeholder: 'Ange clearingnummer',
          bankAccNumber: 'Bankkontonummer',
          bankAccNumber_placeholder: 'Ange bankkontonummer',
          startTime: 'När vill du börja jobba?',
          daysAWeek: 'Hur många dagar i veckan vill du köra?',
          workingTime: 'Arbetstider?',
          workTime: 'Vill du jobba?',
          workDays: 'Vilka dagar i veckan är du intresserad av att köra?',
          workDays_placeholder: 'Måndag',
          ownCar: 'Har du egen bil för att ta dig till arbetsplatsen?',
          sunday: 'Söndag',
          monday: 'Måndag',
          tuesday: 'Tisdag',
          wednesday: 'Onsdag',
          thursday: 'Torsdag',
          friday: 'Fredag',
          saturday: 'Lördag',
          day: 'Dag',
          night: 'Kväll',
          combined: 'Kombinerad',
          fullTime: 'Heltid',
          partTime: 'Deltid',
          yes: 'Ja',
          no: 'Nej',
          both: 'Båda',
          lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
          wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
          custom: 'Anpassad',
          notAllowed: 'Inte tillåtet',
          advanceLimit: 'Gräns ​​för förskottsbetalning',
          sek: 'SEK',
        },
        requestComment: 'Ytterligare information eller frågor',
        sunday: 'Söndag',
        monday: 'Måndag',
        tuesday: 'Tisdag',
        wednesday: 'Onsdag',
        thursday: 'Torsdag',
        friday: 'Fredag',
        saturday: 'Lördag',
        save: 'Spara',
        editData: 'Redigera data',
        cancel: 'Avbryt',
        driverWord: 'Chaufför',
        detailsWord: 'detaljer',
        contactInfo: 'Kontaktinformation',
        username: 'Användarnamn',
        role: 'Roll',
        email: 'E-post',
        phone: 'Telefon',
        postalCode: 'Postnummer',
        city: 'Stad',
        street: 'Gata',
        documents: 'Dokument',
        socialSecurityNumber: 'Personnummer',
        taxiIdNumber: 'Nummer för taxilegitimation',
        taxiDriverLicense: 'Taxiförarlegitimation',
        driverAccount: 'Förarkonto',
        drivingExperience: 'Tidigare erfarenhet',
        drivenFor: 'Kört för',
        interestedIn: 'Intresserad av',
        existingDriverAccount: 'Befintligt förarkonto',
        personalDataUber: 'Personuppgifter Uber',
        personalDataBolt: 'Personuppgifter Bolt',
        bank: 'Bank',
        clearingNumber: 'Clearingnummer',
        bankAccountNumber: 'Bankkontonummer',
        car: 'Bil',
        availability: 'Tillgänglighet',
        yes: 'Ja',
        no: 'Nej',
        interestedInDriving: 'Intresserad av att köra bil',
        workSchedule: 'Arbetsschema',
        startWorking: 'Börja jobba',
        workingDays: 'Arbetsdagar',
        workingTime: 'Arbetstid',
        preference: 'Preferens',
        days: 'Dagar',
        info: 'Ytterligare information eller frågor',
        decline: 'Neka',
        approve: 'Godkänna',
        day: 'Dag',
        night: 'Kväll',
        combined: 'Kombinerad',
        fullTime: 'Heltid',
        partTime: 'Deltid',
        both: 'Båda',
        bolt: 'Bolt',
        uber: 'Uber',
        advanceLimit: 'Gräns ​​för förskottsbetalning',
        agreement: 'Avtal',
        vilotidbokNumber: 'Vilotidsbok, nummer',
        registrationPlate: 'Registreringsskylt',
        carMake: 'Bilmärke',
        carModel: 'Bilmodell',
        createdAt: 'Skapad',
        roadbirdSignedAt: 'Roadbird signerade',
        driverSignedAt: 'Chaufför signerade',
        downloadAgreement: 'Ladda ner avtal',
        sendAgreement: 'Skicka avtal',

        agreementModal: {
          modalTitle: 'Koppla chauffören till den första bilen',
          modalNote: 'För att skapa ett avtal, tilldela chauffören en bil från listan',
          submitTitle: 'Skapa och skicka avtal',
          carSearch_placeholder: 'Sök på registreringsskylt, märke eller modell',
          noActiveCarsAvailable: 'Inga aktiva bilar tillgängliga',
          currentlySelectedCar: 'Denna bil är för närvarande vald',
        },
      },
      manager: {
        edit: {
          firstName: 'Förnamn',
          firstName_placeholder: 'Ange förnamn',
          lastName: 'Efternamn',
          lastName_placeholder: 'Ange efternamn',
          email: 'E-post',
          email_placeholder: 'Skriv in e-postadress',
          phone: 'Telefon',
          phone_placeholder: 'Skriv in telefonnummer',
          postalCode: 'Postnummer',
          postalCode_placeholder: 'Ange postnummer',
          postalCodeLengthMessage: 'Krävs 5 tecken',
          city: 'Stad',
          city_placeholder: 'Ange stad',
          street: 'Gata',
          street_placeholder: 'Ange gata',
          companyNumber: 'Organisationsnummer',
          companyNumber_placeholder: 'Ange organisationsnummer',
          securityNumber: 'Personnummer',
          securityNumber_placeholder: 'Ange personnummer',
          companyName: 'Företagsnamn',
          companyName_placeholder: 'Ange företagsnamn',
          companyAdress: 'Företagsadress om annan än ovan',
          companyAdress_placeholder: 'Ange företagsadress',
          redovisnigscentral: 'Redovisningscentral',
          redovisnigscentral_placeholder: 'Halda',
          companyPostalCode: 'Ange företagets postnummer',
          companyCity: 'Företagets stad',
          companyStreet: 'Företagets gata',
          lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
          wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
          swishNumber: 'Swish-nummer',
          swishNumberPlaceholder: 'Ange Swish-nummer',
        },
        swishNumber: 'Swish-nummer',
        fleetManager: 'Åkerichef',
        save: 'Spara',
        editData: 'Redigera data',
        cancel: 'Avbryt',
        managerWord: 'Åkerichef',
        detailsWord: 'Detaljer',
        contactInfo: 'Kontaktinformation',
        username: 'Användarnamn',
        role: 'Roll',
        email: 'E-post',
        phone: 'Telefon',
        postalCode: 'Postnummer',
        city: 'Stad',
        street: 'Gata',
        documents: 'Handlingar',
        socialSecurityNumber: 'Personnummer',
        taxiIdNumber: 'Nummer för taxiförarlegitimation',
        companyAccount: 'Företagskonto',
        registrationNumber: 'Organisationsnummer',
        companyName: 'Företagsnamn',
        address: 'Adress',
        redovisnigscentral: 'Redovisningscentral',
        decline: 'Neka',
        approve: 'Godkänn',
      },
      car: {
        edit: {
          plate: 'Registreringsnummer',
          make: 'Bilmärke',
          model: 'Modell',
          year: 'Fordonsår',
          insuranceNumber: 'Fyll i bilförsäkringsnumret',
          shiftPlan: 'Skifschema',
          splitTimes: 'Delat skift',
          workingTime: 'Skiftlängd',
          driverPercentage: 'Chaufförens procent från nettointäkter',
          maximumMileage: 'Max körsträcka för specifik bil',
          pickUpLocation: 'Upphämtnings- och avlämningsplats',
          year_placeholder: '2012',
          plate_placeholder: 'Ange registreringsnummer',
          make_placeholder: 'Ange bilmärke',
          model_placeholder: 'Ange bilmodell',
          insuranceNumber_placeholder: 'Ange försäkringsnummer',
          maximumMileage_placeholder: 'Ange maximal körsträcka',
          driverPercentage_placeholder: 'Ange chaufförens procentandel',
          firstPlan: '1 skift per dag',
          secondPlan: '2 skift per dag',
          thirdPlan: '3 skift per dag',
          fullDay: '24 timmar',
          halfDay: '12 timmar',
          thirdDay: '8 timmar',
        },
        carWord: 'Bil',
        save: 'Spara',
        detailsWord: 'detaljer',
        plate: 'Registreringsnummer',
        editData: 'Redigera data',
        cancel: 'Avbryt',
        make: 'Bilmärke',
        model: 'Modell',
        year: 'Fordonsår',
        insurance: 'Försäkringsnummer',
        shiftPlan: 'Skiftschema',
        splitTimes: 'Delat skift',
        percentage: 'Chaufförens procent av nettointäkter',
        maxMill: 'Max körsträcka',
        address: 'Bilens nuvarande adress',
        approve: 'Godkänn',
        decline: 'Neka',
        firstPlan: '1 skift per dag',
        secondPlan: '2 skift per dag',
        thirdPlan: '3 skift per dag',
        fullDay: '24 timmar',
        halfDay: '12 timmar',
        thirdDay: '8 timmar',
      },
    },
    declineModal: {
      decline: 'Neka',
      choseReason: 'Välj anledning',
      choseReason_placeholder: 'Betalningsanmärkning',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
      note_part1: 'Vi skickar e-post till',
      note_part2: 'med information om denna åtgärd',
      driverRequestDeclineReasons_1: 'Avsaknad av taxiförarlicens',
      driverRequestDeclineReasons_2: 'Anmärkning i belastningsregister eller upprepade tjänstefel',
      driverRequestDeclineReasons_3: 'Låg kreditvärdighet',
      fleetManagerRequestDeclineReasons_1: 'Avsaknad av taxiförarlicens',
      fleetManagerRequestDeclineReasons_2: 'Företagsinformationen är ogiltig',
      carRequestDeclineReasons_1: 'Avsaknad av vissa dokument',
      carRequestDeclineReasons_2: 'Reparationsbehov',
    },
    approveModal: {
      approve: 'Godkänn',
      note_part1: 'Vänligen välj åkerichef som',
      note_part2: 'skulle vilja jobba med',
      map: 'Karta',
      list: 'Lista',
      search_placeholder: 'Sök',
      manager: 'Åkerichef',
      driver: 'Chaufför',
      email: 'E-post',
      phone: 'Telefon',
      postalCode: 'Postnummer',
      city: 'Stad',
      street: 'Gata',
    },
  },
  users: {
    list: {
      all: 'Allt',
      pending: 'Behandlas',
      active: 'Aktiva',
      disabled: 'Inaktiverade',
      search_placeholder: 'Sök',
      getDetails: 'Få detaljer',
      linkWithACar: 'Koppla till en bil',
      disable: 'Inaktivera',
      activate: 'Aktivera',
      createNotification: 'Skapa avisering',
    },
    details: {
      success: 'Slutförd',
      vat: 'Moms',
      deductableVat: 'Avdragsgill moms',
      estimatedVat: 'Beräknad moms',
      vatTooltipText: 'Uppskattad data. Baserat på åkerichefs moms%',
      dispute: 'Tvist',
      decline: 'Neka',
      error: 'Fel',
      pay: 'Betala',
      monthly: 'Månadsvis',
      weekly: 'Veckovis',
      selectedItems: 'Valda {{count}} objekt',
      payWithSwish: 'Betala med Swish',
      submitSalary: 'Lämna in lön',
      save: 'Spara',
      cancel: 'Avbryt',
      searchByName: 'Sök på namn',
      searchPlaceholder: 'Sök på registreringsskylt',
      weeklyReports: 'Veckorapporter',
      contactInfo: 'Kontaktinformation',
      cars: 'Bilar',
      finalPayment: 'Slutlig månadsbetalning',
      addAdjustments: 'Lägg till justeringar',
      submitTheSalary: 'Lämna in lönen',
      shifts: 'Skift',
      linkWithCars: 'Koppla till bilar',
      createNotification: 'Skapa avisering',
      disable: 'Inaktivera',
      edit: 'Redigera info',
      markAsPaid: 'Markera som betald',
      getDetails: 'Få detaljer',
      chooseRoadbirdFee: 'Välj RoadBird-avgift',
      chooseTaxTable: 'Välj skattetabell',
      changelog: 'Aviseringar har skickats',
      salary: 'Lön',
      activate: 'Aktivera',
      earnings: 'Intäkter',
      header: {
        salary: 'Lön',
        revenue: 'Omsättning',
        reimbursements: 'Ersättningar',
        report: 'Rapportera',
      },
      detailsModal: { title: 'Detaljer' },
      errorsModal: {
        okay: 'Okay',
      },
      payoutModal: {
        weeklyPayment: 'Veckobetalning',
        monthlyPayment: 'Månadsbetalning',
        items: 'Objekt',
        total: 'Total',
        submit: 'Skicka betalning',
        successText: 'Alla betalningar genomfördes framgångsrikt',
        errorText: 'Vissa betalningar misslyckades.',
        okay: 'Okej',
        success: 'Slutförd',
      },
      addAdjustmentsModal: {
        platform: 'Plattform',
        platform_placeholder: 'Uber',
        car: 'Bil',
        car_placeholder: 'Tesla 3',
        addAdjustments: 'Lägg till justeringar',
        note: 'Ange all ytterligare information om lön. Det hjälper dig att kontrollera dina utbetalningar.',
        place: 'Välj typ',
        place_placeholder: 'Inkomster',
        period: 'Välj period',
        period_placeholder: '28.05 - 05.06',
        amount: 'Belopp',
        amount_placeholder: 'Ange belopp',
        comment: 'Kommentar',
        comment_placeholder: 'Ange kommentar',
        submit: 'Skicka in',
        sek: 'SEK',
        earningAdjustment: 'Intäktsjustering',
        provisionAdjustment: 'Provisionsjustering',
        finalAdjustment: 'Slutlig justering',
        finalAdjustmentTitle: 'Lägg till justering till slutlönen',
        finalNote: 'Ange all ytterligare information om justering. Det hjälper dig att kontrollera dina utbetalningar.',
        finalAdjustmentPlace: 'Typ av justering',
        isZeroAmountValidationMessage: 'Beloppet ska inte vara lika med 0',
      },

      addAdvance: 'Lägg till förskott',

      addAdvanceModal: {
        addAdvance: 'Lägg till förskott',
        submit: 'Skicka in',
        amount: 'Belopp',
        amount_placeholder: 'Ange belopp',
      },

      feeModal: {
        title: 'Välj RoadBird-avgift',
        amount: 'Välj summa',
        submit: 'Skicka in',
        amount_placeholder: 'Ange belopp',
        note: 'Ange all ytterligare information om lön. Det hjälper dig att kontrollera dina utbetalningar direkt.',
      },
      taxTableModal: {
        title: 'Välj skattetabell',
        type: 'Välj typ',
        submit: 'Skicka in',
        type_placeholder: 'Ange typ',
        note: 'Ange all ytterligare information om lön. Det hjälper dig att kontrollera dina utbetalningar direkt.',
      },
      driver: {
        uberDriverUuid: 'Uber-förarnummer',
        boltDriverPhone: 'Bolt-förares telefonnummer',
        username: 'Användarnamn',
        role: 'Roll',
        email: 'E-post',
        phone: 'Telefon',
        postalCode: 'Postnummer',
        city: 'Stad',
        street: 'Gata',
        socialSecurityNumber: 'Personnummer',
        taxiIdNumber: 'Nummer för taxiförarlegitimation',
        fleetManagersLinked: 'Kopplade åkerichefer',
        carsLinked: 'Antal kopplade bilar',
        platformUsedToDrive: 'Plattform som används för att köra',
        clearingNumber: 'Clearingnummer',
        noManagers: 'Inga åkerichefer',
        bankAccountNumber: 'Bankkontonummer',
        advanceLimit: 'Gräns ​​för förskottsbetalning',
        edit: {
          uberDriverUuid: 'Uber-förarnummer',
          boltDriverPhone: 'Bolt-förares telefonnummer',
          firstName: 'Förnamn',
          firstName_placeholder: 'Ange förnamn',
          lastName: 'Efternamn',
          lastName_placeholder: 'Ange efternamn',
          email: 'E-post',
          email_placeholder: 'Skriv in e-mail',
          phone: 'Telefon',
          boltAccountEmail: 'Bolt-konto e-post',
          boltAccountPhone: 'Bolt-konto telefonnummer',
          uberAccountEmail: 'Uber-konto e-post',
          uberAccountPhone: 'Uber-konto telefonnummer',
          phone_placeholder: 'Skriv in telefonnummer',
          postalCode: 'Postnummer',
          postalCode_placeholder: 'Ange postnummer',
          postalCodeLengthMessage: 'Krävs 5 tecken',
          city: 'Stad',
          city_placeholder: 'Ange stad',
          street: 'Gata',
          street_placeholder: 'Ange gata',
          securityNumber: 'Personnummer',
          securityNumber_placeholder: 'Ange personnummer',
          clearingNumber: 'Clearingnummer',
          clearingNumber_placeholder: 'Ange clearingnummer',
          bankAccNumber: 'Bankkontonummer',
          bankAccNumber_placeholder: 'Ange bankkontonummer',
          taxiIDNumber: 'Nummer för taxiförarlegitimation',
          taxiIDNumber_placeholder: 'Ange nummer för taxileg.',
          drivingPlatformExp: 'Vilken plattform har du kört för?',
          yes: 'Ja',
          no: 'Nej',
          lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
          wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
          custom: 'Anpassad',
          notAllowed: 'Inte tillåtet',
          advanceLimit: 'Gräns ​​för förskottsbetalning',
          sek: 'SEK',
        },
        roadbirdSignedAt: 'Roadbird signerade',
        vilotidbokNumber: 'Vilotidsbok, nummer',
        driverSignedAt: 'Chaufför signerade',
      },
      manager: {
        username: 'Användarnamn',
        role: 'Roll',
        email: 'E-post',
        phone: 'Telefon',
        postalCode: 'Postnummer',
        city: 'Stad',
        street: 'Gata',
        socialSecurityNumber: 'Personnummer',
        taxiIdNumber: 'Nummer för taxiförarlegitimation',
        companyRegNumber: 'Organisationsnummer',
        companyName: 'Företagsnamn',
        accountingCenter: 'Redovisningscentral',
        companyAddress: 'Företagsadress',
        carsNumber: 'Antal tillagda bilar',
        driversNumber: 'Antal kopplade chaufförer',
        swishNumber: 'Swish-nummer',
        roles: {
          driver: 'Chaufför',
          manager: 'Åkerichef',
        },
        edit: {
          swishNumber: 'Swish-nummer',
          swishNumberPlaceholder: 'Ange Swish-nummer',
          firstName: 'Förnamn',
          firstName_placeholder: 'Ange förnamn',
          lastName: 'Efternamn',
          lastName_placeholder: 'Ange efternamn',
          email: 'E-post',
          email_placeholder: 'Skriv in e-post',
          phone: 'Telefon',
          boltAccountEmail: 'Bolt-konto e-post',
          boltAccountPhone: 'Bolt-konto telefonnummer',
          uberAccountEmail: 'Uber-konto e-post',
          uberAccountPhone: 'Uber-konto telefonnummer',
          phone_placeholder: 'Skriv in telefonnummer',
          postalCode: 'Postnummer',
          postalCode_placeholder: 'Ange postnummer',
          postalCodeLengthMessage: 'Krävs 5 tecken',
          city: 'Stad',
          city_placeholder: 'Ange stad',
          street: 'Gata',
          street_placeholder: 'Ange gata',
          companyNumber: 'Organisationsnummer',
          companyNumber_placeholder: 'Ange organisationsnummer',
          companyName: 'Företagsnamn',
          companyName_placeholder: 'Ange företagsnamn',
          companyPostalCode: 'Ange företagets postnummer',
          companyCity: 'Företagets stad',
          companyStreet: 'Företagets gatuadress',
          redovisnigscentral: 'Redovisningscentral',
          redovisnigscentral_placeholder: 'Halda',
          securityNumber: 'Personnummer',
          securityNumber_placeholder: 'Ange personnummer',
          lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
          wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
        },
      },
      downloadAgreement: 'Ladda ner avtal',
      noActions: 'Inga tillgängliga åtgärder',
    },
    disableUserModal: {
      disable: 'Inaktivera',
      disableUser: 'Inaktivera användare',
      note: 'Vi kommer att skicka e-post till chauffören med information om denna åtgärd.',
      chooseReason: 'Välj anledning',
      chooseReason_placeholder: 'Välj inaktiveringsorsak',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
      driverDisableByAdminReasons_1: 'Mer än 10 skift missade',
      driverDisableByAdminReasons_2: 'Avtalsbrott',
      driverDisableByAdminReasons_3: 'Ingen aktivitet under mer än 45 dagar',
      driverDisableByAdminReasons_4: 'Chauffören initierade uppsägning av avtal',
      driverDisableByManagerReasons_1: 'Effektivitet lägre än 70%',
      driverDisableByManagerReasons_2: 'Mer än 10 skift missade',
      driverDisableByManagerReasons_3: 'Ingen aktivitet under mer än 45 dagar',
      fleetManagerDisableReasons_1: 'Avtalsbrott',
      fleetManagerDisableReasons_2: 'Inga bilar har lagts till under mer än 45 dagar',
      fleetManagerDisableReasons_3: 'Åkerichef initierade uppsägning av avtal',
    },
    activateUserModal: {
      activate: 'Aktivera',
      activateUser: 'Aktivera användare',
      note: 'Vi kommer att skicka e-post till chauffören med information om denna åtgärd.',
      chooseReason: 'Välj anledning',
      chooseReason_placeholder: 'Välj aktiveringsorsak',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
      fleetManagerActivateReasons_1: 'Kontraktsförnyelse',
      fleetManagerActivateReasons_2: 'Väntar på granskning',
      driverActivateReasons_1: 'Kontraktsförnyelse',
      driverActivateReasons_2: 'Väntar på granskning',
    },
    createNotificationModal: {
      createNotification: 'Skapa avisering till',
      create: 'Skapa',
      note: 'Vi kommer att skicka e-post till chauffören med information om denna åtgärd',
      typeOfAlert: 'Typ av varning',
      typeOfAlert_placeholder: 'Fel',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
    },
  },
  cars: {
    activateCarModal: {
      activate: 'Aktivera',
      activateCar: 'Aktivera bilen',
      note: 'Vi skickar ett e-postmeddelande till åkerichefen med information om denna åtgärd.',
      chooseReason: 'Välj anledning',
      chooseReason_placeholder: 'Välj aktiveringsorsak',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
      carActivateReasons_1: 'Skador åtgärdade',
      carActivateReasons_2: 'Väntar på granskning',
    },
    calculator: {
      shift: 'skift',
      title: 'Beräknad bilinkomst per vecka',
      note: 'Detta är endast uppskattade intäkter baserat på vår statistik, faktiska resultat kan variera relaterat till chaufförens prestation.',
      shiftSetting: 'Skift-inställning',
      driverProvicion: 'Chaufförens provision från nettointäkter %',
      sek: 'SEK',
      recomendShifts: 'Vi rekommenderar att du använder 2-skiftsschema för att optimera dina intäkter.',
      confirm: 'Bekräfta',
      recomendPercent: 'Du betalar en genomsnittlig marknadslön till dina chaufförer.',
      lowDriverPercent: 'Lönen du vill betala är för låg.',
      highDriverPercent: 'Lönen du vill betala är väldigt hög, vilket gör att du kanske inte får någon vinst.',
    },
    details: {
      save: 'Spara',
      cancel: 'Avbryt',
      plate: 'Registreringsnummer',
      make: 'Bilmärke',
      model: 'Modell',
      year: 'Fordonsår',
      insuranceAggrement: 'Bilförsäkringsavtal',
      shiftPlan: 'Skiftschema',
      shiftSplitTimes: 'Delat skift',
      driverPercent: 'Förarprocent från nettointäkter',
      maxMill: 'Max körsträcka för bil',
      location: 'Hämtning/lämning plats',
      regCertificate: 'Registreringsbevis',
      carInsurance: 'Bilförsäkring',
      car: 'Bil',
      drivers: 'Chaufförer',
      searchPlaceholder: 'Sök på registreringsnummer',
      weeklyReports: 'Veckorapporter',
      contactInfo: 'Kontaktinformation',
      shifts: 'Skift',
      disable: 'Inaktivera',
      createNotification: 'Skapa avisering',
      edit: 'Redigera info',
      activate: 'Aktivera',
      firstPlan: '1 skift per dag',
      secondPlan: '2 skift per dag',
      thirdPlan: '3 skift per dag',
      fullDay: '24 timmar',
      halfDay: '12 timmar',
      thirdDay: '8 timmar',
    },
    steps: {
      backButtonText: 'Tillbaka till adminpanelen',
      title: 'Fyll i formuläret',
      [AddCarSteps.FIRST]: {
        title: 'Bilinformation',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        chooseFleetManager: 'Välj åkerichef',
        chooseFleetManager_placeholder: 'Stefan Andersson',
        plate: 'Registreringsnummer',
        plate_placeholder: 'Ange registreringsnummer',
        make: 'Bilmärke',
        make_placeholder: 'Ange bilmärke',
        model: 'Bilmodell',
        model_placeholder: 'Ange bilmodell',
        year: 'Fordonsår',
        year_placeholder: '2012',
      },
      [AddCarSteps.SECOND]: {
        title: 'Dokument',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        upload: 'Ladda upp',
        registrationNumber: 'Fyll i registreringsnumret',
        registrationNumber_placeholder: 'Ange registreringsnummer',
        registrationCertificate: 'Ladda upp bild på registreringsbevis',
        registrationCertificate_label: 'Lägg till bilder som visar framsidan av registreringsbeviset',
        insuranceNumber: 'Fyll i bilförsäkringsnumret',
        insuranceNumber_placeholder: 'Ange bilförsäkringsnummer',
        insuranceExpire: 'Försäkringens utgångsdatum',
        insuranceAgreement: 'Ladda upp bild eller PDF av bilförsäkringsavtalet',
        insuranceAgreement_label: 'Lägg till bild eller PDF-fil.',
        carPictures: 'Ladda upp 3 bilder på bilen',
        carPictures_label: 'Lägg till bild på bilens framsida och baksida samt insidan av baksätet.',
        frontSide: 'Framsida',
        backSide: 'Baksidan',
        backseat: 'Inuti baksätet',
        modalTitle: 'Mallar för foton',
        modalSubtitle: 'Nedan kan du titta på exempel på bilder som ska laddas upp till vårt system. Oroa dig inte om du inte får till det exakt så.',
        seeTemplates: 'Se mallar',
      },
      [AddCarSteps.THIRD]: {
        title: 'Skift',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        shiftPlan: 'Skiftschema',
        workingTime: 'Starttid',
        splitTimes: 'Delat skift',
        firstPlan: '1 skift per dag',
        secondPlan: '2 skift per dag',
        thirdPlan: '3 skift per dag',
        fullDay: '24 timmar',
        halfDay: '12 timmar',
        thirdDay: '8 timmar',
      },
      [AddCarSteps.FOURTH]: {
        title: 'Ytterligare',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        driverPercentage: 'Förarprocent från nettoinkomst',
        driverPercentage_placeholder: 'Ange förarprocent',
        maximumMileage: 'Max körsträcka för denna bil',
        maximumMileage_placeholder: 'Ange maximal körsträcka',
        pickUpLocation: 'Upphämtnings- och avlämningsplats',
        pickUpLocation_placeholder: 'Ange plats',
      },
    },
    list: {
      all: 'Allt',
      pending: 'Behandlas',
      active: 'Aktiv',
      disabled: 'Inaktiverad',
      search_placeholder: 'Sök efter registreringsnummer',
      addCar: 'Lägg till bil',
      activate: 'Aktivera',
      disable: 'Inaktivera',
    },
    linkCarModal: {
      linkWord: 'Koppla',
      withACar: 'med en bil',
      searchPlaceholder: 'Sök',
      driver: 'Chaufför',
      car: 'Bil',
      startWorking: 'Börja jobba',
      workingTime: 'Arbetstid',
      days: 'Dagar',
      workingDays: 'Arbetsdagar',
      preference: 'Preferens',
      manufacturer: 'Biltillverkare',
      model: 'Modell',
      year: 'Fordonsår',
      startTime: 'Starttid',
      splitTimes: 'Delat skift',
      maxMill: 'Max körsträcka',
      summary: 'Sammanfattning',
      linkedWith: 'Kopplad till',
      notLinkedYet: 'Inte kopplad än',
      chooseTheCar: 'Välj bilen först på kartan eller från listan och koppla sedan till chauffören.',
      'full-time': 'Heltid',
      'part-time': 'Deltid',
      day: 'Dag',
      night: 'Natt',
      combined: 'Kombinerad',
      sunday: 'Söndag',
      monday: 'Måndag',
      tuesday: 'Tisdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lördag',
      hours: 'timmar',
    },
    disableCarModal: {
      disable: 'Inaktivera',
      activate: 'Aktivera',
      activateCar: 'Aktivera bilen',
      disableCar: 'Inaktivera bilen',
      note: 'Vi kommer att skicka e-post till chauffören med information om denna åtgärd.',
      chooseReason: 'Välj anledning',
      chooseReason_placeholder: 'Välj inaktiveringsorsak',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
      carDisableReasons_1: 'Bilolycka',
      carDisableReasons_2: 'Reparationsbehov',
    },
    createNotificationModal: {
      createNotification: 'Skapa avisering till bil',
      create: 'Skapa',
      note: 'Vi kommer att skicka e-post till chauffören med information om denna åtgärd',
      typeOfAlert: 'Typ av varning',
      typeOfAlert_placeholder: 'Fel',
      comment: 'Kommentar',
      comment_placeholder: 'Ange kommentar',
    },
  },
  changelog: {
    all: 'Allt',
    userManagement: 'Användarhantering',
    performance: 'Prestation',
    search_placeholder: 'Sök efter utförare',
  },
  dashboard: {
    value: 'Värde',
    format: `YYYY-'F'Q`,
    revenue: 'Intäktsmix, SEK',
    totalDriven: 'Mätarställning km',
    driversPaid: 'Chaufförer betalda',
    roadBirdRevenue: 'RoadBird-omsättning',
    managerPaid: 'Åkerichefer betalda',
    hi: 'Hej',
    selectDate: 'Välj datumintervall för att skapa rapport',
    perCarTitle: 'Intäkt per bil, SEK',
    listOfCars: 'Lista över bilar',
    noData: 'Ingen data',
    week: 'Vecka',
    month: 'Månad',
    quarter: 'Kvartal',
    year: 'År',
    activeCars: 'Aktiva bilar',
    activeDrivers: 'Aktiva chaufförer',
    activeManagers: 'Aktiva åkerichefer',
  },
  profile: {
    contactInfo: 'Kontaktinformation',
    changePassword: 'Ändra lösenord',
    email: 'E-post',
    phone: 'Telefon',
    email_placeholder: 'Ange e-post',
    phone_placeholder: 'Ange telefon',
    password_placeholder: 'Lösenord',
    currentPassword: 'Nuvarande lösenord',
    newPassword: 'Nytt lösenord',
    save: 'Spara',
    other: 'Annat',
    becomeADriver: 'Bli chaufför',
    ifYouWant: 'om du vill komma igång, klicka här',
    theLink: 'länken.',
  },
};
