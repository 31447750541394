import { TableColumnsType } from 'antd';

import { IManagerWeeklyReportTableData } from 'modules/manager/types';
import { IManagerWeeklyReport } from 'modules/reports/types';

import { generateManagerWeeklyReportsColumns } from './generateManagerWeeklyReportsColumns';
import { mapManagerWeeklyReports } from './mapManagerWeeklyReports';

interface IProps {
  weeklyReports: IManagerWeeklyReport[];
}

type ReturnType = [IManagerWeeklyReportTableData[], TableColumnsType<IManagerWeeklyReportTableData>];

export const useManagerWeeklyReportsTable = (props: IProps): ReturnType => {
  const { weeklyReports } = props;
  const mappedReports = mapManagerWeeklyReports(weeklyReports);
  const tableColumns = generateManagerWeeklyReportsColumns();

  return [mappedReports, tableColumns];
};
