import { ICar, ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { getCarWeeklyReports } from '../thunks';

export const getCarWeeklyReportsReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Get car weekly reports

  builder
    .addCase(getCarWeeklyReports.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCar = { ...(state.selectedCar as ICar), weeklyReports: action.payload };
    })
    .addCase(getCarWeeklyReports.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCarWeeklyReports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
