import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType } from 'antd';

import { RoleTag } from 'modules/common/components';
import { DateWrapper, MoreButton, Time } from 'modules/requests/pages/List/styled';
import { IRequestsListTableData } from 'modules/requests/types';

interface IProps {
  moreDropdown?: (record: IRequestsListTableData) => JSX.Element;
}

export const generateUsersRequestsColumns = (props: IProps): TableColumnsType<IRequestsListTableData> => {
  const { moreDropdown } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: value => <RoleTag role={value} />,
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={moreDropdown!(record)} trigger={['click']} placement='bottom'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
