import { Form } from 'antd';

import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 12px;
  background: #ffffff;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
  margin-bottom: 16px;
`;

export const StyledForm = styled(Form)`
  width: 440px;

  :first-of-type {
    margin-bottom: 40px;
  }
`;

export const LanguageWrapper = styled.div`
  margin-top: 24px;
`;

export const SelectLanguage = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
  margin-bottom: 16px;
`;
