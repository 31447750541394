import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { Entities } from 'constants/global';
import { THUNKS } from 'modules/requests/enums';
import { ICarRequest, IDriverRequest, IGetRequestByIdRequest, IManagerRequest } from 'modules/requests/types';

// Get request by Id
export const getRequestById = createAsyncThunk(THUNKS.GET_REQUEST_BY_ID, async ({ id, entity }: IGetRequestByIdRequest, thunkApi) => {
  try {
    const endpoint = () => {
      switch (entity) {
        case Entities.DRIVER:
          return Endpoints.DRIVER_REQUEST;
        case Entities.MANAGER:
          return Endpoints.MANAGER_REQUEST;
        default:
          return Endpoints.CAR_REQUEST;
      }
    };

    const res = await api.private.get<{ data: IManagerRequest | IDriverRequest | ICarRequest }>(`${endpoint()}/${id}`);
    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
