import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';

export const disputeReimbursements = createAsyncThunk(THUNKS.DISPUTE_REIMBURSEMENT, async (props: { reimbursementId: number; comment: string }, thunkApi) => {
  try {
    const res = await api.private.post<{ data: string }>(`${Endpoints.DISPUTE_REIMBURSEMENT}`, props);

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
