import { IManager } from 'modules/manager/types';
import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getManagerWeeklyReports } from '../thunks';

export const getManagerWeeklyReportsReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get manager weekly reports

  builder
    .addCase(getManagerWeeklyReports.fulfilled, (state, action) => {
      state.isLoading = false;

      // Todo: Rework
      state.selectedUser = { ...(state.selectedUser as IManager), weeklyReports: action.payload };
    })
    .addCase(getManagerWeeklyReports.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getManagerWeeklyReports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
