import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ICalculateDriverEarning } from 'modules/cars/types';

// Calculate driver earcning
export const calculateDriverEarning = createAsyncThunk(
  THUNKS.CALCULATE_DRIVER_EARNING,
  async ({ shiftType, driverPercent }: ICalculateDriverEarning, thunkApi) => {
    try {
      const result = await api.private
        .get<{ data: number }>(`${Endpoints.CALC}?shiftType=${shiftType}&driverPercent=${driverPercent}`)
        .then(({ data }) => data.data);

      return result;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
