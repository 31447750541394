import styled from 'styled-components';

export const Contacts = styled.p`
  position: absolute;
  bottom: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #393939;

  span {
    text-decoration: underline;
  }
`;
