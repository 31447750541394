import { TableColumnsType } from 'antd';

import { IReimbursement } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';

import { generateManagerReimbursementsColumns } from './generateManagerReimbursementsColumns';

interface IProps {
  reimbursements: IReimbursement[];
  hoveredId: number | undefined;
  dateRangeFilterValue: RangeValue<string>;
  driverFilterValue: number[];
  statusFilterValue: string[];
  typeFilterValue: string[];
  fetchReimbursements: () => void;
}

type ReturnType = [IReimbursement[], TableColumnsType<IReimbursement>];

export const useManagerReimbursementsTable = (props: IProps): ReturnType => {
  const { reimbursements, ...restProps } = props;
  const tableData = reimbursements.map(reimbursement => ({ key: reimbursement.id, ...reimbursement }));
  const tableColumns = generateManagerReimbursementsColumns(restProps);

  return [tableData, tableColumns];
};
