import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { linkDriverWithACar } from '../thunks';

export const linkDriverWithACarReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Link driver with a car

  builder
    .addCase(linkDriverWithACar.fulfilled, (state, action) => {
      state.users = state.users.map(user => (user.id !== action.payload.id ? user : action.payload));
      state.isLoading = false;
    })
    .addCase(linkDriverWithACar.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(linkDriverWithACar.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
