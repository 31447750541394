export enum THUNKS {
  GET_USERS = 'users/getUsers',
  GET_DRIVERS_BY_MANAGER = 'users/getDriversByManager',
  LINK_DRIVER_WITH_A_CAR = 'users/linkDriverWithACar',
  UNLINK_CAR_FROM_DRIVER = 'users/unlinkCarFromDriver',
  GET_DRIVER_BY_ID = 'users/getDriverById',
  GET_MANAGER_BY_ID = 'users/getManagerById',
  CHANGE_USER_STATUS = 'users/changeUserStatus',
  EDIT_USER = 'users/editUser',
  GET_DRIVER_SHIFTS_BY_ID = 'users/getDriverShiftsById',
  GET_DRIVER_WEEKLY_REPORTS = 'users/getDriverWeeklyReports',
  GET_MANAGER_WEEKLY_REPORTS = 'users/getManagerWeeklyReports',
}
