import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/auth/enums';

export const forgotPassword = createAsyncThunk(THUNKS.FORGOT_PASSWORD, async (email: string, thunkApi) => {
  try {
    await api.public.post(`${Endpoints.PASSWORD}/${email}`);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.forgotPassword'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
