import { TableColumnsType } from 'antd';

import { IFinalPayment } from 'modules/salaries/types';
import { IDriverSalary } from 'modules/salaries/types/IDriverSalary';
import { IManagerSalary } from 'modules/salaries/types/IManagerSalary';

import { generateFinalSalaryColumns } from './generateFinalSalaryColumns';

interface IFinalSalaryData extends IFinalPayment {
  key: string;
}

interface IProps {
  selectedUserSalary: (IDriverSalary & IManagerSalary) | null;
  moreDropdown: (record: IFinalPayment) => JSX.Element;
}

type ReturnType = [IFinalSalaryData[], TableColumnsType<IFinalPayment>];

export const useFinalSalaryTable = (props: IProps): ReturnType => {
  const { moreDropdown, selectedUserSalary } = props;
  const tableData = selectedUserSalary?.finalPayment ? [{ ...selectedUserSalary?.finalPayment, key: 'finalPayment' }] : [];
  const tableColumns = generateFinalSalaryColumns({ moreDropdown });

  return [tableData, tableColumns];
};
