import { IReimbursementsState } from 'modules/reimbursements/types';
import { ModuleExtraReducer } from 'types/Module';

import { getAdvanceList } from '../thunks';

export const getAdvanceListReducer: ModuleExtraReducer<IReimbursementsState> = builder => {
  builder
    .addCase(getAdvanceList.fulfilled, (state, action) => {
      state.advanceListData = action.payload;
      state.isLoading = false;
    })
    .addCase(getAdvanceList.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getAdvanceList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
