import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IManagerWeeklyReport, IUserWeeklyReports } from 'modules/reports/types';
import { THUNKS } from 'modules/user/enums';
import { IGetWeeklyReports } from 'modules/user/types';

// Get User weekly reports
export const getManagerWeeklyReports = createAsyncThunk(
  THUNKS.GET_MANAGER_WEEKLY_REPORTS,
  async ({ id, page = 0, pageSize = 7, start, end }: IGetWeeklyReports, thunkApi) => {
    try {
      const res = await api.private.get<{ data: IUserWeeklyReports<IManagerWeeklyReport> }>(
        `${Endpoints.REPORT_WEEKLY_MANAGER}?id=${id}&page=${page}&pageSize=${pageSize}${start ? `&start=${start}&end=${end}` : ''}`
      );

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
