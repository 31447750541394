import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { getSalary } from '../thunks';

export const getSalaryReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Get salary

  builder
    .addCase(getSalary.fulfilled, (state, action) => {
      state.salary = action.payload;
      state.monthlyTotal = action.payload.total.totalMonthly;
      state.weeklyTotal = action.payload.total.total;
      state.isLoading = false;
    })
    .addCase(getSalary.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getSalary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
