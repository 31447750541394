import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IChangeFinalSalaryStatus, IUpdateSalaryStatusResult } from 'modules/salaries/types';

// Change final salary status
export const changeFinalSalaryStatus = createAsyncThunk(
  THUNKS.CHANGE_FINAL_SALARY_STATUS,
  async ({ finalPaymentIds, status }: IChangeFinalSalaryStatus, thunkApi) => {
    try {
      const res = await api.private.put<{ data: IUpdateSalaryStatusResult }>(`${Endpoints.SALARY_FINAL_UPDATE_STATUS}`, {
        finalPaymentIds,
        status,
      });

      if (res.data.data.errors.length) {
        return thunkApi.rejectWithValue(res.data.data.errors[0].errors[0]);
      }

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
