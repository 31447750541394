/* eslint-disable react/jsx-boolean-value */
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Col, Form, FormInstance, Input, Radio, Row } from 'antd';

import { Globals } from 'constants/global';
import { getOptions } from 'modules/auth';
import { IDriver } from 'modules/driver/types';

import { AdvanceLimitInput, StyledForm } from './styled';

interface IProps {
  driverData: IDriver;
  setDriverData: Dispatch<SetStateAction<IDriver>>;
  form: FormInstance;
}

const fieldsHandler = (driverData: IDriver) => [
  {
    name: 'firstName',
    value: driverData.user.firstName || null,
  },
  {
    name: 'lastName',
    value: driverData.user.lastName || null,
  },
  {
    name: 'email',
    value: driverData.user.email || null,
  },
  {
    name: 'phone',
    value: driverData.user.phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'postalCode',
    value: driverData.address.postalCode || null,
  },
  {
    name: 'city',
    value: driverData.address.city || null,
  },
  {
    name: 'street',
    value: driverData.address.street || null,
  },

  {
    name: 'securityNumber',
    value: driverData.socialSecurityNumber || null,
  },
  {
    name: 'taxiIDNumber',
    value: driverData.taxiIdNumber || null,
  },

  {
    name: 'clearingNumber',
    value: driverData.bankInfo.clearingNumber || null,
  },
  {
    name: 'bankAccNumber',
    value: driverData.bankInfo.bankAccount || null,
  },
  {
    name: 'drivingPlatformExp',
    value: driverData.platform || null,
  },
  {
    name: 'uberDriverUuid',
    value: driverData.uberDriverUuid || null,
  },
  {
    name: 'boltDriverPhone',
    value: driverData.boltDriverPhone?.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'advanceLimit',
    value: driverData.advanceLimit || null,
  },
];

const Edit: FC<IProps> = ({ driverData, setDriverData, form }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.driver.edit' });
  const dispatch = useAppDispatch();
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IDriver };
  const [isAdvanceLimitAtive, setIsAdvanceLimitActive] = useState(!!driverData.advanceLimit);

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(
    () => () => {
      setDriverData(selectedUser);
    },
    [selectedUser]
  );

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Row justify='space-between' gutter={40}>
        <Col span={8}>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('firstName')} name='firstName' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('firstName_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, user: { ...prev.user, firstName: target.value }}))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('lastName')} name='lastName' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('lastName_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, user: { ...prev.user, lastName: target.value }}))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
            <Input
              placeholder={t('email_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, user: { ...prev.user, email: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('phone')} name='phone' rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}>
            <Input
              type='number'
              placeholder={t('phone_placeholder')}
              prefix={Globals.SWEDEN_COUNTRY_CODE}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, user: { ...prev.user, phone: `${Globals.SWEDEN_COUNTRY_CODE}${target.value}` }}))}
            />
          </Form.Item>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('postalCode')} name='postalCode' rules={[{ required: true, len: 5, message: t('postalCodeLengthMessage') }]}>
                  <Input
                    type='number'
                    placeholder={t('postalCode_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, address: { ...prev.address, postalCode: target.value }}))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('city')} name='city' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('city_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, address: { ...prev.address, city: target.value }}))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('street')} name='street' rules={[{ required: true }]}>
            <Input
              placeholder={t('street_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, address: { ...prev.address, street: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('advanceLimit')} name='advanceLimit' rules={[{ min: 0, type: 'number' }]}>
            <Radio.Group
              optionType='button'
              defaultValue={!!driverData.advanceLimit}
              onChange={({ target }) => {
                if (!target.value) {
                  setDriverData(prev => ({ ...prev, advanceLimit: undefined }));
                }

                setIsAdvanceLimitActive(target.value);
              }}
              options={[
                { label: t('custom'), value: true },
                { label: t('notAllowed'), value: false },
              ]}
            />
            {isAdvanceLimitAtive && (
              <div>
                <AdvanceLimitInput
                  value={driverData.advanceLimit}
                  onChange={event =>
                    setDriverData(prev => ({
                      ...prev,
                      advanceLimit: event.target.value ? Number(event.target.value) : undefined,
                    }))
                  }
                  type='number'
                  suffix='SEK'
                />
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t('clearingNumber')} name='clearingNumber' rules={[{ required: true }]}>
            <Input
              type='number'
              placeholder={t('clearingNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, clearingNumber: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('bankAccNumber')} name='bankAccNumber' rules={[{ required: true }]}>
            <Input
              type='number'
              placeholder={t('bankAccNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, bankAccount: target.value }}))}
            />
          </Form.Item>
          <Form.Item
            label={t('securityNumber')}
            name='securityNumber'
            rules={[
              {
                required: true,
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-/g, '');
                    const { length } = textWithoutDashes;

                    if (length >= 10 && length <= 12) resolve('');
                    else reject(new Error());
                  }),
                message: t('lengthValidationMessage10_12'),
              },
              {
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-|[0-9]/g, '');
                    const { length } = textWithoutDashes;

                    if (length === 0) resolve('');
                    else reject(new Error());
                  }),
                message: t('wrongDigestError'),
              },
            ]}
          >
            <Input
              placeholder={t('securityNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, socialSecurityNumber: target.value }))}
            />
          </Form.Item>
          <Form.Item label={t('taxiIDNumber')} name='taxiIDNumber' rules={[{ required: true, len: 6 }]}>
            <Input
              type='number'
              placeholder={t('taxiIDNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, taxiIdNumber: target.value }))}
            />
          </Form.Item>
          <Form.Item label={t('uberDriverUuid')} name='uberDriverUuid'>
            <Input onChange={({ target }) => setDriverData(prev => ({ ...prev, uberDriverUuid: target.value }))} />
          </Form.Item>
          <Form.Item
            label={t('boltDriverPhone')}
            name='boltDriverPhone'
            rules={[{ max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
          >
            <Input
              type='number'
              prefix={Globals.SWEDEN_COUNTRY_CODE}
              onChange={({ target }) =>
                setDriverData(prev => ({ ...prev, boltDriverPhone: target.value ? `${Globals.SWEDEN_COUNTRY_CODE}${target.value}` : undefined }))
              }
            />
          </Form.Item>
          <Form.Item label={t('drivingPlatformExp')} name='drivingPlatformExp' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, platform: target.value }))}>
              <Radio.Button value='uber'>Uber</Radio.Button>
              <Radio.Button value='bolt'>Bolt</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default Edit;
