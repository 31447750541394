import { Key } from 'react';

import { ISalary } from 'modules/salaries/types';

interface IProps {
  weeklyPayments: ISalary | null;
  selectedPayoutsIds: Key[];
}

export const calculateWeekly = (props: IProps): { total: number; count: number } => {
  const { weeklyPayments, selectedPayoutsIds } = props;
  const selectedSalaries = weeklyPayments?.items?.filter(user => selectedPayoutsIds.some(selectedId => selectedId === user.salaryId));
  const total = selectedSalaries?.reduce((sum, userSalary) => sum + userSalary.payout, 0) || 0;
  const count = selectedSalaries?.length || 0;

  return { total, count };
};
