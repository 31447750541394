import { FC, Key } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Col,Row } from 'antd';

import { currencyServices } from 'services/currencyServices';

type PropsType = { selectedIds: Key[] };

const PayoutInfo: FC<PropsType> = ({ selectedIds }) => {
  const { reimbursements } = useAppSelector(state => state.reimbursements);
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.payModal' });

  const selectedReimbursements = reimbursements?.filter(reimbursement => selectedIds.some(selectedId => selectedId === reimbursement.id));
  const sum = selectedReimbursements?.reduce((sum, reimbursement) => sum + reimbursement.amount, 0);

  return (
    <Row justify='space-between'>
      <Col span={8}>{selectedReimbursements?.length || 0}</Col>
      <Col span={8}>{t('items')}</Col>
      <Col span={8}>{currencyServices.convertToCurrency(sum || 0)}</Col>
    </Row>
  );
};

export default PayoutInfo;
