import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';

import { Steps as STPS } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import i18n from 'locales';
import { getOptions } from 'modules/auth';
import { DriverSteps } from 'modules/auth/enums';
import Container from 'modules/common/components/Container';
import { ICreateDriverData } from 'modules/driver/types';

import { FifthStep, FirstStep, FourthStep, SecondStep, SeventhStep, SixthStep, ThirdStep } from './components';
import { DriverAccountOptionsEnum } from './components/ThirdStep/enums';
import { StyledSteps, Title } from './styled';

const { Step } = STPS;

const DRIVER_INITIAL_DATA: ICreateDriverData = {
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    lang: i18n.language,
  },
  addressInfo: {
    postalCode: '',
    city: '',
    street: '',
  },
  socialSecurityNumber: '',
  taxiIdNumber: '',
  driverInfo: {
    driverExperience: '',
    driverPlatform: [],
    interestedPlatform: '',
  },
  bankInfo: {
    bankAccount: '',
    clearingNumber: '',
  },
  workScheduleInfo: {
    startingDate: null,
    daysPerWeek: '',
    workTime: '',
    workSchedule: '',
    workWeekdays: [],
    employmentGrade: null,
  },
  havingAccount: 'no',
  uberAccountInfo: undefined,
  boltAccountInfo: undefined,
  hasCar: false,
  interestedCarModel: '',
  comment: '',
  driverLicenceExpireDate: null,
  taxiLicenceExpireDate: null,
  requestComment: '',
  documents: [],
};

const DriverRole: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.driverRole' });
  const dispatch = useAppDispatch();
  const [driverData, setDriverData] = useState<ICreateDriverData>(DRIVER_INITIAL_DATA);
  const [driverLicense, setDriverLicense] = useState<UploadFile[]>([]);
  const [taxiLicense, setTaxiLicense] = useState<UploadFile[]>([]);
  const [step, setStep] = useState<number>(0);
  const [selectedDriverAccount, setSelectedDriverAccount] = useState<DriverAccountOptionsEnum>(DriverAccountOptionsEnum.NO);

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  const markup = () => {
    switch (step) {
      case 0:
        return <FirstStep setStep={setStep} driverData={driverData} setDriverData={setDriverData} />;
      case 1:
        return (
          <SecondStep
            setStep={setStep}
            taxiLicense={taxiLicense}
            setTaxiLicense={setTaxiLicense}
            driverLicense={driverLicense}
            setDriverLicense={setDriverLicense}
            driverData={driverData}
            setDriverData={setDriverData}
          />
        );
      case 2:
        return (
          <ThirdStep
            setStep={setStep}
            driverData={driverData}
            setDriverData={setDriverData}
            selectedDriverAccount={selectedDriverAccount}
            setSelectedDriverAccount={setSelectedDriverAccount}
          />
        );
      case 3:
        return <FourthStep setStep={setStep} driverData={driverData} setDriverData={setDriverData} />;
      case 4:
        return <FifthStep setStep={setStep} driverData={driverData} setDriverData={setDriverData} />;
      case 5:
        return <SixthStep setStep={setStep} driverData={driverData} setDriverData={setDriverData} />;
      case 6:
        return <SeventhStep setStep={setStep} driverData={driverData} setDriverData={setDriverData} documents={[...driverLicense, ...taxiLicense]} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <Title>{t('title')}</Title>
      <StyledSteps progressDot current={step}>
        <Step title={t(`steps.${DriverSteps.FIRST}.title`)} />
        <Step title={t(`steps.${DriverSteps.SECOND}.title`)} />
        <Step title={t(`steps.${DriverSteps.THIRD}.title`)} />
        <Step title={t(`steps.${DriverSteps.FOURTH}.title`)} />
        <Step title={t(`steps.${DriverSteps.FIFTH}.title`)} />
        <Step title={t(`steps.${DriverSteps.SIXTH}.title`)} />
        <Step title={t(`steps.${DriverSteps.SEVENTH}.title`)} />
      </StyledSteps>

      {markup()}
    </Container>
  );
};

export default DriverRole;
