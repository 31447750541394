import { useTranslation } from 'react-i18next';

import { Dropdown, TableColumnsType } from 'antd';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import { IManagerTableData } from 'modules/manager/types';
import { DateWrapper, MoreButton, PendingName, Time } from 'modules/user/pages/UsersList/styled';

interface IProps {
  moreDropdown: (user: IManagerTableData) => JSX.Element;
}

export const generateManagerColumns = (props: IProps): TableColumnsType<IManagerTableData> => {
  const { moreDropdown } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) =>
        record.status === Statuses.PENDING ? (
          <PendingName>
            {value} <ClockCircleOutlined />
          </PendingName>
        ) : (
          value
        ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('drivers'),
      dataIndex: 'drivers',
      key: 'drivers',
    },
    {
      title: t('cars'),
      dataIndex: 'cars',
      key: 'cars',
    },
    {
      title: t('shiftsAvailable'),
      dataIndex: 'shiftsAvailable',
      key: 'shiftsAvailable',
    },
    {
      title: t('efficiency'),
      dataIndex: 'efficiency',
      key: 'efficiency',
    },
    {
      title: t('earnings'),
      dataIndex: 'earnings',
      key: 'earnings',
    },
    {
      title: t('driverProvision'),
      dataIndex: 'driverProvision',
      key: 'driverProvision',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={moreDropdown(record)} trigger={['click']} placement='bottom'>
          <MoreButton />
        </Dropdown>
      ),
    },
  ];
};
