import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store';

import { Table } from 'antd';

import { IDriver } from 'modules/driver/types';
import { useUserCarTable } from 'modules/user/hooks/useUserCarTable';

interface IProps {
  search: string;
}

// TODO: Can be reused. The same as Manager > Details > Car

const Cars: FC<IProps> = ({ search }) => {
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IDriver };
  const navigate = useNavigate();

  const { cars } = selectedUser;
  const [data, columns] = useUserCarTable({ cars, search });

  return (
    <Table
      dataSource={data}
      columns={columns}
      onRow={record => ({
        onClick: () => navigate(`/cars/${record.plate}?id=${record.id}`),
      })}
    />
  );
};

export default Cars;
