import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledCircleIcon = styled(CheckCircleOutlined)`
  margin-bottom: 32px;

  svg {
    height: 105px;
    width: 105px;
    fill: ${({ theme }) => theme.green};
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #070707;
  margin-bottom: 8px;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #393939;
  width: 662px;

  @media (max-width: 980px) {
    width: auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  button:first-child {
    margin-right: 16px;
  }
`;

export const LanguageContainer = styled.div`
  margin-top: 16px;
  margin-right: 16px;
  justify-content: flex-end;
  display: flex;
 `;

export const Locale = styled.img`
  display: inline-block;
  cursor: pointer;
  width: 30px;

  :hover {
    opacity: 0.85;
  }

  :first-of-type {
    margin-right: 8px;
  }
`;
