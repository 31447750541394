import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { IManagerRequest } from 'modules/requests/types';

// Edit manager request
export const editManagerRequest = createAsyncThunk(THUNKS.EDIT_MANAGER_REQUEST, async (data: IManagerRequest, thunkApi) => {
  try {
    const res = await api.private
      .put<{ data: IManagerRequest }>(`${Endpoints.MANAGER_REQUEST}?id=${data.userInfo.id}`, data)
      .then(response => response.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.editedManagerRequest'),
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
