import { DriverSteps, ManagerSteps } from 'modules/auth/enums';

export default {
  welcome: {
    title: 'Välkommen till RoadBird',
    subTitle: 'Börja köra med oss',
    button: 'Låt oss sätta igång',
  },
  roleSelect: {
    title: 'Välj din roll',
    subTitle: 'Berätta för oss vad du föredrar. Fyll i ansökningsformuläret.',
    driver: {
      title: 'Chaufför',
      subTitle: 'Jag har ingen bil och jag vill bli taxichaufför',
    },
    manager: {
      title: 'Åkerichef',
      subTitle: 'Jag har en eller flera bilar och vill ansluta mitt taxiåkeri',
    },
    button: 'Fyll i ansökningsformuläret',
  },
  driverRole: {
    title: 'Fyll i formuläret',

    steps: {
      [DriverSteps.FIRST]: {
        title: 'Kontaktinformation',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        firstName: 'Förnamn',
        firstName_placeholder: 'Ange förnamn',
        lastName: 'Efternamn',
        lastName_placeholder: 'Ange efternamn',
        email: 'E-post',
        email_placeholder: 'Skriv in e-post',
        phone: 'Telefon',
        phone_placeholder: 'Skriv in telefonnummer',
        postalCode: 'Postnummer',
        postalCode_placeholder: 'Ange postnummer',
        postalCodeLengthMessage: '5 tecken',
        city: 'Stad',
        city_placeholder: 'Ange stad',
        street: 'Gata',
        street_placeholder: 'Ange gata',
        only_alphabetic_characters_allowed: 'Endast alfabetiska tecken tillåtna!',
      },
      [DriverSteps.SECOND]: {
        title: 'Dokument',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        securityNumber: 'Personnummer',
        securityNumber_placeholder: 'Ange personnummer',
        taxiIDNumber: 'Nummer för taxiförarlegitimation',
        taxiIDNumber_placeholder: 'Ange numret på din taxilegitimation',
        uploadTaxi: 'Ladda upp bilder på taxiförarlegitimation',
        uploadDriver: 'Ladda upp bilder av körkort',
        upload_title: 'Klicka eller dra filen till detta område för att ladda upp',
        upload_subTitle: 'Lägg till 2 bilder som visar fram- och baksidan av licensen',
        socialSecurityNumberExpireDate: 'WrongQuestion-remove',
        taxiIdNumberExpireDate: 'Taxilegitimationen giltig t o m',
        expireDate: 'Körkortets sista giltighetsdatum',
        validationMessage: 'Sista giltighetsdatum krävs',
        lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
        wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
        lengthValidationMessage6: 'Måste vara 6 tecken',
        fileError: 'Bör vara 2 filer',
      },
      [DriverSteps.THIRD]: {
        title: 'Förarkonto',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        drivingTerm: 'Hur länge har du kört taxi?',
        drivingTerm_placeholder: '0-1 år',
        drivingPlatformExp: 'Vilken plattform har du kört för?',
        drivingPlatformExp_placeholder: 'Uber',
        drivingPlatformInterest: 'Vilka plattformar är du intresserad av att köra för?',
        existingDrivingAcc: 'Har du ett befintligt förarkonto hos följande plattformar?',
        existingDrivingAcc_message: "'Har du ett befintligt förarkonto...' krävs",
        samePersonalData: 'Använder du samma personuppgifter för ditt konto/dina konton?',
        email: 'E-post',
        email_placeholder: 'Skriv in e-post',
        phone: 'Telefonnummer',
        phone_placeholder: 'Skriv in telefonnummer',
        no: 'Nej',
        yes: 'Ja',
        both: 'Båda',
        useUberData: 'Jag använder annan information för Uber',
        useBoltData: 'Jag använder andra information för Bolt',
        years: 'år',
        year: 'år',
      },
      [DriverSteps.FOURTH]: {
        title: 'Bank',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        clearingNumber: 'Clearingnummer',
        clearingNumber_placeholder: 'Ange clearingnummer',
        bankAccNumber: 'Bankkontonummer',
        bankAccNumber_placeholder: 'Ange bankkontonummer',
      },
      [DriverSteps.FIFTH]: {
        title: 'Arbetsschema',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        startTime: 'När vill du börja jobba?',
        daysAWeek: 'Hur många dagar i veckan vill du köra?',
        workingTime: 'Är du intresserad av arbetstid?',
        workTime: 'Vill du jobba?',
        workDays: 'Vilka dagar i veckan är du intresserad av att köra?',
        workDays_placeholder: 'Måndag',
        allDays: 'Välj alla dagar',
        workSchedule: 'Vill du jobba?',
        day: 'Dag',
        night: 'Kväll',
        combined: 'Kombinerad',
        fullTime: 'Heltid',
        partTime: 'Deltid',
        sunday: 'Söndag',
        monday: 'Måndag',
        tuesday: 'Tisdag',
        wednesday: 'Onsdag',
        thursday: 'Torsdag',
        friday: 'Fredag',
        saturday: 'Lördag',
      },
      [DriverSteps.SIXTH]: {
        title: 'Bil',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        ownCar: 'Har du egen bil för att ta dig till arbetsplatsen?',
        yes: 'Ja',
        no: 'Nej',
      },
      [DriverSteps.SEVENTH]: {
        title: 'Kommentarer',
        backButton: 'Tillbaka',
        info: 'Ytterligare information eller frågor',
        iAcceptThe: 'Jag accepterar',
        privacyPolicy: 'Användarvillkor och integritetspolicy.',
        privacyPolicy_message: 'Vänligen acceptera integritetspolicy',
        submitButton: 'Skicka in',
      },
    },
  },
  managerRole: {
    title: 'Fyll i formuläret',
    steps: {
      [ManagerSteps.FIRST]: {
        title: 'Kontaktinformation',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        firstName: 'Förnamn',
        firstName_placeholder: 'Ange förnamn',
        lastName: 'Efternamn',
        lastName_placeholder: 'Ange efternamn',
        email: 'E-post',
        email_placeholder: 'Skriv in e-post',
        phone: 'Telefon',
        phone_placeholder: 'Skriv in telefonnummer',
        postalCode: 'Postnummer',
        postalCode_placeholder: 'Ange postnummer',
        postalCodeLengthMessage: 'Krävs 5 tecken',
        city: 'Stad',
        city_placeholder: 'Ange stad',
        street: 'Gata',
        street_placeholder: 'Ange gata',
        only_alphabetic_characters_allowed: 'Endast alfabetiska tecken tillåtna!',
      },
      [ManagerSteps.SECOND]: {
        title: 'Företagskonto',
        backButton: 'Tillbaka',
        nextButton: 'Nästa',
        companyNumber: 'Organisationsnummer',
        companyNumber_placeholder: 'Ange organisationsnummer',
        securityNumber: 'Personnummer',
        securityNumber_placeholder: 'Ange personnummer',
        companyName: 'Företagsnamn',
        companyName_placeholder: 'Ange företagsnamn',
        companyAdress: 'Företagsadress om annan än ovan',
        companyAdress_placeholder: 'Ange företagsadress',
        redovisnigscentral: 'Redovisningscentral',
        redovisnigscentral_placeholder: 'Halda',
        agreement: 'Godkännandeavtal för dataåtkomst från Redovisningsscentral',
        agreement_message: 'Vänligen acceptera avtal om dataåtkomst',
        postalCode: 'Postnummer',
        postalCode_placeholder: 'Ange postnummer',
        postalCodeLengthMessage: 'Krävs 5 tecken',
        city: 'Stad',
        city_placeholder: 'Ange stad',
        street: 'Gata',
        street_placeholder: 'Ange gata',
        lengthValidationMessage10_12: 'Längd från 10 till 12 tecken',
        wrongDigestError: 'Personnumret bör endast innehålla siffror och bindestreck',
        swishNumber: 'Swish-nummer',
        swishNumberPlaceholder: 'Ange Swish-nummer',
      },
    },
  },
  finalScreen: {
    title: 'Tack för att du tog dig tid!',
    subTitle:
      'Vårt onboarding-team kommer att kontakta dig inom kort för att bekräfta din information. Vi kommer också att skicka ett e-postmeddelande med mer information.',
    back: 'Tillbaka',
    backToSite: 'Tillbaka till hemsidan',
    addAnotherCar: 'Lägg till ännu en bil',
  },
  createPassword: {
    title: 'Bli Medlem',
    subTitle: 'Registrera ditt åkeri och börja hantera dina bilar!',
    email: 'E-post',
    createPassword: 'Skapa lösenord',
    confirmPassword: 'Bekräfta lösenord',
    password_placeholder: 'Skriv in lösenord',
    loginButton: 'Logga in',
    mismatchError: 'Lösenorden matchar inte!',
  },
};
