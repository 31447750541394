import { Form, Row } from 'antd';

import styled from 'styled-components';

interface ITitleProps {
  noMarginBottom?: boolean;
}

export const Container = styled.div`
  padding: 32px 12px;
  background: #ffffff;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div<ITitleProps>`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
  margin-bottom: 16px;

  ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0px;'}
`;

export const StyledForm = styled(Form)`
  width: 440px;

  :first-of-type {
    margin-bottom: 40px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;

  > div:first-child {
    margin-right: 15%;
  }
`;

export const FieldBlock = styled.div`
  :not(:first-child) {
    margin-top: 16px;
  }
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #9f9f9f;
`;

export const Value = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #070707;
`;

export const BecomeADriverWrapper = styled.div`
  border-top: 1px solid #ebebeb;
  margin-top: 40px;
  padding-top: 40px;
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const LanguageWrapper = styled.div`
  margin-top: 24px;
  margin-bottom:14px
`;

export const SelectLanguage = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
  margin-bottom: 16px;
`;
