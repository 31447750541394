export default {
  contacts: 'Om du har några frågor, vänligen ring till oss',
  selectLanguage: 'Välj språk',
  passwordPattern: 'Måste innehålla minst 8 tecken, 1 versal, 1 siffra',

  youAlready: 'Du har redan ett förarkonto. Du kan',
  signIn: 'Logga in',
  asADriver: 'som chaufför.',

  dateRanges: {
    week: 'Vecka',
    month: 'Månad',
    quarter: 'Kvartal',
    year: 'År',
  },

  layout: {
    supportText: 'Om du har några frågor, vänligen kontakta oss via vår företagsmail.',
    details: 'Detaljer',
    dashboard: 'Översikt',
    requests: 'Förfrågningar',
    drivers: 'Chaufförer',
    managers: 'Åkerichefer',
    salary: 'Lön',
    cars: 'Bilar',
    hi: 'Hej',
    profile: 'Profil',
    signOut: 'Logga ut',
    adminPanel: 'Adminpanel',
    uploadReports: 'Ladda upp rapporter',
    earnings: 'Intäkter',
    changelog: 'Ändringslogg',
    payments: 'Betalningar',
    uploadReportsModal: {
      title: 'Ladda upp rapporter',
      bolt: 'Bolt',
      uber: 'Uber',
      taximeter: 'Taxameter',
      sling: 'Sling',
      csv_laceholder: 'Ladda upp CSV-fil',
      xls_laceholder: 'Ladda upp XLS-fil',
      submit: 'Skicka in',

      success: 'Slutförd',
      ok: 'Ok',
      allYour: 'Alla dina rapporter finns i vårt system nu: taxameter, Sling, Bolt. Du kan kontrollera all statistik.',
      choosePeriod: 'Välj period',
      back: 'Tillbaka',
      next: 'Nästa',
      skip: 'Hoppa över',
      click: 'Klicka eller dra filen till detta område för att ladda upp',
      support: 'Stöd för enkel eller flerfilsuppladdning. Det är strängt förbjudet att ladda upp skadliga eller destruktiva filer.',
    },
    reportStep: {
      deletedSlingRecords: 'Raderade Sling-poster',
      insertedSlingRecords: 'Inlagda Sling-poster',
      deletedSlingShifts: 'Borttagna Sling-skift',
      insertedSlingShifts: 'Inlagda Sling-skift',
      deletedTaximeterRecords: 'Raderade taxameterposter',
      insertedTaximeterRecords: 'Inlagda taxameterposter',
      deletedTaximeterShifts: 'Raderade taxameterskift',
      insertedTaximeterShifts: 'Inlagda taxameterskift',
      deletedShiftReportRecords: 'Raderade skiftrapportposter',
      insertedShiftReportRecords: 'Inlagda skiftrapportposter',
      deletedUberRecords: 'Raderade Uber-poster',
      insertedUberRecords: 'Inlagda Uber-poster',
      deletedUberTrips: 'Raderade Uber-resor',
      insertedUberTrips: 'Inlagda Uber-resor',
      deletedBoltRecords: 'Raderade Bolt-poster',
      insertedBoltRecords: 'Inlagda Bolt-poster',
      deletedBoltTrips: 'Raderade Bolt-resor',
      insertedBoltTrips: 'Inlagda Bolt-resor',
      successMessage: 'Vi hittade inga fel. Nu kan du spara din rapport i vårt system. Tack.',
      driverFullName: 'Chaufförens fullständiga namn',
      driverPhone: 'Telefonnummer',
      orderTime: 'Beställningstid',
      paymentTime: 'Betalningstid',
      travelPrice: 'Resepris',
      bookingFee: 'Bokningsavgift',
      customsDuties: 'Tullavgifter',
      cancellationFee: 'Avbokningsavgift',
      tips: 'Dricks',
      moreThanOneDriverBoltTrips: 'Fler än en chaufför, Bolt-resor',
      noShiftBoltTrips: 'Inget skift, Bolt-resor',
      noCarBoltTrips: 'Ingen bil, Bolt-resor',
      paidByUber: 'Betalat av Uber',
      serviceFee: 'Serviceavgift',
      waitingTime: 'Väntetid',
      nonStopFee: 'Non-stop avgift',
      topTraffic: 'Top traffic',
      airportFee: 'Flygplatsavgift',
      petsFee: 'Avgift för husdjur',
      incentive: 'Incitament',
      noDriverUberTrips: 'Ingen chaufför, Uber-resor',
      moreThanOneDriverUberTrips: 'Mer än en chaufför, Uber-resor',
      noShiftUberTrips: 'Inget skift, Uber-resor',
      carRegistrationPlate: 'Bilens registreringsskylt',
      shiftStartTime: 'Skift starttid',
      shiftEndTime: 'Skift sluttid',
      noCarSlingShifts: 'Sling-skiftet saknar bil',
      noDriverSlingShifts: 'Sling-skiftet saknar chaufför',
      moreThanOneDriverSlingShifts: 'Mer än en chaufför för Sling-skiftet',
      taximeterStartTime: 'Taxameterns starttid',
      taximeterEndTime: 'Taxameterns sluttid',
      drivenKm: 'Kört km',
      driverTaxiIdNumber: 'Taxiförarnummer',
      noCarTaximeterShifts: 'Taxameterskiftet saknar bil',
      noDriverTaximeterShifts: 'Taxameterskiftet saknar chaufför',
      noSlingShiftTaximeterShifts: 'Taxameterskiftet saknar Sling-skift',
      success: 'Genomförd',
    },
  },

  notifications: {
    error: {
      errorWord: 'Fel',
      serverError: 'Något gick fel...',
    },
    success: {
      successWord: 'Det gick bra!',
      adjAdded: 'Justering tillagd',
      taxTableNumberWasSet: 'Skattetabellsnummer fastställdes',
      adjDeleted: 'Justering raderad',
      markAsPaid: 'Markerad som betald',
      contactInfo: 'Din kontaktinformation har ändrats',
      forgotPassword: 'Ett e-postmeddelande med en återställningskod har skickats till din e-post',
      reportsUploaded: 'Rapporter har laddats upp',
      changePassword: 'Ditt lösenord har ändrats',
      approveManagerRequest: 'Åkerichefens begäran har godkänts',
      declineManagerRequest: 'Åkerichefens begäran har nekats',
      approveDriverRequest: 'Förarbegäran har godkänts',
      declineDriverRequest: 'Förarbegäran har nekats',
      approveCarRequest: 'Bilförfrågan har godkänts',
      declineCarRequest: 'Bilförfrågan har nekats',
      editedManagerRequest: 'Åkerichefens förfrågningsinformation har ändrats',
      editedDriverRequest: 'Chaufförens förfrågningsinformation har ändrats',
      editDriver: 'Förarinformationen har ändrats',
      editManager: 'Åkerichefsinformationen har ändrats',
      carEdit: 'information har ändrats',
      linkDriverWithACar: 'var kopplad till en bil',
      unlinkCarFromDriver: 'bilen kopplades bort från en chaufför',
      carWord: 'Bil',
      disabled: 'har avaktiverats',
      activated: 'har aktiverats',
      manager: 'Åkerichef',
      driver: 'Chaufför',
      payedWithSwish: 'Betalad med Swish',
      errorPayedWithSwish: 'Problem med Swish-betalning',
      swishPayment: 'Swish-betalning',
      advanceAdded: 'Förskott har lagts till',
      linkedCar: 'Du har lyckats koppla bilen',
    },
  },
};
