import { FC } from 'react';

import { TableHeader, Wrapper } from 'modules/common/components/TableWrapper/styled';
import AdvancedMonthly from 'modules/reimbursements/components/AdvancedMonthly';
import PaymentsHeader from 'modules/reimbursements/components/PaymentsHeader';
import { PaymentsSearchParamsEnum, PaymentsTabNamesEnum } from 'modules/reimbursements/enums/Payments';
import { useSearchParams } from 'utils/searchParams';

import Advanced from '../../components/Advanced';
import Reimbursements from '../../components/Reimbursements';

const paymentComponents = {
  [PaymentsTabNamesEnum.REIMBURSEMENTS]: Reimbursements,
  [PaymentsTabNamesEnum.ADVANCE]: Advanced,
  [PaymentsTabNamesEnum.ADVANCE_MONTHLY]: AdvancedMonthly,
};

const Payments: FC = () => {
  const { getSearchParam } = useSearchParams();

  const currentTab = (getSearchParam(PaymentsSearchParamsEnum.TAB) as PaymentsTabNamesEnum) || PaymentsTabNamesEnum.REIMBURSEMENTS;

  const Tab = paymentComponents[currentTab];

  return (
    <Wrapper>
      <TableHeader>
        <PaymentsHeader />
      </TableHeader>
      <Tab />
    </Wrapper>
  );
};

export default Payments;
