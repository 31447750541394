import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #070707;
  margin-bottom: 8px;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
  margin-bottom: 40px;
  text-align: center;
`;

export const Cards = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  cursor: pointer;
  width: 280px;
  height: 184px;
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  text-align: center;

  :first-child {
    margin-right: 24px;

    @media (max-width: 980px) {
      margin-bottom: 24px;
    }
  }

  // Icon
  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
  }

  :hover {
    background-color: #000;
    border-color: #000;

    svg {
      fill: #fff;
    }

    p:nth-of-type(1) {
      color: #fff;
    }

    p:nth-of-type(2) {
      color: rgba(255, 255, 255, 0.72);
    }
  }
`;

export const CardTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #070707;
  margin-bottom: 8px;
`;

export const CardSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
`;

export const Locale = styled.img`
  display: inline-block;
  cursor: pointer;
  width: 30px;

  :hover {
    opacity: 0.85;
  }

  :first-of-type {
    margin-right: 8px;
  }
`;
