import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IDriverShifts } from 'modules/driver/types';
import { THUNKS } from 'modules/user/enums';
import { IGetDriverShiftsById } from 'modules/user/types';

// Get Driver shifts by Id
export const getDriverShiftsById = createAsyncThunk(
  THUNKS.GET_DRIVER_SHIFTS_BY_ID,
  async ({ userId, page = 0, pageSize = 7 }: IGetDriverShiftsById, thunkApi) => {
    try {
      const res = await api.private.get<{ data: IDriverShifts }>(`${Endpoints.REPORT_SHIFTS_FOR_DRIVER}?userId=${userId}&page=${page}&pageSize=${pageSize}`);
      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
