import { createSlice } from '@reduxjs/toolkit';

import { IAnalyticsState } from '../types';
import { buildAnalyticsReducers, globalReducers } from './reducers';
import { getChangelog, getReport, uploadReports } from './thunks';

const initialState: IAnalyticsState = {
  uploadRequestReport: null,
  report: null,
  changelog: {
    items: [],
    total: {
      'not.read': 0,
      read: 0,
    },
  },
  isLoading: false,
  error: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: globalReducers,
  extraReducers: builder => buildAnalyticsReducers(builder),
});

export { getReport, uploadReports, getChangelog };

export const { cleanUploadRequestReport } = analyticsSlice.actions;

export default analyticsSlice.reducer;
