import { FC, Key } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Col, Radio, Row } from 'antd';

import DatePicker from 'modules/common/components/DatePicker';
import { PaymentTypeEnum } from 'modules/salaries/enums';
import { openPayoutModal } from 'modules/salaries/slice';
import { useSearchParams } from 'utils/searchParams';

import { HeaderContainer, StyledRow } from './styled';

type PropsType = {
  paymentType: PaymentTypeEnum;
  selectedMonth: Date;
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
};

const Header: FC<PropsType> = ({ paymentType, selectedMonth, markAsPaid }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });

  const {
    isLoading,
    monthlyTotal,
    weeklyTotal,
    payoutModal: { selectedPayoutsIds },
  } = useAppSelector(state => state.salaries);

  const { setSearchParams } = useSearchParams();
  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(openPayoutModal());
  };

  const markPaymentsAsPaid = () => {
    markAsPaid(selectedPayoutsIds);
  };

  const filterByDateRangeHandler = (val: Date | null) => {
    setSearchParams({ selectedMonth: val?.toString() || '', page: '1' });
  };

  const onPaymentRangeChange = (value: PaymentTypeEnum) => {
    setSearchParams({
      paymentRangeType: value,
      selectedMonth: selectedMonth.toString(),
    });
  };

  return (
    <HeaderContainer>
      <StyledRow justify='space-between'>
        <Col>
          <Row gutter={[40, 0]} align='middle'>
            <Col>
              <Radio.Group
                size='large'
                value={paymentType}
                onChange={e => onPaymentRangeChange(e.target.value)}
                options={[
                  { value: PaymentTypeEnum.Weekly, label: `${t('weekly')} ${weeklyTotal}` },
                  { value: PaymentTypeEnum.Monthly, label: `${t('monthly')} ${monthlyTotal}` },
                ]}
                optionType='button'
              />
            </Col>
            <Col>
              <DatePicker.MonthPicker size='large' allowClear={false} onChange={filterByDateRangeHandler} value={selectedMonth} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[12, 0]} align='middle'>
            {selectedPayoutsIds.length > 0 && <Col>{t('selectedItems', { count: selectedPayoutsIds.length })}</Col>}
            <Col>
              <Button loading={isLoading} onClick={openModal} disabled={selectedPayoutsIds.length === 0} type='primary' size='large'>
                {t('pay')}
              </Button>
            </Col>
            <Col>
              <Button onClick={markPaymentsAsPaid} loading={isLoading} disabled={selectedPayoutsIds.length === 0} size='large'>
                {t('markAsPaid')}
              </Button>
            </Col>
          </Row>
        </Col>
      </StyledRow>
    </HeaderContainer>
  );
};

export default Header;
