import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { getCars } from '../thunks';

// TODO: check if refactored properly

export const getCarsReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Get cars

  builder
    .addCase(getCars.fulfilled, (state, action) => {
      const { cars, total } = action.payload;
      state.cars = cars;
      state.total = total;
      state.isLoading = false;
    })
    .addCase(getCars.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCars.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
