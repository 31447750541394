import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch,useAppSelector } from 'store';

import { Table } from 'antd';

import { config } from 'config';
import { useCarShiftsTable } from 'modules/cars/hooks';
import { getCarShiftsById } from 'modules/cars/slice/thunks';

const Shifts: FC = () => {
  const { selectedCar, isLoading } = useAppSelector(state => state.cars);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [SearchParams] = useSearchParams();
  const id = useMemo(() => SearchParams.get('id'), []);

  useEffect(() => {
    if (id) {
      dispatch(getCarShiftsById({ carId: id, page }));
    }
  }, [page]);

  const [data, columns] = useCarShiftsTable({ shifts: selectedCar?.shifts });

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={isLoading}
      pagination={{
        showSizeChanger: false,
        current: page,
        onChange: setPage,
        pageSize: config.DEFAULT_PAGE_SIZE,
        total: selectedCar?.shifts?.total?.total,
      }}
    />
  );
};

export default Shifts;
