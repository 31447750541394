import { Dispatch, FC, SetStateAction } from 'react';

import { FormInstance } from 'antd';

import { IManager } from 'modules/manager/types';

import Edit from './components/Edit';
import Info from './components/Info';

interface IProps {
  isEdit: boolean;
  managerData: IManager;
  setManagerData: Dispatch<SetStateAction<IManager>>;
  form: FormInstance;
}

const Information: FC<IProps> = ({ isEdit, managerData, setManagerData, form }) => {
  if (isEdit) return <Edit managerData={managerData} setManagerData={setManagerData} form={form} />;
  return <Info />;
};

export default Information;
