import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppDispatch } from 'store';

import { Button, Col, Form, Input, Row } from 'antd';

import english from 'assets/english.svg';
import sveden from 'assets/sveden.svg';
import { Locales } from 'constants/global';
import i18n from 'locales';
import { signIn } from 'modules/auth';
import ContactMark from 'modules/common/components/ContactMark';
import Container from 'modules/common/components/Container';

import { Locale } from '../SignUp/RoleSelect/styled';
import { SelectLanguage,StyledButton, StyledForm, SubTitle, Title } from './styled';

const fieldsHandler = (email: string, password: string) => [
  {
    name: 'email',
    value: email || null,
  },
  {
    name: 'password',
    value: password || null,
  },
];

const SignIn: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signIn' });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // TODO: HIDDEN FOR v1.0
  // const [remember, setRemember] = useState<boolean>(false);

  const submitHandler = async () => {
    await form.validateFields();
    await dispatch(signIn({ email, password }));

    // Getting current state from store
    const { error } = store.getState().auth;
    if (!error) navigate('/dashboard');
  };

  return (
    <Container justifyCenter>
      <Title>{t('title')}</Title>
      <SubTitle>{t('subTitle')}</SubTitle>
      <StyledForm
        form={form}
        fields={fieldsHandler(email, password)}
        validateTrigger={['onChange', 'onBlur']}
        layout='vertical'
        requiredMark={false}
        size='large'
      >
        <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
          <Input placeholder={t('email_placeholder')} onChange={({ target }) => setEmail(target.value)} />
        </Form.Item>
        {/* PASSWORD PATTERN : { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/ } */}
        <Form.Item label={t('password')} name='password' rules={[{ required: true }]}>
          <Input.Password placeholder={t('password_placeholder')} onChange={({ target }) => setPassword(target.value)} />
        </Form.Item>
        <Row justify='space-between' align='middle'>
          {/* // TODO: HIDDEN FOR v1.0 */}
          <Col>
            {/* <Checkbox checked={remember} onClick={({ target }) => setRemember((target as HTMLInputElement).checked)}>
              {t('remember')}
            </Checkbox> */}
          </Col>
          <Col>
            <Button size='small' type='link' onClick={() => navigate('/forgot-password')}>
              {t('forgotPass')}
            </Button>
          </Col>
        </Row>
        <StyledButton onClick={submitHandler} htmlType='submit' type='primary'>
          {t('loginButton')}
        </StyledButton>
        <StyledButton onClick={() => navigate('/sign-up')}>{t('signUp')}</StyledButton>
      </StyledForm>
      <SelectLanguage>
        <Locale src={english} alt='en' onClick={() => i18n.changeLanguage(Locales.EN)} />
        <Locale src={sveden} alt='sv' onClick={() => i18n.changeLanguage(Locales.SV)} />
      </SelectLanguage>
      <ContactMark />
    </Container>
  );
};

export default SignIn;
