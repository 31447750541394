import { Key } from 'react';

import { TableColumnsType } from 'antd';

import { ISalaryItem } from 'modules/salaries/types';

import { IWeeklySalaryTableData } from '../../types/IWeeklySalaryTableData';
import { generateWeeklySalaryColumns } from './generateWeeklySalaryColumns';
import { mapWeeklySalary } from './mapWeeklySalary';

interface IProps {
  salary?: ISalaryItem[];
  hoveredSalaryId: number | undefined;
  statusFilter: string[];
  roleFilter: string[];
  swishStatusFilter: string[];
  periodFilter: string[];
  nameFilter: string[];
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
}

type ReturnType = [IWeeklySalaryTableData[], TableColumnsType<IWeeklySalaryTableData>];

export const useWeeklySalaryTable = (props: IProps): ReturnType => {
  const { salary, ...generateColumnsProps } = props;
  const tableData = mapWeeklySalary(salary || []);
  const tableColumns = generateWeeklySalaryColumns(generateColumnsProps);

  return [tableData, tableColumns];
};
