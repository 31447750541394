import { config } from 'config';
import { format } from 'date-fns';
import { ISalaryByWeekTableData } from 'modules/salaries/types';
import { IManagerSalary } from 'modules/salaries/types/IManagerSalary';
import { currencyServices } from 'services/currencyServices';

export const mapManagerSalariesByWeek = (managerSalary: IManagerSalary | null): ISalaryByWeekTableData[] => {
  if (!managerSalary?.salariesByWeek?.length) {
    return [];
  }

  return [
    ...managerSalary.salariesByWeek.map(item => ({
      key: item.id,
      salaryId: item.id,
      dateRange: item.week,
      period: `${format(new Date(item.week.start), config.DATE_FORMAT_DAY_MONTH)} - ${format(new Date(item.week.end), config.DATE_FORMAT_DAY_MONTH)}`,
      status: item.status,
      netEarning: currencyServices.convertToCurrency(item.amount.netEarning),
      provision: currencyServices.convertToCurrency(item.amount.brutto),
      efficiencyAdj: currencyServices.convertToCurrency(item.amount.adjustment),
      employerTax: currencyServices.convertToCurrency(item.amount.employerTax),
      payout: currencyServices.convertToCurrency(item.amount.payout),
      reimbursements: currencyServices.convertToCurrency(item.amount.reimbursements),
      reimbursementsVat: currencyServices.convertToCurrency(item.amount.reimbursementsVat),
    })),
    {
      key: Math.random(),
      netEarning: currencyServices.convertToCurrency(managerSalary.amount.netEarning),
      provision: currencyServices.convertToCurrency(managerSalary.amount.brutto),
      efficiencyAdj: currencyServices.convertToCurrency(managerSalary.amount.adjustment),
      employerTax: currencyServices.convertToCurrency(managerSalary.amount.employerTax),
      payout: currencyServices.convertToCurrency(managerSalary.amount.payout),
      isSummary: true,
      reimbursements: currencyServices.convertToCurrency(managerSalary.amount.reimbursements),
      reimbursementsVat: currencyServices.convertToCurrency(managerSalary.amount.reimbursementsVat),
    },
  ];
};
