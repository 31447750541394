import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Button, Col, Form, Radio } from 'antd';

import { AddCarSteps } from 'modules/cars/enums';
import { IAddCarStepsProps, ICreateCarData } from 'modules/cars/types';
import DatePicker from 'modules/common/components/DatePicker';

import { StyledForm, StyledRow } from '../styled';

const fieldsHandler = (carData: ICreateCarData) => [
  {
    name: 'shiftPlan',
    value: carData.shiftPlan,
  },
  {
    name: 'splitTimes',
    value: carData.shiftSplitTimes,
  },
  {
    name: 'workingTime',
    value: carData.startTime,
  },
];

// TODO should be refactored with backend
const localeByValue = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};

const ThirdStep: FC<IAddCarStepsProps> = ({ setStep, carData, setCarData }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', { keyPrefix: `admin.cars.steps.${AddCarSteps.THIRD}` });

  const {
    options: { shifts, shiftPlans },
  } = useAppSelector(state => state.cars);

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(carData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('shiftPlan')} name='shiftPlan' rules={[{ required: true }]}>
        <Radio.Group size='large' onChange={({ target }) => setCarData(prev => ({ ...prev, shiftPlan: target.value, shiftSplitTimes: target.value }))}>
          {shiftPlans.map(item => (
            <Radio.Button key={item.id} value={item.id}>
              {t(localeByValue[item.value as keyof typeof localeByValue])}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('splitTimes')} name='splitTimes' rules={[{ required: true }]}>
        <Radio.Group size='large' onChange={({ target }) => setCarData(prev => ({ ...prev, shiftPlan: target.value, shiftSplitTimes: target.value }))}>
          {shifts.map(item => (
            <Radio.Button key={item.id} value={item.id}>
              {t(localeByValue[item.value as keyof typeof localeByValue])}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('workingTime')} name='workingTime' rules={[{ required: true }]}>
        <DatePicker.TimePicker onChange={val => setCarData(prev => ({ ...prev, startTime: val }))} style={{ width: 160 }} />
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default ThirdStep;
