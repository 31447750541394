import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Area } from '@ant-design/plots';
import { config } from 'config';
import { useEarningPerCarChartTable } from 'modules/analytics/hooks';
import { ICarEarning, IEarningPerCar } from 'modules/analytics/types';
import { renderDateRange } from 'utils/date';

import ChartCard from '../ChartCard';
import { StyledTable, TableTitle } from './styled';

interface IProps {
  earningsPerCar: IEarningPerCar[];
  carsEarning: ICarEarning;
}

const EarningPerCarChart: FC<IProps> = ({ earningsPerCar, carsEarning }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.dashboard' });

  const chartData =
    carsEarning.paidByTimeRange.length === 1 && carsEarning.paidByTimeRange[0].timeRangeLabel.range
      ? [
          {
            date: renderDateRange({
              timeRange: { day: carsEarning.paidByTimeRange[0].timeRangeLabel.range.start },
              formatOfDate: config.DATE_FORMAT_DAY_MONTH,
            }),
            value: carsEarning.paidByTimeRange[0].value,
          },
          {
            date: renderDateRange({ timeRange: { day: carsEarning.paidByTimeRange[0].timeRangeLabel.range.end }, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
            value: carsEarning.paidByTimeRange[0].value,
          },
        ]
      : carsEarning.paidByTimeRange.map(paymentData => ({
          date: renderDateRange({ timeRange: paymentData.timeRangeLabel, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
          value: paymentData.value,
        }));

  const [tableData, columns] = useEarningPerCarChartTable({ earningsPerCar });

  return (
    <ChartCard title={t('perCarTitle')}>
      <Row justify='space-between' gutter={64}>
        <Col span={16}>
          <Area
            data={chartData}
            xField='date'
            yField='value'
            color='#7B2CBF'
            xAxis={{ range: [0, 1]}}
            height={400}
            meta={{
              value: {
                alias: t('value'),
              },
            }}
          />
        </Col>
        <Col span={8}>
          <TableTitle>{t('listOfCars')}</TableTitle>
          {/* Styled table broke columns generic types */}
          <StyledTable pagination={false} showHeader={false} dataSource={tableData} columns={columns as ColumnsType<object>} scroll={{ y: 391 }} />
        </Col>
      </Row>
    </ChartCard>
  );
};

export default EarningPerCarChart;
