import { TableColumnsType } from 'antd';

import { ICarShifts } from 'modules/cars/types';
import { ICarShiftsTableData } from 'modules/cars/types/ICarShiftsTableData';

import { generateCarShiftsColumns } from './generateCarShiftsColumns';
import { mapCarShifts } from './mapCarShifts';

interface IProps {
  shifts?: ICarShifts;
}

type ReturnType = [ICarShiftsTableData[], TableColumnsType<ICarShiftsTableData>];

export const useCarShiftsTable = (props: IProps): ReturnType => {
  const { shifts } = props;
  const tableData = mapCarShifts(shifts!) || [];
  const tableColumns = generateCarShiftsColumns();

  return [tableData, tableColumns];
};
