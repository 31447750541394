import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Button, Form } from 'antd';

import { Entities } from 'constants/global';
import Loader from 'modules/common/components/Loader';
import { approveCarRequest, clearSelectedRequest, editCarRequest, getRequestById } from 'modules/requests';
import { Content, Details, Footer } from 'modules/requests/pages/Details/styled';
import { ICarRequest } from 'modules/requests/types';

import DeclineModal from '../DeclineModal';
import Edit from './components/Edit';
import Info from './components/Info';

const CarDetails: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.car' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: ICarRequest };
  const [SearchParams] = useSearchParams();
  const id = useMemo(() => SearchParams.get('id'), []);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [carData, setCarData] = useState<ICarRequest>(selectedRequest);

  const approveRequestHandler = async () => {
    await dispatch(approveCarRequest(id));

    // Getting current state from store
    const { error } = store.getState().requests;
    if (!error) navigate('/requests');
  };

  useEffect(() => {
    if (id) dispatch(getRequestById({ id, entity: Entities.CAR })).then(val => setCarData(val.payload as ICarRequest));

    return () => {
      dispatch(clearSelectedRequest());
    };
  }, []);

  const renderHandler = () => {
    if (isEdit) return <Edit carData={carData} setCarData={setCarData} form={form} />;
    return <Info />;
  };

  const editInfoHandler = async () => {
    await form.validateFields();
    await dispatch(editCarRequest(carData));
    setIsEdit(false);
  };

  if (!selectedRequest) return <Loader size='large' />;

  return (
    <>
      <Details>
        <Content>{renderHandler()}</Content>

        <Footer>
          {isEdit ? (
            <>
              <Button onClick={() => setIsEdit(false)}>{t('cancel')}</Button>
              <Button type='primary' onClick={editInfoHandler}>
                {t('save')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsEdit(true)}>{t('editData')}</Button>
              <Button danger onClick={() => setIsDeclineModalOpen(true)}>
                {t('decline')}
              </Button>
              <Button type='primary' onClick={approveRequestHandler}>
                {t('approve')}
              </Button>
            </>
          )}
        </Footer>
      </Details>

      <DeclineModal entity={Entities.CAR} isModalOpen={isDeclineModalOpen} setIsModalOpen={setIsDeclineModalOpen} />
    </>
  );
};

export default CarDetails;
