import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { ICarRequest } from 'modules/requests/types';

// Edit car request
export const editCarRequest = createAsyncThunk(THUNKS.EDIT_CAR_REQUEST, async (car: ICarRequest, thunkApi) => {
  try {
    const res = await api.private.put<{ data: ICarRequest }>(`${Endpoints.CAR_REQUEST}/${car.id}`, car).then(response => response.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${t<string>('global.notifications.success.carWord')} ${car.registrationPlate} ${t<string>('global.notifications.success.carEdit')}`,
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
