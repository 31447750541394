import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IFinalPaymentsResult, IGetMonthlyPayments } from 'modules/salaries/types';
import { convertIntoISODate } from 'utils/index';

// Get monthly payments
export const getMonthlyPayments = createAsyncThunk(
  THUNKS.GET_MONTHLY_PAYMENTS,
  async ({ page, pageSize, userId, status, payoutStatus, start, end }: IGetMonthlyPayments, thunkApi) => {
    try {
      const data = await api.private
        .get<{ data: IFinalPaymentsResult }>(`${Endpoints.SALARY_FINAL_PAYMENTS}?${`start=${convertIntoISODate(start)}&end=${convertIntoISODate(end)}`}`, {
          params: { status, payoutStatus, page, pageSize, userId },
        })
        .then(res => res.data.data);

      return data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
