import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getDriverById } from '../thunks';

export const getDriverByIdReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get driver by ID

  builder
    .addCase(getDriverById.fulfilled, (state, action) => {
      state.selectedUser = action.payload;
      state.isLoading = false;
    })
    .addCase(getDriverById.pending, state => {
      state.selectedUser = null;
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getDriverById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
