import { Button } from 'antd';

import styled from 'styled-components';

export const ImgDiv = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgButton = styled(Button)`
  height: 48px;
`;
