import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Row } from 'antd';

import { StyledColumn, SuccessIcon, TextContainer } from './styled';

type PropsType = {
  closePayoutModal: () => void;
};

const SuccessfulPayout: FC<PropsType> = ({ closePayoutModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });

  return (
    <Row gutter={[0, 12]} justify='center'>
      <StyledColumn span={24}>
        <SuccessIcon />
      </StyledColumn>
      <StyledColumn span={24}>
        <TextContainer>{t('successText')}</TextContainer>
      </StyledColumn>
      <StyledColumn span={24}>
        <Button onClick={closePayoutModal} type='primary'>
          {t('okay')}
        </Button>
      </StyledColumn>
    </Row>
  );
};

export default SuccessfulPayout;
