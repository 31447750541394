import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/cars/enums';
import { ICar } from 'modules/cars/types';

// Edit car data
export const editCar = createAsyncThunk(THUNKS.EDIT_CAR, async (car: ICar, thunkApi) => {
  try {
    const res = await api.private.put<{ data: ICar }>(`${Endpoints.CAR}/${car.info.id}`, car).then(resposnse => resposnse.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: `${t<string>('global.notifications.success.carWord')} ${car?.info.registrationPlate} ${t<string>('global.notifications.success.carEdit')}`,
    });

    return res;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
