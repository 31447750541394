import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-table-wrapper {
    flex: 1;
  }
`;

export const Changelog = styled.div`
  width: 432px;
  max-height: 526px;
  margin-left: 24px;
  overflow-y: auto;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
`;

export const Label = styled.p`
  line-height: 38px;
  font-weight: 400;
  font-size: 14px;
  color: #070707;
`;
