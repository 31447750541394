import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';

import { Form, Input } from 'antd';

import { forgotPassword } from 'modules/auth';
import ContactMark from 'modules/common/components/ContactMark';
import Container from 'modules/common/components/Container';

import { StyledButton,StyledForm, SubTitle, Title } from './styled';

const fieldsHandler = (email: string) => [
  {
    name: 'email',
    value: email || null,
  },
];

const ForgotPassword: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'passwordActions.forgotPassword' });
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');

  const submitHandler = async () => {
    await form.validateFields();
    await dispatch(forgotPassword(email));
  };

  return (
    <Container justifyCenter>
      <Title>{t('title')}</Title>
      <SubTitle>{t('subTitle')}</SubTitle>
      <StyledForm form={form} fields={fieldsHandler(email)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
        <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
          <Input placeholder={t('email_placeholder')} onChange={({ target }) => setEmail(target.value)} />
        </Form.Item>
        <StyledButton onClick={submitHandler} htmlType='submit' type='primary'>
          {t('sendButton')}
        </StyledButton>
      </StyledForm>
      <ContactMark />
    </Container>
  );
};

export default ForgotPassword;
