import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IGetSalaryById } from 'modules/salaries/types';
import { IManagerSalary } from 'modules/salaries/types/IManagerSalary';
import { convertIntoISODate } from 'utils/index';

// Get Manager salary
export const getManagerSalary = createAsyncThunk(THUNKS.GET_MANAGER_SALARY, async ({ date, id }: IGetSalaryById, thunkApi) => {
  try {
    const data = await api.private
      .get<{ data: IManagerSalary }>(
        `${Endpoints.SALARY_MANAGER}?managerId=${id}&start=${convertIntoISODate(date[0] as Date)}&end=${convertIntoISODate(date[1] as Date)}`
      )
      .then(res => res.data.data);

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
