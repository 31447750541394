import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { ICarShiftsTableData } from 'modules/cars/types/ICarShiftsTableData';

export const generateCarShiftsColumns = (): TableColumnsType<ICarShiftsTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('driver'),
      dataIndex: 'driver',
      key: 'driver',
    },
    {
      title: t('start'),
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: t('end'),
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: t('schedule'),
      dataIndex: 'schedule',
      key: 'schedule',
    },
  ];
};
