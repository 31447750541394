import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Modal } from 'antd';

import { Entities } from 'constants/global';
import { searchActiveCars } from 'modules/cars';
import { createAndSendAgreement, getRequestById, setSelectedCarForAgreement } from 'modules/requests/slice/thunks';
import { IDriverRequest } from 'modules/requests/types';

import Autocomplete from './Autocomplete';
import { Note } from './styled';

interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
  requestId: number;
}

export const LinkCarModal: FC<IProps> = ({ isModalOpen, closeModal, requestId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver.agreementModal' });
  const dispatch = useAppDispatch();
  const { isLoading, activeCars } = useAppSelector(state => state.cars);
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IDriverRequest };

  const refresh = async () => {
    await dispatch(getRequestById({ id: String(requestId), entity: Entities.DRIVER }));
  };

  const submitHandler = async () => {
    await dispatch(createAndSendAgreement({ requestId }));
    refresh();
    closeModal();
  };

  const selectHandler = async (carId: number) => {
    await dispatch(setSelectedCarForAgreement({ carId, requestId }));
    refresh();
  };

  const searchCars = async (newSearch: string) => {
    if (newSearch !== '') {
      await dispatch(searchActiveCars({ search: newSearch }));
    }
  };

  const initialInputValue = selectedRequest.agreement?.carId
    ? `${selectedRequest.agreement.carRegistrationPlate}, ${selectedRequest.agreement.carMake}, ${selectedRequest.agreement.carModel}`
    : '';

  return (
    <Modal
      title={t('modalTitle')}
      open={isModalOpen}
      destroyOnClose
      okText={t('submitTitle')}
      onOk={submitHandler}
      onCancel={closeModal}
      okButtonProps={{ loading: isLoading, disabled: !selectedRequest.agreement?.carId }}
    >
      <Note>{t('modalNote')}</Note>
      <Autocomplete
        cars={activeCars}
        searchCars={searchCars}
        onSelect={selectHandler}
        addedCarId={selectedRequest.agreement?.carId}
        initialInputValue={initialInputValue}
      />
    </Modal>
  );
};
