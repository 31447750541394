import { Input, Tag } from 'antd';

import DatePicker from 'modules/common/components/DatePicker';
import styled from 'styled-components';

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  margin-right: 16px;
  margin-left: auto;
`;

export const StyledSearch = styled(Input)`
  width: 320px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
`;

export const StyledTag = styled(Tag)`
  text-transform: capitalize;
`;
