import { Locales } from 'constants/global';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import i18n from 'locales';
import { ITimeRangeLabel } from 'modules/analytics/types';

export const dateLocaleByUserLanguage = {
  [Locales.EN]: enGB,
  [Locales.SV]: sv,
};

export const renderDateRange = ({ timeRange, formatOfDate }: { timeRange: ITimeRangeLabel; formatOfDate: string }): string => {
  if (timeRange.day)
    return format(new Date(timeRange.day), formatOfDate, {
      locale: dateLocaleByUserLanguage[i18n.language as Locales],
    });

  if (timeRange.range)
    return `${format(new Date(timeRange.range.start), formatOfDate, {
      locale: dateLocaleByUserLanguage[i18n.language as Locales],
    })} - ${format(new Date(timeRange.range.end), formatOfDate, {
      locale: dateLocaleByUserLanguage[i18n.language as Locales],
    })}`;

  return '';
};

export const getStartOfWeek = (date: Date) => startOfWeek(date, { weekStartsOn: 1 });
export const getEndOfWeek = (date: Date) => endOfWeek(date, { weekStartsOn: 1 });

export const getMonthRangeByDate = (date: Date): [Date, Date] => [startOfMonth(date), endOfMonth(date)];
