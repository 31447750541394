import { Key } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { TableColumnsType, Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { SalaryStatus, SwishStatusEnum } from 'constants/global';

import PayoutCell from '../../components/PayoutCell';
import { localeByStatus } from '../../locales/localeByStatus';
import { PayoutCellWrapper, StatusMessageIcon } from '../../pages/Salary/styled';
import { IMonthlySalaryTableData } from '../../types/IMonthlySalaryTableData';

interface IProps {
  statusFilter: string[];
  swishStatusFilter: string[];
  nameFilter: string[];
  hoveredSalaryId: number | undefined;
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
}

const swishColorsByStatuses: { [key in SwishStatusEnum]: PresetColorType } = {
  [SwishStatusEnum.CREATED]: 'blue',
  [SwishStatusEnum.DEBITED]: 'blue',
  [SwishStatusEnum.ERROR]: 'red',
  [SwishStatusEnum.INITIATED]: 'blue',
  [SwishStatusEnum.PAID]: 'green',
  [SwishStatusEnum.PLANNED]: 'blue',
};

const salaryStatusColorByValue: { [key: string]: PresetColorType } = {
  [SalaryStatus.NOT_PAID]: 'yellow',
  [SalaryStatus.PAID]: 'green',
  [SalaryStatus.SUBMITTED]: 'blue',
};

export const generateMonthlySalaryColumns = (props: IProps): TableColumnsType<IMonthlySalaryTableData> => {
  const { statusFilter, swishStatusFilter, nameFilter, hoveredSalaryId, markAsPaid } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const { salaryStatuses, swishStatuses, monthlyPayments } = useAppSelector(store => store.salaries);

  const statusFilterOptions: ColumnFilterItem[] | undefined = salaryStatuses?.map(status => ({
    value: status.value,
    text: t(localeByStatus[status.value]),
  }));

  const swishStatusFilterOptions: ColumnFilterItem[] | undefined = swishStatuses?.map(status => ({
    value: status.value,
    text: t(status.value),
  }));

  const userNameFilterOptions: ColumnFilterItem[] | undefined = monthlyPayments?.filters?.users?.map(user => ({
    value: user.id,
    text: `${user.firstName} ${user.lastName}`,
  }));

  return [
    {
      title: t('period'),
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      filterSearch: true,
      filteredValue: nameFilter,
      filters: userNameFilterOptions,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filteredValue: statusFilter,
      filters: statusFilterOptions,
      filterMultiple: false,
      render: (value: keyof typeof localeByStatus) => <Tag color={salaryStatusColorByValue[value]}>{t(localeByStatus[value])}</Tag>,
    },
    {
      title: t('swishStatus'),
      dataIndex: 'swishStatus',
      key: 'swishStatus',
      filteredValue: swishStatusFilter,
      filters: swishStatusFilterOptions,
      render: (value: SwishStatusEnum | undefined, rowData) => {
        if (!value) return '-';

        return rowData.errorMessage ? (
          <Tooltip title={rowData.errorMessage}>
            <Tag color={swishColorsByStatuses[value]}>{t(value)}</Tag>
            <StatusMessageIcon />
          </Tooltip>
        ) : (
          <Tag color={swishColorsByStatuses[value]}>{t(value)}</Tag>
        );
      },
    },
    {
      title: t('tips'),
      dataIndex: 'tips',
      key: 'tips',
    },
    {
      title: t('brutto'),
      dataIndex: 'brutto',
      key: 'brutto',
    },
    {
      title: t('taxes'),
      dataIndex: 'taxes',
      key: 'taxes',
    },
    {
      title: t('payouts'),
      dataIndex: 'payout',
      key: 'payout',
    },
    {
      title: t('reimbursement_short'),
      dataIndex: 'reimbursement',
      key: 'reimbursement',
    },
    {
      title: t('final'),
      dataIndex: 'final',
      key: 'final',
    },
    {
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <PayoutCellWrapper onClick={e => e.stopPropagation()}>
          {record.salaryId === hoveredSalaryId && <PayoutCell markAsPaid={markAsPaid} salaryData={record} />}
        </PayoutCellWrapper>
      ),
    },
  ];
};
