import { IAuthState, IAuthUser } from 'modules/auth/types';
import { setUser } from 'modules/user';
import { ModuleExtraReducer } from 'types/Module';

import { updateLanguage } from '../thunks';

export const updateLanguageReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Update language

  builder
    .addCase(updateLanguage.fulfilled, (state, action) => {
      const updatedProfile = state.isAdmin ? action.payload : { ...state.user, user: action.payload };
      state.user = updatedProfile as IAuthUser;
      setUser(JSON.stringify(updatedProfile));
      state.isLoading = false;
    })
    .addCase(updateLanguage.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(updateLanguage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
