import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row } from 'antd';

import { SuccessIcon } from './styled';

type PropsType = {
  closeModal: () => void;
};

const Success: FC<PropsType> = ({ closeModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.disputeModal' });

  return (
    <Row gutter={[0, 24]} justify='center'>
      <Col>
        <SuccessIcon />
      </Col>
      <Col span={24}>{t('successText')}</Col>
      <Col>
        <Button type='primary' onClick={closeModal}>
          {t('okay')}
        </Button>
      </Col>
    </Row>
  );
};

export default Success;
