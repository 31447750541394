import { FC, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch } from 'store';

import { Button, InputNumber, notification } from 'antd';

import { ReimbursementStatusEnum } from 'constants/global';
import { updateReimbursement } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';
import { currencyServices } from 'services/currencyServices';

type PropsType = {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
};

const AmountCell: FC<PropsType> = ({ reimbursement, fetchReimbursements }) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number>(reimbursement.amount);
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.notifications' });

  const onTextClick = () => {
    setIsEdit(true);
  };

  const onSubmit = async () => {
    setIsEdit(false);

    await dispatch(
      updateReimbursement({
        id: reimbursement.id,
        carId: reimbursement.carId,
        vat: reimbursement.reimbursementVat,
        amount,
      })
    );

    const { error } = store.getState().cars;

    if (!error) {
      notification.success({
        placement: 'topRight',
        message: t('success'),
        description: t('amountUpdate'),
      });
    }

    fetchReimbursements();
  };

  const onNumberInputChange = (value: number | null) => {
    const accurateValue = (value || 0) <= 0 ? 0 : value || 0;
    setAmount(accurateValue);
  };

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return isEdit && reimbursement.status === ReimbursementStatusEnum.PENDING ? (
    <InputNumber onKeyDown={onKeyPress} onBlur={onSubmit} value={amount} onChange={onNumberInputChange} />
  ) : (
    // added event for enter key
    <Button onClick={onTextClick} type='text'>
      {currencyServices.convertToCurrency(reimbursement.amount)}
    </Button>
  );
};

export default AmountCell;
