import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { config } from 'config';
import { format } from 'date-fns';
import { IDriver } from 'modules/driver/types';

import { FieldBlock, InfoBlock, Label, Value } from '../../../../../manager/pages/Details/styled';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.driver' });
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IDriver };

  return (
    <>
      <InfoBlock>
        <FieldBlock>
          <Label>{t('username')}</Label>
          <Value>
            {selectedUser.user.firstName} {selectedUser.user.lastName}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('role')}</Label>
          <Value>Driver</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('email')}</Label>
          <Value>
            <a href={`mailto:${selectedUser.user.email}`}>{selectedUser.user.email}</a>
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('phone')}</Label>
          <Value>{selectedUser.user.phone}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('postalCode')}</Label>
          <Value>{selectedUser.address.postalCode}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('city')}</Label>
          <Value>{selectedUser.address.city}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('street')}</Label>
          <Value>{selectedUser.address.street}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('socialSecurityNumber')}</Label>
          <Value>{selectedUser.socialSecurityNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('taxiIdNumber')}</Label>
          <Value>{selectedUser.taxiIdNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('fleetManagersLinked')}</Label>
          <Value>{selectedUser.managers.map(manager => `${manager.firstName} ${manager.lastName}`).join(', ') || t('noManagers')}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('carsLinked')}</Label>
          <Value>{selectedUser.cars.length}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('platformUsedToDrive')}</Label>
          <Value>{selectedUser.platform}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('advanceLimit')}</Label>
          <Value>{selectedUser.advanceLimit ? `${selectedUser.advanceLimit} ${t('edit.sek')}` : t('edit.notAllowed')}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('clearingNumber')}</Label>
          <Value>{selectedUser.bankInfo.clearingNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('bankAccountNumber')}</Label>
          <Value>{selectedUser.bankInfo.bankAccount}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('uberDriverUuid')}</Label>
          <Value>{selectedUser.uberDriverUuid || '-'}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('boltDriverPhone')}</Label>
          <Value>{selectedUser.boltDriverPhone || '-'}</Value>
        </FieldBlock>
      </InfoBlock>

      <InfoBlock>
        <FieldBlock>
          <Label>{t('roadbirdSignedAt')}</Label>
          <Value>
            {(selectedUser.agreement?.acrobatAgreement?.roadbirdSignedAt &&
              format(new Date(selectedUser.agreement?.acrobatAgreement?.roadbirdSignedAt), config.DATE_FORMAT)) ||
              '-'}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('driverSignedAt')}</Label>
          <Value>
            {(selectedUser.agreement?.acrobatAgreement?.driverSignedAt &&
              format(new Date(selectedUser.agreement?.acrobatAgreement?.driverSignedAt), config.DATE_FORMAT)) ||
              '-'}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('vilotidbokNumber')}</Label>
          <Value>{selectedUser?.agreement?.vilotidbokNumber || '-'}</Value>
        </FieldBlock>
      </InfoBlock>
    </>
  );
};

export default Info;
