import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IUpdateReimbursementResponse } from 'modules/reimbursements/types';

export const updateReimbursement = createAsyncThunk(
  THUNKS.UPDATE_REIMBURSEMENT,
  async (props: { id: number; carId: number; vat: number; amount: number }, thunkApi) => {
    try {
      const res = await api.private.post<{ data: IUpdateReimbursementResponse }>(`${Endpoints.UPDATE_REIMBURSEMENT}`, props);

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
