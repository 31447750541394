import { createSlice } from '@reduxjs/toolkit';
import { Roles } from 'constants/global';
import { getUser } from 'modules/user';

import { IAuthState, IAuthUser } from '../types';
import { buildAuthReducers } from './reducers';

const user: IAuthUser = getUser() ? JSON.parse(getUser() as string) : null;
const isAdmin = user?.role === Roles.ADMIN;

const initialState: IAuthState = {
  user,
  options: {
    platforms: [],
    weekdays: [],
    employmentTypes: [],
    schedules: [],
  },
  isAdmin,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => buildAuthReducers(builder),
});

export default authSlice.reducer;
