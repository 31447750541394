import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SalaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-table-wrapper:first-child {
    margin-bottom: 40px;
  }
`;

export const Details = styled.div`
  background-color: #fff;
  padding: 24px 16px;
  width: 100%;
`;

export const StatusMessageIcon = styled(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.warning};
`;

export const PayoutCellWrapper = styled.div`
  height: 36px;
`;
