import { Key } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { TableColumnsType, Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { config } from 'config';
import { Roles, SalaryStatus, SwishStatusEnum } from 'constants/global';
import { format } from 'date-fns';
import { RoleTag } from 'modules/common/components';
import { renderDateRange } from 'utils/date';

import PayoutCell from '../../components/PayoutCell';
import { localeByStatus } from '../../locales/localeByStatus';
import { PayoutCellWrapper, StatusMessageIcon } from '../../pages/Salary/styled';
import { IWeeklySalaryTableData } from '../../types/IWeeklySalaryTableData';

interface IProps {
  hoveredSalaryId: number | undefined;
  statusFilter: string[];
  roleFilter: string[];
  swishStatusFilter: string[];
  periodFilter: string[];
  nameFilter: string[];
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
}

const swishColorsByStatuses: { [key in SwishStatusEnum]: PresetColorType } = {
  [SwishStatusEnum.CREATED]: 'blue',
  [SwishStatusEnum.DEBITED]: 'blue',
  [SwishStatusEnum.ERROR]: 'red',
  [SwishStatusEnum.INITIATED]: 'blue',
  [SwishStatusEnum.PAID]: 'green',
  [SwishStatusEnum.PLANNED]: 'blue',
};

const salaryStatusColorByValue: { [key: string]: PresetColorType } = {
  [SalaryStatus.NOT_PAID]: 'yellow',
  [SalaryStatus.PAID]: 'green',
  [SalaryStatus.SUBMITTED]: 'blue',
};

export const generateWeeklySalaryColumns = (props: IProps): TableColumnsType<IWeeklySalaryTableData> => {
  const { roleFilter, statusFilter, swishStatusFilter, nameFilter, periodFilter, hoveredSalaryId, markAsPaid } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const { salaryStatuses, swishStatuses, salary } = useAppSelector(store => store.salaries);

  const roleFilterOptions: ColumnFilterItem[] = [
    {
      text: t('driver'),
      value: Roles.DRIVER,
    },
    {
      text: t('manager'),
      value: Roles.MANAGER,
    },
  ];

  const statusFilterOptions: ColumnFilterItem[] | undefined = salaryStatuses?.map(status => ({
    value: status.value,
    text: t(localeByStatus[status.value]),
  }));

  const swishStatusFilterOptions: ColumnFilterItem[] | undefined = swishStatuses?.map(status => ({
    value: status.value,
    text: t(status.value),
  }));

  const periodFilterOptions: ColumnFilterItem[] | undefined = salary?.filters?.periods?.map(period => ({
    value: format(new Date(period.week.start), config.DATE_FORMAT_MONOLITH_YEAR_MONTH_DAY),
    text: renderDateRange({ timeRange: { range: period.week }, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
  }));

  const userNameFilterOptions: ColumnFilterItem[] | undefined = salary?.filters?.users?.map(user => ({
    value: user.id,
    text: `${user.firstName} ${user.lastName}`,
  }));

  return [
    {
      title: t('period'),
      dataIndex: 'period',
      key: 'period',
      filters: periodFilterOptions,
      filteredValue: periodFilter,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      filterSearch: true,
      filteredValue: nameFilter,
      filters: userNameFilterOptions,
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      filteredValue: roleFilter,
      filters: roleFilterOptions,
      filterMultiple: false,
      render: value => <RoleTag role={value} />,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filteredValue: statusFilter,
      filters: statusFilterOptions,
      filterMultiple: false,
      render: (value: keyof typeof localeByStatus) => <Tag color={salaryStatusColorByValue[value]}>{t(localeByStatus[value])}</Tag>,
    },
    {
      title: t('swishStatus'),
      dataIndex: 'swishStatus',
      key: 'swishStatus',
      filteredValue: swishStatusFilter,
      filters: swishStatusFilterOptions,
      render: (value: SwishStatusEnum | undefined, rowData) => {
        if (!value) return '-';

        return rowData.errorMessage ? (
          <Tooltip title={rowData.errorMessage}>
            <Tag color={swishColorsByStatuses[value]}>{t(value)}</Tag>
            <StatusMessageIcon />
          </Tooltip>
        ) : (
          <Tag color={swishColorsByStatuses[value]}>{t(value)}</Tag>
        );
      },
    },
    {
      title: t('brutto'),
      dataIndex: 'brutto',
      key: 'brutto',
    },
    {
      title: t('provision_word'),
      dataIndex: 'provision',
      key: 'provision',
    },
    {
      title: t('payout'),
      dataIndex: 'payout',
      key: 'payout',
    },
    {
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <PayoutCellWrapper onClick={e => e.stopPropagation()}>
          {record.salaryId === hoveredSalaryId && <PayoutCell salaryData={record} markAsPaid={markAsPaid} />}
        </PayoutCellWrapper>
      ),
    },
  ];
};
