import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Col, Form, FormInstance, Input, Radio, Row, Select } from 'antd';

import { Globals } from 'constants/global';
import { startOfDay } from 'date-fns';
import { getOptions } from 'modules/auth';
import DatePicker from 'modules/common/components/DatePicker';
import { driverEditLocalesByValue } from 'modules/requests/locales';
import { IDriverRequest } from 'modules/requests/types';

import { AdvanceLimitInput, StyledForm } from './styled';

interface IProps {
  driverData: IDriverRequest;
  setDriverData: Dispatch<SetStateAction<IDriverRequest>>;
  form: FormInstance;
}

const fieldsHandler = (driverData: IDriverRequest) => [
  {
    name: 'firstName',
    value: driverData.userInfo.firstName || null,
  },
  {
    name: 'lastName',
    value: driverData.userInfo.lastName || null,
  },
  {
    name: 'email',
    value: driverData.userInfo.email || null,
  },
  {
    name: 'phone',
    value: driverData.userInfo.phone.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'postalCode',
    value: driverData.addressInfo.postalCode || null,
  },
  {
    name: 'city',
    value: driverData.addressInfo.city || null,
  },
  {
    name: 'street',
    value: driverData.addressInfo.street || null,
  },

  {
    name: 'securityNumber',
    value: driverData.socialSecurityNumber || null,
  },
  {
    name: 'taxiIDNumber',
    value: driverData.taxiIdNumber || null,
  },

  {
    name: 'clearingNumber',
    value: driverData.bankInfo.clearingNumber || null,
  },
  {
    name: 'bankAccNumber',
    value: driverData.bankInfo.bankAccount || null,
  },

  {
    name: 'drivingTerm',
    value: driverData.driverInfo.driverExperience || null,
  },
  {
    name: 'drivingPlatformExp',
    value: driverData.driverInfo.driverPlatform || null,
  },
  {
    name: 'drivingPlatformInterest',
    value: driverData.driverInfo.interestedPlatform || null,
  },
  {
    name: 'existingDrivingAcc',
    value: driverData.havingAccount || null,
  },
  {
    name: 'uberAccountEmail',
    value: driverData.uberAccountInfo?.email || null,
  },
  {
    name: 'uberAccountPhone',
    value: driverData.uberAccountInfo?.phone?.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'boltAccountEmail',
    value: driverData.boltAccountInfo?.email || null,
  },
  {
    name: 'boltAccountPhone',
    value: driverData.boltAccountInfo?.phone?.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'ownCar',
    value: driverData.hasCar ? 'yes' : 'no',
  },
  {
    name: 'startTime',
    value: new Date(driverData.workSchedule.startingDate!),
  },
  {
    name: 'daysAWeek',
    value: driverData.workSchedule.daysPerWeek || null,
  },
  {
    name: 'workingTime',
    value: driverData.workSchedule.workTime || null,
  },
  {
    name: 'workTime',
    value: driverData.workSchedule.workSchedule || null,
  },
  {
    name: 'workDays',
    value: driverData.workSchedule.workWeekdays.map(day => day.id),
  },
  {
    name: 'advanceLimit',
    value: driverData.advanceLimit,
  },
];

type PlatformAccountInfoNamesType = 'uberAccountInfo' | 'boltAccountInfo';

const Edit: FC<IProps> = ({ driverData, setDriverData, form }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.driver.edit' });
  const dispatch = useAppDispatch();
  const { platforms, employmentTypes, schedules, weekdays } = useAppSelector(state => state.auth.options);
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IDriverRequest };
  const [isAdvanceLimitAtive, setIsAdvanceLimitActive] = useState(!!driverData.advanceLimit);

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(
    () => () => {
      setDriverData(selectedRequest);
    },
    [selectedRequest]
  );

  const changePlatformInfo = (dataName: PlatformAccountInfoNamesType) => (platformData: { email?: string; phone?: string }) => {
    setDriverData(data => ({ ...data, [dataName]: { ...data[dataName], ...platformData }}));
  };

  const changeUber = useMemo(() => changePlatformInfo('uberAccountInfo'), []);
  const changeBolt = useMemo(() => changePlatformInfo('boltAccountInfo'), []);

  const havingExistingAccountHandler = (value: string) => {
    setDriverData(prev => ({ ...prev, havingAccount: value }));
  };

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Row justify='space-between' gutter={40}>
        <Col span={8}>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('firstName')} name='firstName' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('firstName_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, firstName: target.value }}))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('lastName')} name='lastName' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('lastName_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, lastName: target.value }}))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('email')} name='email' rules={[{ required: true, type: 'email' }]}>
            <Input
              placeholder={t('email_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, email: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('phone')} name='phone' rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}>
            <Input
              type='number'
              placeholder={t('phone_placeholder')}
              prefix={Globals.SWEDEN_COUNTRY_CODE}
              onChange={({ target }) =>
                setDriverData(prev => ({ ...prev, userInfo: { ...prev.userInfo, phone: `${Globals.SWEDEN_COUNTRY_CODE}${target.value}` }}))
              }
            />
          </Form.Item>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('postalCode')} name='postalCode' rules={[{ required: true, len: 5, message: t('postalCodeLengthMessage') }]}>
                  <Input
                    placeholder={t('postalCode_placeholder')}
                    type='number'
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, postalCode: target.value }}))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('city')} name='city' rules={[{ required: true }]}>
                  <Input
                    placeholder={t('city_placeholder')}
                    onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, city: target.value }}))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('street')} name='street' rules={[{ required: true }]}>
            <Input
              placeholder={t('street_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, addressInfo: { ...prev.addressInfo, street: target.value }}))}
            />
          </Form.Item>
          <Form.Item
            label={t('securityNumber')}
            name='securityNumber'
            rules={[
              {
                required: true,
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-/g, '');
                    const { length } = textWithoutDashes;

                    if (length >= 10 && length <= 12) resolve('');
                    else reject(new Error());
                  }),
                message: t('lengthValidationMessage10_12'),
              },
              {
                validator: (_, value: string) =>
                  new Promise((resolve, reject) => {
                    const textWithoutDashes = value.replace(/-|[0-9]/g, '');
                    const { length } = textWithoutDashes;

                    if (length === 0) resolve('');
                    else reject(new Error());
                  }),
                message: t('wrongDigestError'),
              },
            ]}
          >
            <Input
              placeholder={t('securityNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, socialSecurityNumber: target.value }))}
            />
          </Form.Item>
          <Form.Item label={t('taxiIDNumber')} name='taxiIDNumber' rules={[{ required: true, len: 6 }]}>
            <Input
              type='number'
              placeholder={t('taxiIDNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, taxiIdNumber: target.value }))}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t('clearingNumber')} name='clearingNumber' rules={[{ required: true }]}>
            <Input
              type='number'
              placeholder={t('clearingNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, clearingNumber: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('bankAccNumber')} name='bankAccNumber' rules={[{ required: true }]}>
            <Input
              type='number'
              placeholder={t('bankAccNumber_placeholder')}
              onChange={({ target }) => setDriverData(prev => ({ ...prev, bankInfo: { ...prev.bankInfo, bankAccount: target.value }}))}
            />
          </Form.Item>
          <Form.Item label={t('drivingTerm')} name='drivingTerm' rules={[{ required: true }]}>
            <Select
              placeholder={t('drivingTerm_placeholder')}
              onChange={val => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, driverExperience: val }}))}
            >
              <Select.Option value='0-1'>0-1</Select.Option>
              <Select.Option value='1-3'>1-3</Select.Option>
              <Select.Option value='3-6'>3-6</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t('drivingPlatformExp')} name='drivingPlatformExp' rules={[{ required: true }]}>
            <Select
              mode='multiple'
              placeholder={t('drivingPlatformExp_placeholder')}
              onChange={val => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, driverPlatform: val }}))}
            >
              {platforms.map(item => (
                <Select.Option value={item.value} key={item.id}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('drivingPlatformInterest')} name='drivingPlatformInterest' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, interestedPlatform: target.value }}))}>
              <Radio.Button value='uber'>Uber</Radio.Button>
              <Radio.Button value='bolt'>Bolt</Radio.Button>
              <Radio.Button value='both'>{t('both')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('existingDrivingAcc')} name='existingDrivingAcc' rules={[{ required: true, message: t('existingDrivingAcc_message') }]}>
            <Radio.Group onChange={({ target }) => havingExistingAccountHandler(target.value)}>
              <Radio.Button value='uber'>Uber</Radio.Button>
              <Radio.Button value='bolt'>Bolt</Radio.Button>
              <Radio.Button value='both'>{t('both')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {driverData.uberAccountInfo && (
            <>
              <Form.Item label={t('uberAccountEmail')} name='uberAccountEmail' rules={[{ required: true, type: 'email' }]}>
                <Input placeholder={t('email_placeholder')} onChange={({ target: { value }}) => changeUber({ email: value })} />
              </Form.Item>
              <Form.Item
                label={t('uberAccountPhone')}
                name='uberAccountPhone'
                rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
              >
                <Input
                  type='number'
                  placeholder={t('phone_placeholder')}
                  prefix={Globals.SWEDEN_COUNTRY_CODE}
                  onChange={({ target: { value }}) => changeUber({ phone: `${Globals.SWEDEN_COUNTRY_CODE}${value}` })}
                />
              </Form.Item>
            </>
          )}
          {driverData.boltAccountInfo && (
            <>
              <Form.Item label={t('boltAccountEmail')} name='boltAccountEmail' rules={[{ required: true, type: 'email' }]}>
                <Input placeholder={t('email_placeholder')} onChange={({ target: { value }}) => changeBolt({ email: value })} />
              </Form.Item>
              <Form.Item
                label={t('boltAccountPhone')}
                name='boltAccountPhone'
                rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
              >
                <Input
                  type='number'
                  placeholder={t('phone_placeholder')}
                  prefix={Globals.SWEDEN_COUNTRY_CODE}
                  onChange={({ target: { value }}) => changeBolt({ phone: `${Globals.SWEDEN_COUNTRY_CODE}${value}` })}
                />
              </Form.Item>
            </>
          )}
        </Col>

        <Col span={8}>
          <Form.Item label={t('ownCar')} name='ownCar' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, hasCar: target.value === 'yes' }))}>
              <Radio.Button value='yes'>{t('yes')}</Radio.Button>
              <Radio.Button value='no'>{t('no')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('startTime')} name='startTime' rules={[{ required: true }]}>
            <DatePicker
              onChange={val => setDriverData(prev => ({ ...prev, workSchedule: { ...prev.workSchedule, startingDate: val }}))}
              disabledDate={current => current && current < startOfDay(new Date())}
              format='DD MMM, YYYY'
              style={{ width: 160 }}
            />
          </Form.Item>
          <Form.Item label={t('daysAWeek')} name='daysAWeek' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, workSchedule: { ...prev.workSchedule, daysPerWeek: target.value }}))}>
              <Radio.Button value='1-3'>1-3</Radio.Button>
              <Radio.Button value='3-5'>3-5</Radio.Button>
              <Radio.Button value='5-7'>5-7</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('workingTime')} name='workingTime' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, workSchedule: { ...prev.workSchedule, workTime: target.value }}))}>
              {employmentTypes.map(item => (
                <Radio.Button value={item.value} key={item.id}>
                  {t(driverEditLocalesByValue[item.value])}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('workTime')} name='workTime' rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, workSchedule: { ...prev.workSchedule, workSchedule: target.value }}))}>
              {schedules.map(item => (
                <Radio.Button value={item.value} key={item.id}>
                  {t(item.value)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('workDays')} name='workDays' rules={[{ required: true }]}>
            <Select
              mode='multiple'
              placeholder={t('workDays_placeholder')}
              onChange={val =>
                setDriverData(prev => ({
                  ...prev,
                  workSchedule: { ...prev.workSchedule, workWeekdays: val.map((id: number) => weekdays.find(day => day.id === id)) },
                }))
              }
            >
              {weekdays.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {t(driverEditLocalesByValue[item.value])}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('advanceLimit')} name='advanceLimit' rules={[{ min: 0, type: 'number' }]}>
            <Radio.Group
              optionType='button'
              defaultValue={!!driverData.advanceLimit}
              onChange={({ target }) => {
                if (!target.value) {
                  setDriverData(prev => ({ ...prev, advanceLimit: undefined }));
                }

                setIsAdvanceLimitActive(target.value);
              }}
              options={[
                { label: t('custom'), value: true },
                { label: t('notAllowed'), value: false },
              ]}
            />
            {isAdvanceLimitAtive && (
              <div>
                <AdvanceLimitInput
                  value={driverData.advanceLimit}
                  onChange={event =>
                    setDriverData(prev => ({
                      ...prev,
                      advanceLimit: event.target.value ? Number(event.target.value) : undefined,
                    }))
                  }
                  type='number'
                  suffix='SEK'
                />
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default Edit;
