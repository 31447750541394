import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { ReimbursementStatusEnum } from 'constants/global';
import { THUNKS } from 'modules/reimbursements/enums';

export const updateReimbursementStatus = createAsyncThunk(
  THUNKS.UPDATE_REIMBURSEMENT_STATUS,
  async (props: { reimbursementIds: number[]; status: ReimbursementStatusEnum; reason?: string }, thunkApi) => {
    try {
      const res = await api.private.put(`${Endpoints.UPDATE_REIMBURSEMENT_STATUS}`, props);

      return res.data.data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
