import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { IDriverShiftTableData } from 'modules/driver/types';

export const generateDriverShiftsColumns = (): TableColumnsType<IDriverShiftTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('car'),
      dataIndex: 'car',
      key: 'car',
    },
    {
      title: t('start'),
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: t('end'),
      dataIndex: 'end',
      key: 'end',
    },
    {
      title: t('schedule'),
      dataIndex: 'schedule',
      key: 'schedule',
    },
    {
      title: t('shiftType'),
      dataIndex: 'shiftType',
      key: 'shiftType',
    },
  ];
};
