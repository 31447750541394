import styled from 'styled-components';

interface IInfoWrapperProps {
  marginBottom?: boolean;
}

export const Label = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.gray};
`;

export const InfoWrapper = styled.div<IInfoWrapperProps>`
  display: flex;
  align-items: center;

  ${({ marginBottom }) => marginBottom && 'margin-bottom: 8px'}
`;

export const Value = styled.span`
  font-weight: 400;
  font-size: 46px;
  line-height: 54px;
  color: #070707;
  margin-right: 16px;
`;
