import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/cars/enums';
import { ManagerNameType } from 'modules/manager/types';

// Get car manager names
export const getCarManagerNames = createAsyncThunk(THUNKS.GET_MANAGERS, (_, thunkApi) => {
  try {
    return api.private.get<{ data: ManagerNameType[] }>(Endpoints.CAR_MANAGERS).then(res => res.data.data);
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
