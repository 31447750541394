import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Form, Input, Modal } from 'antd';

import { config } from 'config';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { addAdjustment } from 'modules/salaries';
import { AdjustmentType } from 'modules/salaries/enums';
import { IFinalPayment } from 'modules/salaries/types';
import { useSearchParams } from 'utils/searchParams';

import { Note, StyledInputNumber } from './styled';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  driverId: string;
  getSalaryByRole: (dateRange: [Date, Date]) => void;
  finalPayment: IFinalPayment;
}

const fieldsHandler = (amount: string, comment: string) => [
  {
    name: 'amount',
    value: amount,
  },
  {
    name: 'comment',
    value: comment || null,
  },
];

export const AddFinalAdjustmentModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, finalPayment, driverId, getSalaryByRole }) => {
  const { start, end } = finalPayment.period;
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.addAdjustmentsModal' });
  const dispatch = useAppDispatch();
  const { getSearchParam } = useSearchParams();
  const { isLoading } = useAppSelector(state => state.salaries);
  const [form] = Form.useForm();
  const [amount, setAmount] = useState<string>('');

  const [comment, setComment] = useState<string>('');
  const endDate = getSearchParam('endDate');

  const dateRange = useMemo<[Date, Date]>(
    () => [startOfMonth(endDate ? new Date(endDate) : new Date()), endOfMonth(endDate ? new Date(endDate) : new Date())],
    [endDate]
  );

  const resetHandler = () => {
    setIsModalOpen(false);
    setAmount('');
    setComment('');
  };

  const submitHandler = async () => {
    try {
      await form.validateFields();

      await dispatch(
        addAdjustment({
          adjustmentDate: new Date(start).toISOString(),
          adjustmentType: AdjustmentType.FINAL,
          amount: Number(amount),
          comment,
          driverId: Number(driverId),
        })
      );

      getSalaryByRole(dateRange);
      resetHandler();

      // TODO: fix validation runtime crash without try catch
      // eslint-disable-next-line
    } catch (e) {}
  };

  const period = `${format(new Date(start), config.DATE_FORMAT_DAY_MONTH)} - ${format(new Date(end), config.DATE_FORMAT_DAY_MONTH)}`;

  return (
    <Modal
      title={t('finalAdjustmentTitle')}
      open={isModalOpen}
      destroyOnClose
      okText={t('submit')}
      onOk={submitHandler}
      onCancel={resetHandler}
      okButtonProps={{ loading: isLoading }}
    >
      <Note>{t('finalNote')}</Note>
      <Form fields={fieldsHandler(amount, comment)} form={form} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
        <Form.Item label={t('finalAdjustmentPlace')}>
          <Input disabled value={t('finalAdjustment')} />
        </Form.Item>

        <Form.Item label={t('period')}>
          <Input disabled value={period} />
        </Form.Item>
        <Form.Item
          label={t('amount')}
          name='amount'
          rules={[
            { required: true },
            {
              validator: (_, value: string) =>
                new Promise((resolve, reject) => {
                  if (Number(value) === 0) {
                    reject(new Error());
                  }

                  resolve('');
                }),
              message: t('isZeroAmountValidationMessage'),
            },
          ]}
        >
          <StyledInputNumber suffix={t('sek')} type='number' onChange={({ target }) => setAmount(target.value)} placeholder={t('amount_placeholder')} />
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <Input.TextArea rows={1} onChange={({ target }) => setComment(target.value)} placeholder={t('comment_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
