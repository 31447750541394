import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #070707;
  margin-top: 184px;
  margin-bottom: 8px;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
  margin-bottom: 40px;
`;
