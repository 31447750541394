import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store';

import { Col, Dropdown, Layout as LayoutComponent, Menu, Row } from 'antd';

import {
  AppstoreOutlined,
  BankOutlined,
  BellOutlined,
  CarOutlined,
  DollarCircleOutlined,
  DownOutlined,
  FileTextOutlined,
  LogoutOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import LogoIcon from 'assets/logo.svg';
import { getAuthToken, signOut } from 'modules/auth';
import Container from 'modules/common/components/Container';

import UploadReportsModal from '../../../analytics/components/UploadReportsModal';
import {
  DividerWithoutMargin,
  Logo,
  SpaceWithPadding,
  StyledBreadcrumb,
  StyledContent,
  StyledDropdown,
  StyledHeader,
  StyledMenu,
  SupportLink,
  SupportText,
  UploadReportsButton,
  Username,
  UserWrapper,
} from './styled';

const Layout: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'global.layout' });
  const navigate = useNavigate();
  const { user, isAdmin } = useAppSelector(state => state.auth);
  const AuthToken = getAuthToken();

  const locationName = decodeURI(useLocation().pathname)
    .split('-')
    .join(' ')
    .split('/')
    .filter(item => item);

  const [isUploadReportsModalOpen, setIsUploadReportsModalOpen] = useState<boolean>(false);

  const signOutHandler = () => {
    signOut();
  };

  // Auth check
  useEffect(() => {
    if (!AuthToken) signOutHandler();
  }, []);

  const items = useMemo(
    () =>
      [
        {
          label: t('dashboard'),
          key: 'dashboard',
          icon: <AppstoreOutlined />,
        },
        {
          label: t('requests'),
          key: 'requests',
          icon: <FileTextOutlined />,
          hide: !isAdmin,
        },
        {
          label: t('managers'),
          key: 'managers',
          icon: <BankOutlined />,
          hide: !isAdmin,
        },
        {
          label: t('drivers'),
          key: 'drivers',
          icon: <UserOutlined />,
        },
        {
          label: t('cars'),
          key: 'cars',
          icon: <CarOutlined />,
        },
        {
          label: t('payments'),
          key: 'payments',
          icon: <WalletOutlined />,
          hide: !isAdmin,
        },
        {
          label: t('salary'),
          key: 'salary',
          icon: <DollarCircleOutlined />,
          hide: !isAdmin,
        },
        {
          label: t('earnings'),
          key: 'earnings',
          icon: <FileTextOutlined />,
          hide: isAdmin,
        },
      ]
        .filter(item => !item.hide)
        .map(item => ({ label: item.label, key: item.key, icon: item.icon })),
    [t, isAdmin]
  );

  const userDropdown = useCallback(
    () => (
      <StyledDropdown>
        <Menu
          items={[
            {
              key: '1',
              label: t('profile'),
              icon: <UserOutlined />,
              onClick: () => navigate('/profile'),
            },
            {
              key: '2',
              label: t('signOut'),
              onClick: signOutHandler,
              icon: <LogoutOutlined />,
            },
          ]}
        />
        <DividerWithoutMargin />
        <SpaceWithPadding>
          <Row>
            <Col span={24}>
              <SupportText>{t('supportText')}</SupportText>
            </Col>
            <Col span={24}>
              <SupportLink href='mailto: support@roadbird.se'>support@roadbird.se</SupportLink>
            </Col>
          </Row>
        </SpaceWithPadding>
      </StyledDropdown>
    ),
    []
  );

  return (
    <LayoutComponent>
      <StyledHeader>
        <Logo src={LogoIcon} alt='logo' />
        <StyledMenu mode='horizontal' selectedKeys={[locationName[0]]} items={items} onClick={info => navigate(`/${info.key}`)} />
        <UserWrapper>
          {isAdmin && <UploadReportsButton onClick={() => setIsUploadReportsModalOpen(true)}>{t('uploadReports')}</UploadReportsButton>}
          <BellOutlined onClick={() => navigate('/changelog')} />
          <Dropdown overlay={userDropdown} trigger={['click']}>
            <Username>
              {t('hi')}, {isAdmin ? `${user?.firstName} ${user?.lastName}` : `${user?.user?.firstName} ${user?.user?.lastName}`} <DownOutlined />
            </Username>
          </Dropdown>
        </UserWrapper>
      </StyledHeader>
      <StyledContent>
        <Container alignStart smallPadding>
          <StyledBreadcrumb>
            <StyledBreadcrumb.Item>{t('adminPanel')}</StyledBreadcrumb.Item>
            {locationName.map((item, index) => (
              <StyledBreadcrumb.Item key={item}>{index > 0 ? t('details') : t(item)}</StyledBreadcrumb.Item>
            ))}
          </StyledBreadcrumb>
          <Outlet />
        </Container>
      </StyledContent>
      <UploadReportsModal isOpen={isUploadReportsModalOpen} setIsOpen={setIsUploadReportsModalOpen} />
    </LayoutComponent>
  );
};

export default Layout;
