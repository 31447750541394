import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'store';

import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import sv_SE from 'antd/lib/locale-provider/sv_SE';

import { Locales } from 'constants/global';
import RouterComponent from 'router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from 'styles';
import WebFont from 'webfontloader';

import 'normalize.css';
import 'antd/dist/antd.less'; // Antd styles
import './styles/fonts/fonts.css'; // Fonts
import './locales'; // i18

if (en_GB.DatePicker) {
  en_GB.DatePicker.lang.quarterFormat = 'QQQ';
}

if (sv_SE.DatePicker) {
  sv_SE.DatePicker.lang.locale = 'sv';
  sv_SE.DatePicker.lang.quarterFormat = `'F'Q`;
}

const LocaleProviderByLocale = {
  [Locales.EN]: en_GB,
  [Locales.SV]: sv_SE,
};

const App: FC = () => {
  const preventChangeNumberInputValueOnScrollHandler = () => {
    const numberInput = document.activeElement as HTMLInputElement;

    if (numberInput.type === 'number') {
      numberInput.blur();
    }
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    document.addEventListener('wheel', preventChangeNumberInputValueOnScrollHandler);
    return () => document.removeEventListener('wheel', preventChangeNumberInputValueOnScrollHandler);
  }, []);

  WebFont.load({
    custom: {
      families: ['SF Pro'],
      urls: [
        './styles/fonts/SF-Pro-Display-Thin.otf',
        './styles/fonts/SF-Pro-Display-Light.otf',
        './styles/fonts/SF-Pro-Display-Regular.otf',
        './styles/fonts/SF-Pro-Display-Medium.otf',
        './styles/fonts/SF-Pro-Display-Semibold.otf',
        './styles/fonts/SF-Pro-Display-Bold.otf',
        './styles/fonts/SF-Pro-Display-Heavy.otf',
        './styles/fonts/SF-Pro-Display-Black.otf',
      ],
    },
  });

  return (
    <Router>
      <ConfigProvider locale={LocaleProviderByLocale[i18n.language as Locales]}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <RouterComponent />
          </ThemeProvider>
        </Provider>
      </ConfigProvider>
    </Router>
  );
};

export default App;
