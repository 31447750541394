import { DriverSteps, ManagerSteps } from 'modules/auth/enums';

export default {
  welcome: {
    title: 'Welcome to RoadBird',
    subTitle: 'Start driving with us',
    button: 'Let’s start',
  },
  expiredLink: {
    title: 'Sorry, but your link expired',
    subTitle: 'If you want to continue sign up, you will generate another link. And then check youe email again. Thank you.',
    email: 'Email',
    generate: 'Generate',
  },
  roleSelect: {
    title: 'Chose your role',
    subTitle: 'Tell us your preference. Fill in the application form.',
    driver: {
      title: 'Driver',
      subTitle: 'I don’t have a car and I want to be a taxi-driver',
    },
    manager: {
      title: 'Fleet manager',
      subTitle: 'I have one or more cars and want to manage my fleet',
    },
    button: 'Fill application form',
  },
  driverRole: {
    title: 'Fill out the form',
    steps: {
      [DriverSteps.FIRST]: {
        title: 'Contact info',
        backButton: 'Back',
        nextButton: 'Next',
        firstName: 'First name',
        firstName_placeholder: 'Enter first name',
        lastName: 'Last name',
        lastName_placeholder: 'Enter last Name',
        email: 'Email',
        email_placeholder: 'Enter email',
        phone: 'Phone',
        phone_placeholder: 'Enter phone nubmer',
        postalCode: 'Postal code',
        postalCode_placeholder: 'Enter postal code',
        postalCodeLengthMessage: 'Required 5 characters',
        city: 'City',
        city_placeholder: 'Enter city',
        street: 'Street',
        street_placeholder: 'Enter street',
        only_alphabetic_characters_allowed: 'Only alphabetic characters allowed!',
      },
      [DriverSteps.SECOND]: {
        title: 'Documents',
        backButton: 'Back',
        nextButton: 'Next',
        securityNumber: 'Social security number',
        securityNumber_placeholder: 'Enter social security number',
        taxiIDNumber: 'Taxi ID number',
        taxiIDNumber_placeholder: 'Enter taxi ID number',
        uploadTaxi: 'Upload pictures of Taxi License',
        uploadDriver: 'Upload pictures of Driver License',
        upload_title: 'Click or drag file to this area to upload',
        upload_subTitle: 'Add please 2 pictures with Front and Back side of License',
        socialSecurityNumberExpireDate: 'SSN Expire date',
        taxiIdNumberExpireDate: 'TIN Expire date',
        expireDate: 'Licence expiry date',
        validationMessage: 'Expiry date is required',
        lengthValidationMessage10_12: 'Length from 10 to 12 characters',
        wrongDigestError: 'Security number should include only numbers and dashes',
        lengthValidationMessage6: 'Must be exactly 6 characters',
        fileError: 'Should be 2 files',
      },
      [DriverSteps.THIRD]: {
        title: 'Driver account',
        backButton: 'Back',
        nextButton: 'Next',
        drivingTerm: 'How long have you been driving a taxi?',
        drivingTerm_placeholder: '0-1 year',
        drivingPlatformExp: 'What platform have you driven for?',
        drivingPlatformExp_placeholder: 'Uber',
        drivingPlatformInterest: 'What platforms are you interested in driving for?',
        existingDrivingAcc: 'Do you have an existing driver account with the following platforms?',
        existingDrivingAcc_message: "'Do you have an existing driver account...' is requiered",
        samePersonalData: 'Do you use the same personal data for your account(s)?',
        email: 'Email',
        email_placeholder: 'Enter email',
        phone: 'Phone number',
        phone_placeholder: 'Enter phone number',
        no: 'No',
        yes: 'Yes',
        both: 'Both',
        useUberData: 'I use other data for uber',
        useBoltData: 'I use other data for bolt',
        years: 'years',
        year: 'year',
      },
      [DriverSteps.FOURTH]: {
        title: 'Bank',
        backButton: 'Back',
        nextButton: 'Next',
        clearingNumber: 'Clearing number',
        clearingNumber_placeholder: 'Enter clearing number',
        bankAccNumber: 'Bank account number',
        bankAccNumber_placeholder: 'Enter bank account number',
      },
      [DriverSteps.FIFTH]: {
        title: 'Work schedule',
        backButton: 'Back',
        nextButton: 'Next',
        startTime: 'When do you want to start working?',
        daysAWeek: 'How many days a week do you want to drive?',
        workingTime: 'Are you interested in working time?',
        workTime: 'Do you want to work?',
        workDays: 'Which days of the week are you interested in driving?',
        workDays_placeholder: 'Monday',
        workSchedule: 'Do you want to work?',
        allDays: 'Select All Days',
        day: 'Day',
        night: 'Night',
        combined: 'Combined',
        fullTime: 'Full time',
        partTime: 'Part time',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
      },
      [DriverSteps.SIXTH]: {
        title: 'Car',
        backButton: 'Back',
        nextButton: 'Next',
        ownCar: 'Do you have your own car to get to workplace?',
        yes: 'Yes',
        no: 'No',
      },
      [DriverSteps.SEVENTH]: {
        title: 'Comments',
        backButton: 'Back',
        info: 'Further information or questions',
        iAcceptThe: 'I accept the',
        privacyPolicy: 'Terms of Use & Privacy Policy.',
        privacyPolicy_message: 'Please accept privacy policy',
        submitButton: 'Submit',
      },
    },
  },
  managerRole: {
    title: 'Fill out the form',
    steps: {
      [ManagerSteps.FIRST]: {
        title: 'Contact info',
        backButton: 'Back',
        nextButton: 'Next',
        firstName: 'First name',
        firstName_placeholder: 'Enter first name',
        lastName: 'Last name',
        lastName_placeholder: 'Enter last Name',
        email: 'Email',
        email_placeholder: 'Enter email',
        phone: 'Phone',
        phone_placeholder: 'Enter phone nubmer',
        postalCode: 'Postal code',
        postalCode_placeholder: 'Enter postal code',
        postalCodeLengthMessage: 'Required 5 characters',
        city: 'City',
        city_placeholder: 'Enter city',
        street: 'Street',
        street_placeholder: 'Enter street',
        only_alphabetic_characters_allowed: 'Only alphabetic characters allowed!',
      },
      [ManagerSteps.SECOND]: {
        title: 'Company account',
        backButton: 'Back',
        nextButton: 'Next',
        companyNumber: 'Company registration number',
        companyNumber_placeholder: 'Enter company registration number',
        securityNumber: 'Social security number',
        securityNumber_placeholder: 'Enter social security number',
        companyName: 'Company Name',
        companyName_placeholder: 'Enter company Name',
        companyAdress: 'Company address if different from above',
        companyAdress_placeholder: 'Enter company address',
        redovisnigscentral: 'Accounting center',
        redovisnigscentral_placeholder: 'Halda',
        agreement: 'Acceptance Agreement of data access from Redovisningsscentral',
        agreement_message: 'Please accept agreement of data access',
        postalCode: 'Postal code',
        postalCode_placeholder: 'Enter postal code',
        postalCodeLengthMessage: 'Required 5 characters',
        city: 'City',
        city_placeholder: 'Enter city',
        street: 'Street',
        street_placeholder: 'Enter street',
        lengthValidationMessage10_12: 'Length from 10 to 12 characters',
        wrongDigestError: 'Security number should include only numbers and dashes',
        swishNumber: 'Swish ID',
        swishNumberPlaceholder: 'Enter Swish ID',
      },
    },
  },
  finalScreen: {
    title: 'Thank you for your time',
    subTitle: 'Our manager will contact you shortly to confirm your information. We will also send you an email with more information about further actions.',
    back: 'Back',
    backToSite: 'Back to site',
    addAnotherCar: 'Add another car',
  },
  createPassword: {
    title: 'Sign up',
    subTitle: 'Sign up and start managing your cars!',
    email: 'Email',
    createPassword: 'Create password',
    confirmPassword: 'Comfirm password',
    password_placeholder: 'Enter password',
    loginButton: 'Login',
    mismatchError: 'The two passwords that you entered do not match!',
  },
};
