import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';

// Get driver last salary date
export const getDriverLastSalaryDate = createAsyncThunk(THUNKS.GET_DRIVER_LAST_SALARY_DATE, async (_, thunkApi) => {
  try {
    return await api.private.get<{ data: Date }>(Endpoints.GET_DRIVER_LAST_SALARY_DATE).then(res => res.data.data);
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
