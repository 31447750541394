import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getUsers } from '../thunks';

export const getUsersReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get users

  builder
    .addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload.users;
      state.total = action.payload.total;
      state.isLoading = false;
    })
    .addCase(getUsers.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
