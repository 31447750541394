import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { editManagerRequest } from '../thunks';

export const editManagerRequestReducer: ModuleExtraReducer<IRequestsState> = builder => {
  // Edit manager request data

  builder
    .addCase(editManagerRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedRequest = action.payload;
    })
    .addCase(editManagerRequest.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(editManagerRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
