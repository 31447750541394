import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { IOption } from 'modules/analytics/types';
import { THUNKS } from 'modules/salaries/enums';

// Get adjustment types
export const getAdjustmentTypes = createAsyncThunk(THUNKS.GET_ADJUSTMENT_TYPES, async (_, thunkApi) => {
  try {
    const data = await api.private.get<{ data: IOption[] }>(`${Endpoints.DATA_ADJUSTMENT_TYPES}`).then(res => res.data.data);
    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
