export default {
  contacts: 'If you have some questions, please call',
  selectLanguage: 'Select language',
  passwordPattern: 'Must contain minimum 8 characters, 1 capital letter, 1 number',
  youAlready: 'You already have a driver account. You can',
  signIn: 'Sign in',
  asADriver: 'as a Driver.',
  dateRanges: {
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
  },
  layout: {
    supportText: 'If you have any questions, please contact us via our corporate email.',
    details: 'Details',
    dashboard: 'Dashboard',
    requests: 'Requests',
    drivers: 'Drivers',
    managers: 'Fleet managers',
    salary: 'Salary',
    cars: 'Cars',
    hi: 'Hi',
    profile: 'Profile',
    signOut: 'Sign out',
    adminPanel: 'Admin panel',
    uploadReports: 'Upload reports',
    earnings: 'Earnings',
    changelog: 'Changelog',
    payments: 'Payments',
    uploadReportsModal: {
      title: 'Upload Reports',
      bolt: 'Bolt',
      uber: 'Uber',
      taximeter: 'Taximeter',
      sling: 'Sling',
      csv_laceholder: 'Upload CSV file',
      xls_laceholder: 'Upload XLS file',
      submit: 'Submit',
      success: 'Success',
      ok: 'Ok',
      allYour: 'All you reports are in our system now: taximeter, sling, bolt. You can check all statistic.',
      choosePeriod: 'Choose period',
      back: 'Back',
      next: 'Next',
      skip: 'Skip',
      click: 'Click or drag file to this area to upload',
      support: 'Support of a single or bulk upload. It is strictly prohibited to upload company data or other band files',
    },
    reportStep: {
      successMessage: 'We didn’t find any errors. Now, you can save your report in our system. Thank you.',
      deletedSlingRecords: 'Deleted sling records',
      insertedSlingRecords: 'Inserted sling records',
      deletedSlingShifts: 'Deleted sling shifts',
      insertedSlingShifts: 'Inserted sling shifts',
      deletedTaximeterRecords: 'Deleted taximeter records',
      insertedTaximeterRecords: 'Inserted taximeter records',
      deletedTaximeterShifts: 'Deleted taximeter shifts',
      insertedTaximeterShifts: 'Inserted taximeter shifts',
      deletedShiftReportRecords: 'Deleted shift report records',
      insertedShiftReportRecords: 'Inserted shift report records',
      deletedUberRecords: 'Deleted Uber records',
      insertedUberRecords: 'Inserted Uber records',
      deletedUberTrips: 'Deleted Uber trips',
      insertedUberTrips: 'Inserted Uber trips',
      deletedBoltRecords: 'Deleted Bolt records',
      insertedBoltRecords: 'Inserted Bolt records',
      deletedBoltTrips: 'Deleted Bolt trips',
      insertedBoltTrips: 'Inserted Bolt trips',
      driverFullName: 'Driver full name',
      driverPhone: 'Phone number',
      orderTime: 'Order time',
      paymentTime: 'Payment time',
      travelPrice: 'Travel price',
      bookingFee: 'Booking fee',
      customsDuties: 'Customs duties',
      cancellationFee: 'Cancellation fee',
      tips: 'Tips',
      moreThanOneDriverBoltTrips: 'More than one driver Bolt trips',
      noShiftBoltTrips: 'No shift Bolt trips',
      noCarBoltTrips: 'No car Bolt trips',
      paidByUber: 'Paid by Uber',
      serviceFee: 'Service fee',
      waitingTime: 'Waiting time',
      nonStopFee: 'Non stop fee',
      topTraffic: 'Top traffic',
      airportFee: 'Airport fee',
      petsFee: 'Pets fee',
      incentive: 'Incentive',
      noDriverUberTrips: 'No driver Uber trips',
      moreThanOneDriverUberTrips: 'More than one driver Uber trips',
      noShiftUberTrips: 'No shift Uber trips',
      carRegistrationPlate: 'Car registration plate',
      shiftStartTime: 'Shift start time',
      shiftEndTime: 'Shift end time',
      noCarSlingShifts: 'No car sling shifts',
      noDriverSlingShifts: 'No driver sling shifts',
      moreThanOneDriverSlingShifts: 'More than one driver sling shifts',
      taximeterStartTime: 'Taximeter start time',
      taximeterEndTime: 'Taximeter end time',
      drivenKm: 'Driven km',
      driverTaxiIdNumber: 'Driver taxi id number',
      noCarTaximeterShifts: 'No car taximeter shifts',
      noDriverTaximeterShifts: 'No driver taximeter shifts',
      noSlingShiftTaximeterShifts: 'No sling shift taximeter shifts',
      success: 'Success',
    },
  },
  notifications: {
    error: {
      errorWord: 'Error',
      serverError: 'Request rejected by the server',
    },
    success: {
      successWord: 'Success',
      markAsPaid: 'Marked as paid',
      adjAdded: 'Adjustment added',
      taxTableNumberWasSet: 'Tax table number was set',
      adjDeleted: 'Adjustment deleted',
      contactInfo: 'Your contact information has been successfully changed',
      forgotPassword: 'An email with a reset code has been sent to your email',
      reportsUploaded: 'Reports has been successfully uploaded',
      changePassword: 'Your password has been successfully changed',
      nowYouCanLogIn: 'Now you can sign in with your password',
      approveManagerRequest: 'Manager request has been approved',
      declineManagerRequest: 'Manager request has been declined',
      approveDriverRequest: 'Driver request has been approved',
      declineDriverRequest: 'Driver request has been declined',
      approveCarRequest: 'Car request has been approved',
      declineCarRequest: 'Car request has been declined',
      editedManagerRequest: 'Manager request information has been changed',
      editedDriverRequest: 'Driver request information has been changed',
      editDriver: 'Driver information has been changed',
      editManager: 'Manager information has been changed',
      carEdit: 'information has been changed',
      linkDriverWithACar: 'was linked with a car',
      unlinkCarFromDriver: 'car was unlinked from a driver',
      carWord: 'Car',
      disabled: 'has been disabled',
      activated: 'has been activated',
      manager: 'Fleet manager',
      driver: 'Driver',
      payedWithSwish: 'Payed with Swish',
      errorPayedWithSwish: 'Error with Swish payment',
      swishPayment: 'Swish payment',
      advanceAdded: 'Advance added',
      linkedCar: 'You have successfully linked the car',
    },
  },
};
