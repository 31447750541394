import { Key } from 'react';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/reimbursements/enums';
import { IPayReimbursementResponse } from 'modules/reimbursements/types';

export const payReimbursement = createAsyncThunk(THUNKS.PAY_REIMBURSEMENT, async ({ paymentIds }: { paymentIds: Key[] }, thunkApi) => {
  try {
    const res = await api.private.put<{ data: IPayReimbursementResponse }>(`${Endpoints.PAY_REIMBURSEMENT}`, { paymentIds });

    return res.data.data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
