import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { getRequestById } from '../thunks';

export const getRequestByIdReducer: ModuleExtraReducer<IRequestsState> = builder => {
  // Get request by ID

  builder
    .addCase(getRequestById.fulfilled, (state, action) => {
      state.selectedRequest = action.payload;
      state.isLoading = false;
    })
    .addCase(getRequestById.pending, state => {
      state.selectedRequest = null;
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getRequestById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
