import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Steps as STPS } from 'antd';

import i18n from 'locales';
import { ManagerSteps } from 'modules/auth/enums';
import Container from 'modules/common/components/Container';
import { ICreateManagerData } from 'modules/manager/types';

import { FirstStep, SecondStep } from './components';
import { StyledSteps, Title } from './styled';

const { Step } = STPS;

const MANAGER_INITIAL_DATA = {
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    lang: i18n.language,
  },
  addressInfo: {
    postalCode: '',
    city: '',
    street: '',
  },
  socialSecurityNumber: '',
  companyInfo: {
    companyRegistrationNumber: '',
    companyName: '',
    companyAddress: {
      postalCode: '',
      city: '',
      street: '',
    },
  },
  accountingCenter: '',
  accountingAcceptance: false,
  swishNumber: '',
};

const ManagerRole: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.managerRole' });
  const [managerData, setManagerData] = useState<ICreateManagerData>(MANAGER_INITIAL_DATA);
  const [step, setStep] = useState<number>(0);

  const markup = () => {
    switch (step) {
      case 0:
        return <FirstStep setStep={setStep} managerData={managerData} setManagerData={setManagerData} />;
      case 1:
        return <SecondStep setStep={setStep} managerData={managerData} setManagerData={setManagerData} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <Title>{t('title')}</Title>
      <StyledSteps progressDot current={step}>
        <Step title={t(`steps.${ManagerSteps.FIRST}.title`)} />
        <Step title={t(`steps.${ManagerSteps.SECOND}.title`)} />
      </StyledSteps>

      {markup()}
    </Container>
  );
};

export default ManagerRole;
