import { config } from 'config';
import { Roles } from 'constants/global';
import { IFinalPayment } from 'modules/salaries/types';
import { currencyServices } from 'services/currencyServices';
import { renderDateRange } from 'utils/date';

import { IMonthlySalaryTableData } from '../../types/IMonthlySalaryTableData';

export const mapMonthlySalary = (salary: IFinalPayment[]): IMonthlySalaryTableData[] =>
  salary.map(item => ({
    key: item.id,
    period: renderDateRange({ timeRange: { range: item.period }, formatOfDate: config.DATE_FORMAT_DAY_MONTH }),
    name: item.userName,
    status: item.status,
    swishStatus: item.payoutInfo?.status,
    tips: currencyServices.convertToCurrency(item.tips),
    taxes: item.taxes ? currencyServices.convertToCurrency(item.taxes) : '-',
    brutto: currencyServices.convertToCurrency(item.brutto),
    salaryId: item.id,
    payout: currencyServices.convertToCurrency(item.payouts),
    userId: item.userId,
    errorMessage: item.payoutInfo?.errorMessage,
    reimbursement: currencyServices.convertToCurrency(item.finalAdjustment),
    final: item.finalPayout ? currencyServices.convertToCurrency(item.finalPayout) : '-',
    role: Roles.DRIVER,
    endDate: item.period.end,
  }));
