import { config } from 'config';
import { format } from 'date-fns';
import { ICarShifts } from 'modules/cars/types';

import { ICarShiftsTableData } from '../../types/ICarShiftsTableData';

export const mapCarShifts = (shifts: ICarShifts): ICarShiftsTableData[] =>
  shifts.items.map(item => ({
    key: item.date + Math.random(),
    date: format(new Date(item.date), config.DATE_FORMAT),
    driver: item.driver,
    start: item.start,
    end: item.end,
    schedule: item.shiftSchedule,
  }));
