import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAppSelector } from 'store';

import { config } from 'config';
import i18n from 'locales';
import { Changelog, Dashboard } from 'modules/analytics';
import authRoutes from 'modules/auth/routes';
import { AddCar, CarDetails, CarsList } from 'modules/cars';
import Layout from 'modules/common/components/Layout';
import { Payments } from 'modules/reimbursements';
import { RequestDetails, RequestsList } from 'modules/requests';
import { Salary } from 'modules/salaries';
import SalaryDetails from 'modules/salaries/components/Details';
import { AdminProfile, getUser, ManagerProfile, usersAdminRoutes, usersRoutes } from 'modules/user';

// TODO: Rework routing

const RouterComponent = () => {
  const { isAdmin, user } = useAppSelector(state => state.auth);

  useEffect(() => {
    const lang = user?.lang || user?.user?.lang;
    const userStringified = getUser();

    if (userStringified) {
      const storedUser = JSON.parse(userStringified);
      const storedLang = storedUser?.lang || storedUser?.user.lang;

      i18n.changeLanguage(storedLang);
    } else i18n.changeLanguage(lang || config.DEFAULT_LANGUAGE);
  }, [user]);

  return (
    <Routes>
      {authRoutes.map(props => (
        <Route {...props} key={props.path} />
      ))}
      <Route element={<Layout />}>
        <Route path='/dashboard' element={<Dashboard />} />
        {isAdmin && <Route path='/requests' element={<RequestsList />} />}
        {isAdmin && <Route path='/requests/:id' element={<RequestDetails />} />}

        {isAdmin && usersAdminRoutes.map(props => <Route {...props} key={props.path} />)}
        {usersRoutes.map(props => (
          <Route {...props} key={props.path} />
        ))}

        <Route path='/cars' element={<CarsList />} />
        <Route path='/cars/:id' element={<CarDetails />} />
        <Route path='/profile' element={isAdmin ? <AdminProfile /> : <ManagerProfile />} />
        <Route path='/changelog' element={<Changelog />} />
        <Route path='/salary' element={isAdmin ? <Salary /> : <Navigate to='/dashboard' replace />} />
        <Route path='/salary/details' element={isAdmin ? <SalaryDetails /> : <Navigate to='/dashboard' replace />} />
        <Route path='/payments' element={isAdmin ? <Payments /> : <Navigate to='/dashboard' replace />} />
        <Route path='/earnings' element={!isAdmin ? <SalaryDetails /> : <Navigate to='/dashboard' replace />} />
      </Route>
      <Route path='/add-car' element={<AddCar />} />
      <Route path='/' element={<Navigate to='/dashboard' replace />} />
      <Route path='*' element={<Navigate to='/sign-in' replace />} />
    </Routes>
  );
};

export default RouterComponent;
