import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { calculateDriverEarning } from '../thunks';

export const calculateDriverEarningReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Calculate driver earning

  builder
    .addCase(calculateDriverEarning.fulfilled, state => {
      state.isLoading = false;
    })
    .addCase(calculateDriverEarning.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(calculateDriverEarning.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
