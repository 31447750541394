/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { FC } from 'react';

import { ImageProps } from 'antd/lib/image';

import { Image } from './styled';

const Photo: FC<ImageProps> = props => <Image {...props} />;

export default Photo;
