import { Form, InputNumber } from 'antd';

import styled from 'styled-components';

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const StyledNumberInput = styled(InputNumber)`
  width: 219px;
`;

export const YearPicker = styled(InputNumber)`
  width: 100%;
`;
