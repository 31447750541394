import { ICarsState } from 'modules/cars/types';
import { ModuleExtraReducer } from 'types/Module';

import { getCarManagerNames } from '../thunks';

export const getCarManagerNamesReducer: ModuleExtraReducer<ICarsState> = builder => {
  // Get car manager names

  builder
    .addCase(getCarManagerNames.fulfilled, (state, action) => {
      state.isLoading = false;
      state.managerNames = action.payload;
    })
    .addCase(getCarManagerNames.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCarManagerNames.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
