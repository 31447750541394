import { Button } from 'antd';

import styled from 'styled-components';

export const OpenTemplatesButton = styled(Button)`
  text-align: left;
  padding-left: 0;
  margin-bottom: 16px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ImageLabel = styled.p`
  margin-bottom: 16px;
`;
