import { FC } from 'react';

import { InfoWrapper, Label, Value } from './styled';

interface IProps {
  label?: string;
  value?: string | number | JSX.Element;
  marginBottom?: boolean;
}

const Change: FC<IProps> = ({ label, value = 0, marginBottom }) => (
  <div>
    <Label>{label}</Label>
    <InfoWrapper marginBottom={marginBottom}>
      <Value>{value}</Value>
    </InfoWrapper>
  </div>
);

export default Change;
