import { config } from 'config';
import { format } from 'date-fns';
import { ICar } from 'modules/cars/types';

import { IMappedCar } from './types/IMappedCar';

export const mapCarsLinkModal = (cars: ICar[]): IMappedCar[] =>
  cars.map(car => ({
    key: car.info.id,
    id: car.info.id,
    plate: car.info.registrationPlate,
    make: car.info.make,
    fulfillment: '-',
    shiftPlan: car.info.shiftPlan.value,
    startTime: format(new Date(car.info.startTime), config.TIME_FORMAT_HOUR_MINUTE_12H), // TODO
    splitTimes: car.info.shiftSplitTimes.value,
    model: car.info.model,
    year: car.info.year,
    maxMill: car.info.mileageMax,
    latitude: car.info.address.location?.latitude,
    longitude: car.info.address.location?.longitude,
  }));
