import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { IOption } from 'modules/analytics/types';
import { THUNKS } from 'modules/salaries/enums';
import { ISetFinalTaxTableNumber } from 'modules/salaries/types';

// Set final tax table number
export const setFinalTaxTableNumber = createAsyncThunk(THUNKS.SET_TAX_TABLE_NUMBERS, async (data: ISetFinalTaxTableNumber, thunkApi) => {
  try {
    await api.private
      .put<{ data: IOption[] }>(`${Endpoints.SALARY_FINAL_TAX_TABLE_NUMBER}?finalPaymentId=${data.finalPaymentId}&taxTableNumber=${data.taxTableNumber}`)
      .then(res => res.data.data);

    notification.success({
      placement: 'topRight',
      message: t<string>('global.notifications.success.successWord'),
      description: t<string>('global.notifications.success.taxTableNumberWasSet'),
    });
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
