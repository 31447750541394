import { Dispatch, FC, SetStateAction } from 'react';

import { FormInstance } from 'antd';

import { ICar } from 'modules/cars/types';

import Edit from './components/Edit';
import Info from './components/Info';

interface IProps {
  isEdit: boolean;
  carData: ICar;
  setCarData: Dispatch<SetStateAction<ICar>>;
  form: FormInstance;
}

const Information: FC<IProps> = ({ isEdit, carData, setCarData, form }) => {
  if (isEdit) return <Edit carData={carData} setCarData={setCarData} form={form} />;
  return <Info />;
};

export default Information;
