import { FC, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch } from 'store';

import { Button, notification,Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { ReimbursementStatusEnum } from 'constants/global';
import { updateReimbursement } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';

type PropsType = {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
};

const CarCell: FC<PropsType> = ({ reimbursement, fetchReimbursements }) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [currentCarId, setCurrentCarId] = useState<number>(reimbursement.carId);
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.notifications' });

  const options: DefaultOptionType[] = reimbursement.possibleCars.map(car => ({
    value: car.id,
    label: car.registrationPlate,
  }));

  const onTextClick = () => {
    setIsEdit(true);
  };

  const onSubmit = async () => {
    setIsEdit(false);

    await dispatch(
      updateReimbursement({
        id: reimbursement.id,
        carId: currentCarId,
        vat: reimbursement.reimbursementVat,
        amount: reimbursement.amount,
      })
    );

    const { error } = store.getState().cars;

    if (!error) {
      notification.success({
        placement: 'topRight',
        message: t('success'),
        description: t('carUpdate'),
      });
    }

    fetchReimbursements();
  };

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return isEdit && reimbursement.status === ReimbursementStatusEnum.PENDING ? (
    <Select onKeyDown={onKeyPress} onChange={setCurrentCarId} onBlur={onSubmit} options={options} value={currentCarId} />
  ) : (
    <Button type='text' onClick={onTextClick}>
      {reimbursement.carRegistrationPlate}
    </Button>
  );
};

export default CarCell;
