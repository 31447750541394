import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Modal, Table } from 'antd';

import { AdjustmentType } from 'modules/salaries/enums';
import { getSalaryAdjustments } from 'modules/salaries/slice/thunks';
import { ISalaryAdjustment } from 'modules/salaries/types';
import { convertIntoISODate } from 'utils';

import { useGetSalaryAdjustmentsModalTable } from '../../hooks/useGetSalaryAdjustmentsModalTable';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  dateRange: { start: Date; end: Date };
  driverId: number | string;
  refreshSalaryByRole: (dateRang: [Date, Date]) => void;
}

export const GetFinalAdjustmentDetailsModal: FC<IProps> = ({ refreshSalaryByRole, isModalOpen, setIsModalOpen, dateRange, driverId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.detailsModal' });
  const { isLoading } = useAppSelector(state => state.salaries);
  const [details, setDetails] = useState<ISalaryAdjustment[]>([]);
  const formattedDateRange = { start: convertIntoISODate(dateRange.start), end: convertIntoISODate(dateRange.end) };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getSalaryAdjustments({ date: formattedDateRange, driverId, adjustmentType: [AdjustmentType.FINAL]})).then(res =>
        setDetails(res.payload as ISalaryAdjustment[])
      );
    }
  }, [isModalOpen]);

  const [data, columns] = useGetSalaryAdjustmentsModalTable({
    adjustments: details,
    setAdjustments: setDetails,
    refreshSalaryByRole,
    driverId,
    date: formattedDateRange,
    isFinal: true,
  });

  const resetHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal width={640} title={t('title')} cancelButtonProps={{ hidden: true }} open={isModalOpen} destroyOnClose onOk={resetHandler} onCancel={resetHandler}>
      <Table loading={isLoading} dataSource={data} columns={columns} pagination={false} />
    </Modal>
  );
};
