import { Roles } from 'constants/global';
import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer } from 'types/Module';

import { signIn } from '../thunks';

export const signInReducer: ModuleExtraReducer<IAuthState> = builder => {
  // SIGN IN

  builder
    .addCase(signIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.isAdmin = action.payload.role === Roles.ADMIN;
    })
    .addCase(signIn.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(signIn.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
};
