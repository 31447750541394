import { notification } from 'antd';

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { t } from 'i18next';
import { THUNKS } from 'modules/requests/enums';
import { ISelectedCarForAgreement } from 'modules/requests/types';

export const setSelectedCarForAgreement = createAsyncThunk(
  THUNKS.SET_SELECTED_CAR_FOR_AGREEMENT,
  async (params: { carId: number; requestId: number }, thunkApi) => {
    try {
      const { carId, requestId } = params;

      const res = await api.private
        .put<{ data: ISelectedCarForAgreement }>(`${Endpoints.AGREEMENT_SET_SELECTED_CAR}?requestId=${requestId}&carId=${carId}`)
        .then(response => response.data.data);

      // TODO: add wordings

      notification.success({
        placement: 'topRight',
        message: `${t('global.notifications.success.linkedCar')}`,
      });

      return res;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
