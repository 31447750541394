import { Key } from 'react';

import { TableColumnsType } from 'antd';

import { IFinalPayment } from 'modules/salaries/types';

import { IMonthlySalaryTableData } from '../../types/IMonthlySalaryTableData';
import { generateMonthlySalaryColumns } from './generateMonthlySalaryColumns';
import { mapMonthlySalary } from './mapMonthlySalary';

interface IProps {
  salary?: IFinalPayment[];
  statusFilter: string[];
  swishStatusFilter: string[];
  nameFilter: string[];
  hoveredSalaryId: number | undefined;
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
}

type ReturnType = [IMonthlySalaryTableData[], TableColumnsType<IMonthlySalaryTableData>];

export const useMonthlySalaryTable = (props: IProps): ReturnType => {
  const { salary, ...generateColumnsProps } = props;
  const tableData = mapMonthlySalary(salary || []);
  const tableColumns = generateMonthlySalaryColumns(generateColumnsProps);

  return [tableData, tableColumns];
};
