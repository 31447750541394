import { Form, InputNumber, Row, Upload } from 'antd';

import styled from 'styled-components';

interface ILabelProps {
  noMargin?: boolean;
}

export const StyledForm = styled(Form)`
  width: 440px;
`;

export const StyledRow = styled(Row)`
  padding-top: 16px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const Label = styled.p<ILabelProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #888888;
  margin-top: 8px;

  ${({ noMargin }) => noMargin && 'margin-top: 0;'}
`;

export const StyledUpload = styled(Upload)`
  .ant-upload {
    margin-top: 8px;
  }
`;

export const StyledNumberInput = styled(InputNumber)`
  width: 219px;
`;
