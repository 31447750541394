export enum RequestStatuses {
  APPROVED = 'approved',
  NEW = 'new',
  DECLINED = 'declined',
}

export enum RequestTypes {
  CAR = 'carRequests',
  DRIVER = 'driverRequests',
  MANAGER = 'managerRequests',
  HISTORY = 'history',
}
