export default {
  title: 'Sign in',
  signUp: 'Sign up',
  subTitle: 'Sign in and start managing your candidates!',
  email: 'Email',
  email_placeholder: 'Enter email',
  password: 'Password',
  password_placeholder: 'Enter password',
  loginButton: 'Login',
  remember: 'Remember me',
  forgotPass: 'Forgot password',
};
