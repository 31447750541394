import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/index';

import { TableColumnsType } from 'antd';

import { StyledDeleteIcon } from 'modules/salaries/components/GetAdjustmentsModal/styled';
import { AdjustmentType } from 'modules/salaries/enums';
import { deleteAdjustment, getSalaryAdjustments } from 'modules/salaries/slice/thunks';
import { IGetSalaryAdjustments, IGetSalaryAdjustmentsTableData, ISalaryAdjustment } from 'modules/salaries/types';

interface IProps extends IGetSalaryAdjustments {
  setAdjustments: Dispatch<SetStateAction<ISalaryAdjustment[]>>;
  refreshSalaryByRole: (dateRange: [Date, Date]) => void;
  isFinal: boolean;
  dateRange: [Date, Date];
}

export const generateGetSalaryAdjustmentsModalColumns = (props: IProps): TableColumnsType<IGetSalaryAdjustmentsTableData> => {
  const { refreshSalaryByRole, setAdjustments, driverId, date, isFinal } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const dispatch = useAppDispatch();

  const weeklyTableColumns: TableColumnsType<IGetSalaryAdjustmentsTableData> = [
    {
      title: t('period'),
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: t('adjustments'),
      dataIndex: 'adjustments',
      key: 'adjustments',
    },
    {
      title: t('car'),
      dataIndex: 'car',
      key: 'car',
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      key: 'delete',
      render: (_, tableData) => {
        const onDelete = async () => {
          const adjustmentType = isFinal ? [AdjustmentType.FINAL] : [AdjustmentType.EARNING, AdjustmentType.PROVISION];
          await dispatch(deleteAdjustment(tableData.key));
          const result = await dispatch(getSalaryAdjustments({ driverId, date, adjustmentType }));
          refreshSalaryByRole(props.dateRange);
          setAdjustments(result.payload as ISalaryAdjustment[]);
        };

        return <StyledDeleteIcon onClick={onDelete} />;
      },
    },
  ];

  const monthlyTableColumns = weeklyTableColumns.filter(column => column.key !== 'car');

  return isFinal ? monthlyTableColumns : weeklyTableColumns;
};
