import { CurrencyType } from './types';

class CurrencyServices {
  private currencyFormat: CurrencyType = 'sv-SE';

  convertToSwedishCurrency = (value: number) =>
    new Intl.NumberFormat(this.currencyFormat, {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'code',
      maximumFractionDigits: 0,
    }).format(value);

  convertToSwedishCurrencyNoLabel = (value: number) =>
    new Intl.NumberFormat(this.currencyFormat, {
      currency: 'SEK',
      maximumFractionDigits: 0,
    }).format(value);

  convertToCurrency = (value: number) => {
    const convertFunctions: { [key in CurrencyType]: (value: number) => string } = {
      'sv-SE': this.convertToSwedishCurrency,
    };

    return convertFunctions[this.currencyFormat](value);
  };

  setCurrencyFormat = (format: CurrencyType) => {
    this.currencyFormat = format;
  };
}

export const currencyServices = new CurrencyServices();
