import { Dispatch, SetStateAction } from 'react';

import { TableColumnsType } from 'antd';

import { ICar } from 'modules/cars/types';
import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';
import { IDriver } from 'modules/driver/types';

import { addColorsToRows } from './addColorsToRows';
import { generateCarLinkColumns } from './generateCarLinkColumns';
import { mapCarsLinkModal } from './mapCarsLinkModal';

interface IProps {
  search: string;
  carsInRadius: ICar[];
  user: IDriver;
  selectedCar: ILinkCarTableData | null;
  highlightedCars: number[];
  setSelectedCar: Dispatch<SetStateAction<ILinkCarTableData | null>>;
}

type ReturnType = [ILinkCarTableData[], TableColumnsType<ILinkCarTableData>];

export const useCarLinkTable = (props: IProps): ReturnType => {
  const { carsInRadius, search, selectedCar, highlightedCars, user, setSelectedCar } = props;
  const filteredCarsInRadius = carsInRadius.filter(car => car.info.registrationPlate.toLowerCase().includes(search.toLowerCase()));
  const mappedFilteredCarsInRadius = mapCarsLinkModal(filteredCarsInRadius);
  const tableData = addColorsToRows({ cars: mappedFilteredCarsInRadius, selectedCar, highlightedCars, user });
  const tableColumns = generateCarLinkColumns(setSelectedCar);

  return [tableData, tableColumns];
};
