export default {
  forgotPassword: {
    title: 'Forgot password',
    subTitle: 'We will send you an email to reset your password',
    email: 'Email',
    email_placeholder: 'Enter email',
    sendButton: 'Send',
  },
  resetPassword: {
    title: 'Reset password',
    subTitle: 'Create new password for your account',
    password: 'Password',
    password_placeholder: 'Enter password',
    confirmPassword: 'Confirm password',
    mismatchError: 'The two passwords that you entered do not match!',
    sendButton: 'Reset',
  },
};
