import { ChangeEventHandler, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';

import { Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { ReimbursementStatusEnum } from 'constants/global';
import { updateReimbursementStatus } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';

type PropsType = {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const DeclineModal: FC<PropsType> = ({ reimbursement, fetchReimbursements, setIsOpen, isOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.declineModal' });
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setText(event.target.value);
  };

  const decline = async () => {
    await dispatch(
      updateReimbursementStatus({
        reimbursementIds: [reimbursement.id],
        status: ReimbursementStatusEnum.DECLINED,
        reason: text,
      })
    );

    fetchReimbursements();
    setIsOpen(false);
  };

  const onCancelClick = () => {
    setIsOpen(false);
  };

  return (
    <Modal closable={false} onCancel={onCancelClick} okButtonProps={{ danger: true }} open={isOpen} title={t('title')} okText={t('decline')} onOk={decline}>
      <p>{t('comment')}</p>
      <TextArea value={text} onChange={onTextAreaChange} rows={4} />
    </Modal>
  );
};

export default DeclineModal;
