import { config } from 'config';
import { Roles } from 'constants/global';
import { format } from 'date-fns';
import { IDriver, IDriverTableData } from 'modules/driver/types';
import { currencyServices } from 'services/currencyServices';

export const mapDrivers = (rawDrivers: IDriver[]): IDriverTableData[] =>
  rawDrivers.map(driverData => ({
    key: driverData.user.id,
    id: driverData.user.id,
    status: driverData.status,
    data: driverData,
    role: Roles.DRIVER,
    date: format(new Date(driverData.user.createdAt), config.DATE_FORMAT),
    time: format(new Date(driverData.user.createdAt), config.TIME_FORMAT),
    name: `${driverData.user.firstName} ${driverData.user.lastName}`,
    phone: driverData.user.phone,
    manager: driverData.managers?.map(manager => `${manager.firstName} ${manager.lastName}`) || [],
    carAccess: driverData.cars?.length > 0 ? driverData.cars.map(car => car.registrationPlate).join(', ') : '-',
    earnings: driverData.lastWeekMetrics?.earning ? currencyServices.convertToCurrency(driverData.lastWeekMetrics.earning) : '-',
    employment: driverData.workSchedule?.employmentGrade ? `${driverData.workSchedule.employmentGrade}%` : '-',
    efficiency: driverData.lastWeekMetrics?.efficiency ? `${driverData.lastWeekMetrics.efficiency}%` : '-',
    fulfillmentRate: driverData.lastWeekMetrics?.fulfillment ? `${driverData.lastWeekMetrics.fulfillment}%` : '-',
  }));
