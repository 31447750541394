import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Modal, Table } from 'antd';

import { getSalaryAdjustments } from 'modules/salaries';
import { AdjustmentType } from 'modules/salaries/enums';
import { ISalaryAdjustment } from 'modules/salaries/types';
import { convertIntoISODate } from 'utils';

import { useGetSalaryAdjustmentsModalTable } from '../../hooks/useGetSalaryAdjustmentsModalTable';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  dateRange: { start: string; end: string };
  driverId: number | string;
  refreshSalaryByRole: (dateRange: [Date, Date]) => void;
}

// TODO: rename to GetAdjustmentsModal

const DetailsModal: FC<IProps> = ({ refreshSalaryByRole, isModalOpen, setIsModalOpen, dateRange, driverId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.detailsModal' });
  const { isLoading } = useAppSelector(state => state.salaries);
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<ISalaryAdjustment[]>([]);
  const formattedDateRange = { start: convertIntoISODate(dateRange.start), end: convertIntoISODate(dateRange.end) };

  useEffect(() => {
    dispatch(getSalaryAdjustments({ date: formattedDateRange, driverId, adjustmentType: [AdjustmentType.EARNING, AdjustmentType.PROVISION]})).then(res => {
      const adjustments = res.payload as ISalaryAdjustment[];
      setDetails(adjustments);
    });
  }, [isModalOpen]);

  const [data, columns] = useGetSalaryAdjustmentsModalTable({
    adjustments: details,
    setAdjustments: setDetails,
    refreshSalaryByRole,
    driverId,
    date: formattedDateRange,
    isFinal: false,
  });

  const resetHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal width={640} title={t('title')} cancelButtonProps={{ hidden: true }} open={isModalOpen} destroyOnClose onOk={resetHandler} onCancel={resetHandler}>
      <Table loading={isLoading} dataSource={data} columns={columns} pagination={false} />
    </Modal>
  );
};

export default DetailsModal;
