import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Col } from 'antd';

import { FieldBlock, Label, Title, Value } from 'modules/requests/pages/Details/styled';
import { IManagerRequest } from 'modules/requests/types';

import { StyledRow } from './styled';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.manager' });
  const { selectedRequest } = useAppSelector(state => state.requests) as { selectedRequest: IManagerRequest };

  return (
    <StyledRow gutter={[150, 0]}>
      <Col>
        <Title>{t('contactInfo')}</Title>
        <FieldBlock>
          <Label>{t('username')}</Label>
          <Value>
            {selectedRequest?.userInfo.firstName} {selectedRequest?.userInfo.lastName}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('role')}</Label>
          <Value>{t('fleetManager')}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('email')}</Label>
          <Value>
            <a href={`mailto:${selectedRequest?.userInfo.email}`}>{selectedRequest?.userInfo.email}</a>
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('phone')}</Label>
          <Value>{selectedRequest?.userInfo.phone}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('postalCode')}</Label>
          <Value>{selectedRequest?.addressInfo.postalCode}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('city')}</Label>
          <Value>{selectedRequest?.addressInfo.city}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('street')}</Label>
          <Value>{selectedRequest?.addressInfo.street}</Value>
        </FieldBlock>
      </Col>

      <Col>
        <Title>{t('documents')}</Title>
        <FieldBlock>
          <Label>{t('socialSecurityNumber')}</Label>
          <Value>{selectedRequest?.socialSecurityNumber}</Value>
        </FieldBlock>
        {selectedRequest?.swishNumber && (
          <FieldBlock>
            <Label>{t('swishNumber')}</Label>
            <Value>{selectedRequest?.swishNumber}</Value>
          </FieldBlock>
        )}
      </Col>

      <Col>
        <Title>{t('companyAccount')}</Title>
        <FieldBlock>
          <Label>{t('registrationNumber')}</Label>
          <Value>{selectedRequest?.companyInfo?.companyRegistrationNumber}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('companyName')}</Label>
          <Value>{selectedRequest?.companyInfo.companyName}</Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('address')}</Label>
          <Value>
            {selectedRequest?.companyInfo.companyAddress?.street}, {selectedRequest?.companyInfo.companyAddress?.city},{' '}
            {selectedRequest?.companyInfo.companyAddress?.postalCode}
          </Value>
        </FieldBlock>
        <FieldBlock>
          <Label>{t('redovisnigscentral')}</Label>
          <Value>{selectedRequest && selectedRequest.accountingCenter}</Value>
        </FieldBlock>
      </Col>
    </StyledRow>
  );
};

export default Info;
