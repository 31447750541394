import { FC, Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Table, TableProps } from 'antd';

import { config } from 'config';
import { Roles, SalaryStatus } from 'constants/global';
import { Wrapper } from 'modules/common/components/TableWrapper/styled';
import { setSelectedPayoutsIds } from 'modules/salaries/slice';
import { useSearchParams } from 'utils/searchParams';

import { useWeeklySalaryTable } from '../../hooks/useWeeklySalaryTable';
import { IWeeklySalaryTableData } from '../../types/IWeeklySalaryTableData';

type PropTypes = {
  nameFilter: string[];
  swishStatusFilter: string[];
  roleFilter: string[];
  statusFilter: string[];
  periodFilter: string[];
  page: number;
  pageSize: number;
  changePageHandler: (currentPage: number, pageSize: number) => void;
  changeableParams: (string | number | null | Date)[];
  refetchSalary: () => void;
  markAsPaid: (paymentsIds: Key[]) => Promise<void>;
};

const WeeklyTable: FC<PropTypes> = ({
  pageSize,
  page,
  statusFilter,
  roleFilter,
  swishStatusFilter,
  nameFilter,
  periodFilter,
  changePageHandler,
  changeableParams,
  refetchSalary,
  markAsPaid,
}) => {
  const {
    salary,
    isLoading,
    payoutModal: { selectedPayoutsIds },
  } = useAppSelector(state => state.salaries);

  const { addSearchParams } = useSearchParams();
  const navigate = useNavigate();
  const [hoveredSalaryId, setHoveredSalaryId] = useState<number>();
  const dispatch = useAppDispatch();

  const onTableChange: TableProps<IWeeklySalaryTableData>['onChange'] = ({ current, pageSize }, filters) => {
    addSearchParams({
      page: current?.toString() || '1',
      pageSize: pageSize?.toString() || config.DEFAULT_PAGE_SIZE.toString(),
      roleFilter: JSON.stringify(filters?.role || []),
      statusFilter: JSON.stringify(filters?.status || []),
      swishStatusFilter: JSON.stringify(filters?.swishStatus || []),
      periodFilter: JSON.stringify(filters?.period || []),
      nameFilter: JSON.stringify(filters?.name || []),
    });
  };

  useEffect(() => {
    refetchSalary();
  }, changeableParams);

  const [weeklyData, weeklyColumns] = useWeeklySalaryTable({
    salary: salary?.items,
    statusFilter,
    roleFilter,
    swishStatusFilter,
    nameFilter,
    periodFilter,
    hoveredSalaryId,
    markAsPaid,
  });

  const onSelect = (_Ids: Key[], selectedRows: IWeeklySalaryTableData[]) => {
    const filteredRows = selectedRows.filter(row => row.role !== Roles.MANAGER && row.status !== SalaryStatus.PAID);

    const ids = filteredRows.map(row => row.salaryId);

    if (JSON.stringify(selectedPayoutsIds) === JSON.stringify(ids)) {
      dispatch(setSelectedPayoutsIds([]));
      return;
    }

    dispatch(setSelectedPayoutsIds(ids));
  };

  return (
    <Wrapper>
      <Table
        onChange={onTableChange}
        rowSelection={{
          selectedRowKeys: selectedPayoutsIds,
          onChange: onSelect,
          renderCell: (_checked, record, _index, ReactNode) => (record.role === Roles.MANAGER || record.status === SalaryStatus.PAID ? null : ReactNode),
        }}
        pagination={{
          showSizeChanger: true,
          current: page,
          onChange: changePageHandler,
          total: salary?.total?.total,
          pageSize,
        }}
        onRow={record => ({
          onClick: () => navigate(`/salary/details?id=${record.userId}&role=${record.role}&endDate=${record.endDate}`),
          onMouseEnter: () => setHoveredSalaryId(record.salaryId),
          onMouseLeave: () => setHoveredSalaryId(undefined),
        })}
        loading={isLoading}
        dataSource={weeklyData}
        columns={weeklyColumns}
      />
    </Wrapper>
  );
};

export default WeeklyTable;
