import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
`;

export const StatusMessageIcon = styled(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.warning};
`;
