import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';

import { Button, Form, Radio } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { Statuses } from 'constants/global';
import DatePicker from 'modules/common/components/DatePicker';
import Loader from 'modules/common/components/Loader';
import Cars from 'modules/manager/components/Cars';
import Information from 'modules/manager/components/Information';
import Reports from 'modules/manager/components/Reports';
import { IManager } from 'modules/manager/types';
import { clearSelectedUser, editUser, getManagerById } from 'modules/user';
import ActivateUserModal from 'modules/user/components/ActivateUserModal';
import DisableUserModal from 'modules/user/components/DisableUserModal';
import useDebounce from 'utils/debounce';

import { Content, Details, Footer, Header, OptionsWrapper, StyledSearch, Title } from './styled';

// TODO: HIDDEN FOR v1.0
// import CreateNotificationModal from '../../components/CreateNotificationModal';

enum Tabs {
  REPORTS = 'reports',
  INFORMATION = 'information',
  CARS = 'cars',
}

const ManagerDetails: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const { isAdmin } = useAppSelector(state => state.auth);
  const [SearchParams] = useSearchParams();
  const [tab, setTab] = useState<Tabs>(Tabs.INFORMATION);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { selectedUser } = useAppSelector(state => state.users) as { selectedUser: IManager };
  const id = useMemo(() => SearchParams.get('id'), []);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [managerData, setManagerData] = useState<IManager>(selectedUser);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
  // TODO: HIDDEN FOR v1.0
  // const [isCreateNotificationModalOpen, setIsCreateNotificationModalOpen] = useState<boolean>(false);

  // Set driverData from selectedUser
  useEffect(() => {
    if (selectedUser) setManagerData(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (id) dispatch(getManagerById({ id }));

    return () => {
      dispatch(clearSelectedUser());
    };
  }, []);

  const renderHandler = () => {
    switch (tab) {
      case Tabs.REPORTS:
        return <Reports dateRange={dateRange} />;
      case Tabs.INFORMATION:
        return <Information isEdit={isEdit} managerData={managerData} setManagerData={setManagerData} form={form} />;
      case Tabs.CARS:
        return <Cars search={debouncedSearch} />;

      default:
        break;
    }
  };

  const editInfoHandler = async () => {
    await form.validateFields();
    await dispatch(editUser({ data: managerData, isAdmin }));
    setIsEdit(false);
  };

  if (!selectedUser) return <Loader size='large' />;

  return (
    <>
      <Details>
        <Header>
          <Title>
            {selectedUser.user.firstName} {selectedUser.user.lastName}
          </Title>

          <OptionsWrapper>
            <Radio.Group value={tab} size='large' onChange={({ target }) => setTab(target.value)}>
              <Radio.Button value={Tabs.REPORTS}>{t('weeklyReports')}</Radio.Button>
              <Radio.Button value={Tabs.INFORMATION}>{t('contactInfo')}</Radio.Button>
              <Radio.Button value={Tabs.CARS}>
                {t('cars')} ({selectedUser.cars.length})
              </Radio.Button>
            </Radio.Group>

            {tab === Tabs.CARS && (
              <StyledSearch size='large' prefix={<SearchOutlined />} placeholder={t('searchPlaceholder')} onChange={({ target }) => setSearch(target.value)} />
            )}

            {tab === Tabs.REPORTS && <DatePicker.RangePicker size='large' onChange={date => setDateRange(date as [Date, Date])} value={dateRange} />}
          </OptionsWrapper>
        </Header>

        <Content>{renderHandler()}</Content>

        <Footer>
          {isEdit ? (
            <>
              <Button onClick={() => setIsEdit(false)}>{t('cancel')}</Button>
              <Button type='primary' onClick={editInfoHandler}>
                {t('save')}
              </Button>
            </>
          ) : selectedUser.status !== Statuses.DISABLED ? (
            <>
              {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
              {/* // TODO: HIDDEN FOR v1.0 */}
              {/* <Button onClick={() => setIsCreateNotificationModalOpen(true)}>{t('createNotification')}</Button> */}
              <Button type='primary' danger onClick={() => setIsDisableModalOpen(true)}>
                {t('disable')}
              </Button>
            </>
          ) : (
            <>
              {tab === Tabs.INFORMATION && <Button onClick={() => setIsEdit(true)}>{t('edit')}</Button>}
              <Button type='primary' onClick={() => setIsActivateModalOpen(true)}>
                {t('activate')}
              </Button>
            </>
          )}
        </Footer>
      </Details>

      <DisableUserModal isModalOpen={isDisableModalOpen} setIsModalOpen={setIsDisableModalOpen} statusFilter={selectedUser.status} />
      <ActivateUserModal statusFilter={selectedUser.status} isModalOpen={isActivateModalOpen} setIsModalOpen={setIsActivateModalOpen} />
      {/* // TODO: HIDDEN FOR v1.0 */}
      {/* <CreateNotificationModal isModalOpen={isCreateNotificationModalOpen} setIsModalOpen={setIsCreateNotificationModalOpen} /> */}
    </>
  );
};

export default ManagerDetails;
