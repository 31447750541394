import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { getSwishStatuses } from '../thunks';

export const getSwishStatusesReducer: ModuleExtraReducer<ISalaryState> = builder => {
  // Get swish statuses

  builder
    .addCase(getSwishStatuses.fulfilled, (state, action) => {
      state.swishStatuses = action.payload;
      state.isLoading = false;
    })
    .addCase(getSwishStatuses.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getSwishStatuses.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
