import { IAuthState } from 'modules/auth/types';
import { ModuleExtraReducer } from 'types/Module';

import { getOptions } from '../thunks';

export const getOptionsReducer: ModuleExtraReducer<IAuthState> = builder => {
  // Get Select Options

  builder
    .addCase(getOptions.fulfilled, (state, action) => {
      state.options = action.payload;
      state.isLoading = false;
    })
    .addCase(getOptions.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getOptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
