import { TableColumnsType } from 'antd';

import { IDriverWeeklyReportTableData } from 'modules/driver/types';
import { IDriverWeeklyReport } from 'modules/reports/types';

import { generateDriverWeeklyReportsColumns } from './generateDriverWeeklyReportsColumns';
import { mapDriverWeeklyReports } from './mapDriverWeeklyReports';

interface IProps {
  weeklyReports: IDriverWeeklyReport[];
}

type ReturnType = [IDriverWeeklyReportTableData[], TableColumnsType<IDriverWeeklyReportTableData>];

export const useDriverWeeklyReportsTable = (props: IProps): ReturnType => {
  const { weeklyReports } = props;
  const mappedReports = mapDriverWeeklyReports(weeklyReports);
  const tableColumns = generateDriverWeeklyReportsColumns();

  return [mappedReports, tableColumns];
};
