import { PayoutStatus } from 'modules/salaries/enums';
import { ISalaryState } from 'modules/salaries/types';
import { ModuleExtraReducer } from 'types/Module';

import { payMonthly } from '../thunks';

export const payMonthlyReducer: ModuleExtraReducer<ISalaryState> = builder => {
  builder
    .addCase(payMonthly.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { errors } = payload;
      const hasErrors = !!errors.length;
      state.payoutModal.payoutResult = payload;
      state.payoutModal.payoutStatus = hasErrors ? PayoutStatus.PayedWithErrors : PayoutStatus.Payed;
    })
    .addCase(payMonthly.pending, state => {
      state.isLoading = true;
      state.payoutModal.payoutResult = null;
      state.payoutModal.payoutStatus = PayoutStatus.NotPayed;
    })
    .addCase(payMonthly.rejected, state => {
      state.isLoading = false;
    });
};
