import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { TableColumnsType, Tag } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { config } from 'config';
import { ReimbursementStatusEnum } from 'constants/global';
import { format } from 'date-fns';
import { DateWrapper, Time } from 'modules/analytics/components/UploadReportsModal/styled';
import DatePicker from 'modules/common/components/DatePicker';
import PayoutCell from 'modules/reimbursements/components/PayoutCell';
import PhotoWithSpin from 'modules/reimbursements/components/PhotoWithSpin';
import { IReimbursement } from 'modules/reimbursements/types';
import { PayoutCellWrapper } from 'modules/salaries/pages/Salary/styled';
import { RangeValue } from 'rc-picker/lib/interface';
import { currencyServices } from 'services/currencyServices';
import { useSearchParams } from 'utils/searchParams';

interface IProps {
  hoveredId: number | undefined;
  dateRangeFilterValue: RangeValue<string>;
  driverFilterValue: number[];
  statusFilterValue: string[];
  typeFilterValue: string[];
  fetchReimbursements: () => void;
}

const reimbursementColorsByStatuses: { [key in ReimbursementStatusEnum]: PresetColorType } = {
  [ReimbursementStatusEnum.PENDING]: 'yellow',
  [ReimbursementStatusEnum.DECLINED]: 'red',
  [ReimbursementStatusEnum.RECEIVED]: 'green',
};

const localeByStatus = {
  [ReimbursementStatusEnum.PENDING]: 'notPaid',
  [ReimbursementStatusEnum.RECEIVED]: 'paid',
  [ReimbursementStatusEnum.DECLINED]: 'declined',
};

export const generateManagerReimbursementsColumns = (props: IProps): TableColumnsType<IReimbursement> => {
  const { hoveredId, dateRangeFilterValue, driverFilterValue, statusFilterValue, typeFilterValue, fetchReimbursements } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const { drivers, dateRange, reimbursementTypes } = useAppSelector(state => state.reimbursements);
  const { addSearchParams } = useSearchParams();

  const driverOptions: ColumnFilterItem[] = drivers?.map(driver => ({ text: `${driver.firstName} ${driver.lastName}`, value: driver.id })) || [];

  const [startDate, endDate] = dateRange || [new Date(), new Date()];

  const disableDate = (date: Date) => {
    if (date < new Date(startDate)) return true;
    if (date > new Date(endDate)) return true;
    return false;
  };

  const onDateChange = (dates: RangeValue<Date>) => {
    addSearchParams({ dateRange: JSON.stringify(dates) });
  };

  const filterRange: RangeValue<Date> =
    dateRangeFilterValue?.[0] && dateRangeFilterValue?.[1] ? [new Date(dateRangeFilterValue[0]), new Date(dateRangeFilterValue[1])] : null;

  const statusFilterOptions: ColumnFilterItem[] = [
    {
      text: t('managerStatuses.notPaid'),
      value: ReimbursementStatusEnum.PENDING,
    },
    {
      text: t('managerStatuses.paid'),
      value: ReimbursementStatusEnum.RECEIVED,
    },
    {
      text: t('managerStatuses.declined'),
      value: ReimbursementStatusEnum.DECLINED,
    },
  ];

  const reimbursementsTypesFilterOptions: ColumnFilterItem[] =
    reimbursementTypes?.map(type => ({ text: t(`reimbursementTypes.${type.value}`), value: type.value })) || [];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      filteredValue: [],
      filtered: !!filterRange,
      filterDropdown: () => <DatePicker.RangePicker value={filterRange} onChange={onDateChange} disabledDate={disableDate} />,
      render: (_, record) => (
        <DateWrapper>
          <p>{format(new Date(record.createdAt), config.DATE_FORMAT_DAY_MONTH)}</p>
          <Time>{format(new Date(record.createdAt), config.TIME_FORMAT)}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'driver',
      key: 'driver',
      fixed: 'left',
      filterSearch: true,
      filteredValue: driverFilterValue,
      filters: driverOptions,
      render: (_, record) => record.driverName,
    },
    {
      title: t('car'),
      dataIndex: 'car',
      key: 'car',
      render: (_, record) => record.carRegistrationPlate,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filteredValue: statusFilterValue,
      filters: statusFilterOptions,
      render: (_, record) => <Tag color={reimbursementColorsByStatuses[record.status]}>{t(`managerStatuses.${localeByStatus[record.status]}`)}</Tag>,
    },

    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => currencyServices.convertToCurrency(record.amount),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      filteredValue: typeFilterValue,
      filters: reimbursementsTypesFilterOptions,
      render: (_, record) => t(`reimbursementTypes.${record.reimbursementType}`),
    },
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      render: (_, record) => <PhotoWithSpin photoName={record.photoName} reimbursementId={record.id} />,
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => record.comment,
    },
    {
      key: 'actions',
      width: 125,
      fixed: 'right',
      render: (_, record) => (
        <PayoutCellWrapper>{record.id === hoveredId && <PayoutCell reimbursement={record} fetchReimbursements={fetchReimbursements} />}</PayoutCellWrapper>
      ),
    },
  ];
};
