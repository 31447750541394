import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Tabs } from 'antd';

import { Column } from '@ant-design/plots';
import { config } from 'config';
import { IAveragePayment } from 'modules/analytics/types';
import styled from 'styled-components';
import { renderDateRange } from 'utils/date';

import ChartCard from '../ChartCard';

const StyledTabs = styled(Tabs)`
  margin-bottom: 40px;
  .ant-tabs-tab {
    padding-bottom: 24px;
  }

  .ant-tabs-tab-active {
    position: relative;

    ::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0px;
      background-color: #7b2cbf;
    }
  }
`;

enum HeaderTabs {
  EFFICIENCY = 'averageEfficiency',
  EMPLOYMENT = 'averageEmploymentGrade',
  FULFILLMENT = 'averageFulfillment',
  PROVISION = 'averageDriverProvision',
}

const renderChartItem = (item: IAveragePayment) => ({
  Date: renderDateRange({ timeRange: item.timeRangeLabel, formatOfDate: config.DATE_FORMAT_WITH_WEEKDAY_NAME }),
  Value: item.value ? Math.round(item.value) : 0,
});

const AverageChart: FC = () => {
  const [tab, setTab] = useState<string>(HeaderTabs.EFFICIENCY);
  const { report } = useAppSelector(state => state.analytics);
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.averageChart' });

  const dataHandler = useMemo(() => {
    if (report) {
      switch (tab) {
        case HeaderTabs.EFFICIENCY:
          return report.efficiency?.map(renderChartItem);
        case HeaderTabs.EMPLOYMENT:
          return report.employmentGrade?.map(renderChartItem);
        case HeaderTabs.FULFILLMENT:
          return report.fulfillment?.map(renderChartItem);
        default: // PROVISION
          return report.driverProvision?.map(renderChartItem);
      }
    }
  }, [report, tab]);

  return (
    <ChartCard>
      <>
        <StyledTabs
          items={[
            { key: HeaderTabs.EFFICIENCY, label: t(HeaderTabs.EFFICIENCY) },
            ...(report?.employmentGrade ? [{ key: HeaderTabs.EMPLOYMENT, label: t(HeaderTabs.EMPLOYMENT) }] : []),
            { key: HeaderTabs.FULFILLMENT, label: t(HeaderTabs.FULFILLMENT) },
            { key: HeaderTabs.PROVISION, label: t(HeaderTabs.PROVISION) },
          ]}
          activeKey={tab}
          onChange={key => setTab(key)}
        />
        <Column
          data={dataHandler || []}
          xField='Date'
          yField='Value'
          color='#7B2CBF'
          meta={{
            Value: {
              alias: t('value'),
            },
          }}
          maxColumnWidth={100}
        />
      </>
    </ChartCard>
  );
};

export default AverageChart;
