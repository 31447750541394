import { FC, Key, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Modal } from 'antd';

import { payReimbursement } from 'modules/reimbursements';
import { IPayoutResult } from 'modules/salaries/types';

import FailedPayout from './components/FailedPayout';
import PayoutInfo from './components/PayoutInfo';
import SuccessfulPayout from './components/SuccessfulPayout';
import { PayoutStatusEnum } from './enums';

type PropsType = {
  isOpen: boolean;
  selectedIds: Key[];
  closePayoutModal: () => void;
  fetchReimbursements: () => void;
};

const PayoutModal: FC<PropsType> = ({ isOpen, selectedIds, closePayoutModal, fetchReimbursements }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.payModal' });
  const [payoutStatus, setPayoutStatus] = useState<PayoutStatusEnum>(PayoutStatusEnum.NotPayed);
  const [payoutResult, setPayoutResult] = useState<IPayoutResult | null>(null);
  const { isLoading } = useAppSelector(store => store.reimbursements);
  const dispatch = useAppDispatch();

  const payWithSwish = async () => {
    await dispatch(
      payReimbursement({
        paymentIds: selectedIds,
      })
    );

    const { payReimbursementResult } = store.getState().reimbursements;

    if ((payReimbursementResult?.errors?.length || 0) > 0) {
      setPayoutResult(payReimbursementResult);
      return setPayoutStatus(PayoutStatusEnum.PayedWithErrors);
    }

    fetchReimbursements();
    setPayoutStatus(PayoutStatusEnum.Payed);
  };

  const ContentByPayoutType = useMemo(
    () => ({
      [PayoutStatusEnum.NotPayed]: <PayoutInfo selectedIds={selectedIds} />,
      [PayoutStatusEnum.Payed]: <SuccessfulPayout closePayoutModal={closePayoutModal} />,
      [PayoutStatusEnum.PayedWithErrors]: <FailedPayout payoutResult={payoutResult} closePayoutModal={closePayoutModal} />,
    }),
    [payoutResult]
  );

  return (
    <Modal
      width={600}
      title={payoutStatus === PayoutStatusEnum.NotPayed ? t('payment') : false}
      onOk={payWithSwish}
      okText={t('submit')}
      closable={false}
      open={isOpen}
      onCancel={closePayoutModal}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading }}
      {...(payoutStatus === PayoutStatusEnum.NotPayed ? {} : { footer: null })}
    >
      {ContentByPayoutType[payoutStatus]}
    </Modal>
  );
};

export default PayoutModal;
