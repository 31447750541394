import { Col, Row } from 'antd';

import { CloseCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const WarningIcon = styled(ExclamationCircleOutlined)`
  font-size: 100px;
  color: ${({ theme }) => theme.warning};
`;

export const ErrorIcon = styled(CloseCircleOutlined)`
  font-size: 100px;
  color: ${({ theme }) => theme.dangerous};
`;

export const StyledColumn = styled(Col)`
  text-align: center;
`;

export const ErrorsBlock = styled(Row)`
  text-align: start;
  height: 300px;
  padding: 10px;
`;

export const ErrorBlockContainer = styled.div`
  background-color: ${({ theme }) => theme.lightGray};
  overflow: scroll;
  border-radius: 2px;
  box-shadow: inset 0 0 3px ${({ theme }) => theme.gray};
`;
