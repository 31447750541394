import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Modal } from 'antd';

import { PaymentTypeEnum, PayoutStatus } from 'modules/salaries/enums';
import { payMonthly, payWeekly } from 'modules/salaries/slice/thunks';

import FailedPayout from './FailedPayout';
import { MarkAsPaidError } from './MarkAsPaidError';
import PayoutInfo from './PayoutInfo';
import SuccessfulPayout from './SuccessfulPayout';

type PropsType = {
  paymentType: PaymentTypeEnum;
  closeModal: () => void;
};

const PayoutModal: FC<PropsType> = ({ paymentType, closeModal }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });
  const { isLoading, payoutModal } = useAppSelector(store => store.salaries);
  const { isOpen, payoutStatus, selectedPayoutsIds } = payoutModal;
  const dispatch = useAppDispatch();
  const isWeeklyPayment = paymentType === PaymentTypeEnum.Weekly;
  const payoutInfoTitle = isWeeklyPayment ? t('weeklyPayment') : t('monthlyPayment');

  const payWithSwish = async (): Promise<void> => {
    if (isWeeklyPayment) {
      await dispatch(payWeekly(selectedPayoutsIds));
    } else {
      await dispatch(payMonthly(selectedPayoutsIds));
    }
  };

  const paymentActions = {
    [PayoutStatus.NotPayed]: async () => {
      await payWithSwish();
    },

    [PayoutStatus.Payed]: closeModal,
    [PayoutStatus.PayedWithErrors]: closeModal,
    [PayoutStatus.MarkAsPaidError]: closeModal,
  };

  const modalContentByPayoutStatus = {
    [PayoutStatus.NotPayed]: <PayoutInfo paymentType={paymentType} />,
    [PayoutStatus.Payed]: <SuccessfulPayout closeModal={closeModal} />,
    [PayoutStatus.PayedWithErrors]: <FailedPayout closeModal={closeModal} />,
    [PayoutStatus.MarkAsPaidError]: <MarkAsPaidError closeModal={closeModal} paymentType={paymentType} />,
  };

  return (
    <Modal
      key={Math.random()}
      width={600}
      title={payoutStatus === PayoutStatus.NotPayed ? payoutInfoTitle : false}
      onOk={paymentActions[payoutStatus]}
      okText={t('submit')}
      closable={false}
      open={isOpen && !isLoading}
      onCancel={closeModal}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading }}
      {...(payoutStatus === PayoutStatus.NotPayed ? {} : { footer: null })}
    >
      {modalContentByPayoutStatus[payoutStatus]}
    </Modal>
  );
};

export default PayoutModal;
