import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IGetSalaryById } from 'modules/salaries/types';
import { IDriverSalary } from 'modules/salaries/types/IDriverSalary';
import { convertIntoISODate } from 'utils/index';

// Get Driver salary
export const getDriverSalary = createAsyncThunk(THUNKS.GET_DRIVER_SALARY, async ({ date, id }: IGetSalaryById, thunkApi) => {
  try {
    const data = await api.private
      .get<{ data: IDriverSalary }>(
        `${Endpoints.SALARY_DRIVER}?driverId=${id}&start=${convertIntoISODate(date[0] as Date)}&end=${convertIntoISODate(date[1] as Date)}`
      )
      .then(res => res.data.data);

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
