import { FC, useMemo } from 'react';

import { Badge } from 'antd';

import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';

import { ICarsGroup } from '../../types';
import { ReactComponent as CarIcon } from './assets/car.svg';
import { ReactComponent as SelectedCarIcon } from './assets/selected-car.svg';

interface IProps {
  carsGroup: ICarsGroup;
  selectedCar: ILinkCarTableData | null;
  highlightedCars: number[];
}

const CarWithBadges: FC<IProps> = ({ carsGroup, selectedCar, highlightedCars }) => {
  const Icon = useMemo(() => {
    if (
      (selectedCar && (carsGroup.carIDs.includes(selectedCar.id) || highlightedCars.includes(selectedCar.id))) ||
      highlightedCars.some(carId => carsGroup.carIDs.some(id => id === carId))
    )
      return SelectedCarIcon;

    return CarIcon;
  }, [highlightedCars, carsGroup, selectedCar]);

  return (
    <Badge count={carsGroup.unlinked} color='black' size='small' offset={[-23, 24]}>
      <Badge count={carsGroup.linked} color='#0DAB6C' size='small' offset={[-23, 36]}>
        <Icon />
      </Badge>
    </Badge>
  );
};

export default CarWithBadges;
