import { IReimbursementsState } from 'modules/reimbursements/types';
import { ModuleExtraReducer } from 'types/Module';

import { getReimbursementPhoto } from '../thunks';

// TODO: check if isLoading state mutation needed here.

export const getReimbursementPhotoReducer: ModuleExtraReducer<IReimbursementsState> = builder => {
  builder
    .addCase(getReimbursementPhoto.fulfilled, (state, action) => {
      state.photos[action.payload.id] = { isLoading: false, photo: action.payload.photo };
    })
    .addCase(getReimbursementPhoto.pending, (state, action) => {
      state.photos[action.meta.arg.reimbursementId] = { isLoading: true, photo: '' };
      state.error = null;
    })
    .addCase(getReimbursementPhoto.rejected, (state, action) => {
      state.error = action.payload as string;
    });
};
