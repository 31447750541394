import { Dispatch, SetStateAction, useMemo } from 'react';

import { TableColumnsType } from 'antd';

import { endOfMonth, startOfMonth } from 'date-fns';
import { IGetSalaryAdjustments, IGetSalaryAdjustmentsTableData, ISalaryAdjustment } from 'modules/salaries/types';
import { useSearchParams } from 'utils/searchParams';

import { generateGetSalaryAdjustmentsModalColumns } from './generateGetSalaryAdjustmentsModalColumns';
import { mapSalaryAdjustments } from './mapSalaryAdjustments';

interface IProps extends IGetSalaryAdjustments {
  adjustments: ISalaryAdjustment[];
  setAdjustments: Dispatch<SetStateAction<ISalaryAdjustment[]>>;
  refreshSalaryByRole: (dateRange: [Date, Date]) => void;
  isFinal: boolean;
}

type ReturnType = [IGetSalaryAdjustmentsTableData[], TableColumnsType<IGetSalaryAdjustmentsTableData>];

export const useGetSalaryAdjustmentsModalTable = (props: IProps): ReturnType => {
  const { getSearchParam } = useSearchParams();
  const endDate = getSearchParam('endDate');

  const dateRange = useMemo<[Date, Date]>(
    () => [startOfMonth(endDate ? new Date(endDate) : new Date()), endOfMonth(endDate ? new Date(endDate) : new Date())],
    [endDate]
  );

  const { adjustments, isFinal, setAdjustments, refreshSalaryByRole, date, driverId } = props;
  const tableData = mapSalaryAdjustments(adjustments, isFinal);
  const tableColumns = generateGetSalaryAdjustmentsModalColumns({ setAdjustments, refreshSalaryByRole, isFinal, driverId, date, dateRange });

  return [tableData, tableColumns];
};
