import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch, useAppSelector } from 'store';

import { Col, notification,Row, Tooltip } from 'antd';

import { CheckOutlined, CloseOutlined, DollarCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { ReimbursementStatusEnum } from 'constants/global';
import { payReimbursement, updateReimbursementStatus } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';

import DeclineModal from '../DeclineModal';
import DisputeModal from '../DisputeModal';
import { StyledButton } from './styled';

interface IPayoutCellProps {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
}

const PayoutCell: FC<IPayoutCellProps> = ({ reimbursement, fetchReimbursements }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAdmin } = useAppSelector(state => state.auth);

  const markAsPaid = async () => {
    await dispatch(
      updateReimbursementStatus({
        reimbursementIds: [reimbursement.id],
        status: ReimbursementStatusEnum.RECEIVED,
      })
    );

    const { error } = store.getState().cars;

    if (!error) {
      notification.success({
        placement: 'topRight',
        message: t('success'),
      });
    }

    fetchReimbursements();
  };

  const payWithSwish = async () => {
    await dispatch(
      payReimbursement({
        paymentIds: [reimbursement.id],
      })
    );

    const errors = store.getState().reimbursements.payReimbursementResult?.errors;

    if ((errors?.length || 0) > 0) {
      notification.error({
        message: t('error'),
        description: errors?.[0]?.errors?.join('; '),
      });
    } else {
      notification.success({
        placement: 'topRight',
        message: t('success'),
      });
    }

    fetchReimbursements();
  };

  const openModalClick = () => {
    setIsModalOpen(true);
  };

  const Modal = useMemo(() => (isAdmin ? DeclineModal : DisputeModal), [isAdmin]);

  return (
    <>
      <Row hidden={reimbursement.status !== (isAdmin ? ReimbursementStatusEnum.PENDING : ReimbursementStatusEnum.RECEIVED)} justify='space-around'>
        {isAdmin ? (
          <>
            <Col span={8}>
              <Tooltip title={t('payWithSwish')}>
                <StyledButton onClick={payWithSwish} type='text' icon={<DollarCircleOutlined />} />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title={t('markAsPaid')}>
                <StyledButton onClick={markAsPaid} type='text' icon={<CheckOutlined />} />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title={t('decline')}>
                <StyledButton onClick={openModalClick} type='text' icon={<CloseOutlined />} />
              </Tooltip>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <Tooltip title={t('dispute')}>
              <StyledButton onClick={openModalClick} type='text' icon={<WarningOutlined />} />
            </Tooltip>
          </Col>
        )}
      </Row>
      <Modal reimbursement={reimbursement} fetchReimbursements={fetchReimbursements} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};

export default PayoutCell;
