import { Dispatch, FC, SetStateAction } from 'react';

import { FormInstance } from 'antd';

import { IDriver } from 'modules/driver/types';

import Edit from './components/Edit';
import Info from './components/Info';

interface IProps {
  isEdit: boolean;
  driverData: IDriver;
  setDriverData: Dispatch<SetStateAction<IDriver>>;
  form: FormInstance;
}

const Information: FC<IProps> = ({ isEdit, driverData, setDriverData, form }) => {
  if (isEdit) return <Edit driverData={driverData} setDriverData={setDriverData} form={form} />;
  return <Info />;
};

export default Information;
