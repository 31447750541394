import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/index';

import { TableColumnsType, Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { config } from 'config';
import { ReimbursementStatusEnum, SwishStatusEnum } from 'constants/global';
import { format } from 'date-fns';
import { DateWrapper, Time } from 'modules/analytics/components/UploadReportsModal/styled';
import { DatePicker } from 'modules/common/components';
import { PayoutCellWrapper, StatusMessageIcon } from 'modules/salaries/pages/Salary/styled';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSearchParams } from 'utils/searchParams';

import AmountCell from '../../components/AmountCell';
import CarCell from '../../components/CarCell';
import PayoutCell from '../../components/PayoutCell';
import PhotoWithSpin from '../../components/PhotoWithSpin';
import VatCell from '../../components/VatCell';
import { IReimbursementTableData } from '../../types/IReimbursementTableData';

interface IProps {
  hoveredId: number | undefined;
  dateRangeFilterValue: RangeValue<string>;
  managerFilterValue: number[];
  driverFilterValue: number[];
  statusFilterValue: string[];
  swishStatusFilterValue: string[];
  typeFilterValue: string[];
  fetchReimbursements: () => void;
}

const swishColorsByStatuses: { [key in SwishStatusEnum]: PresetColorType } = {
  [SwishStatusEnum.CREATED]: 'blue',
  [SwishStatusEnum.DEBITED]: 'blue',
  [SwishStatusEnum.ERROR]: 'red',
  [SwishStatusEnum.INITIATED]: 'blue',
  [SwishStatusEnum.PAID]: 'green',
  [SwishStatusEnum.PLANNED]: 'blue',
};

const reimbursementColorsByStatuses: { [key in ReimbursementStatusEnum]: PresetColorType } = {
  [ReimbursementStatusEnum.PENDING]: 'yellow',
  [ReimbursementStatusEnum.DECLINED]: 'red',
  [ReimbursementStatusEnum.RECEIVED]: 'green',
};

const localeByStatus = {
  [ReimbursementStatusEnum.PENDING]: 'notPaid',
  [ReimbursementStatusEnum.RECEIVED]: 'paid',
  [ReimbursementStatusEnum.DECLINED]: 'declined',
};

export const generateReimbursementsColumns = (props: IProps): TableColumnsType<IReimbursementTableData> => {
  const {
    hoveredId,
    dateRangeFilterValue,
    managerFilterValue,
    driverFilterValue,
    statusFilterValue,
    swishStatusFilterValue,
    typeFilterValue,
    fetchReimbursements,
  } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const { drivers, managers, dateRange, reimbursementTypes } = useAppSelector(state => state.reimbursements);
  const { addSearchParams } = useSearchParams();

  const driverOptions: ColumnFilterItem[] = drivers?.map(driver => ({ text: `${driver.firstName} ${driver.lastName}`, value: driver.id })) || [];

  const managerOptions: ColumnFilterItem[] = managers?.map(manager => ({ text: `${manager.firstName} ${manager.lastName}`, value: manager.id })) || [];

  const [startDate, endDate] = dateRange || [new Date(), new Date()];

  const disableDate = (date: Date) => {
    if (date < new Date(startDate)) return true;
    if (date > new Date(endDate)) return true;
    return false;
  };

  const onDateChange = (dates: RangeValue<Date>) => {
    addSearchParams({ dateRange: JSON.stringify(dates) });
  };

  const filterRange: RangeValue<Date> =
    dateRangeFilterValue?.[0] && dateRangeFilterValue?.[1] ? [new Date(dateRangeFilterValue[0]), new Date(dateRangeFilterValue[1])] : null;

  const statusFilterOptions: ColumnFilterItem[] = [
    {
      text: t('notPaid'),
      value: ReimbursementStatusEnum.PENDING,
    },
    {
      text: t('paid'),
      value: ReimbursementStatusEnum.RECEIVED,
    },
    {
      text: t('declined'),
      value: ReimbursementStatusEnum.DECLINED,
    },
  ];

  const swishStatusFilterOptions: ColumnFilterItem[] = [
    {
      text: t('swishStatuses.created'),
      value: SwishStatusEnum.CREATED,
    },
    {
      text: t('swishStatuses.debited'),
      value: SwishStatusEnum.DEBITED,
    },
    {
      text: t('swishStatuses.error'),
      value: SwishStatusEnum.ERROR,
    },
    {
      text: t('swishStatuses.initiated'),
      value: SwishStatusEnum.INITIATED,
    },
    {
      text: t('swishStatuses.paid'),
      value: SwishStatusEnum.PAID,
    },
    {
      text: t('swishStatuses.planned'),
      value: SwishStatusEnum.PLANNED,
    },
  ];

  const reimbursementsTypesFilterOptions: ColumnFilterItem[] =
    reimbursementTypes?.map(type => ({ text: t(`reimbursementTypes.${type.value}`), value: type.value })) || [];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      filteredValue: [],
      filtered: !!filterRange,
      filterDropdown: () => <DatePicker.RangePicker value={filterRange} onChange={onDateChange} disabledDate={disableDate} />,
      render: (_, record) => (
        <DateWrapper>
          <p>{format(new Date(record.createdAt), config.DATE_FORMAT_DAY_MONTH)}</p>
          <Time>{format(new Date(record.createdAt), config.TIME_FORMAT)}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('driver'),
      dataIndex: 'driver',
      key: 'driver',
      fixed: 'left',
      filterSearch: true,
      filteredValue: driverFilterValue,
      filters: driverOptions,
      render: (_, record) => record.driverName,
    },
    {
      title: t('fleetManager'),
      dataIndex: 'fleetManager',
      key: 'fleetManager',
      filterSearch: true,
      filteredValue: managerFilterValue,
      filters: managerOptions,
      render: (_, record) => record.managerName,
    },
    {
      title: t('car'),
      dataIndex: 'car',
      key: 'car',
      render: (_, record) => <CarCell fetchReimbursements={fetchReimbursements} reimbursement={record} />,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filteredValue: statusFilterValue,
      filters: statusFilterOptions,
      render: (_, record) => <Tag color={reimbursementColorsByStatuses[record.status]}>{t(localeByStatus[record.status])}</Tag>,
    },
    {
      title: t('swishStatus'),
      dataIndex: 'swishStatus',
      key: 'swishStatus',
      filteredValue: swishStatusFilterValue,
      filters: swishStatusFilterOptions,
      render: (_, record) => {
        if (!record.payout) return ' ';

        return record.payout.errorMessage ? (
          <Tooltip title={record.payout.errorMessage}>
            <Tag color={swishColorsByStatuses[record.payout.status]}>{t(record.payout.status)}</Tag>
            <StatusMessageIcon />
          </Tooltip>
        ) : (
          <Tag color={swishColorsByStatuses[record.payout.status]}>{t(record.payout.status)}</Tag>
        );
      },
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => <AmountCell reimbursement={record} fetchReimbursements={fetchReimbursements} />,
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      filteredValue: typeFilterValue,
      filters: reimbursementsTypesFilterOptions,
      render: (_, record) => t(`reimbursementTypes.${record.reimbursementType}`),
    },
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      render: (_, record) => <PhotoWithSpin photoName={record.photoName} reimbursementId={record.id} />,
    },
    {
      title: t('vat'),
      dataIndex: 'vat',
      key: 'vat',
      render: (_, record) => <VatCell reimbursement={record} fetchReimbursements={fetchReimbursements} />,
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => record.comment,
    },
    {
      title: t('processedDate'),
      dataIndex: 'processedDate',
      key: 'processedDate',
      render: (_, record) =>
        record.processedAt ? (
          <DateWrapper>
            <p>{format(new Date(record.processedAt), config.DATE_FORMAT_DAY_MONTH)}</p>
            <Time>{format(new Date(record.processedAt), config.TIME_FORMAT)}</Time>
          </DateWrapper>
        ) : (
          '-'
        ),
    },
    {
      key: 'actions',
      width: 125,
      fixed: 'right',
      render: (_, record) => (
        <PayoutCellWrapper>{record.id === hoveredId && <PayoutCell reimbursement={record} fetchReimbursements={fetchReimbursements} />}</PayoutCellWrapper>
      ),
    },
  ];
};
