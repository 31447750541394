import { useSearchParams as useSearchParamsRRD } from 'react-router-dom';

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsRRD();

  const allParams = Object.fromEntries(searchParams);

  const getAllSearchParams = () => allParams;

  const addSearchParams = (params: { [key: string]: string }) => {
    setSearchParams({
      ...allParams,
      ...params,
    });
  };

  const getSearchParam = (paramName: string) => searchParams.get(paramName);

  return {
    getAllSearchParams,
    addSearchParams,
    getSearchParam,
    setSearchParams,
  };
};
