// Todo: remove eslint ignore
/* eslint-disable react/jsx-boolean-value */
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store';

import { Col, Form, FormInstance, Input, InputNumber, Radio, Row } from 'antd';

import { CarConstants } from 'constants/car';
import { getOptions } from 'modules/cars';
import { ICar } from 'modules/cars/types';
import DatePicker from 'modules/common/components/DatePicker';

import { StyledForm, StyledNumberInput, YearPicker } from './styled';

interface IProps {
  carData: ICar;
  setCarData: Dispatch<SetStateAction<ICar>>;
  form: FormInstance;
}

const fieldsHandler = (carData: ICar, address: string) => [
  {
    name: 'plate',
    value: carData.info.registrationPlate || null,
  },
  {
    name: 'make',
    value: carData.info.make || null,
  },
  {
    name: 'model',
    value: carData.info.model || null,
  },
  {
    name: 'year',
    value: carData.info.year || null,
  },
  {
    name: 'insuranceNumber',
    value: carData.info.insuranceAgreement || null,
  },
  {
    name: 'shiftPlan',
    value: carData.info.shiftPlan.id,
  },
  {
    name: 'splitTimes',
    value: carData.info.shiftSplitTimes.id,
  },
  {
    name: 'workingTime',
    value: new Date(carData.info.startTime),
  },
  {
    name: 'driverPercentage',
    value: carData.info.driverPercent || null,
  },
  {
    name: 'maximumMileage',
    value: carData.info.mileageMax,
  },
  {
    name: 'pickUpLocation',
    value: address,
  },
];

// TODO should be refactored with backend
const localeByValue: { [key: string]: string } = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};

const Edit: FC<IProps> = ({ carData, setCarData, form }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.requests.details.car.edit' });
  const dispatch = useAppDispatch();

  const {
    options: { shifts, shiftPlans },
  } = useAppSelector(state => state.cars);

  const { selectedCar } = useAppSelector(state => state.cars) as { selectedCar: ICar };

  const address = useMemo(
    () => `${carData?.info.address.street}, ${carData?.info.address.city}, ${carData?.info.address.postalCode}`,
    [carData?.info.address.street, carData?.info.address.city, carData?.info.address.postalCode]
  );

  const changeWorkScheduleHandler = (id: number) => {
    const shiftPlanById = shiftPlans.find(item => item.id === id);
    const shiftSplitTimesById = shifts.find(item => item.id === id);
    setCarData(prev => ({ ...prev, info: { ...prev.info, shiftPlan: shiftPlanById!, shiftSplitTimes: shiftSplitTimesById! }}));
  };

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(
    () => () => {
      setCarData(selectedCar);
    },
    [selectedCar]
  );

  return (
    <StyledForm
      form={form}
      fields={fieldsHandler(carData, address)}
      validateTrigger={['onChange', 'onBlur']}
      layout='vertical'
      requiredMark={false}
      size='large'
    >
      <Row justify='space-between' gutter={40}>
        <Col span={8}>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('plate')} name='plate' rules={[{ required: true, len: 6 }]}>
                  <Input
                    onChange={({ target }) => setCarData(prev => ({ ...prev, info: { ...prev.info, registrationPlate: target.value }}))}
                    placeholder={t('plate_placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('make')} name='make' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) => setCarData(prev => ({ ...prev, info: { ...prev.info, make: target.value }}))}
                    placeholder={t('make_placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row justify='space-between' gutter={16}>
              <Col span={12}>
                <Form.Item label={t('model')} name='model' rules={[{ required: true }]}>
                  <Input
                    onChange={({ target }) => setCarData(prev => ({ ...prev, info: { ...prev.info, model: target.value }}))}
                    placeholder={t('model_placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('year')} name='year' rules={[{ required: true }]}>
                  <YearPicker
                    min={CarConstants.MIN_YEAR}
                    max={new Date().getFullYear()}
                    onChange={value => setCarData(prev => ({ ...prev, info: { ...prev.info, year: Number(value) }}))}
                    placeholder={t('year_placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Form.Item label={t('insuranceNumber')} name='insuranceNumber' rules={[{ required: true }]}>
            <Input
              type='number'
              placeholder={t('insuranceNumber_placeholder')}
              onChange={({ target }) => setCarData(prev => ({ ...prev, info: { ...prev.info, insuranceAgreement: target.value }}))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pickUpLocation')} name='pickUpLocation'>
            <Input disabled />
          </Form.Item>
          <Form.Item label={t('shiftPlan')} name='shiftPlan' rules={[{ required: true }]}>
            <Radio.Group size='large' onChange={({ target }) => changeWorkScheduleHandler(target.value)}>
              {shiftPlans.map(item => (
                <Radio.Button key={item.id} value={item.id}>
                  {t(localeByValue[item.value])}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('splitTimes')} name='splitTimes' rules={[{ required: true }]}>
            <Radio.Group size='large' onChange={({ target }) => changeWorkScheduleHandler(target.value)}>
              {shifts.map(item => (
                <Radio.Button key={item.id} value={item.id}>
                  {t(localeByValue[item.value])}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t('workingTime')} name='workingTime' rules={[{ required: true }]}>
            <DatePicker.TimePicker
              allowClear={false}
              onChange={val => setCarData(prev => ({ ...prev, info: { ...prev.info, startTime: val!.toISOString() }}))}
              style={{ width: 160 }}
            />
          </Form.Item>
          <Form.Item label={t('maximumMileage')} name='maximumMileage' rules={[{ required: true }, { min: 0, max: CarConstants.MAX_MILAGE, type: 'number' }]}>
            <StyledNumberInput
              min={0}
              max={CarConstants.MAX_MILAGE}
              type='number'
              placeholder={t('maximumMileage_placeholder')}
              onChange={val => setCarData(prev => ({ ...prev, info: { ...prev.info, mileageMax: val as number }}))}
            />
          </Form.Item>
          <Form.Item
            label={t('driverPercentage')}
            name='driverPercentage'
            rules={[{ required: true, min: CarConstants.MIN_PERCENTAGE, max: CarConstants.MAX_PERCENTAGE, type: 'number' }]}
          >
            <InputNumber
              min={CarConstants.MIN_PERCENTAGE}
              max={CarConstants.MAX_PERCENTAGE}
              type='number'
              placeholder={t('driverPercentage_placeholder')}
              onChange={val => setCarData(prev => ({ ...prev, info: { ...prev.info, driverPercent: val as number }}))}
            />
          </Form.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default Edit;
