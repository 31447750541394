import { Form, Input } from 'antd';

import styled from 'styled-components';

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const AdvanceLimitInput = styled(Input)`
  margin-top: 24px;
  width: 140px;
`;
