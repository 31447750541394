import { Steps } from 'antd';

import styled from 'styled-components';

export const Title = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #070707;
  margin-bottom: 25px;
`;

export const StyledSteps = styled(Steps)`
  margin-bottom: 60px;

  @media (max-width: 980px) {
    margin-bottom: 24px;
  }
`;
