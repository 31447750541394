import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Note = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #888888;
  margin-bottom: 24px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 5px;

  svg {
    fill: #888888;
  }
`;

export const ResultWrapper = styled.div`
  width: 440px;
  height: 235px;
  background: #f5f5f5;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Result = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #070707;
  margin-bottom: 8px;
`;

export const ResultRecomend = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #070707;
`;
