import { FC, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppDispatch } from 'store';

import { Button, notification,Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { ReimbursementStatusEnum } from 'constants/global';
import { updateReimbursement } from 'modules/reimbursements';
import { IReimbursement } from 'modules/reimbursements/types';

type PropsType = {
  reimbursement: IReimbursement;
  fetchReimbursements: () => void;
};

const vatOptions: DefaultOptionType[] = [
  { value: 0, label: '0%' },
  { value: 6, label: '6%' },
  { value: 12, label: '12%' },
  { value: 25, label: '25%' },
];

const VatCell: FC<PropsType> = ({ reimbursement, fetchReimbursements }) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [vat, setVat] = useState<number>(reimbursement.reimbursementVat);
  const { t } = useTranslation('translation', { keyPrefix: 'reimbursements.notifications' });

  const onTextClick = () => {
    setIsEdit(true);
  };

  const onSubmit = async () => {
    setIsEdit(false);

    await dispatch(
      updateReimbursement({
        id: reimbursement.id,
        carId: reimbursement.carId,
        vat,
        amount: reimbursement.amount,
      })
    );

    const { error } = store.getState().cars;

    if (!error) {
      notification.success({
        placement: 'topRight',
        message: t('success'),
        description: t('vatUpdate'),
      });
    }

    fetchReimbursements();
  };

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const isCellUpdatable = isEdit && reimbursement.status === ReimbursementStatusEnum.PENDING && reimbursement.reimbursementType === 'other';

  return isCellUpdatable ? (
    <Select onKeyDown={onKeyPress} onBlur={onSubmit} onChange={setVat} options={vatOptions} value={vat} />
  ) : (
    <Button onClick={onTextClick} type='text'>
      {reimbursement.reimbursementVat}%
    </Button>
  );
};

export default VatCell;
