import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SalaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  .ant-table-wrapper:first-child {
    margin-bottom: 40px;
  }
`;

export const TableLabel = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 16px;
`;

export const SalaryFooter = styled.div`
  margin-top: 16px;
  text-align: end;

  .ant-btn:first-child {
    margin-right: 8px;
  }
`;

export const Details = styled.div`
  background-color: #fff;
  padding: 24px 16px;
  width: 100%;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
`;

export const MoreButton = styled(MoreOutlined)`
  padding: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;
