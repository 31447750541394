import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store';

import { Steps as STPS } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons';
import { getOptions } from 'modules/cars';
import { AddCarSteps } from 'modules/cars/enums';
import { ICreateCarData, IFiles } from 'modules/cars/types';
import Container from 'modules/common/components/Container';

import { FirstStep, FourthStep, SecondStep, ThirdStep } from '../../components';
import { BackButton, StyledSteps, Title } from './styled';

const { Step } = STPS;

const CAR_INITIAL_DATA = {
  managerId: null,
  registrationPlate: '',
  make: '',
  model: '',
  year: null,
  insuranceAgreement: '',
  insuranceExpireDate: null,
  shiftPlan: null,
  shiftSplitTimes: null,
  mileageMax: null,
  driverPercent: 0,
  startTime: null,
};

const FILES_INITIAL_DATA = {
  file1: [],
  file2: [],
  file3: [],
};

const AddCar: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.cars.steps' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [carData, setCarDate] = useState<ICreateCarData>(CAR_INITIAL_DATA);
  const [files, setFiles] = useState<IFiles>(FILES_INITIAL_DATA);
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  const markup = () => {
    switch (step) {
      case 0:
        return <FirstStep setStep={setStep} carData={carData} setCarData={setCarDate} />;
      case 1:
        return <SecondStep setStep={setStep} carData={carData} setCarData={setCarDate} files={files} setFiles={setFiles} />;
      case 2:
        return <ThirdStep setStep={setStep} carData={carData} setCarData={setCarDate} />;
      case 3:
        return <FourthStep setStep={setStep} carData={carData} setCarData={setCarDate} files={files} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <Title>{t('title')}</Title>
      <BackButton type='text' onClick={() => navigate('/cars')}>
        {t('backButtonText')} <ArrowRightOutlined />
      </BackButton>
      <StyledSteps progressDot current={step}>
        <Step title={t(`${AddCarSteps.FIRST}.title`)} />
        <Step title={t(`${AddCarSteps.SECOND}.title`)} />
        <Step title={t(`${AddCarSteps.THIRD}.title`)} />
        <Step title={t(`${AddCarSteps.FOURTH}.title`)} />
      </StyledSteps>

      {markup()}
    </Container>
  );
};

export default AddCar;
