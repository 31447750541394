// TODO refactor with the backend
export const localesByValue: { [key: string]: string } = {
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
  'full-time': 'fullTime',
  'part-time': 'partTime',
};

// TODO should be refactored with backend
export const carInfoLocalesByValue: { [key: string]: string } = {
  '1 shift per day': 'firstPlan',
  '2 shifts per day': 'secondPlan',
  '3 shifts per day': 'thirdPlan',
  '24 hours': 'fullDay',
  '12 hours': 'halfDay',
  '8 hours': 'thirdDay',
};

// TODO refactor with the backend
export const driverEditLocalesByValue: { [key: string]: string } = {
  'full-time': 'fullTime',
  'part-time': 'partTime',
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
};
