import { TableColumnsType } from 'antd';

import { IDriverShift, IDriverShiftTableData } from 'modules/driver/types';

import { generateDriverShiftsColumns } from './generateDriverShiftsColumns';
import { mapDriverShifts } from './mapDriverShifts';

interface IProps {
  driverShifts: IDriverShift[];
}

type ReturnType = [IDriverShiftTableData[], TableColumnsType<IDriverShiftTableData>];

export const useDriverShiftsTableData = (props: IProps): ReturnType => {
  const { driverShifts } = props;
  const mappedDriverShifts = mapDriverShifts(driverShifts);
  const tableColumns = generateDriverShiftsColumns();

  return [mappedDriverShifts, tableColumns];
};
