import { Empty, Row } from 'antd';

import Loader from 'modules/common/components/Loader';
import styled from 'styled-components';

interface IIconWrapperProps {
  background: string;
  iconColor: string;
}

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const Card = styled.div`
  padding: 24px;
  background: #ffffff;
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  height: 72px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #070707;
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
  width: 80px;
  height: 76px;
  margin-right: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => background};

  svg {
    width: 35px;
    height: 35px;
    fill: ${({ iconColor }) => iconColor};
  }
`;

export const StyledEmpty = styled(Empty)`
  flex: 1;
  padding-top: 100px;
`;

export const StyledLoader = styled(Loader)`
  padding-top: 150px;
`;
