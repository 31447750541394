import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { setSelectedCarForAgreement } from '../thunks/setSelectedCarForAgreement';

export const setSelectedCarForAgreementRequestReducer: ModuleExtraReducer<IRequestsState> = builder => {
  builder
    .addCase(setSelectedCarForAgreement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCarForAgreementRequest = action.payload;
    })
    .addCase(setSelectedCarForAgreement.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(setSelectedCarForAgreement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
