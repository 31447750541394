import { notification } from 'antd';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { config } from 'config';
import { t } from 'i18next';
import { IRequestError } from 'modules/analytics/types';
import { getAuthToken } from 'modules/auth';
import qs from 'qs';

const api = {
  private: axios.create({
    baseURL: config.BASE_URL,
  }),
  public: axios.create({
    baseURL: config.BASE_URL,
  }),
};

const beforePrivateRequest = (axiosConfig: AxiosRequestConfig) => {
  const AuthToken = getAuthToken();

  if (!AuthToken) window.location.replace('/sign-in');

  if (AuthToken) {
    axiosConfig.headers = { ...axiosConfig.headers, 'X-Auth': AuthToken };
  }

  axiosConfig.paramsSerializer = parameter => qs.stringify(parameter, { arrayFormat: 'repeat' });

  return axiosConfig;
};

const errorHandler = (err: AxiosError) => {
  if ((err.response?.data as IRequestError)?.errors?.length) {
    (err.response?.data as IRequestError).errors.map(error =>
      notification.error({
        message: t<string>('global.notifications.error.errorWord'),
        description: error.details,
        placement: 'topRight',
      })
    );
  } else {
    notification.error({
      message: t<string>('global.notifications.error.errorWord'),
      description: t<string>('global.notifications.error.serverError'),
      placement: 'topRight',
    });
  }

  return Promise.reject(err);
};

api.private.interceptors.request.use(beforePrivateRequest);
api.public.interceptors.response.use(response => response, errorHandler);
api.private.interceptors.response.use(response => response, errorHandler);

export default api;
