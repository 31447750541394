export default {
  declineModal: {
    title: 'Decline the request',
    decline: 'Decline',
    comment: 'Comment',
  },
  disputeModal: {
    title: 'Dispute the request',
    dispute: 'Dispute',
    comment: 'Comment',
    okay: 'Okay',
    successText:
      'Your dispute request has been sent to our support team. We will take a look at it and reach out to you with response by Email as soon as possible',
  },
  table: {},
  tableHeader: {
    selectedItems: 'Selected {{count}} items',
    pay: 'Pay with Swish',
    markAsPaid: 'Mark as paid',
    reimbursements: 'Reimbursements',
    advance: 'Advance',
    advanceMonthly: 'Advance monthly',
  },
  payModal: {
    submit: 'Submit',
    payment: 'Payment',
    items: 'Items',
  },
  notifications: {
    success: 'Success',
    carUpdate: 'Car was updated',
    amountUpdate: 'Amount was updated',
    vatUpdate: 'VAT was updated',
  },
};
