import { admin, dashboard,global, passwordActions, reimbursements, signIn, signUp, tables } from './sources';

export default {
  global,
  signUp,
  signIn,
  passwordActions,
  admin,
  tables,
  dashboard,
  reimbursements,
};
