import { FC, Key } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';

import { Col, Row, Tooltip } from 'antd';

import { CheckOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { SalaryStatus } from 'constants/global';
import { openPayoutModal, setSelectedPayoutsIds } from 'modules/salaries/slice';

import { IMonthlySalaryTableData } from '../../types/IMonthlySalaryTableData';
import { IWeeklySalaryTableData } from '../../types/IWeeklySalaryTableData';
import { StyledButton } from './styled';

type PropsType = {
  salaryData: IWeeklySalaryTableData | IMonthlySalaryTableData;
  markAsPaid: (paymentIds: Key[]) => Promise<void>;
};

const PayoutCell: FC<PropsType> = ({ salaryData, markAsPaid }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details' });
  const dispatch = useAppDispatch();

  const openModal = (): void => {
    dispatch(setSelectedPayoutsIds([salaryData.salaryId]));
    dispatch(openPayoutModal());
  };

  const markPaymentsAsPaid = async () => {
    await markAsPaid([salaryData.salaryId]);
  };

  return (
    <Row hidden={salaryData.status === SalaryStatus.PAID} justify='space-around'>
      <Col span={12}>
        <Tooltip title={t('payWithSwish')}>
          <StyledButton type='text' icon={<DollarCircleOutlined />} onClick={openModal} />
        </Tooltip>
      </Col>
      <Col span={12}>
        <Tooltip title={t('markAsPaid')}>
          <StyledButton type='text' icon={<CheckOutlined />} onClick={markPaymentsAsPaid} />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default PayoutCell;
