import { FC, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import { DivIcon } from 'leaflet';
import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';

import { ReactComponent as UserIcon } from './assets/user.svg';
import CarWithBadges from './components/CarWithBadges';
import { CarsCounter, ICarsGroup, IGroupedCars, IProps } from './types';

const userIcon = new DivIcon({ html: renderToString(<UserIcon />), iconSize: [80, 80]});

const carIcon = (carsGroup: ICarsGroup, selectedCar: ILinkCarTableData | null, highlightedCars: number[]) =>
  new DivIcon({
    html: renderToString(<CarWithBadges carsGroup={carsGroup} selectedCar={selectedCar} highlightedCars={highlightedCars} />),
    iconSize: [80, 80],
  });

const Map: FC<IProps> = ({ cars, user, selectedCar, setSelectedCar, highlightedCars, setHighlightedCars }) => {
  const center: [number, number] = useMemo(() => [user.address.location.latitude, user.address.location.longitude], [user]);

  const groupedCars = useMemo(() => {
    const counter = cars.reduce((acc: IGroupedCars, car: ILinkCarTableData) => {
      const carCoordinates = `${car.latitude},${car.longitude}`;

      if (acc[carCoordinates]) {
        acc[carCoordinates] = {
          ...acc[carCoordinates],
          [car.linked ? CarsCounter.LINKED : CarsCounter.UNLINKED]: acc[carCoordinates][car.linked ? CarsCounter.LINKED : CarsCounter.UNLINKED] + 1,
          carIDs: [...acc[carCoordinates].carIDs, car.id],
        };
      } else {
        acc[carCoordinates] = { linked: car.linked ? 1 : 0, unlinked: car.linked ? 0 : 1, latitude: car.latitude, longitude: car.longitude, carIDs: [car.id]};
      }

      return acc;
    }, {});

    return Object.values(counter);
  }, [cars]);

  const markerClickHandler = (group: ICarsGroup) => {
    setHighlightedCars(group.carIDs);
    setSelectedCar(null);
  };

  return (
    <MapContainer center={center} zoom={14} doubleClickZoom={false}>
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

      <Marker position={center} icon={userIcon} zIndexOffset={100000} />

      {groupedCars.map(group => (
        <Marker
          key={group.latitude}
          position={[group.latitude, group.longitude]}
          icon={carIcon(group, selectedCar, highlightedCars)}
          eventHandlers={{ click: () => markerClickHandler(group) }}
        />
      ))}
    </MapContainer>
  );
};

export default Map;
