import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd';

import { Globals } from 'constants/global';
import { DriverSteps } from 'modules/auth/enums';
import { IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';
import { DriverAccountOptionsEnum } from './enums';

const fieldsHandler = (driverData: ICreateDriverData) => [
  {
    name: 'drivingTerm',
    value: driverData.driverInfo.driverExperience || null,
  },
  {
    name: 'drivingPlatformExp',
    value: driverData.driverInfo.driverPlatform || null,
  },
  {
    name: 'drivingPlatformInterest',
    value: driverData.driverInfo.interestedPlatform || null,
  },
  {
    name: 'existingDrivingAcc',
    value: driverData.havingAccount || null,
  },
  {
    name: 'uberEmail',
    value: driverData.uberAccountInfo?.email || null,
  },
  {
    name: 'uberPhone',
    value: driverData.uberAccountInfo?.phone?.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
  {
    name: 'boltEmail',
    value: driverData.boltAccountInfo?.email || null,
  },
  {
    name: 'boltPhone',
    value: driverData.boltAccountInfo?.phone?.replaceAll(Globals.SWEDEN_COUNTRY_CODE, '') || null,
  },
];

type PlatformAccountInfoNamesType = 'uberAccountInfo' | 'boltAccountInfo';

type PropTypes = {
  setSelectedDriverAccount: Dispatch<SetStateAction<DriverAccountOptionsEnum>>;
  selectedDriverAccount: DriverAccountOptionsEnum;
};

const ThirdStep: FC<IDriverStepsProps & PropTypes> = ({ setStep, driverData, setDriverData, setSelectedDriverAccount, selectedDriverAccount }) => {
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.THIRD}` });
  const [form] = Form.useForm();
  const { platforms } = useAppSelector(state => state.auth.options);
  const [isUberPersonalData, setIsUberPersonalData] = useState(!!driverData.uberAccountInfo);
  const [isBoltPersonalData, setIsBoltPersonalData] = useState(!!driverData.boltAccountInfo);

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  const havingExistingAccountHandler = (value: DriverAccountOptionsEnum) => {
    setSelectedDriverAccount(value);

    setDriverData(prev => ({ ...prev, havingAccount: value }));
  };

  const isUberAvailable = selectedDriverAccount === DriverAccountOptionsEnum.UBER || selectedDriverAccount === DriverAccountOptionsEnum.BOTH;
  const isBoltAvailable = selectedDriverAccount === DriverAccountOptionsEnum.BOLT || selectedDriverAccount === DriverAccountOptionsEnum.BOTH;

  const changePlatformInfo = (dataName: PlatformAccountInfoNamesType) => (platformData: { email?: string; phone?: string }) => {
    setDriverData(data => ({ ...data, [dataName]: { ...data[dataName], ...platformData }}));
  };

  const cleanPlatformInfo = (dataName: PlatformAccountInfoNamesType) => () => {
    setDriverData(data => ({ ...data, [dataName]: undefined }));
  };

  const changeUber = useMemo(() => changePlatformInfo('uberAccountInfo'), []);
  const changeBolt = useMemo(() => changePlatformInfo('boltAccountInfo'), []);

  const cleanUber = useMemo(() => cleanPlatformInfo('uberAccountInfo'), []);
  const cleanBolt = useMemo(() => cleanPlatformInfo('boltAccountInfo'), []);

  const resetMutedFieldsByDrivenAccount = {
    [DriverAccountOptionsEnum.BOLT]: () => {
      cleanUber();
    },
    [DriverAccountOptionsEnum.UBER]: () => {
      cleanBolt();
    },
    [DriverAccountOptionsEnum.BOTH]: () => {},
    [DriverAccountOptionsEnum.NO]: () => {
      cleanUber();
      cleanBolt();
    },
  };

  useEffect(() => {
    resetMutedFieldsByDrivenAccount[selectedDriverAccount]();
  }, [selectedDriverAccount]);

  return (
    <StyledForm form={form} fields={fieldsHandler(driverData)} validateTrigger={['onChange', 'onBlur']} layout='vertical' requiredMark={false} size='large'>
      <Form.Item label={t('drivingTerm')} name='drivingTerm' rules={[{ required: true }]}>
        <Select
          placeholder={t('drivingTerm_placeholder')}
          onChange={val => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, driverExperience: val }}))}
        >
          <Select.Option value='0-1'>{`0-1 ${t('year')}`}</Select.Option>
          <Select.Option value='1-3'>{`1-3 ${t('years')}`}</Select.Option>
          <Select.Option value='3-6'>{`3-6 ${t('years')}`}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label={t('drivingPlatformExp')} name='drivingPlatformExp' rules={[{ required: true }]}>
        <Select
          mode='multiple'
          placeholder={t('drivingPlatformExp_placeholder')}
          onChange={val => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, driverPlatform: val }}))}
        >
          {platforms.map(item => (
            <Select.Option value={item.value} key={item.id}>
              {item.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={t('drivingPlatformInterest')} name='drivingPlatformInterest' rules={[{ required: true }]}>
        <Radio.Group onChange={({ target }) => setDriverData(prev => ({ ...prev, driverInfo: { ...prev.driverInfo, interestedPlatform: target.value }}))}>
          <Radio.Button value='uber'>Uber</Radio.Button>
          <Radio.Button value='bolt'>Bolt</Radio.Button>
          <Radio.Button value='both'>{t('both')}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('existingDrivingAcc')} name='existingDrivingAcc' rules={[{ required: true, message: t('existingDrivingAcc_message') }]}>
        <Radio.Group value={selectedDriverAccount} onChange={({ target }) => havingExistingAccountHandler(target.value)}>
          <Radio.Button value={DriverAccountOptionsEnum.UBER}>Uber</Radio.Button>
          <Radio.Button value={DriverAccountOptionsEnum.BOLT}>Bolt</Radio.Button>
          <Radio.Button value={DriverAccountOptionsEnum.BOTH}>{t('both')}</Radio.Button>
          <Radio.Button value={DriverAccountOptionsEnum.NO}>{t('no')}</Radio.Button>
        </Radio.Group>
      </Form.Item>

      {selectedDriverAccount !== DriverAccountOptionsEnum.NO && (
        <>
          <p>{t('samePersonalData')} </p>
          <Row>
            {isUberAvailable && (
              <Col span={24}>
                <Checkbox
                  checked={isUberPersonalData}
                  onChange={({ target: { checked }}) => {
                    if (!checked) cleanUber();
                    setIsUberPersonalData(checked);
                  }}
                >
                  {t('useUberData')}
                </Checkbox>
                {isUberPersonalData && (
                  <>
                    <Form.Item label={t('email')} name='uberEmail' rules={[{ required: true, type: 'email' }]}>
                      <Input placeholder={t('email_placeholder')} onChange={({ target: { value }}) => changeUber({ email: value })} />
                    </Form.Item>
                    <Form.Item
                      label={t('phone')}
                      name='uberPhone'
                      rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
                    >
                      <Input
                        type='number'
                        placeholder={t('phone_placeholder')}
                        prefix={Globals.SWEDEN_COUNTRY_CODE}
                        onChange={({ target: { value }}) => changeUber({ phone: `${Globals.SWEDEN_COUNTRY_CODE}${value}` })}
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            )}
            {isBoltAvailable && (
              <Col span={24}>
                <Checkbox
                  checked={isBoltPersonalData}
                  onChange={({ target: { checked }}) => {
                    if (!checked) cleanBolt();
                    setIsBoltPersonalData(checked);
                  }}
                >
                  {t('useBoltData')}
                </Checkbox>
                {isBoltPersonalData && (
                  <>
                    <Form.Item label={t('email')} name='boltEmail' rules={[{ required: true, type: 'email' }]}>
                      <Input placeholder={t('email_placeholder')} onChange={({ target: { value }}) => changeBolt({ email: value })} />
                    </Form.Item>
                    <Form.Item
                      label={t('phone')}
                      name='boltPhone'
                      rules={[{ required: true, max: Globals.SWEDEN_MAX_NUMBER_LENGTH, min: Globals.SWEDEN_MIN_NUMBER_LENGTH }]}
                    >
                      <Input
                        type='number'
                        placeholder={t('phone_placeholder')}
                        prefix={Globals.SWEDEN_COUNTRY_CODE}
                        onChange={({ target: { value }}) => changeBolt({ phone: `${Globals.SWEDEN_COUNTRY_CODE}${value}` })}
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            )}
          </Row>
        </>
      )}

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default ThirdStep;
