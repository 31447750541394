import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const MoreButton = styled(MoreOutlined)`
  padding: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
`;
