import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { DateWrapper, StyledTag, Time } from 'modules/analytics/pages/Changelog/styled';

import { IChangelogTableData } from '../../types/IChangelogTableData';

export const generateChangelogColumns = (): TableColumnsType<IChangelogTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <DateWrapper>
          <p>{record.date}</p>
          <Time>{record.time}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('performer'),
      dataIndex: 'performer',
      key: 'performer',
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: value => <StyledTag color='default'>{value}</StyledTag>,
    },
    {
      title: t('object'),
      dataIndex: 'object',
      key: 'object',
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: t('reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: reason => (reason ? t(reason) : '-'),
    },
  ];
};
