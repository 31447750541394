import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { analyticsReducer as analytics } from 'modules/analytics';
import { authReducer as auth } from 'modules/auth';
import { carsReducer as cars } from 'modules/cars';
import { reimbursementsReducer as reimbursements } from 'modules/reimbursements';
import { requestsReducer as requests } from 'modules/requests';
import { salariesReducer as salaries } from 'modules/salaries';
import { usersReducer as users } from 'modules/user';

const rootReducer = combineReducers({ auth, requests, users, cars, analytics, salaries, reimbursements });

const setupStore = () => configureStore({ reducer: rootReducer });
export const store = setupStore();

// Redux hooks with types
type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof setupStore>;
type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
