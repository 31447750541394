import styled from 'styled-components';

export const Card = styled.div`
  padding: 24px;
  background: #ffffff;
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 40px;
`;

export const ChartTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #070707;
  margin-right: auto;
`;
