import { IDriver } from 'modules/driver/types';
import { IUsersState } from 'modules/user/types';
import { ModuleExtraReducer } from 'types/Module';

import { getDriverWeeklyReports } from '../thunks';

export const getDriverWeeklyReportsReducer: ModuleExtraReducer<IUsersState> = builder => {
  // Get driver weekly reports

  builder
    .addCase(getDriverWeeklyReports.fulfilled, (state, action) => {
      state.isLoading = false;

      // Todo: rework
      state.selectedUser = { ...(state.selectedUser as IDriver), weeklyReports: action.payload };
    })
    .addCase(getDriverWeeklyReports.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getDriverWeeklyReports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
