import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { TableColumnsType } from 'antd';

import { CheckOutlined } from '@ant-design/icons';
import { CarLinkModalLinkButton, Check } from 'modules/cars/components/CarLinkModal/styled';
import { localeByPlan } from 'modules/cars/locales';
import { ILinkCarTableData } from 'modules/cars/types/ILinkCarTableData';

export const generateCarLinkColumns = (setSelectedCar: Dispatch<SetStateAction<ILinkCarTableData | null>>): TableColumnsType<ILinkCarTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('plate'),
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: t('fulfillment'),
      dataIndex: 'fulfillment',
      key: 'fulfillment',
    },
    {
      title: t('shiftsPlan'),
      dataIndex: 'shiftPlan',
      key: 'shiftPlan',
      render: (plan: keyof typeof localeByPlan) => t(localeByPlan[plan]),
    },
    {
      title: t('shiftSplitTimes'),
      dataIndex: 'splitTimes',
      key: 'splitTimes',
      render: (plan: keyof typeof localeByPlan) => t(localeByPlan[plan]),
    },
    {
      key: 'link',
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.linked ? (
          <Check>
            <CheckOutlined />
          </Check>
        ) : (
          <CarLinkModalLinkButton disabled={record.selected} type='link' onClick={() => setSelectedCar(record)}>
            {t('goToLink')}
          </CarLinkModalLinkButton>
        ),
    },
  ];
};
