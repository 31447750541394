import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Tag, Tooltip } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';

import { config } from 'config';
import { AdvancedStatusesEnum, SwishStatusEnum } from 'constants/global';
import { format } from 'date-fns';
import { DatePicker } from 'modules/common/components';
import { IAdvance } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';
import { currencyServices } from 'services/currencyServices';
import { useSearchParams } from 'utils/searchParams';

import { DateWrapper, StatusMessageIcon, Time } from './styled';

const swishColorsByStatuses: { [key in SwishStatusEnum]: PresetColorType } = {
  [SwishStatusEnum.CREATED]: 'blue',
  [SwishStatusEnum.DEBITED]: 'blue',
  [SwishStatusEnum.ERROR]: 'red',
  [SwishStatusEnum.INITIATED]: 'blue',
  [SwishStatusEnum.PAID]: 'green',
  [SwishStatusEnum.PLANNED]: 'blue',
};

const advanceColorsByStatuses: { [key in AdvancedStatusesEnum]: PresetColorType } = {
  [AdvancedStatusesEnum.PAID]: 'green',
  [AdvancedStatusesEnum.ERROR]: 'red',
  [AdvancedStatusesEnum.DEBITED]: 'purple',
  [AdvancedStatusesEnum.PENDING]: 'yellow',
};

interface IProps {
  statusFilterValue: string[];
  payoutStatusFilterValue: string[];
}

export const useColumns = ({ statusFilterValue, payoutStatusFilterValue }: IProps): ColumnsType<IAdvance> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const drivers = useAppSelector(state => state.reimbursements.advanceListData?.filters?.drivers);
  const dateRange = useAppSelector(state => state.reimbursements.advanceListData?.dateRange);
  const { addSearchParams, getSearchParam } = useSearchParams();

  const driverIdsSearchParam = getSearchParam('driverNames') || '';
  const driverIds: string[] = driverIdsSearchParam ? JSON.parse(driverIdsSearchParam) : [];

  const selectedDateRange = getSearchParam('dateRange');
  const dateRangeFilterValue: RangeValue<string> = selectedDateRange && JSON.parse(selectedDateRange);

  const driverOptions: ColumnFilterItem[] = drivers?.map(driver => ({ text: `${driver.firstName} ${driver.lastName}`, value: driver.id })) || [];

  const filterRange: RangeValue<Date> =
    dateRangeFilterValue?.[0] && dateRangeFilterValue?.[1] ? [new Date(dateRangeFilterValue[0]), new Date(dateRangeFilterValue[1])] : null;

  const disableDate = (date: Date) => {
    if (dateRange?.start && date < new Date(dateRange.start)) return true;
    if (dateRange?.end && date > new Date(dateRange.end)) return true;
    return false;
  };

  const onDateChange = (dates: RangeValue<Date>) => {
    addSearchParams({ dateRange: JSON.stringify(dates) });
  };

  const statusFilterOptions: ColumnFilterItem[] = [
    {
      text: t('advancePaymentStatuses.debited'),
      value: AdvancedStatusesEnum.DEBITED,
    },
    {
      text: t('advancePaymentStatuses.error'),
      value: AdvancedStatusesEnum.ERROR,
    },
    {
      text: t('advancePaymentStatuses.paid'),
      value: AdvancedStatusesEnum.PAID,
    },
    {
      text: t('advancePaymentStatuses.pending'),
      value: AdvancedStatusesEnum.PENDING,
    },
  ];

  const swishStatusFilterOptions: ColumnFilterItem[] = [
    {
      text: t('swishStatuses.created'),
      value: SwishStatusEnum.CREATED,
    },
    {
      text: t('swishStatuses.debited'),
      value: SwishStatusEnum.DEBITED,
    },
    {
      text: t('swishStatuses.error'),
      value: SwishStatusEnum.ERROR,
    },
    {
      text: t('swishStatuses.initiated'),
      value: SwishStatusEnum.INITIATED,
    },
    {
      text: t('swishStatuses.paid'),
      value: SwishStatusEnum.PAID,
    },
    {
      text: t('swishStatuses.planned'),
      value: SwishStatusEnum.PLANNED,
    },
  ];

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      filteredValue: [],
      filtered: !!filterRange,
      filterDropdown: () => <DatePicker.RangePicker value={filterRange} onChange={onDateChange} disabledDate={disableDate} />,
      render: (_, rowData) => (
        <DateWrapper>
          <p>{format(new Date(rowData.createdAt), config.DATE_FORMAT)}</p>
          <Time>{format(new Date(rowData.createdAt), config.TIME_FORMAT)}</Time>
        </DateWrapper>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      filters: driverOptions,
      filteredValue: driverIds,
      render: (_, rowData) => rowData.driverName,
    },

    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filteredValue: statusFilterValue,
      filters: statusFilterOptions,
      render: (_, record) => <Tag color={advanceColorsByStatuses[record.status]}>{t(`advancePaymentStatuses.${record.status}`)}</Tag>,
    },
    {
      title: t('swishStatus'),
      dataIndex: 'swishStatus',
      key: 'swishStatus',
      filteredValue: payoutStatusFilterValue,
      filters: swishStatusFilterOptions,
      render: (_, record) => {
        if (!record.payout) return ' ';

        return record.payout.errorMessage ? (
          <Tooltip title={record.payout.errorMessage}>
            <Tag color={swishColorsByStatuses[record.payout.status]}>{t(`swishStatuses.${record.payout.status}`)}</Tag>
            <StatusMessageIcon />
          </Tooltip>
        ) : (
          <Tag color={swishColorsByStatuses[record.payout.status]}>{t(`swishStatuses.${record.payout.status}`)}</Tag>
        );
      },
    },

    {
      title: t('requested'),
      dataIndex: 'requested',
      key: 'requested',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.requestedAmount),
    },
    {
      title: t('advanceUsed'),
      dataIndex: 'advancedUsed',
      key: 'advancedUsed',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.usedAmount),
    },
    {
      title: t('limit'),
      dataIndex: 'limit',
      key: 'limit',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.advanceLimit),
    },
    {
      title: t('availableBalance'),
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      render: (_, rowData) => currencyServices.convertToCurrency(rowData.availableBalance),
    },
  ];
};
