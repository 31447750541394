import { resetUser } from 'modules/user';

import { resetAuthToken } from './resetAuthToken';

// TODO should be refactored after user logic updated
export const signOut = () => {
  resetAuthToken();
  window.location.href = '/sign-in';
  resetUser();
};
