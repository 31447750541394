import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { config } from 'config';
import Endpoints from 'constants/endpoints';
import { Roles } from 'constants/global';
import { format } from 'date-fns';
import { THUNKS } from 'modules/salaries/enums';
import { IGetSalaryReport } from 'modules/salaries/types';
import { convertIntoISODate } from 'utils/index';

// Get salary report
export const getSalaryReport = createAsyncThunk(THUNKS.GET_SALARY_REPORT, async ({ role, selectedUserData, start, end }: IGetSalaryReport, thunkApi) => {
  try {
    const { id, companyName, lastName, firstName } = selectedUserData!;
    const isDriver = role === Roles.DRIVER;
    const url = isDriver ? Endpoints.SALARY_DRIVER_REPORT : Endpoints.SALARY_MANAGER_REPORT;
    const dateQuery = `start=${convertIntoISODate(start)}&end=${convertIntoISODate(end)}`;
    const idQuery = isDriver ? `driverId=${id}` : `managerId=${id}`;

    await api.private.get<Blob>(`${url}?${dateQuery}&${idQuery}`, { responseType: 'blob' }).then(res => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      const reportDate = format(start, config.DATE_FORMAT_MONTH).toUpperCase();
      const reportNameUserIndicator = isDriver ? `${firstName.slice(0, 1)}${lastName.slice(0, 1)}` : `${companyName?.slice(0, 5)}`;
      const reportName = isDriver ? `DR-${reportDate}-${reportNameUserIndicator}` : `FMR-${reportDate}-${reportNameUserIndicator.toUpperCase()}`;

      link.setAttribute('href', url);
      link.setAttribute('download', `${reportName}.pdf`);
      link.click();
      link.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    });

    return;
  } catch (e) {
    return thunkApi.rejectWithValue((e as Error).message);
  }
});
