import { Input } from 'antd';

import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledSearch = styled(Input)`
  width: 320px;
  margin-right: 16px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MoreButton = styled(MoreOutlined)`
  padding: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const PendingName = styled.p`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;
