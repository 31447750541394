import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import Endpoints from 'constants/endpoints';
import { THUNKS } from 'modules/salaries/enums';
import { IGetSalary, ISalary } from 'modules/salaries/types';
import { convertIntoISODate } from 'utils/index';

// Get salary
export const getSalary = createAsyncThunk(
  THUNKS.GET_SALARY,
  async ({ page, pageSize = 10, date, userName, status, payoutStatus, role, userId, period }: IGetSalary, thunkApi) => {
    try {
      const data = await api.private
        .get<{ data: ISalary }>(`${Endpoints.SALARY_WEEKLY}?${`start=${convertIntoISODate(date[0])}&end=${convertIntoISODate(date[1])}`}`, {
          params: { status, payoutStatus, userName, page, pageSize, role, userId, period },
        })
        .then(res => res.data.data);

      return data;
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
