import { AddCarSteps } from 'modules/cars/enums';

export default {
  salary: {
    searchPlaceholder: 'Search by name',
    downloadReport: 'Download payout report',
    submitSalary: 'Submit salary',
    addAdjustments: 'Add adjustments',
  },
  requests: {
    list: {
      drivers: 'Drivers',
      managers: 'Managers',
      cars: 'Cars',
      search_placeholder: 'Search',
      history: 'History',
      getDetails: 'Get details',
      approve: 'Approve',
      decline: 'Decline',
    },
    details: {
      driver: {
        edit: {
          firstName: 'First name',
          firstName_placeholder: 'Enter first name',
          lastName: 'Last name',
          lastName_placeholder: 'Enter last Name',
          email: 'Email',
          email_placeholder: 'Enter email',
          phone: 'Phone',
          boltAccountEmail: 'Bolt account Email',
          boltAccountPhone: 'Bolt account Phone',
          uberAccountEmail: 'Uber account Email',
          uberAccountPhone: 'Uber account Phone',
          phone_placeholder: 'Enter phone number',
          postalCode: 'Postal code',
          postalCode_placeholder: 'Enter postal code',
          postalCodeLengthMessage: 'Required 5 characters',
          city: 'City',
          city_placeholder: 'Enter city',
          street: 'Street',
          street_placeholder: 'Enter street',
          securityNumber: 'Social security number',
          securityNumber_placeholder: 'Enter social security number',
          taxiIDNumber: 'Taxi ID number',
          taxiIDNumber_placeholder: 'Enter taxi ID number',
          drivingTerm: 'How long have you been driving a taxi?',
          drivingTerm_placeholder: '0-1',
          drivingPlatformExp: 'What platform have you driven for?',
          drivingPlatformExp_placeholder: 'Uber',
          drivingPlatformInterest: 'What platforms are you interested in driving for?',
          existingDrivingAcc: 'Existing driver account?',
          existingDrivingAcc_message: "'Do you have an existing driver account...' is required",
          samePersonalData: 'Do you use the same personal data for it?',
          clearingNumber: 'Clearing number',
          clearingNumber_placeholder: 'Enter clearing number',
          bankAccNumber: 'Bank account number',
          bankAccNumber_placeholder: 'Enter bank account number',
          startTime: 'When do you want to start working?',
          daysAWeek: 'How many days a week do you want to drive?',
          workingTime: 'Are you interested in working time?',
          workTime: 'Do you want to work?',
          workDays: 'Which days of the week are you interested in driving?',
          workDays_placeholder: 'Monday',
          ownCar: 'Do you have your own car to get to workplace?',
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          yes: 'Yes',
          no: 'No',
          day: 'Day',
          night: 'Night',
          combined: 'Combined',
          fullTime: 'Full time',
          partTime: 'Part time',
          both: 'Both',
          lengthValidationMessage10_12: 'Length from 10 to 12 characters',
          wrongDigestError: 'Security number should include only numbers and dashes',
          custom: 'Custom',
          notAllowed: 'Not allowed',
          advanceLimit: 'Advance payment limit',
          sek: 'SEK',
        },
        requestComment: 'Further information or questions',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        save: 'Save',
        editData: 'Edit data',
        cancel: 'Cancel',
        driverWord: 'Driver',
        detailsWord: 'details',
        contactInfo: 'Contact info',
        username: 'Username',
        role: 'Role',
        email: 'Email',
        phone: 'Phone',
        postalCode: 'Postal code',
        city: 'City',
        street: 'Street',
        documents: 'Documents',
        socialSecurityNumber: 'Social security number',
        taxiIdNumber: 'Taxi ID number',
        taxiDriverLicense: 'Taxi Driver License',
        driverAccount: 'Driver account',
        drivingExperience: 'Driving experience',
        drivenFor: 'Driven for',
        interestedIn: 'Interested in',
        existingDriverAccount: 'Existing driver account',
        personalDataUber: 'Personal data Uber',
        personalDataBolt: 'Personal data Bolt',
        bank: 'Bank',
        clearingNumber: 'Clearing number',
        bankAccountNumber: 'Bank account number',
        car: 'Car',
        availability: 'Availability',
        yes: 'Yes',
        no: 'No',
        interestedInDriving: 'Interested in driving',
        workSchedule: 'Work schedule',
        startWorking: 'Start working',
        workingDays: 'Working days',
        workingTime: 'Working time',
        preference: 'Preference',
        days: 'Days',
        info: 'Further information or questions',
        decline: 'Decline',
        approve: 'Approve',
        day: 'Day',
        night: 'Night',
        combined: 'Combined',
        fullTime: 'Full time',
        partTime: 'Part time',
        both: 'Both',
        bolt: 'Bolt',
        uber: 'Uber',
        advanceLimit: 'Advance payment limit',
        agreement: 'Agreement',
        vilotidbokNumber: 'Vilotidbok number',
        registrationPlate: 'Car plate',
        carMake: 'Car make',
        carModel: 'Car model',
        createdAt: 'Created at',
        roadbirdSignedAt: 'Roadbird signed at',
        driverSignedAt: 'Driver signed at',
        downloadAgreement: 'Download agreement',
        sendAgreement: 'Send agreement',

        agreementModal: {
          modalTitle: 'Link driver with first car',
          modalNote: 'To create an agreement assign a car from the list to the driver',
          submitTitle: 'Create & Send agreement',
          carSearch_placeholder: 'Search by registration plate, make or model',
          noActiveCarsAvailable: 'No active cars available',
          currentlySelectedCar: 'This car is currently selected',
        },
      },
      manager: {
        edit: {
          firstName: 'First name',
          firstName_placeholder: 'Enter first name',
          lastName: 'Last name',
          lastName_placeholder: 'Enter last Name',
          email: 'Email',
          email_placeholder: 'Enter email',
          phone: 'Phone',
          phone_placeholder: 'Enter phone number',
          postalCode: 'Postal code',
          postalCode_placeholder: 'Enter postal code',
          postalCodeLengthMessage: 'Required 5 characters',
          city: 'City',
          city_placeholder: 'Enter city',
          street: 'Street',
          street_placeholder: 'Enter street',
          companyNumber: 'Company registration number',
          companyNumber_placeholder: 'Enter company registration number',
          securityNumber: 'Social security number',
          securityNumber_placeholder: 'Enter social security number',
          companyName: 'Company Name',
          companyName_placeholder: 'Enter company Name',
          companyAdress: 'Company address if different from above',
          companyAdress_placeholder: 'Enter company address',
          redovisnigscentral: 'Accounting center',
          redovisnigscentral_placeholder: 'Halda',
          companyPostalCode: 'Company postal code',
          companyCity: 'Company city',
          companyStreet: 'Company street',
          lengthValidationMessage10_12: 'Length from 10 to 12 characters',
          wrongDigestError: 'Security number should include only numbers and dashes',
          swishNumber: 'Swish ID',
          swishNumberPlaceholder: 'Enter Swish ID',
        },
        swishNumber: 'Swish ID',
        fleetManager: 'Flotta chef',
        save: 'Save',
        editData: 'Edit data',
        cancel: 'Cancel',
        managerWord: 'Fleet manager',
        detailsWord: 'details',
        contactInfo: 'Contact info',
        username: 'Username',
        role: 'Role',
        email: 'Email',
        phone: 'Phone',
        postalCode: 'Postal code',
        city: 'City',
        street: 'Street',
        documents: 'Documents',
        socialSecurityNumber: 'Social security number',
        taxiIdNumber: 'Taxi ID number',
        companyAccount: 'Company account',
        registrationNumber: 'Registration number',
        companyName: 'Company name',
        address: 'Address',
        redovisnigscentral: 'Accounting center',
        decline: 'Decline',
        approve: 'Approve',
      },
      car: {
        edit: {
          plate: 'Registration Plate',
          make: 'Make',
          model: 'Model',
          year: 'Year',
          insuranceNumber: 'Fill the car insurance number',
          shiftPlan: 'Shift plan',
          splitTimes: 'Shift split times',
          workingTime: 'Start time',
          driverPercentage: 'Driver percentage from Net Earnings',
          maximumMileage: 'Maximum mileage for specified car',
          pickUpLocation: 'Pick up and drop location',
          year_placeholder: '2009',
          plate_placeholder: 'Enter plate',
          make_placeholder: 'Enter make',
          model_placeholder: 'Tesla 3',
          insuranceNumber_placeholder: 'Enter car insurance number',
          maximumMileage_placeholder: 'Enter maximum mileage',
          driverPercentage_placeholder: 'Enter driver percentage',
          firstPlan: '1 shift per day',
          secondPlan: '2 shifts per day',
          thirdPlan: '3 shifts per day',
          fullDay: '24 hours',
          halfDay: '12 hours',
          thirdDay: '8 hours',
        },
        carWord: 'Car',
        save: 'Save',
        detailsWord: 'details',
        plate: 'Registration Plate',
        editData: 'Edit data',
        cancel: 'Cancel',
        make: 'Make',
        model: 'Model',
        year: 'Year',
        insurance: 'Car insurance agreement',
        shiftPlan: 'Shift plan',
        splitTimes: 'Shift split times',
        percentage: 'Driver percentage from Net Earnings',
        maxMill: 'Maximum mileage',
        address: 'Current address of the car',
        approve: 'Approve',
        decline: 'Decline',
        firstPlan: '1 shift per day',
        secondPlan: '2 shifts per day',
        thirdPlan: '3 shifts per day',
        fullDay: '24 hours',
        halfDay: '12 hours',
        thirdDay: '8 hours',
      },
    },
    declineModal: {
      decline: 'Decline',
      choseReason: 'Choose reason',
      choseReason_placeholder: 'Bad bank history',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
      note_part1: 'We will send email to',
      note_part2: 'with information about this action',
      driverRequestDeclineReasons_1: 'Absence of Taxi license',
      driverRequestDeclineReasons_2: 'Bad criminal record or repeated misconduct',
      driverRequestDeclineReasons_3: 'Bad credit history',
      fleetManagerRequestDeclineReasons_1: 'Absence of Taxi license',
      fleetManagerRequestDeclineReasons_2: 'Company data is invalid',
      carRequestDeclineReasons_1: 'Absence of some documents',
      carRequestDeclineReasons_2: 'Necessity of repair',
    },
    approveModal: {
      approve: 'Approve',
      note_part1: 'Please choose the fleet manager with whom',
      note_part2: 'would like to work',
      map: 'Map',
      list: 'List',
      search_placeholder: 'Search',
      manager: 'Fleet manager',
      driver: 'Driver',
      email: 'Email',
      phone: 'Phone',
      postalCode: 'Postal code',
      city: 'City',
      street: 'Street',
    },
  },
  users: {
    list: {
      all: 'All',
      pending: 'Pending',
      active: 'Active',
      disabled: 'Disabled',
      search_placeholder: 'Search',
      getDetails: 'Get details',
      linkWithACar: 'Link with a car',
      disable: 'Disable',
      activate: 'Activate',
      createNotification: 'Create notification',
    },
    details: {
      success: 'Success',
      vat: 'VAT',
      deductableVat: 'Deductible VAT',
      estimatedVat: 'Estimated VAT',
      vatTooltipText: 'Estimated data. Based on Fleet manager VAT%',
      dispute: 'Dispute',
      decline: 'Decline',
      error: 'Error',
      pay: 'Pay',
      monthly: 'Monthly',
      weekly: 'Weekly',
      selectedItems: 'Selected {{count}} items',
      payWithSwish: 'Pay with Swish',
      submitSalary: 'Submit salary',
      save: 'Save',
      cancel: 'Cancel',
      searchByName: 'Search by name',
      searchPlaceholder: 'Search by registration plate',
      weeklyReports: 'Weekly reports',
      contactInfo: 'Contact information',
      cars: 'Cars',
      finalPayment: 'Final monthly payment',
      addAdjustments: 'Add adjustments',
      submitTheSalary: 'Submit the salary',
      shifts: 'Shifts',
      linkWithCars: 'Link with cars',
      createNotification: 'Create notification',
      disable: 'Disable',
      edit: 'Edit info',
      markAsPaid: 'Mark as paid',
      getDetails: 'Get details',
      chooseRoadbirdFee: 'Choose RoadBird Fee',
      chooseTaxTable: 'Choose tax table',
      changelog: 'Notifications sent',
      salary: 'Salary',
      activate: 'Activate',
      earnings: 'Earnings',
      header: {
        salary: 'Salary',
        revenue: 'Revenue',
        reimbursements: 'Reimbursements',
        report: 'Report',
      },
      detailsModal: { title: 'Details' },
      errorsModal: {
        okay: 'Okay',
      },
      payoutModal: {
        weeklyPayment: 'Weekly payment',
        monthlyPayment: 'Monthly payment',
        items: 'Items',
        total: 'Total',
        submit: 'Submit payment',
        successText: 'All payments were successfully completed',
        errorText: 'Some payments were failed.',
        okay: 'Okay',
        success: 'Success',
      },
      addAdjustmentsModal: {
        platform: 'Platform',
        platform_placeholder: 'Uber',
        car: 'Car',
        car_placeholder: 'Tesla 3',
        addAdjustments: 'Add adjustments',
        note: 'Provide all additional information on salary. It helps to control your payouts directly.',
        place: 'Choose type',
        place_placeholder: 'Earnings',
        period: 'Choose period',
        period_placeholder: '28.05 - 05.06',
        amount: 'Amount',
        amount_placeholder: 'Enter amount',
        comment: 'Comment',
        comment_placeholder: 'Enter comment',
        submit: 'Submit',
        sek: 'SEK',
        earningAdjustment: 'Earning adjustment',
        provisionAdjustment: 'Provision adjustment',
        finalAdjustment: 'Final adjustment',
        finalAdjustmentTitle: 'Add adjustment to final salary',
        finalNote: 'Provide all additional information on adjustment. It helps to control your payouts directly.',
        finalAdjustmentPlace: 'Adjustment type',
        isZeroAmountValidationMessage: 'Amount should not equal 0',
      },

      addAdvance: 'Add advance',

      addAdvanceModal: {
        addAdvance: 'Add advance',
        submit: 'Submit',
        amount: 'Amount',
        amount_placeholder: 'Enter amount',
      },

      feeModal: {
        title: 'Choose RoadBird fee',
        amount: 'Choose amount',
        submit: 'Submit',
        amount_placeholder: 'Enter amount',
        note: 'Provide all additional information on salary. It helps to control your payouts directly.',
      },
      taxTableModal: {
        title: 'Choose Tax Table',
        type: 'Choose type',
        submit: 'Submit',
        type_placeholder: 'Enter type',
        note: 'Provide all additional information on salary. It helps to control your payouts directly.',
      },
      driver: {
        uberDriverUuid: 'Uber driver UUID',
        boltDriverPhone: 'Bolt driver phone',
        username: 'Username',
        role: 'Role',
        email: 'Email',
        phone: 'Phone',
        postalCode: 'Postal code',
        city: 'City',
        street: 'Street',
        socialSecurityNumber: 'Social security number',
        taxiIdNumber: 'Taxi ID number',
        fleetManagersLinked: 'Fleet managers linked',
        carsLinked: 'Number of cars linked',
        platformUsedToDrive: 'Platform used to drive',
        clearingNumber: 'Clearing number',
        noManagers: 'No managers',
        bankAccountNumber: 'Bank account number',
        day: 'Day',
        night: 'Night',
        combined: 'Combined',
        advanceLimit: 'Advance payment limit',
        edit: {
          uberDriverUuid: 'Uber driver UUID',
          boltDriverPhone: 'Bolt driver phone',
          firstName: 'First name',
          firstName_placeholder: 'Enter first name',
          lastName: 'Last name',
          lastName_placeholder: 'Enter last Name',
          email: 'Email',
          email_placeholder: 'Enter email',
          phone: 'Phone',
          boltAccountEmail: 'Bolt account Email',
          boltAccountPhone: 'Bolt account Phone',
          uberAccountEmail: 'Uber account Email',
          uberAccountPhone: 'Uber account Phone',
          phone_placeholder: 'Enter phone number',
          postalCode: 'Postal code',
          postalCode_placeholder: 'Enter postal code',
          postalCodeLengthMessage: 'Required 5 characters',
          city: 'City',
          city_placeholder: 'Enter city',
          street: 'Street',
          street_placeholder: 'Enter street',
          securityNumber: 'Social security number',
          securityNumber_placeholder: 'Enter social security number',
          clearingNumber: 'Clearing number',
          clearingNumber_placeholder: 'Enter clearing number',
          bankAccNumber: 'Bank account number',
          bankAccNumber_placeholder: 'Enter bank account number',
          taxiIDNumber: 'Taxi ID number',
          taxiIDNumber_placeholder: 'Enter taxi ID number',
          drivingPlatformExp: 'What platform have you driven for?',
          yes: 'Yes',
          no: 'No',
          lengthValidationMessage10_12: 'Length from 10 to 12 characters',
          wrongDigestError: 'Security number should include only numbers and dashes',
          custom: 'Custom',
          notAllowed: 'Not allowed',
          advanceLimit: 'Advance payment limit',
          sek: 'SEK',
        },

        roadbirdSignedAt: 'Roadbird signed at',
        vilotidbokNumber: 'Vilotidbok number',
        driverSignedAt: 'Driver signed at',
      },
      manager: {
        username: 'Username',
        role: 'Role',
        email: 'Email',
        phone: 'Phone',
        postalCode: 'Postal code',
        city: 'City',
        street: 'Street',
        socialSecurityNumber: 'Social security number',
        taxiIdNumber: 'Taxi ID number',
        companyRegNumber: 'Company registration number',
        companyName: 'Company Name',
        accountingCenter: 'Accounting center',
        companyAddress: 'Company address',
        carsNumber: 'Number of cars added',
        driversNumber: 'Number of drivers linked',
        swishNumber: 'Swish ID',
        roles: {
          driver: 'Driver',
          manager: 'Fleet manager',
        },
        edit: {
          swishNumber: 'Swish ID',
          swishNumberPlaceholder: 'Enter Swish ID',
          firstName: 'First name',
          firstName_placeholder: 'Enter first name',
          lastName: 'Last name',
          lastName_placeholder: 'Enter last Name',
          email: 'Email',
          email_placeholder: 'Enter email',
          phone: 'Phone',
          boltAccountEmail: 'Bolt account Email',
          boltAccountPhone: 'Bolt account Phone',
          uberAccountEmail: 'Uber account Email',
          uberAccountPhone: 'Uber account Phone',
          phone_placeholder: 'Enter phone number',
          postalCode: 'Postal code',
          postalCode_placeholder: 'Enter postal code',
          postalCodeLengthMessage: 'Required 5 characters',
          city: 'City',
          city_placeholder: 'Enter city',
          street: 'Street',
          street_placeholder: 'Enter street',
          companyNumber: 'Company registration number',
          companyNumber_placeholder: 'Enter company registration number',
          companyName: 'Company Name',
          companyName_placeholder: 'Enter company Name',
          companyPostalCode: 'Company postal code',
          companyCity: 'Company city',
          companyStreet: 'Company street',
          redovisnigscentral: 'Accounting center',
          redovisnigscentral_placeholder: 'Halda',
          securityNumber: 'Social security number',
          securityNumber_placeholder: 'Enter social security number',
          lengthValidationMessage10_12: 'Length from 10 to 12 characters',
          wrongDigestError: 'Security number should include only numbers and dashes',
        },
      },

      downloadAgreement: 'Download agreement',
      noActions: 'No actions available',
    },
    disableUserModal: {
      disable: 'Disable',

      activate: 'Activate',

      disableUser: 'Disable user',
      note: 'We will send email to fleet manager with information about this action.',
      chooseReason: 'Choose reason',
      chooseReason_placeholder: 'Select disable reason',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
      driverDisableByAdminReasons_1: 'More than 10 shifts missed',
      driverDisableByAdminReasons_2: 'Breach of agreement',
      driverDisableByAdminReasons_3: 'No activity for longer than 45 days',
      driverDisableByAdminReasons_4: 'Driver initiated the end of the agreement',
      driverDisableByManagerReasons_1: 'Efficiency less than 70%',
      driverDisableByManagerReasons_2: 'More than 10 shifts missed',
      driverDisableByManagerReasons_3: 'No activity for longer than 45 days',
      fleetManagerDisableReasons_1: 'Breach of agreement',
      fleetManagerDisableReasons_2: 'No cars added for longer than 45 days',
      fleetManagerDisableReasons_3: 'Fleet manager initiated the end of the agreement',
    },
    activateUserModal: {
      activate: 'Activate',
      activateUser: 'Activate user',
      note: 'We will send email to fleet manager with information about this action.',
      chooseReason: 'Choose reason',
      chooseReason_placeholder: 'Select activate reason',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
      fleetManagerActivateReasons_1: 'Contract renewal',
      fleetManagerActivateReasons_2: 'Pending review',
      driverActivateReasons_1: 'Contract renewal',
      driverActivateReasons_2: 'Pending review',
    },
    createNotificationModal: {
      createNotification: 'Create notification to',
      create: 'Create',
      note: 'We will send email to driver with information about this action.',
      typeOfAlert: 'Type of alert',
      typeOfAlert_placeholder: 'Error',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
    },
  },
  cars: {
    calculator: {
      shift: 'shift',
      title: 'Estimated car earnings per week',
      note: 'This is only estimated earnings based on our statistics and actual earnings results may vary based on driver performance.',
      shiftSetting: 'Shifts Setting',
      driverProvicion: 'Driver provision',
      sek: 'SEK',
      recomendShifts: 'We recommend using 2 shifts schedule for optimal earnings.',
      confirm: 'Confirm',
      recomendPercent: 'You are paying average market rate to a driver.',
      lowDriverPercent: 'The rate you want to pay is too low.',
      highDriverPercent: 'The rate you want to pay is too high, you may not have any profit.',
    },
    details: {
      save: 'Save',
      cancel: 'Cancel',
      plate: 'Registration Plate',
      make: 'Make',
      model: 'Model',
      year: 'Year',
      insuranceAggrement: 'Car insurance agreement',
      shiftPlan: 'Shift plan',
      shiftSplitTimes: 'Shift split times',
      driverPercent: 'Driver percentage from Net Earnings',
      maxMill: 'Maximum mileage',
      location: 'Pick up and drop location',
      regCertificate: 'Registration certificate',
      carInsurance: 'Car insurance',
      car: 'Car',
      drivers: 'Drivers',
      searchPlaceholder: 'Search by registration plate',
      weeklyReports: 'Weekly reports',
      contactInfo: 'Contact information',
      shifts: 'Shifts',
      disable: 'Disable',
      createNotification: 'Create notification',
      edit: 'Edit info',
      activate: 'Activate',
      firstPlan: '1 shift per day',
      secondPlan: '2 shifts per day',
      thirdPlan: '3 shifts per day',
      fullDay: '24 hours',
      halfDay: '12 hours',
      thirdDay: '8 hours',
      day: 'Day',
      night: 'Night',
      combined: 'Combined',
      fullTime: 'Full time',
      partTime: 'Part time',
    },
    steps: {
      backButtonText: 'Back to admin panel',
      title: 'Fill out the form',
      [AddCarSteps.FIRST]: {
        title: 'Car information',
        backButton: 'Back',
        nextButton: 'Next',
        chooseFleetManager: 'Choose fleet manager',
        chooseFleetManager_placeholder: 'Velam Lagerlöf',
        plate: 'Registration plate',
        plate_placeholder: 'Enter plate',
        make: 'Make',
        make_placeholder: 'Enter make',
        model: 'Model',
        model_placeholder: 'Tesla 3',
        year: 'Year',
        year_placeholder: '2009',
      },
      [AddCarSteps.SECOND]: {
        title: 'Documents',
        backButton: 'Back',
        nextButton: 'Next',
        upload: 'Upload',
        registrationNumber: 'Fill the registration number',
        registrationNumber_placeholder: 'Enter registration number',
        registrationCertificate: 'Upload Picture of registration certificate',
        registrationCertificate_label: 'Add please pictures of Frontside of registration certificate',
        insuranceNumber: 'Fill the car insurance number',
        insuranceNumber_placeholder: 'Enter car insurance number',
        insuranceExpire: 'Insurance expire date',
        insuranceAgreement: 'Upload Picture or PDF of car insurance agreement',
        insuranceAgreement_label: 'Add please picture or PDF file.',
        carPictures: 'Upload 3 Pictures of the car',
        carPictures_label: 'Add please picture with Front, Back side and Inside Backseat.',
        frontSide: 'Front side',
        backSide: 'Back side',
        backseat: 'Inside Backseat',
        modalTitle: 'Templates of Photos',
        modalSubtitle: "Below, you can look at sample of photos to be uploaded to our system. Don't worry if you don't get a one-to-one",
        seeTemplates: 'See templates',
      },
      [AddCarSteps.THIRD]: {
        title: 'Shifts',
        backButton: 'Back',
        nextButton: 'Next',
        shiftPlan: 'Shift plan',
        workingTime: 'Start time',
        splitTimes: 'Shift split times',
        firstPlan: '1 shift per day',
        secondPlan: '2 shifts per day',
        thirdPlan: '3 shifts per day',
        fullDay: '24 hours',
        halfDay: '12 hours',
        thirdDay: '8 hours',
      },
      [AddCarSteps.FOURTH]: {
        title: 'Additional',
        backButton: 'Back',
        nextButton: 'Next',
        driverPercentage: 'Driver percentage from Net Earnings',
        driverPercentage_placeholder: 'Enter driver percentage',
        maximumMileage: 'Maximum mileage for specified car',
        maximumMileage_placeholder: 'Enter maximum mileage',
        pickUpLocation: 'Pick up and drop location',
        pickUpLocation_placeholder: 'Enter location',
      },
    },
    list: {
      all: 'All',
      pending: 'Pending',
      active: 'Active',
      disabled: 'Disabled',
      search_placeholder: 'Search by plate',
      addCar: 'Add car',
      activate: 'Activate',
      disable: 'Disable',
    },
    linkCarModal: {
      linkWord: 'Link',
      withACar: 'with a car',
      searchPlaceholder: 'Search',
      driver: 'Driver',
      car: 'Car',
      startWorking: 'Start working',
      workingTime: 'Working time',
      days: 'Days',
      workingDays: 'Working days',
      preference: 'Preference',
      manufacturer: 'Manufacturer name',
      model: 'Model',
      year: 'Year',
      startTime: 'Start time',
      splitTimes: 'Shift split times',
      maxMill: 'Maximum mileage',
      summary: 'Summary',
      linkedWith: 'Linked with',
      notLinkedYet: 'Not linked yet',
      chooseTheCar: 'Please choose the car first on the map or from the list and then link with driver.',
      'full-time': 'Full time',
      'part-time': 'Part time',
      day: 'Day',
      night: 'Night',
      combined: 'Combined',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      hours: 'hours',
    },
    disableCarModal: {
      disable: 'Disable',
      disableCar: 'Disable car',
      note: 'We will send email to fleet manager with information about this action.',
      chooseReason: 'Choose reason',
      chooseReason_placeholder: 'Select disable reason',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
      carDisableReasons_1: 'Car accident',
      carDisableReasons_2: 'Necessity of repair',
    },
    activateCarModal: {
      activate: 'Activate',
      activateCar: 'Activate car',
      note: 'We will send email to fleet manager with information about this action.',
      chooseReason: 'Choose reason',
      chooseReason_placeholder: 'Select activate reason',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
      carActivateReasons_1: 'Damages fixed',
      carActivateReasons_2: 'Pending review',
    },
    createNotificationModal: {
      createNotification: 'Create notification to CAR',
      create: 'Create',
      note: 'We will send email to driver with information about this action.',
      typeOfAlert: 'Type of alert',
      typeOfAlert_placeholder: 'Error',
      comment: 'Comment',
      comment_placeholder: 'Enter comment',
    },
  },
  changelog: {
    all: 'All',
    userManagement: 'User management',
    performance: 'Performance',
    search_placeholder: 'Search by performer',
  },
  dashboard: {
    value: 'Value',
    format: 'YYYY-QQQ',
    revenue: 'Revenue mix, SEK',
    totalDriven: 'Total driven, km',
    driversPaid: 'Drivers paid',
    roadBirdRevenue: 'RoadBird Revenue',
    managerPaid: 'Fleet managers paid',
    hi: 'Hi',
    selectDate: 'Select date range to create report',
    perCarTitle: 'Earnings per car, SEK',
    listOfCars: 'The list of cars',
    noData: 'No data',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
    activeCars: 'Active cars',
    activeDrivers: 'Active drivers',
    activeManagers: 'Active fleet managers',
  },
  profile: {
    contactInfo: 'Contact information',
    changePassword: 'Change password',
    email: 'Email',
    phone: 'Phone',
    email_placeholder: 'Enter email',
    phone_placeholder: 'Enter phone',
    password_placeholder: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    save: 'Save',
    other: 'Other',
    becomeADriver: 'Become a driver',
    ifYouWant: 'if you want to get started, click on',
    theLink: 'the link.',
  },
};
