import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { Table, TableProps } from 'antd';

import { config } from 'config';
import { endOfMonth, startOfMonth } from 'date-fns';
import { getAdvanceList } from 'modules/reimbursements';
import { PaymentsSearchParamsEnum } from 'modules/reimbursements/enums';
import { IAdvance } from 'modules/reimbursements/types';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSearchParams } from 'utils/searchParams';

import { useColumns } from './columns';

const Advanced: FC = () => {
  const dispatch = useAppDispatch();
  const { addSearchParams, getSearchParam } = useSearchParams();
  const { isLoading, advanceListData } = useAppSelector(state => state.reimbursements);

  const selectedMonthSearchParam = getSearchParam(PaymentsSearchParamsEnum.SELECTED_MONTH);
  const page = getSearchParam('page') || '1';
  const pageSize = getSearchParam('pageSize') || config.DEFAULT_PAGE_SIZE.toString();
  const driverIdsSearchParam = getSearchParam('driverNames') || '';
  const dateRangeSearchParam = getSearchParam('dateRange') || '';

  const dateRange: RangeValue<string> = dateRangeSearchParam && JSON.parse(dateRangeSearchParam);
  const selectedMonth = new Date(selectedMonthSearchParam || new Date());
  const driverIds = driverIdsSearchParam ? JSON.parse(driverIdsSearchParam) : [];

  const payoutStatusSearchParam = getSearchParam('swishStatus') || '';
  const payoutStatusFilterValue = payoutStatusSearchParam ? JSON.parse(payoutStatusSearchParam) : [];

  const statusSearchParam = getSearchParam('status') || '';
  const statusFilterValue = statusSearchParam ? JSON.parse(statusSearchParam) : [];

  useEffect(() => {
    dispatch(
      getAdvanceList({
        page: Number(page) - 1,
        pageSize: Number(pageSize),
        start: startOfMonth(selectedMonth),
        end: endOfMonth(selectedMonth),
        driverId: driverIds,
        periodStart: dateRange?.[0] || undefined,
        periodEnd: dateRange?.[1] || undefined,
        status: statusFilterValue,
        payoutStatus: payoutStatusFilterValue,
      })
    );
  }, [selectedMonthSearchParam, page, pageSize, driverIdsSearchParam, dateRangeSearchParam, statusSearchParam, payoutStatusSearchParam]);

  const columns = useColumns({ statusFilterValue, payoutStatusFilterValue });

  const onTableChange: TableProps<IAdvance>['onChange'] = ({ current, pageSize }, filters) => {
    addSearchParams({
      status: filters.status ? JSON.stringify(filters.status) : '',
      swishStatus: filters.swishStatus ? JSON.stringify(filters.swishStatus) : '',
      driverNames: filters.name ? JSON.stringify(filters.name) : '',
      pageSize: pageSize?.toString() || config.DEFAULT_PAGE_SIZE.toString(),
      page: current?.toString() || '0',
      date: filters.date ? JSON.stringify(filters.date) : '',
    });
  };

  const tableData = advanceListData?.items?.map(item => ({
    ...item,
    key: item.id,
  }));

  return (
    <Table
      pagination={{
        current: Number(page),
        total: advanceListData?.total?.total,
      }}
      onChange={onTableChange}
      dataSource={tableData}
      loading={isLoading}
      columns={columns}
    />
  );
};

export default Advanced;
