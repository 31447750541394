import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Input, Row } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

import { InboxOutlined } from '@ant-design/icons';
import { DriverSteps } from 'modules/auth/enums';
import { IDocumentsUseState, IDriverStepsProps } from 'modules/auth/types';
import { ICreateDriverData } from 'modules/driver/types';

import { StyledForm, StyledRow } from '../styled';
import { StyledDatePicker, StyledDragger } from './styled';

const fieldsHandler = (driverData: ICreateDriverData, documents: UploadFile[]) => [
  {
    name: 'securityNumber',
    value: driverData.socialSecurityNumber || null,
  },
  // {
  //   name: 'socialSecurityNumberExpireDate',
  //   // value: driverData.socialSecurityNumberExpireDate,
  // },
  {
    name: 'taxiIDNumber',
    value: driverData.taxiIdNumber || null,
  },
  {
    name: 'taxiIdNumberExpireDate',
    value: driverData.taxiLicenceExpireDate,
  },
  // TODO waiting for backend
  {
    name: 'uploadTaxi',
    value: { fileList: [documents[0], documents[1]].filter(file => !!file) },
  },
  {
    name: 'uploadDriver',
    value: { fileList: [documents[2], documents[3]].filter(file => !!file) },
  },
];

const SecondStep: FC<IDriverStepsProps & IDocumentsUseState> = ({
  setStep,
  driverData,
  setDriverData,
  taxiLicense,
  driverLicense,
  setDriverLicense,
  setTaxiLicense,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('translation', { keyPrefix: `signUp.driverRole.steps.${DriverSteps.SECOND}` });

  const nextStepHandler = async () => {
    await form.validateFields();
    setStep(prev => prev + 1);
  };

  return (
    <StyledForm
      form={form}
      // TODO documents should be separate fields
      fields={fieldsHandler(driverData, [...taxiLicense, ...driverLicense])}
      validateTrigger={['onChange', 'onBlur']}
      layout='vertical'
      requiredMark={false}
      size='large'
    >
      <Form.Item
        label={t('securityNumber')}
        name='securityNumber'
        rules={[
          {
            required: true,
            validator: (_, value: string) =>
              new Promise((resolve, reject) => {
                const textWithoutDashes = value.replace(/-/g, '');
                const { length } = textWithoutDashes;

                if (length >= 10 && length <= 12) resolve('');
                else reject(new Error());
              }),
            message: t('lengthValidationMessage10_12'),
          },
          {
            validator: (_, value: string) =>
              new Promise((resolve, reject) => {
                const textWithoutDashes = value.replace(/-|[0-9]/g, '');
                const { length } = textWithoutDashes;

                if (length === 0) resolve('');
                else reject(new Error());
              }),
            message: t('wrongDigestError'),
          },
        ]}
      >
        <Input
          placeholder={t('securityNumber_placeholder')}
          onChange={({ target }) => setDriverData(prev => ({ ...prev, socialSecurityNumber: target.value }))}
        />
      </Form.Item>
      <Input.Group>
        <Row justify='space-between' gutter={16}>
          <Col span={12}>
            <Form.Item label={t('taxiIDNumber')} name='taxiIDNumber' rules={[{ required: true, len: 6, message: t('lengthValidationMessage6') }]}>
              <Input
                type='number'
                placeholder={t('taxiIDNumber_placeholder')}
                onChange={({ target }) => setDriverData(prev => ({ ...prev, taxiIdNumber: target.value }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('expireDate')} name='taxiIdNumberExpireDate' rules={[{ required: true, message: t('validationMessage') }]}>
              <StyledDatePicker onChange={val => setDriverData(prev => ({ ...prev, taxiLicenceExpireDate: val }))} />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>

      <Form.Item
        label={t('uploadTaxi')}
        name='uploadTaxi'
        rules={[
          {
            validator: (_, value?: { fileList: RcFile[] }) =>
              new Promise((resolve, reject) => {
                if (!value?.fileList?.length || value.fileList?.length !== 2) reject(new Error(t('fileError')));
                resolve('');
              }),
          },
        ]}
      >
        <StyledDragger
          name='uploadTaxi'
          multiple
          beforeUpload={() => false}
          fileList={taxiLicense}
          onChange={val => setTaxiLicense(val.fileList)}
          accept='.jpg,.jpeg,.png'
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>{t('upload_title')}</p>
          <p className='ant-upload-hint'>{t('upload_subTitle')}</p>
        </StyledDragger>
      </Form.Item>

      <Form.Item
        label={t('uploadDriver')}
        name='uploadDriver'
        rules={[
          {
            validator: (_, value?: { fileList: RcFile[] }) =>
              new Promise((resolve, reject) => {
                if (!value?.fileList?.length || value.fileList?.length !== 2) reject(new Error(t('fileError')));
                resolve('');
              }),
          },
        ]}
      >
        <StyledDragger
          name='uploadDriver'
          multiple
          beforeUpload={() => false}
          fileList={driverLicense}
          onChange={val => setDriverLicense(val.fileList)}
          accept='.jpg,.jpeg,.png'
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>{t('upload_title')}</p>
          <p className='ant-upload-hint'>{t('upload_subTitle')}</p>
        </StyledDragger>
      </Form.Item>

      <StyledRow justify='space-between'>
        <Col>
          <Button onClick={() => setStep(prev => prev - 1)}>{t('backButton')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={nextStepHandler}>
            {t('nextButton')}
          </Button>
        </Col>
      </StyledRow>
    </StyledForm>
  );
};

export default SecondStep;
