import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { Col, Row } from 'antd';

import { PaymentTypeEnum } from 'modules/salaries/enums';
import { currencyServices } from 'services/currencyServices';

import { calculateMonthly } from './calculateMonthly';
import { calculateWeekly } from './calculateWeekly';

interface IProps {
  paymentType: PaymentTypeEnum;
}

const PayoutInfo: FC<IProps> = ({ paymentType }) => {
  const {
    salary,
    monthlyPayments,
    payoutModal: { selectedPayoutsIds },
  } = useAppSelector(state => state.salaries);

  const { t } = useTranslation('translation', { keyPrefix: 'admin.users.details.payoutModal' });
  const isWeeklyPayment = paymentType === PaymentTypeEnum.Weekly;

  const { total, count } = isWeeklyPayment
    ? calculateWeekly({ weeklyPayments: salary, selectedPayoutsIds })
    : calculateMonthly({ monthlyPayments, selectedPayoutsIds });

  return (
    <Row justify='space-between'>
      <Col span={8}>{count}</Col>
      <Col span={8}>{t('items')}</Col>
      <Col span={8}>{currencyServices.convertToCurrency(total)}</Col>
    </Row>
  );
};

export default PayoutInfo;
