import { IRequestsState } from 'modules/requests/types';
import { ModuleExtraReducer } from 'types/Module';

import { getHistoryRequest } from '../thunks';

export const getHistoryRequestReducer: ModuleExtraReducer<IRequestsState> = builder => {
  // Get history requests

  builder
    .addCase(getHistoryRequest.fulfilled, (state, action) => {
      state.requests.history = action.payload.items;
      state.total = action.payload.total;
      state.isLoading = false;
    })
    .addCase(getHistoryRequest.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getHistoryRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
};
