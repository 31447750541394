import { ICar, ICarTableData } from 'modules/cars/types';

export const mapCars = (rawCars: ICar[]): ICarTableData[] =>
  rawCars.map(car => ({
    key: car.info.id,
    id: car.info.id,
    plate: car.info.registrationPlate,
    status: car.info.status,
    address: `${car.info.address.street}, ${car.info.address.city}, ${car.info.address.postalCode}`,
    insurance: car.info.insuranceAgreement,
    make: car.info.make,
    model: car.info.model,
    manager: car.info.managerName,
    year: car.info.year,
    plan: car.info.shiftPlan.value,
    splitTime: car.info.shiftSplitTimes.value,
    maxMil: car.info.mileageMax,
    persent: car.info.driverPercent,
  }));
