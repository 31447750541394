import { useTranslation } from 'react-i18next';

import { TableColumnsType, Tag } from 'antd';

import { WeeklyReportsStatuses } from 'constants/global';
import { IDriverWeeklyReportTableData } from 'modules/driver/types';

export const generateDriverWeeklyReportsColumns = (): TableColumnsType<IDriverWeeklyReportTableData> => {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });

  return [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: value => (value ? <Tag color={value === WeeklyReportsStatuses.GOOD ? 'green' : 'red'}>{t(value)}</Tag> : '-'),
    },
    {
      title: t('earnings'),
      dataIndex: 'earnings',
      key: 'earnings',
    },
    {
      title: t('grade'),
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: t('efficiency'),
      dataIndex: 'efficiency',
      key: 'efficiency',
    },
    {
      title: t('fulfillment'),
      dataIndex: 'fulfillment',
      key: 'fulfillment',
    },
  ];
};
