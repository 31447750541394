import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { config } from 'config';
import Endpoints from 'constants/endpoints';
import { Entities } from 'constants/global';
import { RequestStatuses } from 'constants/requests';
import { THUNKS } from 'modules/requests/enums';
import { IGetRequestsRequest, IHistoryRequest, ITotal } from 'modules/requests/types';

interface IGetHistoryRequestsResponse {
  data: {
    items: IHistoryRequest[];
    total: ITotal;
  };
}

// Get history request
export const getHistoryRequest = createAsyncThunk(
  THUNKS.GET_HISTORY_REQUESTS,
  async ({ status = RequestStatuses.NEW, page, searchText, entities }: IGetRequestsRequest & { entities?: Entities[] }, thunkApi) => {
    try {
      return api.private
        .get<IGetHistoryRequestsResponse>(Endpoints.REQUEST_HISTORY, {
          params: { status, page, pageSize: config.DEFAULT_PAGE_SIZE, name: searchText, role: entities },
        })
        .then(res => res.data.data);
    } catch (e) {
      return thunkApi.rejectWithValue((e as Error).message);
    }
  }
);
